import Pusher from 'pusher-js';

export default defineNuxtPlugin(({ $config }) => {
  const pusher = new Pusher($config.public.pusherAppKey, $config.public.pusherOptions);

  return {
    provide: {
      websockets: {
        /**
         * Component usage:
         *
         * this.$websockets.subscribe('ChannelName').bind('EventName', callback);
         */
        subscribe(channelName) {
          return pusher.subscribe(channelName);
        },
        /**
         * Component usage:
         *
         * this.$websockets.unsubscribe('ChannelName');
         */
        unsubscribe(channelName) {
          try {
            pusher.unsubscribe(channelName);
          } catch (error) {}
        },
      },
    },
  };
});
