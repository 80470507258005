import { DatasetService } from '~/support/services';

import { SET_DATASET } from './mutation-types';

export default {
  async createSchedules(_, { datasetId, payload }) {
    const service = new DatasetService(useRuntimeConfig().public.datasetsBaseUrl);

    return await service.createSchedules(datasetId, payload);
  },

  async deleteDataset(_, datasetId) {
    const service = new DatasetService(useRuntimeConfig().public.datasetsBaseUrl);

    await service.deleteDataset(datasetId);
  },

  async deleteSchedules(_, { datasetId, type }) {
    const service = new DatasetService(useRuntimeConfig().public.datasetsBaseUrl);

    return await service.deleteSchedules(datasetId, type);
  },

  async fetchDataset({ commit }, datasetId) {
    const service = new DatasetService(useRuntimeConfig().public.datasetsBaseUrl);
    const data = await service.fetchOne(datasetId);

    commit(SET_DATASET, data);

    return data;
  },

  async fetchDatasets(_) {
    const service = new DatasetService(useRuntimeConfig().public.datasetsBaseUrl);
    const data = await service.fetchAll();

    return data;
  },

  async fetchFrames(_, datasetId) {
    const service = new DatasetService(useRuntimeConfig().public.datasetsBaseUrl);

    return await service.fetchFrames(datasetId);
  },

  async fetchHistory(_, datasetId) {
    const service = new DatasetService(useRuntimeConfig().public.datasetsBaseUrl);

    return await service.fetchHistory(datasetId);
  },

  async fetchInferredScheduleHeatmap(_, { datasetId, type, startDate, endDate }) {
    const service = new DatasetService(useRuntimeConfig().public.datasetsBaseUrl);

    return await service.fetchInferredScheduleHeatmap(datasetId, type, startDate, endDate);
  },

  async fetchSchedules(_, datasetId) {
    const service = new DatasetService(useRuntimeConfig().public.datasetsBaseUrl);

    return await service.fetchSchedules(datasetId);
  },

  async reRunPipeline(_, { datasetId, deliveryId }) {
    const service = new DatasetService(useRuntimeConfig().public.datasetsBaseUrl);
    const data = await service.reRunPipeline(datasetId, deliveryId);

    return data;
  },

  async updateDataset({ dispatch }, { datasetId, payload }) {
    const service = new DatasetService(useRuntimeConfig().public.datasetsBaseUrl);

    await service.updateDataset(datasetId, payload);

    return await dispatch('fetchDataset', datasetId);
  },

  async updateSchedules(_, { datasetId, payload }) {
    const service = new DatasetService(useRuntimeConfig().public.datasetsBaseUrl);

    return await service.updateSchedules(datasetId, payload);
  },
};
