export default () => {
  return {
    destinationBannerIsDismissed: false,
    freeBannerIsDismissed: false,
    isSidebarOpen: false,
    proActivationLimitBannerIsDismissed: false,
    scheduleBannerIsDismissed: false,
    selectedTimezone: '',
    showStudioRightPanel: true,
    trialBannerIsDismissed: false,
  };
};
