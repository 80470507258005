import VueMixpanel from 'vue-mixpanel';

export default defineNuxtPlugin(({ vueApp, $config }) => {
  vueApp.use(VueMixpanel, {
    config: {
      debug: true,
    },
    token: $config.public.mixpanelToken,
  });
});
