<template>
  <div
    v-click-outside="handleClickOutside"
    v-hubble="baseSelector"
    :data-value="modelValue"
    :data-name="selectedText"
    :class="{ loading }"
  >
    <div v-if="$slots.default || !!$slots.cornerHint" class="flex justify-between">
      <label
        :id="labelId"
        class="block font-medium text-gray-900 text-sm"
        :class="{ 'sr-only': hideLabel, 'mb-2': !hideLabel }"
      >
        <slot />
      </label>

      <span class="text-gray-500 text-sm">
        <slot name="cornerHint" />
      </span>
    </div>
    <button
      v-if="!$slots.trigger"
      ref="button"
      v-hubble="'toggle'"
      :disabled="disabled"
      class="border focus:outline-0 focus:ring-0 rounded-lg text-left transition w-full"
      :class="[
        { 'border-blue-600': open, 'pointer-events-none': loading },
        disabledClasses,
        sizeClasses,
        validityClasses,
        `bg-${bgColor}`,
      ]"
      type="button"
      @blur="triggerIsFocused = false"
      @click.prevent="toggleOpen"
      @focus="triggerIsFocused = true"
      @keydown.down.stop.prevent
      @keydown.enter.stop.prevent
      @keydown.esc.stop.prevent
      @keydown.space.stop.prevent
      @keydown.tab.shift.exact="handleTriggerBacktab"
      @keydown.up.stop.prevent
      @keyup.down.stop.prevent="handleTriggerDown"
      @keyup.esc.stop.prevent="handleEsc"
      @keyup.space.stop.prevent="handleTriggerSpace"
      @keyup.tab.shift.exact.stop.prevent
      @keyup.up.stop.prevent="handleTriggerUp"
    >
      <div v-if="loading" class="flex flex-1 items-center justify-center">
        <cx-spinner :size="spinnerSize" />
      </div>
      <span v-else class="flex items-center text-gray-500">
        <div v-if="!!$slots.prefix" class="flex items-center justify-center mr-4">
          <slot name="prefix" />
        </div>
        <span
          v-if="selectedText && !hideSelectedPreview"
          v-tooltip="{ content: selectedText, disabled: !!$slots.selectedText }"
          class="flex flex-1 truncate"
          :class="textClass"
        >
          <slot name="selectedText" v-bind="{ selectedOptions, deselect }">{{ selectedText }}</slot>
        </span>
        <span v-else class="flex-1">{{ $attrs.placeholder }}</span>
        <cx-icon
          :name="buttonIcon"
          class="flex-none ml-4"
          :class="{ 'text-blue-600': open, 'text-gray-400': disabled }"
          size="xl"
        />
      </span>
    </button>
    <slot
      v-bind="{
        disabled,
        loading,
        isOpened: open,
        toggleOpen,
        events: {
          focus: () => {
            triggerIsFocused = true;
          },
          blur: () => {
            triggerIsFocused = false;
          },
        },
      }"
      name="trigger"
    />
    <div
      v-show="open"
      ref="menu"
      class="bg-white list-none overflow-hidden rounded-md shadow text-base z-50"
      @keydown="handleKeydown"
      @keyup="handleKeyup"
    >
      <select-search-input
        v-if="shouldShowSearch"
        ref="searchInput"
        v-model="query"
        v-hubble="'search-input'"
        :placeholder="searchPlaceholder"
        @search-focus="searchHasFocus = true"
        @space.stop
        @up="handleSearchUp"
        @down="handleSearchDown"
        @esc="handleEsc"
        @backtab="closeMenu"
      />
      <div class="max-h-60 mt-1 overflow-auto">
        <template v-if="shouldShowEmptyState">
          <slot name="emptyState" v-bind="{ hasNoSearchResults }">
            <select-search-empty-state v-if="hasNoSearchResults" :query="query" />
            <div v-else class="p-2.5 pointer-events-none text-gray-500 text-sm">
              {{ $t('noResultsText') }}
            </div>
          </slot>
        </template>
        <div v-else v-hubble="'options'">
          <options />
          <slot name="options" />
        </div>
      </div>
      <div v-if="$slots.footer" @keydown.stop="footerKeydownHandler" @keyup.self.stop>
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';
import vClickOutside from 'click-outside-vue3';
import { intersection, isArray, take, without } from 'lodash-es';
import { h } from 'vue';

import {
  DEFAULT_OPTION_THRESHOLD,
  EXPAND_LESS_ICON,
  EXPAND_MORE_ICON,
  KEYCODES,
  KEYCODE_MAP,
} from '~/support/constants';
import { search } from '~/support/utils';

import { sameWidth, setWidth } from './CxSelect.utils';
import CxSelectOption from './subcomponents/CxSelectOption/CxSelectOption.vue';
import SelectSearchEmptyState from './subcomponents/SelectSearchEmptyState/SelectSearchEmptyState.vue';
import SelectSearchInput from './subcomponents/SelectSearchInput/SelectSearchInput.vue';
import { CxIcon } from '../CxIcon';
import { CxSpinner } from '../CxSpinner';
import { BASE, LG, SM } from '../constants';

export { CxSelectOption };
export const MAX_OPTIONS_TO_SHOW = 100;
export const SIZES = [BASE, LG, SM];
export const SIZE_CLASS_MAP = {
  [BASE]: 'h-11 px-3 text-sm',
  [LG]: 'h-14 px-3.5 text-base',
  [SM]: 'h-8 px-3.5 text-xs',
};

export const CX_SELECT_KEYCODES = [
  KEYCODES.DOWN,
  KEYCODES.ENTER,
  KEYCODES.ESC,
  KEYCODES.SPACE,
  KEYCODES.TAB,
  KEYCODES.UP,
];

export const CX_SELECT_POSITION_STRATEGIES = {
  absolute: 'absolute',
  fixed: 'fixed',
};

export default {
  name: 'CxSelect',

  directives: {
    clickOutside: vClickOutside.directive,
  },

  hubble: 'cx-select',

  components: {
    options: {
      render({ $parent }) {
        const optionTemplate = $parent.$slots.optionTemplate;

        return h(
          'ul',
          { role: 'listbox', 'aria-labelledby': $parent.labelId },
          $parent.limitedOptions.map((option) => {
            return optionTemplate
              ? optionTemplate({
                  highlightedItem: $parent.highlightedItem,
                  isSelected: $parent.isSelected(option),
                  option,
                  searchHasFocus: $parent.searchHasFocus,
                  select: $parent.select,
                })
              : h(CxSelectOption, {
                  hasCheckbox: $parent.isMulti,
                  highlightedItem: $parent.highlightedItem,
                  isSelected: $parent.isSelected(option),
                  option,
                  searchHasFocus: $parent.searchHasFocus,
                  onSelect: () => $parent.select(option),
                });
          }),
        );
      },
    },

    CxIcon,
    CxSpinner,
    SelectSearchEmptyState,
    SelectSearchInput,
  },

  props: {
    autoCloseOnClickAway: {
      default: true,
      type: Boolean,
    },

    baseSelector: {
      default: 'wrapper',
      type: String,
    },

    bgColor: {
      default: 'white',
      type: String,
    },

    canUnselect: {
      default: false,
      type: Boolean,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    hideLabel: {
      type: Boolean,
      default: false,
    },

    hideSelectedPreview: {
      type: Boolean,
      default: false,
    },

    invalid: {
      default: false,
      type: Boolean,
    },

    loading: {
      default: false,
      type: Boolean,
    },

    menuWidth: {
      default: null,
      type: Number,
      validator: (value) => value > 0,
    },

    modelValue: {
      type: [Array, Number, String],
      default: '',
    },

    options: {
      type: Array,
      required: true,
    },

    positionStrategy: {
      default: CX_SELECT_POSITION_STRATEGIES.absolute,
      type: String,
      validator: (value) => Object.values(CX_SELECT_POSITION_STRATEGIES).includes(value),
    },

    searchKeys: {
      default: () => ['label', 'value'],
      type: Array,
    },

    searchPlaceholder: {
      default: '',
      type: String,
    },

    searchThreshold: {
      type: Number,
      default: DEFAULT_OPTION_THRESHOLD,
    },

    showAllOptions: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: BASE,
      validator: (value) => SIZES.includes(value),
    },
  },

  data() {
    return {
      highlightedItem: 1,
      open: false,
      query: '',
      searchHasFocus: false,
      triggerIsFocused: false,
    };
  },

  computed: {
    buttonIcon() {
      return this.open ? EXPAND_LESS_ICON : EXPAND_MORE_ICON;
    },

    disabledClasses() {
      return this.disabled ? 'bg-gray-100 border-gray-200 cursor-not-allowed' : null;
    },

    filteredOptions() {
      if (!this.query) return this.orderOptions(this.options);

      const instance = this.searchInstance.find(this.query);

      return this.orderOptions(instance);
    },

    filteredOptionsIncludeAtLeastOneSelected() {
      return intersection(
        this.filteredOptions.map(({ value }) => value),
        [].concat(this.modelValue),
      ).length;
    },

    hasNoSearchResults() {
      return this.query && !this.limitedOptions.length;
    },

    highlightedItemIsFirst() {
      return this.highlightedItem === 1;
    },

    highlightedItemIsLast() {
      return this.highlightedItem === this.filteredOptions.length;
    },

    highlightedItemIsSelected() {
      return this.isSelected(this.highlightedItemOption);
    },

    highlightedItemOption() {
      return this.filteredOptions[this.highlightedItem - 1];
    },

    isMulti() {
      return isArray(this.modelValue);
    },

    labelId() {
      return `cx-select-${this._uid}-label`;
    },

    limitedOptions() {
      if (this.showAllOptions) return this.filteredOptions;

      return take(this.filteredOptions, MAX_OPTIONS_TO_SHOW);
    },

    searchInstance() {
      return search(this.options, this.searchKeys);
    },

    selectedOptions() {
      if (this.isMulti) return this.options.filter(({ value }) => this.modelValue.includes(value));

      return this.options.filter(({ value }) => value === this.modelValue);
    },

    selectedText() {
      // This works with numbers, strings, and arrays
      if (!String(this.modelValue).length) return null;

      const selectedValues = [].concat(this.modelValue);
      const labels = this.options.filter(({ value }) => selectedValues.includes(value)).map(({ label }) => label);

      return labels.join(', ');
    },

    shouldShowEmptyState() {
      return this.hasNoSearchResults || !this.options.length;
    },

    shouldShowSearch() {
      return this.options.length >= this.searchThreshold;
    },

    sizeClasses() {
      return SIZE_CLASS_MAP[this.size];
    },

    spinnerSize() {
      return this.size === SM ? 'xs' : 'sm';
    },

    textClass() {
      if (this.invalid) return 'text-red-600';

      return this.disabled ? 'text-gray-400' : 'text-gray-900';
    },

    validityClasses() {
      return this.invalid
        ? 'bg-red-50 border-red-300 text-red-900 placeholder-red-300 focus-within:ring-red-500 focus-within:border-red-500'
        : 'border-gray-200 focus:border-blue-600';
    },
  },

  watch: {
    filteredOptions(newVal) {
      if (!newVal.length) return;

      if (this.filteredOptionsIncludeAtLeastOneSelected) {
        this.scrollSelectedOptionIntoView();
      } else {
        this.resetHighlightedItem();
      }

      this.resetHighlightedItem();
    },

    open(newVal) {
      if (!newVal) return;

      this.$nextTick(() => {
        this.createPopper();
        this.scrollSelectedOptionIntoView();
        this.focusSearch();
        this.highlightedItem = 1;
      });
    },

    options: {
      // deep: true,
      handler() {
        this.query = '';
      },
    },

    query(newVal, oldVal) {
      if (oldVal.length > 0 && newVal.length === 0) {
        this.$nextTick(() => {
          this.scrollSelectedOptionIntoView();
        });
      }
    },
  },

  methods: {
    clearSelect() {
      this.resetSelection();
      this.resetHighlightedItem();
      this.closeMenu();
    },

    closeMenu() {
      this.open = false;
      this.resetQuery();
      this.$nextTick(() => {
        this.focusTrigger();
      });
    },

    /* c8 ignore start */
    createPopper() {
      this.popper = createPopper(this.$el, this.$refs.menu, {
        placement: 'bottom-start',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
          this.menuWidth ? setWidth(this.menuWidth) : sameWidth,
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top-start', 'bottom'],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              altAxis: true,
              padding: 10,
            },
          },
        ],
        strategy: this.positionStrategy,
      });

      this.popper.forceUpdate();
    },
    /* c8 ignore stop */

    deselect(value) {
      const newValue = !this.isMulti ? '' : this.modelValue.filter((option) => option !== value);

      this.$emit('update:modelValue', newValue);
    },

    /* c8 ignore start */
    focusSearch() {
      if (!this.shouldShowSearch) return;

      this.$refs.searchInput.$refs.input.$refs.input?.focus();
    },
    /* c8 ignore stop */

    focusTrigger() {
      this.$refs.button.focus();
    },

    async footerKeydownHandler(e) {
      if (e.shiftKey && e.keyCode === KEYCODES.TAB) {
        // we need to trigger rerender of the options
        // that's why we modify the higlithedItem
        // but we also need to handle the edge case where we don't have limited options
        // and focus on the search filed insted
        if (this.limitedOptions.length) {
          this.highlightedItem--;
        } else {
          this.handleOptionUp();
        }
        await this.$nextTick();
        this.highlightedItem++;
        e.preventDefault();
      } else if (e.keyCode === KEYCODES.TAB) {
        this.closeMenu();
      }
    },

    handleClickOutside() {
      this.$emit('click-outside');
      if (!this.autoCloseOnClickAway) return;
      this.open = false;
    },

    handleEsc() {
      if (!this.open) return;

      this.closeMenu();
    },

    handleKeydown(e) {
      if (e.shiftKey && e.keyCode === KEYCODES.TAB) {
        e.preventDefault();
        e.stopPropagation();
        this.handleOptionBacktab();
      } else if (e.keyCode === KEYCODES.SPACE && !this.searchHasFocus) {
        e.preventDefault();
        e.stopPropagation();
      } else if (e.keyCode === KEYCODES.ENTER) {
        e.stopPropagation();
        e.preventDefault();
      }
    },

    handleKeyup(e) {
      if (!CX_SELECT_KEYCODES.includes(e.keyCode)) return;

      if (e.keyCode === KEYCODES.ENTER) {
        e.stopPropagation();
        e.preventDefault();
        this.handleOptionEnter();
      } else if (e.keyCode === KEYCODES.TAB) {
        e.preventDefault();
        e.stopPropagation();
      } else if (Object.keys(KEYCODE_MAP).includes(e.keyCode.toString())) {
        e.preventDefault();
        e.stopPropagation();
        if (e.keyCode === KEYCODES.ESC) return this.handleEsc();

        this[`handleOption${KEYCODE_MAP[e.keyCode.toString()]}`]();
      }
    },

    handleOptionBacktab() {
      if (this.shouldShowSearch) return this.focusSearch();

      this.closeMenu();
    },

    handleOptionDown() {
      !this.highlightedItemIsLast && this.highlightedItem++;
    },

    handleOptionEnter() {
      !this.searchHasFocus && this.select(this.highlightedItemOption, true);
    },

    handleOptionSpace() {
      !this.searchHasFocus && this.select(this.highlightedItemOption);
    },

    handleOptionUp() {
      if (this.highlightedItemIsFirst && this.shouldShowSearch) {
        this.resetHighlightedItem();
        this.focusSearch();
      } else if (this.highlightedItemIsFirst && !this.shouldShowSearch) {
        this.closeMenu();
      } else {
        this.highlightedItem--;
      }
    },

    handleSearchDown(event) {
      if (!this.open) {
        this.openMenu();
      } else {
        this.searchHasFocus = false;
      }

      if (this.limitedOptions.length !== 0) {
        event.stopPropagation();
        event.preventDefault();
      }
    },

    handleSearchUp() {
      if (!this.highlightedItemIsFirst && !this.searchHasFocus) {
        this.highlightedItem--;
      } else if (this.searchHasFocus) {
        this.closeMenu();
      }
    },

    handleTriggerBacktab(e) {
      if (!this.open) return;

      e.stopPropagation();
      e.preventDefault();
      this.closeMenu();
    },

    handleTriggerDown() {
      if (this.loading) return;

      if (this.open && !this.highlightedItemIsLast) {
        this.highlightedItem++;
      } else if (!this.open) {
        this.openMenu();
      }
    },

    handleTriggerSpace() {
      if (this.loading) return;

      if (!this.open) {
        this.openMenu();
      } else if (this.highlightedItemIsSelected) {
        this.resetSelection();
      } else {
        this.select(this.highlightedItemOption);
      }
    },

    handleTriggerUp() {
      if (this.open && !this.highlightedItemIsFirst) this.highlightedItem--;
    },

    isSelected({ value }) {
      if (this.isMulti) return this.modelValue.includes(value);

      return this.modelValue === value;
    },

    openMenu() {
      this.open = true;
      this.setHighlightedToSelected();
    },

    orderOptions(options) {
      const orderedArray = [];
      let order = 1;

      options.forEach((option) => {
        option.order = order++;
        orderedArray.push(option);
      });

      return orderedArray;
    },

    resetHighlightedItem() {
      this.highlightedItem = 1;
    },

    resetQuery() {
      this.query = '';
    },

    resetSelection() {
      this.$emit('update:modelValue', this.isMulti ? [] : '');
    },

    scrollSelectedOptionIntoView() {
      const selectedEl = this.$refs.menu.querySelector('[aria-selected="true"]');

      if (selectedEl) {
        selectedEl.scrollIntoView();
      }
    },

    select(option, close = false) {
      this.isMulti ? this.selectMulti(option, close) : this.selectSingle(option);
    },

    selectMulti(option, close = false) {
      const { value, order } = option;
      const newValue = this.isSelected(option)
        ? without(this.modelValue, value)
        : [...new Set([...this.modelValue, value])];

      this.$emit('update:modelValue', newValue);
      this.highlightedItem = order;
      close && this.closeMenu();
    },

    selectSingle(option) {
      const { value, order } = option;
      const newValue = this.canUnselect && this.isSelected(option) ? '' : value;

      this.$emit('update:modelValue', newValue);
      this.closeMenu();
      this.highlightedItem = order;
    },

    setHighlightedToSelected() {
      const selectedIndex = this.filteredOptions.findIndex((option) => this.isSelected(option));
      const adjustedIndex = selectedIndex === -1 ? 0 : selectedIndex;

      this.highlightedItem = adjustedIndex + 1;
    },

    toggleOpen() {
      if (!this.triggerIsFocused) return;

      this.open = !this.open;
    },
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "noResultsText": "No results found",
    "searchInputLabel": "options"
  }
}
</i18n>
