import { concat, map } from 'lodash-es';

import { TIMELINE_COLORS } from '~/components/cx/constants';

export const FRAME_PROFILING_LOADER_STEPS = {
  creatingSchemas: {
    endDate: '2023-01-19T18:25:18.026422',
    info: 'Service completed appropriately',
    processedFiles: 8,
    sizeOfProcessedFiles: 184888,
    startDate: '2023-01-19T18:24:19.767326',
    status: 'COMPLETED',
    timeCompleted: 58.259096,
    totalFiles: 9,
    totalSizeOfFiles: 5845473,
  },
  downloading: {
    endDate: '2023-01-19T18:22:51.727646',
    info: 'Service completed appropriately',
    processedFiles: 7,
    sizeOfProcessedFiles: 5699166,
    startDate: '2023-01-19T18:22:42.386070',
    status: 'COMPLETED',
    timeCompleted: 9.341576,
    totalFiles: 7,
    totalSizeOfFiles: 5699166,
  },
  generatingDataPipelines: {
    endDate: null,
    info: null,
    startDate: null,
    status: 'STARTED',
    timeCompleted: null,
  },
  preparing: {
    endDate: '2023-01-19T18:22:40.843291',
    info: 'Service completed appropriately',
    processedFiles: 7,
    sizeOfProcessedFiles: 5699166,
    startDate: '2023-01-19T18:22:40.101538',
    status: 'COMPLETED',
    timeCompleted: 0.741753,
    totalFiles: 7,
    totalSizeOfFiles: 5699166,
  },
  profilingContents: {
    endDate: '2023-01-19T18:24:17.827299',
    info: 'Service completed appropriately',
    processedFiles: 9,
    sizeOfProcessedFiles: 5862745,
    startDate: '2023-01-19T18:23:10.979016',
    status: 'COMPLETED',
    timeCompleted: 66.848283,
    totalFiles: 9,
    totalSizeOfFiles: 5862745,
  },
};

export const COMPLETED_FRAME_PROFILING_LOADER_STEPS = {
  creatingSchemas: {
    endDate: '2023-01-19T18:25:18.026422',
    info: 'Service completed appropriately',
    processedFiles: 8,
    sizeOfProcessedFiles: 184888,
    startDate: '2023-01-19T18:24:19.767326',
    status: 'COMPLETED',
    timeCompleted: 58.259096,
    totalFiles: 9,
    totalSizeOfFiles: 5845473,
  },
  downloading: {
    endDate: '2023-01-19T18:22:51.727646',
    info: 'Service completed appropriately',
    processedFiles: 7,
    sizeOfProcessedFiles: 5699166,
    startDate: '2023-01-19T18:22:42.386070',
    status: 'COMPLETED',
    timeCompleted: 9.341576,
    totalFiles: 7,
    totalSizeOfFiles: 5699166,
  },
  generatingDataPipelines: {
    endDate: '2023-01-19T18:25:27.631214',
    info: 'Service completed appropriately',
    startDate: '2023-01-19T18:25:19.555473',
    status: 'COMPLETED',
    timeCompleted: 8.075741,
  },
  preparing: {
    endDate: '2023-01-19T18:22:40.843291',
    info: 'Service completed appropriately',
    processedFiles: 7,
    sizeOfProcessedFiles: 5699166,
    startDate: '2023-01-19T18:22:40.101538',
    status: 'COMPLETED',
    timeCompleted: 0.741753,
    totalFiles: 7,
    totalSizeOfFiles: 5699166,
  },
  profilingContents: {
    endDate: '2023-01-19T18:24:17.827299',
    info: 'Service completed appropriately',
    processedFiles: 9,
    sizeOfProcessedFiles: 5862745,
    startDate: '2023-01-19T18:23:10.979016',
    status: 'COMPLETED',
    timeCompleted: 66.848283,
    totalFiles: 9,
    totalSizeOfFiles: 5862745,
  },
};

export const FAILED_FRAME_PROFILING_LOADER_STEPS = {
  creatingSchemas: {
    endDate: '2023-01-19T18:25:18.026422',
    info: 'Service completed appropriately',
    processedFiles: 8,
    sizeOfProcessedFiles: 184888,
    startDate: '2023-01-19T18:24:19.767326',
    status: 'COMPLETED',
    timeCompleted: 58.259096,
    totalFiles: 9,
    totalSizeOfFiles: 5845473,
  },
  downloading: {
    endDate: '2023-01-19T18:22:51.727646',
    info: 'Service completed appropriately',
    processedFiles: 7,
    sizeOfProcessedFiles: 5699166,
    startDate: '2023-01-19T18:22:42.386070',
    status: 'COMPLETED',
    timeCompleted: 9.341576,
    totalFiles: 7,
    totalSizeOfFiles: 5699166,
  },
  generatingDataPipelines: {
    cruxErrorCode: 'crux_internal_error',
    endDate: null,
    info: 'Error generating pipelines',
    startDate: '2023-01-19T18:25:19.555473',
    status: 'FAILED',
    timeCompleted: null,
  },
  preparing: {
    endDate: '2023-01-19T18:22:40.843291',
    info: 'Service completed appropriately',
    processedFiles: 7,
    sizeOfProcessedFiles: 5699166,
    startDate: '2023-01-19T18:22:40.101538',
    status: 'COMPLETED',
    timeCompleted: 0.741753,
    totalFiles: 7,
    totalSizeOfFiles: 5699166,
  },
  profilingContents: {
    endDate: '2023-01-19T18:24:17.827299',
    info: 'Service completed appropriately',
    processedFiles: 9,
    sizeOfProcessedFiles: 5862745,
    startDate: '2023-01-19T18:23:10.979016',
    status: 'COMPLETED',
    timeCompleted: 66.848283,
    totalFiles: 9,
    totalSizeOfFiles: 5862745,
  },
};

export const FAILED_FRAME_PROFILING_LOADER_STEPS_PARSING_ERROR = {
  ...FAILED_FRAME_PROFILING_LOADER_STEPS,
  generatingDataPipelines: {
    ...FAILED_FRAME_PROFILING_LOADER_STEPS.generatingDataPipelines,
    cruxErrorCode: 'crux_parsing_error',
  },
};

export const MODELING_STEP_DATA_PRODUCT = {
  cduId: 'Cx13406',
  connectionId: 536,
  createdAt: '2023-05-05T16:02:59.288+0000',
  createdBy: 'ric.flair@cruxinformatics.com',
  datasets: [],
  datastoreId: null,
  datastoresMap: {
    101: [
      {
        datastoreId: 101,
        id: '101',
        name: 'ric-flair-datastore-1-1',
      },
    ],
    102: [
      {
        datastoreId: 102,
        id: '102',
        name: 'ric-flair-datastore-2-1',
      },
    ],
    103: [
      {
        datastoreId: 103,
        id: '103',
        name: 'ric-flair-datastore-3-1',
      },
    ],
  },
  deliverAllRaw: false,
  destinationIds: null,
  id: 'Pr38puc0lzEd',
  inferredDatasets: [
    {
      cron: '0 0 14 * * *',
      frames: [
        {
          datastoreId: 101,
          id: '101',
          name: 'ric-flair-datastore-1-1',
        },
      ],
      id: 'DsuPyNA6',
      name: 'ric-flair-dataset-1',
    },
    {
      cron: '0 0 22 * * 7',
      frames: [
        {
          datastoreId: 102,
          id: '102',
          name: 'ric-flair-datastore-2-1',
        },
      ],
      id: 'DseVCqMi',
      name: 'ric-flair-dataset-2',
    },
    {
      cron: '0 0 22 * * 7',
      frames: [
        {
          datastoreId: 103,
          id: '103',
          name: 'ric-flair-datastore-3-1',
        },
      ],
      id: 'DseVCqMi2',
      name: 'ric-flair-dataset-3',
    },
  ],
  lastStep: 2,
  name: 'Ric Flair Product',
  profilingStatus: {
    countFilesProfiled: 0,
    deliverAllRaw: false,
    profileCompleted: false,
    profileFailed: false,
    profileStarted: false,
    steps: {
      creatingSchemas: {},
      downloading: {},
      generatingDataPipelines: {},
      preparing: {},
      profilingContents: {},
    },
    totalFilesToProfile: 0,
  },
  status: {
    status: 'draft',
  },
  supplier: {
    id: null,
    name: 'N/A',
  },
};

export const MODELING_STEP_DATASETS = [
  {
    cron: '0 0 14 * * *',
    frames: [
      {
        canOverride: true,
        datastoreId: 101,
        deliverRaw: false,
        id: '101',
        inOverrideMode: false,
        name: 'ric-flair-datastore-1-1',
        pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange%Y%m%d%H%M%S-001.zip',
        regex:
          'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d-001.zip',
        status: 'pending',
        steps: FRAME_PROFILING_LOADER_STEPS,
        totalFiles: 1,
        totalSizeOfFiles: 1801,
      },
      {
        canOverride: true,
        datastoreId: 111,
        deliverRaw: false,
        id: '111',
        inOverrideMode: false,
        name: 'ric-flair-datastore-1-2',
        pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange%Y%m%d%H%M%S-002.zip',
        regex:
          'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d-002.zip',
        status: 'pending',
        steps: FRAME_PROFILING_LOADER_STEPS,
        totalFiles: 1,
        totalSizeOfFiles: 1801,
      },
    ],
    id: 'DsuPyNA6',
    name: 'ric-flair-dataset-1',
  },
  {
    cron: '"0 0 22 * * 7',
    frames: [
      {
        canOverride: true,
        datastoreId: 102,
        deliverRaw: false,
        id: '102',
        inOverrideMode: false,
        name: 'ric-flair-datastore-2-1',
        pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull%Y%m%d%H%M%S.zip',
        regex: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d.zip',
        status: 'pending',
        steps: FRAME_PROFILING_LOADER_STEPS,
        totalFiles: 1,
        totalSizeOfFiles: 1801,
      },
    ],
    id: 'DseVCqMi',
    name: 'ric-flair-dataset-2',
  },
  {
    cron: '"0 0 22 * * 7',
    frames: [
      {
        canOverride: true,
        datastoreId: 103,
        deliverRaw: false,
        id: '103',
        inOverrideMode: false,
        name: 'ric-flair-datastore-3-1',
        pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull%Y%m%d%H%M%S.zip',
        regex: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d.zip',
        status: 'pending',
        steps: FRAME_PROFILING_LOADER_STEPS,
        totalFiles: 1,
        totalSizeOfFiles: 1801,
      },
    ],
    id: 'DseVCqMi2',
    name: 'ric-flair-dataset-3',
  },
];

export const MODELING_STEP_FRAMES_INFO = concat(...map(MODELING_STEP_DATASETS, 'frames')).map(
  ({ id, status, datastoreId }) => ({
    datastoreId,
    id,
    included: true,
    isStopped: false,
    status,
  }),
);

export const MODELING_STEP_PROFILED_DATASETS = [
  {
    cron: '0 0 14 * * *',
    frames: [
      {
        canOverride: true,
        datastoreId: 101,
        deliverRaw: false,
        groupId: 'ric-flair-frame-1-1',
        id: 'ric-flair-frame-1-1',
        inOverrideMode: false,
        name: 'ric-flair-frame-1-1',
        pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange%Y%m%d%H%M%S-001.zip',
        pipelines: [
          {
            endDate: null,
            id: 'ric-flair-pipeline-1-1',
            name: 'ric-flair-frame-1-1',
            primaryKey: 1,
            schema: [],
            startDate: '2023-01-19T18:23:10.979016',
            version: 'Version 2.0',
          },
          {
            endDate: null,
            id: 'ric-flair-pipeline-1-2',
            name: 'ric-flair-pipeline-1-2',
            primaryKey: 2,
            schema: [],
            startDate: '2023-01-19T18:23:10.979016',
            version: 'Version 1.0',
          },
        ],
        regex:
          'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d-001.zip',
        schema: [],
        startDate: '2023-01-19T18:23:10.979016',
        status: 'completed',
        steps: COMPLETED_FRAME_PROFILING_LOADER_STEPS,
        totalFiles: 1,
        totalSizeOfFiles: 1801,
        versions: [
          {
            canOverride: true,
            datastoreId: 101,
            deliverRaw: true,
            groupId: 'ric-flair-frame-1-1',
            id: 'ric-flair-frame-1-1',
            inOverrideMode: false,
            name: 'ric-flair-frame-1-1',
            pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange%Y%m%d%H%M%S-001.zip',
            regex:
              'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d-001.zip',
            schema: [],
            startDate: '2023-01-19T18:23:10.979016',
            status: 'completed',
            steps: COMPLETED_FRAME_PROFILING_LOADER_STEPS,
            totalFiles: 1,
            totalSizeOfFiles: 1801,
          },
          {
            canOverride: true,
            datastoreId: 101,
            deliverRaw: true,
            groupId: 'ric-flair-frame-1-1',
            id: 'ric-flair-frame-1-1',
            inOverrideMode: false,
            name: 'ric-flair-datastore-1-1_s2',
            pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange%Y%m%d%H%M%S-001.zip',
            regex:
              'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d-001.zip',
            schema: [],
            startDate: '2023-01-19T18:23:10.979016',
            status: 'completed',
            steps: COMPLETED_FRAME_PROFILING_LOADER_STEPS,
            totalFiles: 1,
            totalSizeOfFiles: 1801,
          },
        ],
      },
    ],
    id: 'DsuPyNA6',
    name: 'ric-flair-dataset-1',
  },
  {
    cron: '"0 0 22 * * 7',
    frames: [
      {
        canOverride: true,
        datastoreId: 102,
        deliverRaw: false,
        groupId: 'ric-flair-frame-2-1',
        id: 'ric-flair-frame-2-1',
        inOverrideMode: false,
        name: 'ric-flair-frame-2-1',
        pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull%Y%m%d%H%M%S.zip',
        pipelines: [
          {
            endDate: null,
            id: 'ric-flair-pipeline-2-1',
            name: 'ric-flair-frame-2-1',
            primaryKey: 3,
            schema: [],
            startDate: '2023-01-19T18:23:10.979016',
            version: 'Version 1.0',
          },
        ],
        regex: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d.zip',
        schema: [],
        startDate: '2023-01-19T18:23:10.979016',
        status: 'completed',
        steps: COMPLETED_FRAME_PROFILING_LOADER_STEPS,
        totalFiles: 1,
        totalSizeOfFiles: 1801,
        versions: [
          {
            canOverride: true,
            datastoreId: 102,
            deliverRaw: true,
            groupId: 'ric-flair-frame-2-1',
            id: '102',
            inOverrideMode: false,
            name: 'ric-flair-frame-2-1',
            pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull%Y%m%d%H%M%S.zip',
            regex:
              'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d.zip',
            schema: [],
            startDate: '2023-01-19T18:23:10.979016',
            status: 'completed',
            steps: COMPLETED_FRAME_PROFILING_LOADER_STEPS,
          },
        ],
      },
    ],
    id: 'DseVCqMi',
    name: 'ric-flair-dataset-2',
  },
  {
    cron: '"0 0 22 * * 7',
    frames: [
      {
        canOverride: true,
        datastoreId: 103,
        deliverRaw: false,
        groupId: 'ric-flair-frame-3-1',
        id: 'ric-flair-frame-3-1',
        inOverrideMode: false,
        name: 'ric-flair-frame-3-1',
        pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull%Y%m%d%H%M%S.zip',
        regex: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d.zip',
        schema: [],
        startDate: '2023-01-19T18:23:10.979016',
        status: 'completed',
        steps: COMPLETED_FRAME_PROFILING_LOADER_STEPS,
        totalFiles: 1,
        totalSizeOfFiles: 1801,
        versions: [
          {
            canOverride: true,
            datastoreId: 102,
            deliverRaw: true,
            groupId: 'ric-flair-frame-3-1',
            id: '102',
            inOverrideMode: false,
            name: 'ric-flair-frame-3-1',
            pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull%Y%m%d%H%M%S.zip',
            regex:
              'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d.zip',
            schema: [],
            startDate: '2023-01-19T18:23:10.979016',
            status: 'completed',
            steps: COMPLETED_FRAME_PROFILING_LOADER_STEPS,
          },
        ],
      },
    ],
    id: 'DseVCqMi2',
    name: 'ric-flair-dataset-3',
  },
];

export const MODELING_STEP_PROFILED_MIXED_CRON_DATASETS = [
  {
    cron: '0 0 14 * * *',
    frames: [
      {
        canOverride: true,
        datastoreId: 101,
        deliverRaw: false,
        groupId: 'ric-flair-frame-1-1',
        id: 'ric-flair-frame-1-1',
        inOverrideMode: false,
        name: 'ric-flair-frame-1-1',
        pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange%Y%m%d%H%M%S-001.zip',
        regex:
          'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d-001.zip',
        schema: [],
        startDate: '2023-01-19T18:23:10.979016',
        status: 'completed',
        steps: COMPLETED_FRAME_PROFILING_LOADER_STEPS,
        totalFiles: 1,
        totalSizeOfFiles: 1801,
        versions: [
          {
            canOverride: true,
            datastoreId: 101,
            deliverRaw: true,
            groupId: 'ric-flair-frame-1-1',
            id: 'ric-flair-frame-1-1',
            inOverrideMode: false,
            name: 'ric-flair-frame-1-1',
            pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange%Y%m%d%H%M%S-001.zip',
            regex:
              'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d-001.zip',
            schema: [],
            startDate: '2023-01-19T18:23:10.979016',
            status: 'completed',
            steps: COMPLETED_FRAME_PROFILING_LOADER_STEPS,
            totalFiles: 1,
            totalSizeOfFiles: 1801,
          },
          {
            canOverride: true,
            datastoreId: 101,
            deliverRaw: true,
            groupId: 'ric-flair-frame-1-1',
            id: 'ric-flair-frame-1-1',
            inOverrideMode: false,
            name: 'ric-flair-datastore-1-1_s2',
            pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange%Y%m%d%H%M%S-001.zip',
            regex:
              'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d-001.zip',
            schema: [],
            startDate: '2023-01-19T18:23:10.979016',
            status: 'completed',
            steps: COMPLETED_FRAME_PROFILING_LOADER_STEPS,
            totalFiles: 1,
            totalSizeOfFiles: 1801,
          },
        ],
      },
    ],
    id: 'DsuPyNA6',
    name: 'ric-flair-dataset-1',
  },
  {
    cron: '@once',
    frames: [
      {
        canOverride: true,
        datastoreId: 102,
        deliverRaw: false,
        groupId: 'ric-flair-frame-2-1',
        id: 'ric-flair-frame-2-1',
        inOverrideMode: false,
        name: 'ric-flair-frame-2-1',
        pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull%Y%m%d%H%M%S.zip',
        regex: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d.zip',
        schema: [],
        startDate: '2023-01-19T18:23:10.979016',
        status: 'completed',
        steps: COMPLETED_FRAME_PROFILING_LOADER_STEPS,
        totalFiles: 1,
        totalSizeOfFiles: 1801,
        versions: [
          {
            canOverride: true,
            datastoreId: 102,
            deliverRaw: true,
            groupId: 'ric-flair-frame-2-1',
            id: '102',
            inOverrideMode: false,
            name: 'ric-flair-frame-2-1',
            pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull%Y%m%d%H%M%S.zip',
            regex:
              'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d.zip',
            schema: [],
            startDate: '2023-01-19T18:23:10.979016',
            status: 'completed',
            steps: COMPLETED_FRAME_PROFILING_LOADER_STEPS,
          },
        ],
      },
    ],
    id: 'DseVCqMi',
    name: 'ric-flair-dataset-2',
  },
];

export const DATASTORE_PROFILED_MULTIPLE_FRAMES = [
  {
    canOverride: true,
    datastoreId: 101,
    deliverRaw: false,
    endDate: '2023-12-31',
    groupId: 'ric-flair-pipeline-group-id',
    id: 'ric-flair-pipeline-1',
    inOverrideMode: false,
    isStopped: false,
    name: 'ric-flair-pipeline',
    pattern: 'pattern',
    primaryKey: 1001,
    regex: 'regex',
    schema: [],
    startDate: '2023-01-01',
    status: 'completed',
    steps: [],
    totalFiles: 10,
    totalSizeOfFiles: 0,
  },
  {
    canOverride: true,
    datastoreId: 101,
    deliverRaw: false,
    endDate: '2023-12-31',
    groupId: 'ric-flair-pipeline-group-id',
    id: 'ric-flair-pipeline-1-2',
    inOverrideMode: false,
    isStopped: false,
    name: 'ric-flair-pipeline',
    pattern: 'pattern',
    primaryKey: 1002,
    regex: 'regex',
    schema: [],
    startDate: '2023-01-01',
    status: 'completed',
    steps: [],
    totalFiles: 20,
    totalSizeOfFiles: 0,
  },
  {
    canOverride: true,
    datastoreId: 102,
    deliverRaw: false,
    endDate: '2023-12-31',
    groupId: 'ric-flair-pipeline-group-id',
    id: 'ric-flair-pipeline-2',
    inOverrideMode: false,
    isStopped: false,
    name: 'ric-flair-pipeline',
    pattern: 'pattern',
    primaryKey: 1003,
    regex: 'regex',
    schema: [],
    startDate: '2023-01-01',
    status: 'completed',
    steps: [],
    totalFiles: 20,
    totalSizeOfFiles: 0,
  },
  {
    canOverride: true,
    datastoreId: 103,
    deliverRaw: false,
    endDate: '2023-12-31',
    groupId: 'ric-flair-pipeline-group-id',
    id: 'ric-flair-pipeline-3',
    inOverrideMode: false,
    isStopped: false,
    name: 'ric-flair-pipeline',
    pattern: 'pattern',
    primaryKey: 1004,
    regex: 'regex',
    schema: [],
    startDate: '2023-01-01',
    status: 'completed',
    steps: [],
    totalFiles: 20,
    totalSizeOfFiles: 0,
  },
];

export const TIMELINE_SEGMENTS = [...DATASTORE_PROFILED_MULTIPLE_FRAMES]
  .reverse()
  .map(({ primaryKey }, index) => ({
    color: TIMELINE_COLORS[index % TIMELINE_COLORS.length],
    id: primaryKey,
    label: `segment${index}`,
    percent: 0,
  }))
  .reverse();

export const DATASTORE_FLATTENED_FRAMES = [
  {
    datastoreId: 101,
    id: '101',
    name: 'ric-flair-datastore-1-1',
  },
  {
    datastoreId: 102,
    id: '102',
    name: 'ric-flair-datastore-2-1',
  },
  {
    datastoreId: 103,
    id: '103',
    name: 'ric-flair-datastore-3-1',
  },
];

export const DATASTORE_FLATTENED_FRAMES_WITH_DISCOVERED_PATTERNS = [
  {
    datastoreId: 101,
    id: '101',
    name: 'ric-flair-datastore-1-1',
    steps: {
      downloading: {
        meta: {
          discoveredPatterns: ['ric-flairs-pattern'],
        },
      },
    },
  },
];

export const PIPELINE = {
  endDate: null,
  id: 'ric-flair-pipeline-1-1',
  name: 'ric-flair-frame-1-1',
  primaryKey: 1,
  schema: [
    { dataType: 'STRING', name: 'product_name' },
    { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_start' },
    { dataType: 'FLOAT', name: 'value' },
    { dataType: 'INTEGER', name: 'kugou_super_vip_' },
  ],
  startDate: '2023-01-19T18:23:10.979016',
  version: 'Version 2.0',
};
