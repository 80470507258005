import { defaults } from 'lodash-es';

import ApiBase from './ApiBase';

export const DISCOVER_ENDPOINT = 'discover-patterns';
export const FETCH_TREE_DEFAULT_PARAMS = {
  endLevel: 1,
  root: '/',
};
export const FILE_HISTOGRAM_ENDPOINT = 'histogram/files';
export const PATTERNS_SEARCH_ENDPOINT = 'patterns-search';
export const SEARCH_ENDPOINT = 'search';
export const STATUS_ENDPOINT = 'status';
export const TREE_ENDPOINT = 'tree';
export const WALK_ENDPOINT = 'walk';
export const WALK_ARCHIVE_ENDPOINT = 'walk/archive';

export default class SourcesService extends ApiBase {
  async createSource(payload) {
    const { data } = await this.client.post('', payload);

    return data;
  }

  async deleteSource(connectionId) {
    const { data } = await this.client.delete(`${connectionId}`);

    return data;
  }

  async discoverPatterns(connectionId, paths) {
    const { data } = await this.client.post(`${connectionId}/${DISCOVER_ENDPOINT}`, paths);

    return data;
  }

  async editSource(connectionId, connection) {
    const { data } = await this.client.put(`${connectionId}`, connection);

    return data;
  }

  async fetchAll(params) {
    const { data } = await this.client.get('', { params });

    return data;
  }

  async fetchFileHistogram(connectionId, patterns) {
    const { data } = await this.client.post(`${connectionId}/${FILE_HISTOGRAM_ENDPOINT}`, patterns);

    return data;
  }

  async fetchOne(connectionId) {
    const { data } = await this.client.get(`${connectionId}`);

    return data;
  }

  async fetchStatus(connectionId) {
    const { data } = await this.client.get(`${connectionId}/${STATUS_ENDPOINT}`);

    return data;
  }

  async fetchTree(connectionId, { endLevel, root } = {}) {
    const params = defaults({ endLevel, root }, FETCH_TREE_DEFAULT_PARAMS);
    const { data } = await this.client.get(`${connectionId}/${TREE_ENDPOINT}`, { params });

    return data;
  }

  async patternsSearchSource(connectionId, patterns) {
    const { data } = await this.client.post(`${connectionId}/${PATTERNS_SEARCH_ENDPOINT}`, patterns);

    return data;
  }

  async search(connectionId, query = '') {
    const { data } = await this.client.get(`${connectionId}/${SEARCH_ENDPOINT}`, { params: { query } });

    return data;
  }

  async walk(connectionId, { force, fullTree, root }) {
    const { data } = await this.client.post(
      `${connectionId}/${WALK_ENDPOINT}`,
      {},
      { params: { force, fullTree, root } },
    );

    return data;
  }

  async walkArchive(connectionId, path) {
    const { data } = await this.client.post(`${connectionId}/${WALK_ARCHIVE_ENDPOINT}`, { path });

    return data;
  }
}
