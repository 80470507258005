<template>
  <cx-overlay>
    <div
      class="bg-white border-gray-200 flex flex-col max-h-full max-w-[90%] overflow-hidden rounded-lg shadow-lg"
      :class="widthClass"
    >
      <div
        v-if="title"
        class="bg-gray-100 border-b border-gray-200 flex flex-none items-center justify-between px-6 py-5 rounded-t-lg"
      >
        <div class="flex items-center space-x-2">
          <cx-icon v-if="titleIcon" :name="titleIcon" size="2xl" />
          <h3 class="font-semibold text-gray-900 text-lg">{{ title }}</h3>
        </div>
        <button
          v-if="showCloseButton"
          v-hubble="'close'"
          type="button"
          class="bg-transparent hover:bg-gray-200 inline-flex items-center p-1 rounded-lg text-gray-900 text-sm"
          :aria-label="$t('ariaLabels.buttons.close')"
          @click="$emit('close')"
        >
          <cx-icon name="close-fill" size="2xl" />
        </button>
      </div>

      <div v-if="!!$slots.unstyled">
        <slot name="unstyled" />
      </div>

      <div v-else class="flex-1 overflow-auto p-6 space-y-6">
        <slot />
      </div>

      <div v-if="!!$slots.footer" class="border-gray-200 border-t flex flex-none items-center p-6">
        <slot name="footer" />
      </div>
    </div>
  </cx-overlay>
</template>

<script>
import { CxIcon } from '../CxIcon';
import { CxOverlay } from '../CxOverlay';
import { MODAL_MIN_WIDTH } from '../constants';

export default {
  name: 'CxModal',

  hubble: 'cx-modal',

  components: {
    CxIcon,
    CxOverlay,
  },

  props: {
    showCloseButton: {
      type: Boolean,
      default: true,
    },

    title: {
      type: String,
      default: '',
    },

    titleIcon: {
      type: String,
      default: '',
    },

    widthClass: {
      type: String,
      default: MODAL_MIN_WIDTH,
    },
  },
};
</script>
