<template>
  <li>
    <component
      :is="tag"
      class="block cursor-pointer font-normal group hover:bg-gray-100 px-4 py-2 text-gray-500 text-left text-sm w-full"
      v-bind="{ ...$attrs, ...$props }"
      @keyup.delete="$emit('delete')"
      v-on="_$listeners"
    >
      <div class="flex justify-between">
        <div class="flex items-center">
          <cx-icon v-if="leadingIcon" :name="leadingIcon" class="mr-2" />
          <slot />
        </div>
        <div
          class="flex group-focus:visible group-hover:visible invisible items-center"
          @click.stop.prevent="$emit('trailing-icon-click')"
        >
          <cx-icon v-if="trailingIcon" :name="trailingIcon" size="lg" />
        </div>
      </div>
    </component>
  </li>
</template>

<script>
import { CxIcon } from '../../CxIcon';

export default {
  name: 'CxListGroupItem',

  components: {
    CxIcon,
  },

  inheritAttrs: false,

  props: {
    leadingIcon: {
      default: 'search-line',
      type: String,
    },

    tag: {
      default: 'button',
      type: String,
    },

    trailingIcon: {
      default: 'corner-down-left-fill',
      type: String,
    },
  },
};
</script>
