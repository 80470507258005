export default () => {
  return {
    connection: null,
    connections: [],
    dataProduct: null,
    dataProductHistory: [],
    dataProducts: [],
    datasets: [],
    destinations: [],
    lastDataProductId: null,
    profilingNotificationActive: null,
    profilingNotificationLoading: false,
    resources: [],
    shouldShowProfileNotificationModal: false,
  };
};
