<template>
  <div class="border border-gray-200 border-solid box-border flex flex-col py-1 rounded-lg">
    <slot />
  </div>
</template>

<script>
export { default as CxListGroupItem } from './subcomponents/CxListGroupItem.vue';
export { default as CxListGroupSection } from './subcomponents/CxListGroupSection.vue';

export default {
  name: 'CxListGroup',
};
</script>
