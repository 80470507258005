import { isValidCron } from 'cron-validator';
import { toString as cronstrueToString } from 'cronstrue';
import { every, isInteger, range, sortBy, split, take, toString } from 'lodash-es';

import {
  CRON_ANY_VALUE,
  CRON_DAY_VALUES,
  CRON_END_TIME_OFFSET,
  CRON_PART_MAX_VALUE,
  CRON_SECOND_DEFAULT,
  CRON_TIMEZONE,
  NON_INTRA_DAY_EXPRESSIONS,
  HEATMAP_X_AXIS,
  HEATMAP_Y_AXIS,
  CRON_PARTS_COUNT,
  CRON_PART_INDEX,
  CRON_SPECIAL_EXPRESSIONS,
  CRON_SPECIAL_DESCRIPTIONS,
} from '~/support/constants';

export default {
  /*
    we are building a custom cron with minute intervals, this results in the end hour
    extending by 59 minutes (12-2:59). This method will offset that added time (ex. 12-2).
    If the form capabilities are expanded in the future this will need to be modified.
  */
  buildCronHours(startTime, endTime) {
    const offsetEndTime = endTime - CRON_END_TIME_OFFSET;

    if (!endTime || startTime === offsetEndTime) return startTime;

    return `${startTime}-${offsetEndTime}`;
  },

  buildExpression(cronParts) {
    const seconds = CRON_SECOND_DEFAULT;
    const minutes = cronParts.minuteInterval ? `*/${cronParts.minuteInterval}` : cronParts.minuteInterval;
    const hours = this.buildCronHours(cronParts.startTime, cronParts.endTime);
    const daysOfMonth = CRON_ANY_VALUE;
    const month = CRON_ANY_VALUE;
    const daysOfWeek = cronParts.dayOfWeek.length ? toString(sortBy(cronParts.dayOfWeek)) : null;

    return `${seconds} ${minutes} ${hours} ${daysOfMonth} ${month} ${daysOfWeek}`;
  },

  // converts '1/5,2,5-7' (hours) to [1, 6, 11, 16, 21, 2, 5, 6, 7]
  getCronPartAllValues(cronPart, cronPartIndex) {
    const partValues = cronPart.split(',');

    const allValues = [];
    partValues.forEach((partValue) => {
      if (partValue.includes('-')) {
        const partDigits = partValue.split('-');
        allValues.push(...range(+partDigits[0], +partDigits[1] + 1));

        return;
      }

      if (partValue.includes('/')) {
        const partDigits = partValue.split('/');
        const step = +partDigits[1];
        const startFrom = +partDigits[0] | 0;
        allValues.push(...range(startFrom, CRON_PART_MAX_VALUE[cronPartIndex] + 1, step));

        return;
      }

      if (partValue === CRON_ANY_VALUE) {
        allValues.push(...range(0, CRON_PART_MAX_VALUE[cronPartIndex] + 1));

        return;
      }

      const value = +partValue;
      allValues.push(value);
    });

    return allValues;
  },

  getHeatmapData(cron, totalFiles) {
    const cronParts = cron.split(' ');
    cronParts[CRON_PART_INDEX.daysOfWeek] = this.mapDaysOfWeek(cronParts[CRON_PART_INDEX.daysOfWeek]);
    const [, , hours, , , daysOfWeek] = cronParts.map(this.getCronPartAllValues);

    const possibleTimeValues = hours;

    const daysInWeek = 7;
    const hoursInDay = 24;

    const data = range(daysInWeek).reduce((result, dayIndex) => {
      const group = [];
      const dayOfWeekIncluded = daysOfWeek.includes(dayIndex);

      for (const block in range(hoursInDay)) {
        const value = possibleTimeValues.includes(+block) && dayOfWeekIncluded ? totalFiles : 0;
        group.push(value);
      }

      return {
        ...result,
        [dayIndex]: group,
      };
    }, {});

    return {
      data,
      xAxis: HEATMAP_X_AXIS,
      yAxis: HEATMAP_Y_AXIS,
    };
  },

  isCronMacros(cron) {
    return CRON_SPECIAL_EXPRESSIONS.includes(cron.trim());
  },

  // Validate if the cron will run more than once daily
  isIntraDaySchedule(cron) {
    if (!this.isValid(cron)) return false;
    if (NON_INTRA_DAY_EXPRESSIONS.includes(cron)) return false;

    // trim cron whitespace and take first three cron parts (seconds, minutes, hours) for validation
    const cronParts = take(split(cron.trim(), /\s+/), 3);

    return !every(cronParts, (cronPart) => isInteger(Number(cronPart)));
  },

  // Display a heatmap if it's less than a week. Remove when all kinds of heatmap are supported
  isNonDisplayableCron(cron) {
    const [, , , dates, months, daysOfWeek] = cron.split(' ');

    return (
      dates !== CRON_ANY_VALUE ||
      months !== CRON_ANY_VALUE ||
      (daysOfWeek.includes('L') && daysOfWeek !== 'L') ||
      this.isCronMacros(cron)
    );
  },

  isValid(cron) {
    if (!cron) return false;

    // added validation for cron macros and spring cron that requires minimum 6 parts
    const isCronMacros = this.isCronMacros(cron.trim());
    const invalidPartsCount = cron.trim().split(/\s+/).length !== CRON_PARTS_COUNT;

    if (!isCronMacros && invalidPartsCount) return false;

    return isValidCron(cron, { allowSevenAsSunday: true, seconds: true }) || isCronMacros;
  },

  // tue-thu,sun -> 2-4,0
  mapDaysOfWeek(cronDaysOfWeekPart) {
    cronDaysOfWeekPart = cronDaysOfWeekPart.toUpperCase();

    if (cronDaysOfWeekPart === 'L') return CRON_PART_MAX_VALUE[CRON_PART_INDEX.daysOfWeek];

    CRON_DAY_VALUES.forEach((dayOfWeek, index) => {
      cronDaysOfWeekPart = cronDaysOfWeekPart.replace(dayOfWeek, index);
    });

    cronDaysOfWeekPart = cronDaysOfWeekPart.replace('7', '0');

    return cronDaysOfWeekPart;
  },

  toString(expression, options = {}, showTimeZone = true, ignoreLength = false) {
    if (!expression) return;

    expression = expression.trim();
    const expressionLength = expression.split(' ').length;
    if (!ignoreLength && !this.isCronMacros(expression) && expressionLength !== CRON_PARTS_COUNT) {
      // eslint-disable-next-line no-throw-literal
      throw `Error: Expression has ${expressionLength} part(s). 6 parts are required.`;
    }

    return this.isCronMacros(expression)
      ? CRON_SPECIAL_DESCRIPTIONS[expression]
      : `${cronstrueToString(expression, options)}${showTimeZone ? ` ${CRON_TIMEZONE}` : ''}`;
  },

  toStringIgnoringLength(expression, options = {}, showTimeZone = true) {
    return this.toString(expression, options, showTimeZone, true);
  },
};
