<template>
  <ul>
    <slot />
  </ul>
</template>

<script>
export default {
  name: 'CxDropdownLinks',
};
</script>
