<template>
  <div :class="{ 'flex flex-1 items-center justify-between': !hideResults }">
    <div v-if="!hideResults">
      <i18n-t class="text-gray-700 text-sm" keypath="resultsHelpText" tag="p">
        <template #start>
          <span v-hubble="'start'" class="font-medium">{{ start }}</span>
        </template>
        <template #end>
          <span v-hubble="'end'" class="font-medium">{{ end }}</span>
        </template>
        <template #count>
          <span v-hubble="'total'" class="font-medium">{{ resultsCount }}</span>
        </template>
      </i18n-t>
    </div>
    <div>
      <pagination-buttons
        :next-button-disabled="shouldDisabledNextButton"
        :previous-button-disabled="shouldDisablePreviousButton"
        @previous-button-click="goToPage(modelValue - 1)"
        @next-button-click="goToPage(modelValue + 1)"
      >
        <template #pageNumberButtons>
          <li v-for="(page, index) in range" :key="index">
            <div v-if="page === ellipsis" class="px-3 py-2">{{ page }}</div>
            <button
              v-else
              v-hubble="`page-${page}`"
              class="border border-gray-300 leading-tight px-3 py-2"
              :class="[
                page === modelValue
                  ? 'bg-blue-50 hover:bg-blue-100 hover:text-blue-700 text-blue-600 '
                  : 'bg-white  hover:bg-gray-100 hover:text-gray-700 text-gray-500',
              ]"
              type="button"
              :aria-current="page === modelValue ? $t('page') : false"
              :aria-label="$t('pageNumber', { number: page })"
              @click.prevent.stop="goToPage(page)"
            >
              {{ page }}
            </button>
          </li>
        </template>
      </pagination-buttons>
    </div>
  </div>
</template>

<script>
import PaginationButtons from './subcomponents/PaginationButtons.vue';
export { default as PaginationButtons } from './subcomponents/PaginationButtons.vue';

export const PAGINATION_ELLIPSIS = '...';

export default {
  name: 'CxPagination',

  hubble: 'cx-pagination',

  components: {
    PaginationButtons,
  },

  props: {
    hideResults: {
      type: Boolean,
      default: false,
    },

    maxLinks: {
      type: Number,
      default: 10,
    },

    modelValue: {
      type: Number,
      default: 1,
    },

    pageSize: {
      type: Number,
      default: 10,
    },

    resultsCount: {
      type: Number,
      required: true,
    },
  },

  computed: {
    /* c8 ignore start */
    ellipsis() {
      return PAGINATION_ELLIPSIS;
    },
    /* c8 ignore stop */

    end() {
      return Math.min(this.start + (this.pageSize - 1), this.resultsCount);
    },

    pages() {
      return Math.ceil(this.resultsCount / this.pageSize);
    },

    range() {
      if (this.pages <= this.maxLinks) return this.pages;
      let range = [this.modelValue];
      let left = this.modelValue;
      let right = this.modelValue;
      let i = this.maxLinks - range.length;

      while (i) {
        left--;
        right++;

        if (i) {
          range.push(right);
          i--;
        }

        if (i) {
          range.unshift(left);
          i--;
        }
      }

      const first = range[0];
      const last = range[range.length - 1];

      if (first < 1) {
        range = range.map((num) => num + Math.abs(1 - first));
      } else if (last > this.pages) {
        range = range.map((num) => num - Math.abs(last - this.pages));
      }

      range.splice(0, 1, 1);
      range.splice(-1, 1, this.pages);

      if (range[1] - range[0] > 1) {
        range.splice(-(range.length - 1), 1, PAGINATION_ELLIPSIS);
      }

      if (range[range.length - 1] - range[range.length - 2] > 1) {
        range.splice(-2, 1, PAGINATION_ELLIPSIS);
      }

      return range;
    },

    shouldDisablePreviousButton() {
      return this.modelValue === 1;
    },

    shouldDisabledNextButton() {
      return this.modelValue === this.pages;
    },

    start() {
      return 1 + (this.modelValue - 1) * this.pageSize;
    },
  },

  methods: {
    goToPage(page) {
      this.$emit('update:modelValue', Math.max(Math.min(page, this.pages), 1));
    },
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "page": "page",
    "pagination": "pagination",
    "pageNumber": "go to page {number}",
    "resultsHelpText": "Showing {start} to {end} of {count}"
  }
}
</i18n>
