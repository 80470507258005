import ApiBase from './ApiBase';

export const REPROFILE_ENDPOINT = 'reprofile';

export default class DatastoreService extends ApiBase {
  async cancelProfiling(datastoreId) {
    const { data } = await this.client.post(`${datastoreId}/cancel-profiling`);

    return data;
  }

  async fetchDelimiters(datastoreId) {
    const { data } = await this.client.get(`${datastoreId}/delimiters`);

    return data;
  }

  async fetchOne(payload) {
    const query = payload.lightweightFetch ? '?lightweight=true' : '';
    const { data } = await this.client.get(`${payload.datastoreId}${query}`);

    return data;
  }

  async reprofile(datastoreId, asRaw = false) {
    const { data } = await this.client.post(`${datastoreId}/${REPROFILE_ENDPOINT}?asRaw=${asRaw}`);

    return data;
  }

  async setNormalizer(datastoreId, type, config, reprofile) {
    const { data } = await this.client.put(`${datastoreId}/normalizer?reprofile=${reprofile}`, { config, type });

    return data;
  }

  async update(datastoreId, payload) {
    const { data } = await this.client.put(`${datastoreId}`, payload);

    return data;
  }
}
