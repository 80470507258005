<template>
  <div v-click-outside="() => (open = false)">
    <cx-input
      v-model="query"
      class="mb-2"
      leading-icon="search-line"
      :leading-icon-color="open ? 'blue-600' : 'gray-500'"
      size="lg"
      trailing-icon="close-fill"
      type="text"
      v-bind="$props"
      v-on="_$listeners"
      @enter="$emit('enter')"
      @focus="open = true"
      @trailing-icon-click="resetSearch"
    />
    <cx-autocomplete-panel
      v-if="open"
      :items="results"
      :query="query"
      :recent-search-list="recentSearchList"
      @suggestion-click="updateRecentSearchList"
      @remove-recent-search-list-item-click="updateRecentSearchList"
    />
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3';

import { search } from '~/support/utils';

import CxAutocompletePanel from './subcomponents/CxAutocompletePanel';
import { CxInput } from '../CxInput';

export default {
  name: 'CxAutocomplete',

  directives: {
    clickOutside: vClickOutside.directive,
  },

  components: {
    CxInput,
    CxAutocompletePanel,
  },

  props: {
    items: {
      default: () => [],
      type: Array,
    },

    placeholder: {
      default: '',
      type: String,
    },

    threshold: {
      default: null,
      type: Number,
    },
  },

  data() {
    return {
      open: false,
      query: '',
      recentSearchList: [],
    };
  },

  computed: {
    results() {
      if (!this.query) return [];

      return this.searchInstance.find(this.query);
    },

    searchInstance() {
      const keys = typeof this.items[0] === 'string' ? [] : ['label'];

      if (this.threshold !== null) {
        return search(this.items, keys, { threshold: this.threshold });
      } else {
        return search(this.items, keys);
      }
    },
  },

  methods: {
    resetSearch() {
      this.query = '';
    },

    updateRecentSearchList({ action, item }) {
      if (action === 'add') {
        if (!this.recentSearchList.includes(item)) {
          this.recentSearchList.push(item);
        }
      } else if (action === 'delete') {
        this.recentSearchList.splice(this.recentSearchList.indexOf(item), 1);
      }
    },
  },
};
</script>
