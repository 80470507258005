<template>
  <div class="w-fill" :class="{ 'h-[18rem]': isImage }">
    <slot v-bind="{ handleOpenFileSelect }">
      <div
        class="border border-dashed flex flex-col gap-2 hover:border-blue-600 items-center justify-center p-12 rounded-lg w-fill"
        :class="[borderClasses, heightClasses]"
        @dragover.prevent="dragover"
        @dragleave.prevent.stop="dragleave"
        @drop.prevent="drop"
      >
        <img v-if="isImage" :class="pointerClasses" :src="useImage('image-with-nature.svg')" :alt="$t('imageAlt')" />
        <span :class="pointerClasses">{{ helpText }}</span>
        <cx-button
          v-if="!isDragging"
          v-hubble="'select-button'"
          icon="attachment-2"
          size="sm"
          type="button"
          color="outline"
          @click.prevent.stop="handleOpenFileSelect"
          >{{ $t('buttonText') }}</cx-button
        >
        <span v-if="isImage" :class="pointerClasses">{{ $t('imgHelpText', { size: recommendedSize }) }}</span>
      </div>
    </slot>
    <input ref="file" type="file" hidden name="file" :multiple="multiple" :accept="accept" @change="onChange" />
  </div>
</template>

<script>
import { CxButton } from '../CxButton';

export default {
  name: 'CxFileInput',

  hubble: 'cx-file-input',

  components: { CxButton },

  props: {
    accept: {
      default: '*',
      type: String,
    },

    isImage: {
      default: false,
      type: Boolean,
    },

    multiple: {
      default: false,
      type: Boolean,
    },

    recommendedSize: {
      default: '',
      type: String,
    },
  },

  data() {
    return {
      isDragging: false,
      files: [],
    };
  },

  computed: {
    borderClasses() {
      return this.isDragging ? 'bg-blue-50 border-blue-600' : 'border-gray-500';
    },

    heightClasses() {
      return this.isImage ? 'h-[18rem]' : 'h-40';
    },

    helpText() {
      return this.isDragging ? this.$t('helpTextDragging') : this.$t('helpText');
    },

    pointerClasses() {
      return this.isDragging ? 'pointer-events-none' : '';
    },
  },

  methods: {
    dragleave() {
      this.isDragging = false;
    },

    dragover() {
      this.isDragging = true;
    },

    drop(e) {
      this.files = e.dataTransfer.files;
      this.emitChange();
      this.isDragging = false;
    },

    emitChange() {
      if (this.multiple) {
        this.$emit('files-attached', this.files);
      } else {
        this.$emit('files-attached', this.files[0]);
      }
    },

    handleOpenFileSelect(e) {
      this.$refs.file.click();
    },

    onChange() {
      this.files = [...this.$refs.file.files];
      this.$refs.file.value = '';
      this.emitChange();
    },
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "helpText": "Drag and Drop your files here or",
    "helpTextDragging": "Drop your files here to start uploading",
    "imgAlt": "upload image",
    "imgHelpText": "Recommended image size: {size}",
    "buttonText": "Select From Your Computer"
  }
}
</i18n>
