<template>
  <div class="bg-black/20 fixed flex inset-4 items-center justify-center rounded-lg z-overlay" :class="offsetClass">
    <slot />
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'CxOverlay',

  computed: {
    ...mapState('settings', ['isSidebarOpen']),

    offsetClass() {
      return this.isSidebarOpen ? 'left-[28rem]' : 'left-20';
    },
  },
};
</script>
