import { WorkflowService } from '~/support/services';

export default {
  async fetchFileSchedule(_, workflowId) {
    const service = new WorkflowService(useRuntimeConfig().public.workflowsBaseUrl);

    return await service.fetchFileSchedule(workflowId);
  },

  async fetchStatus(_, workflowId) {
    const service = new WorkflowService(useRuntimeConfig().public.workflowsBaseUrl);

    return await service.fetchStatus(workflowId);
  },

  async updateSchedule(_, { workflowId, payload }) {
    const service = new WorkflowService(useRuntimeConfig().public.workflowsBaseUrl);

    return await service.updateSchedule(workflowId, payload);
  },
};
