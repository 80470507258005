import { cloneDeep } from 'lodash-es';

import {
  AZURE,
  AZURE_DESTINATION_CONFIG,
  BIGQUERY,
  BIGQUERY_DESTINATION_CONFIG,
  COMMON_CONNECTION_FTP_PARAMS,
  COMMON_CONNECTION_FTP_SFTP_PARAMS,
  COMMON_CONNECTION_GCS_PARAMS,
  COMMON_CONNECTION_S3_PARAMS,
  COMMON_CONNECTION_SFTP_PARAMS,
  COMMON_DESTINATION_FTP_SFTP_PARAMS,
  COMMON_SOURCE_FTP_SFTP_PARAMS,
  DESTINATION,
  FTP,
  FTP_DESTINATION_CONFIG,
  FTP_SOURCE_CONFIG,
  GCS,
  GCS_DESTINATION_CONFIG,
  GCS_SOURCE_CONFIG,
  S3,
  S3_DESTINATION_CONFIG,
  S3_SOURCE_CONFIG,
  SFTP,
  SFTP_DESTINATION_CONFIG,
  SFTP_SOURCE_CONFIG,
  SNOWFLAKE,
  SNOWFLAKE_DESTINATION_CONFIG,
  SOURCE,
} from '~/support/constants';
import { DestinationsService, SourcesService } from '~/support/services';

import { SET_CONNECTION_CONFIG, SET_CONNECTIONS, SET_CONNECTIONS_CACHE } from './mutation-types';

export const getConnectionCache = (connectionsCache, type, method) => {
  if (!connectionsCache[type]) {
    connectionsCache[type] = {};
  }
  if (!connectionsCache[type][method]) {
    connectionsCache[type][method] = cloneDeep(getBaseConnectionConfig(type, method));
  }

  return connectionsCache[type][method];
};

export const getBaseConnectionConfig = (type, method) => {
  const isSource = type === SOURCE;
  switch (method) {
    case BIGQUERY:
      return BIGQUERY_DESTINATION_CONFIG;
    case FTP:
      return isSource ? FTP_SOURCE_CONFIG : FTP_DESTINATION_CONFIG;
    case GCS:
      return isSource ? GCS_SOURCE_CONFIG : GCS_DESTINATION_CONFIG;
    case S3:
      return isSource ? S3_SOURCE_CONFIG : S3_DESTINATION_CONFIG;
    case SFTP:
      return isSource ? SFTP_SOURCE_CONFIG : SFTP_DESTINATION_CONFIG;
    case SNOWFLAKE:
      return SNOWFLAKE_DESTINATION_CONFIG;
    case AZURE:
      return AZURE_DESTINATION_CONFIG;
  }

  return {};
};

export default {
  async createDestinationConnection({ commit }, payload) {
    const service = new DestinationsService(useRuntimeConfig().public.destinationsBaseUrl);

    return await service.createDestination(payload);
  },

  async createSourceConnection({ commit }, payload) {
    const service = new SourcesService(useRuntimeConfig().public.sourcesBaseUrl);

    return await service.createSource(payload);
  },

  async deleteDestinationConnection({ commit, dispatch }, connectionId) {
    const service = new DestinationsService(useRuntimeConfig().public.destinationsBaseUrl);

    return await service.deleteDestination(connectionId);
  },

  async deleteSourceConnection({ commit, dispatch }, connectionId) {
    const service = new SourcesService(useRuntimeConfig().public.sourcesBaseUrl);

    return await service.deleteSource(connectionId);
  },

  async discoverPatterns(_, { connectionId, paths }) {
    const service = new SourcesService(useRuntimeConfig().public.sourcesBaseUrl);

    return await service.discoverPatterns(connectionId, paths);
  },

  async editDestinationConnection({ commit, dispatch }, { connection }) {
    const service = new DestinationsService(useRuntimeConfig().public.destinationsBaseUrl);

    return await service.editDestination(connection.id, connection);
  },

  async editSourceConnection({ commit, dispatch }, { connection }) {
    const service = new SourcesService(useRuntimeConfig().public.sourcesBaseUrl);

    return await service.editSource(connection.id, connection);
  },

  async fetchConnections({ commit }, params) {
    const sourceService = new SourcesService(useRuntimeConfig().public.sourcesBaseUrl);
    const destinationService = new DestinationsService(useRuntimeConfig().public.destinationsBaseUrl);
    const [sources, destinations] = await Promise.all([
      sourceService.fetchAll(params),
      destinationService.fetchAll(params),
    ]);

    const connections = [...sources, ...destinations];

    commit(SET_CONNECTIONS, connections);

    return connections;
  },

  async fetchDestinationConnection(_, { id }) {
    const destinationService = new DestinationsService(useRuntimeConfig().public.destinationsBaseUrl);

    return await destinationService.fetchOne(id);
  },

  async fetchFileHistogram(_, { id, patterns }) {
    const sourceService = new SourcesService(useRuntimeConfig().public.sourcesBaseUrl);

    return await sourceService.fetchFileHistogram(id, patterns);
  },

  async fetchSourceConnection(_, { id }) {
    const sourceService = new SourcesService(useRuntimeConfig().public.sourcesBaseUrl);

    return await sourceService.fetchOne(id);
  },

  async fetchSourceTree(_, { connectionId, params }) {
    const service = new SourcesService(useRuntimeConfig().public.sourcesBaseUrl);

    return await service.fetchTree(connectionId, params);
  },

  async patternsSearchSource(_, { connectionId, patterns }) {
    const service = new SourcesService(useRuntimeConfig().public.sourcesBaseUrl);

    return await service.patternsSearchSource(connectionId, patterns);
  },

  resetConnectionsCache({ commit }) {
    commit(SET_CONNECTION_CONFIG, {});
    commit(SET_CONNECTIONS_CACHE, {});
  },

  async searchSource(_, { connectionId, query }) {
    const service = new SourcesService(useRuntimeConfig().public.sourcesBaseUrl);

    return await service.search(connectionId, query);
  },

  setConnectionConfig({ commit, state }, { type, method, config }) {
    const connectionsCache = cloneDeep(state.connectionsCache);
    const connectionCache = getConnectionCache(connectionsCache, type, method);

    let similarMethod;
    let similarCache;
    let params;

    if ([FTP, SFTP].includes(method)) {
      similarMethod = method === FTP ? SFTP : FTP;
      similarCache = getConnectionCache(connectionsCache, type, similarMethod);
      params = type === SOURCE ? COMMON_SOURCE_FTP_SFTP_PARAMS : COMMON_DESTINATION_FTP_SFTP_PARAMS;
    }

    Object.entries(config).forEach(([key, value]) => {
      connectionCache[key] = value;
      if (params?.includes(key)) {
        similarCache[key] = value;
        connectionsCache[type][similarMethod] = similarCache;
      }
    });

    commit(SET_CONNECTION_CONFIG, connectionCache);
    commit(SET_CONNECTIONS_CACHE, connectionsCache);
  },

  updateConnectionCache({ commit, state }, { type, method, key, value }) {
    const connectionsCache = cloneDeep(state.connectionsCache);
    const connectionCache = getConnectionCache(connectionsCache, type, method);

    connectionCache[key] = value;

    const updateSimilarConnectionType = ({ methods, params, type }) => {
      if (params.includes(key)) {
        methods.forEach((method) => {
          const cache = getConnectionCache(connectionsCache, type, method);
          cache[key] = value;
        });
      }
    };

    switch (method) {
      case GCS:
        updateSimilarConnectionType({
          methods: [GCS],
          params: COMMON_CONNECTION_GCS_PARAMS,
          type: type === SOURCE ? DESTINATION : SOURCE,
        });

        break;
      case S3:
        updateSimilarConnectionType({
          methods: [S3],
          params: COMMON_CONNECTION_S3_PARAMS,
          type: type === SOURCE ? DESTINATION : SOURCE,
        });

        break;
      case FTP:
      case SFTP: {
        const similarMethod = method === FTP ? SFTP : FTP;
        const similarCache = getConnectionCache(connectionsCache, type, similarMethod);
        const params = type === SOURCE ? COMMON_SOURCE_FTP_SFTP_PARAMS : COMMON_DESTINATION_FTP_SFTP_PARAMS;

        if (params.includes(key)) {
          similarCache[key] = value;
          connectionsCache[type][similarMethod] = similarCache;
        }

        // update the shared properties for both FTP and SFTP connection
        updateSimilarConnectionType({
          methods: [FTP, SFTP],
          params: COMMON_CONNECTION_FTP_SFTP_PARAMS,
          type: type === SOURCE ? DESTINATION : SOURCE,
        });

        // update the specific properties for FTP or SFTP connection
        updateSimilarConnectionType({
          methods: [method],
          params: method === FTP ? COMMON_CONNECTION_FTP_PARAMS : COMMON_CONNECTION_SFTP_PARAMS,
          type: type === SOURCE ? DESTINATION : SOURCE,
        });

        break;
      }
    }

    commit(SET_CONNECTION_CONFIG, connectionCache);
    commit(SET_CONNECTIONS_CACHE, connectionsCache);
  },

  updateConnectionMethod({ commit, state }, { type, method }) {
    if (method) {
      const connectionsCache = cloneDeep(state.connectionsCache);
      const connectionCache = getConnectionCache(connectionsCache, type, method);
      commit(SET_CONNECTION_CONFIG, connectionCache);
      commit(SET_CONNECTIONS_CACHE, connectionsCache);
    } else {
      commit(SET_CONNECTION_CONFIG, {});
    }
  },

  async walk(_, { connectionId, force, fullTree, root }) {
    const service = new SourcesService(useRuntimeConfig().public.sourcesBaseUrl);

    return await service.walk(connectionId, { force, fullTree, root });
  },

  async walkArchive(_, { connectionId, path }) {
    const service = new SourcesService(useRuntimeConfig().public.sourcesBaseUrl);

    return await service.walkArchive(connectionId, path);
  },
};
