<template>
  <div>
    <div v-if="$slots.default || !!$slots.cornerHint" class="flex justify-between mb-2">
      <label :for="pickerId" class="block font-medium text-gray-900 text-sm">
        <slot />
      </label>
    </div>
    <div ref="picker" class="flex items-center space-x-2 w-full">
      <div class="relative w-1/2">
        <div class="flex justify-between">
          <label :for="pickerIdStart" class="block text-gray-800 text-sm truncate">
            {{ startLabel || $t('labels.from') }}
          </label>
          <span class="text-gray-500 text-sm">
            <slot name="cornerHint" />
          </span>
        </div>
        <div class="relative w-full">
          <div class="absolute flex inset-y-0 items-center left-0 pl-3 pointer-events-none text-gray-500">
            <cx-icon
              aria-hidden="true"
              name="calendar-2-line"
              size="xl"
              class="h-5 text-gray-500 w-5"
              :class="[iconDisabledClass, iconValidityClass]"
            />
          </div>
          <input
            :id="pickerIdStart"
            name="start"
            :disabled="disabled"
            :aria-invalid="invalid"
            class="bg-gray-50 block border border-gray-300 disabled:text-gray-400 flex-1 p-0 p-2.5 pl-10 rounded-lg sm:text-sm w-full"
            :class="[inputDisabledClasses, inputValidityClasses, sizeClasses]"
            autocomplete="off"
            type="text"
            :value="modelValue.start"
            :placeholder="startPlaceholder"
            @changeDate="handleStartChange"
          />
        </div>
      </div>
      <div class="relative w-1/2">
        <div class="flex justify-between">
          <label :for="pickerIdEnd" class="block text-gray-800 text-sm truncate">
            {{ endLabel || $t('labels.to') }}
          </label>
          <span class="text-gray-500 text-sm">
            <slot name="cornerHint" />
          </span>
        </div>
        <div class="relative w-full">
          <div class="absolute flex inset-y-0 items-center left-0 pl-3 pointer-events-none text-gray-500">
            <cx-icon
              aria-hidden="true"
              name="calendar-2-line"
              size="xl"
              class="h-5 text-gray-500 w-5"
              :class="[iconDisabledClass, iconValidityClass]"
            />
          </div>
          <input
            :id="pickerIdEnd"
            ref="end"
            name="end"
            :disabled="disabled"
            :aria-invalid="invalid"
            class="bg-gray-50 block border border-gray-300 disabled:text-gray-400 flex-1 p-0 p-2.5 pl-10 rounded-lg sm:text-sm w-full"
            :class="[inputDisabledClasses, inputValidityClasses, sizeClasses]"
            autocomplete="off"
            type="text"
            :value="modelValue.end"
            :placeholder="endPlaceholder"
            @changeDate="handleEndChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateRangePicker } from 'flowbite-datepicker';

import CxIcon from '../CxIcon/CxIcon.vue';
import { BASE, LG, SM } from '../constants';

export const AUTO = 'auto';
export const BOTTOM = 'bottom';
export const TOP = 'top';
export const ORIENTATIONS = [AUTO, BOTTOM, TOP];
export const DEFAULT_DATE = 'yyyy-mm-dd';
export const START_PLACEHOLDER = 'Select start date';
export const END_PLACEHOLDER = 'Select end date';
export const SIZES = [BASE, LG, SM];

export const SIZE_CLASS_MAP = {
  [BASE]: 'h-11 text-sm',
  [LG]: 'h-14 text-base',
  [SM]: 'h-8 text-sm',
};

export default {
  name: 'CxDateRangePicker',

  components: {
    CxIcon,
  },

  props: {
    autoOpen: {
      default: true,
      type: Boolean,
    },

    autohide: {
      default: true,
      type: Boolean,
    },

    disabled: {
      default: false,
      type: Boolean,
    },

    endLabel: {
      type: String,
      default: '',
    },

    endPlaceholder: {
      default: END_PLACEHOLDER,
      type: String,
    },

    format: {
      type: String,
      default: DEFAULT_DATE,
    },

    invalid: {
      default: false,
      type: Boolean,
    },

    maxDate: {
      default: '',
      type: String,
    },

    minDate: {
      default: '',
      type: String,
    },

    modelValue: {
      type: Object,
      default: () => ({ start: '', end: '' }),
    },

    orientation: {
      default: AUTO,
      type: String,
      validator: (value) => ORIENTATIONS.includes(value),
    },

    size: {
      type: String,
      default: BASE,
      validator: (value) => SIZES.includes(value),
    },

    startLabel: {
      type: String,
      default: '',
    },

    startPlaceholder: {
      default: START_PLACEHOLDER,
      type: String,
    },
  },

  data() {
    return {
      endDate: '',
      picker: null,
      startDate: '',
    };
  },

  computed: {
    date() {
      return { end: this.endDate, start: this.startDate };
    },

    iconDisabledClass() {
      return this.disabled ? 'text-gray-400' : null;
    },

    iconValidityClass() {
      return this.invalid ? 'text-red-500' : null;
    },

    inputDisabledClasses() {
      return this.disabled ? 'bg-gray-100 border-gray-200 text-gray-400 placeholder-gray-400' : null;
    },

    inputValidityClasses() {
      return this.invalid
        ? 'bg-red-50 border-red-300 text-red-900 placeholder-red-400 focus-within:ring-red-500 focus-within:border-red-500'
        : null;
    },

    pickerId() {
      return `cx-datepicker-${this._uid}`;
    },

    pickerIdEnd() {
      return `cx-datepicker-${this._uid}`;
    },

    pickerIdStart() {
      return `cx-datepicker-${this._uid}`;
    },

    sizeClasses() {
      return SIZE_CLASS_MAP[this.size];
    },
  },

  mounted() {
    this.createPicker();
  },

  beforeUnmount() {
    this.destroyPicker();
  },

  methods: {
    createPicker() {
      const picker = this.$refs.picker;

      this.picker = new DateRangePicker(picker, {
        allowOneSidedRange: true,
        autohide: this.autohide,
        format: this.format,
        maxDate: this.maxDate,
        minDate: this.minDate,
        orientation: this.orientation,
      });
    },

    destroyPicker() {
      this.picker.destroy();
      this.picker = null;
    },

    emitDate() {
      this.$emit('update:model-value', { start: this.startDate, end: this.endDate });
    },

    focusSecondInput() {
      this.$refs.end.focus();
    },

    handleEndChange(event) {
      this.endDate = event.target.value;
      this.emitDate();
    },

    handleStartChange(event) {
      this.startDate = event.target.value;
      this.emitDate();

      if (!this.autohide || !this.autoOpen || this.endDate) return;

      this.focusSecondInput();
    },
  },
};
</script>

<style>
@import '~/node_modules/flowbite-datepicker/dist/css/datepicker.css';

.datepicker.hidden {
  /* can't use tailwind hidden class here, creates circular dependency */
  display: none;
}

.datepicker-controls > .prev-btn,
.datepicker-controls > .next-btn {
  @apply flex justify-center;
}

.datepicker-controls > .view-switch {
  @apply mx-2;
}

.datepicker-input.in-edit:focus,
.datepicker-input.in-edit:active {
  box-shadow: none !important;
}
</style>

<i18n lang="json">
{
  "en": {
    "labels": {
      "from": "From",
      "to": "To"
    }
  }
}
</i18n>
