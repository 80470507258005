import { VueLd } from 'vue-ld';

import { ldStubs } from './stubs.js';

export const plugin = (app, $config) => {
  const ldOptions = {
    clientSideId: $config.public.ldClientId,
    user: {
      key: 'client',
    },
    ...($config.public.nodeEnv === 'development' && { flagsStub: ldStubs }),
  };

  app.mixin({
    computed: {
      $flags() {
        return this.$ld.flags;
      },
    },

    methods: {
      $flag(flagId, not = false) {
        if (not) return this.$ld.ready && !this.$ld.flags[flagId];

        return this.$ld.ready && this.$ld.flags[flagId];
      },
    },
  });

  app.use(VueLd, ldOptions);
};

export default defineNuxtPlugin(({ vueApp, $config }) => {
  plugin(vueApp, $config);
});
