import { compact, map } from 'lodash-es';

import { DISABLED, ENABLED } from '~/support/constants';

export default {
  // adding channelMethods, enabledFilter and eventTypes for filtering
  normalizedNotificationRules(state) {
    return state.notificationRules.map((rule) => {
      return {
        ...rule,
        channelMethods: map(rule.channels, 'method'),
        enabledFilter: rule.enabled ? ENABLED : DISABLED,
        eventTypeKeys: compact(map(rule.eventTypes, (event, key) => (event.enabled ? key : ''))),
      };
    });
  },
};
