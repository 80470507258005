export const DATASET_ROUTES = {
  documentation: 'data-products-id-details-datasets-datasetId-documentation',
  frames: 'data-products-id-details-datasets-datasetId-frames',
  history: 'data-products-id-details-datasets-datasetId-history',
  notificationRule: 'data-products-id-details-datasets-datasetId-notification-rule',
  results: 'data-products-id-details-datasets-datasetId-results',
  schedule: 'data-products-id-details-datasets-datasetId-schedule',
};
export const DELIVERY_STATUS_SUCCESS = 'SUCCESS';
export const DELIVERY_STATUS_ERROR = 'ERROR';
export const MAX_DATASET_NAME_LENGTH = 100;
