export const DESTINATION_TYPES_MAP = {
  adx: 'ADX',
  azure: 'AZURE',
  bigquery: 'BIGQUERY',
  databricks: 'DATABRICKS',
  exabel: 'EXABEL',
  ftp: 'FTP',
  gcs: 'GCS',
  s3: 'S3',
  sftp: 'SFTP',
  snowflake: 'SNOWFLAKE',
};

export const DESTINATION_SHARE_TYPES_MAP = {
  bigquery: 'BIGQUERY_SHARE',
  databricks: 'DATABRICKS_SHARE',
  snowflake: 'SNOWFLAKE_SHARE',
};

export const DESTINATION_SHARE_TYPES = [
  DESTINATION_SHARE_TYPES_MAP.bigquery,
  DESTINATION_SHARE_TYPES_MAP.databricks,
  DESTINATION_SHARE_TYPES_MAP.snowflake,
];

export const DESTINATION_IMAGES = {
  [DESTINATION_TYPES_MAP.azure]: 'azure.svg',
  [DESTINATION_TYPES_MAP.bigquery]: 'bigquery.svg',
  [DESTINATION_SHARE_TYPES_MAP.bigquery]: 'bigquery.svg',
  [DESTINATION_TYPES_MAP.exabel]: 'exabel.svg',
  [DESTINATION_TYPES_MAP.databricks]: 'databricks.svg',
  [DESTINATION_SHARE_TYPES_MAP.databricks]: 'databricks.svg',
  [DESTINATION_TYPES_MAP.ftp]: 'ftp.svg',
  [DESTINATION_TYPES_MAP.gcs]: 'gcs.svg',
  [DESTINATION_TYPES_MAP.s3]: 's3.svg',
  [DESTINATION_TYPES_MAP.sftp]: 'sftp.svg',
  [DESTINATION_TYPES_MAP.snowflake]: 'snowflake.svg',
  [DESTINATION_SHARE_TYPES_MAP.snowflake]: 'snowflake.svg',
};

export const DESTINATION_SHARES_DISPLAY_NAME_MAP = {
  [DESTINATION_SHARE_TYPES_MAP.bigquery]: 'BigQuery',
  [DESTINATION_SHARE_TYPES_MAP.databricks]: 'Databricks',
  [DESTINATION_SHARE_TYPES_MAP.snowflake]: 'Snowflake',
};
