import { DATASET } from '~/support/constants';

import ApiBase from './ApiBase';

export const USAGE_STATS_ENDPOINT = 'usage/stats';
export default class MonitoringService extends ApiBase {
  async fetchUsageStats(body, category = DATASET) {
    const { data } = await this.client.post(USAGE_STATS_ENDPOINT, body, { params: { category } });

    return data;
  }
}
