import {
  CRUX_ADMIN,
  DATA_ENGINEER,
  DATA_PRODUCT_EDITOR,
  DATA_PRODUCT_VIEWER,
  ORGANIZATION_ADMIN,
  PROSPECT,
} from './roles';

export const ACCESS_TOKEN = 'token_access';
export const REFRESH_TOKEN = 'token_refresh';

export const CRUX_ROLES = [CRUX_ADMIN, DATA_ENGINEER];
export const ORG_ROLES = [ORGANIZATION_ADMIN, DATA_PRODUCT_EDITOR, DATA_PRODUCT_VIEWER, PROSPECT];

const TYPE_CATALOG = 'catalog';
const TYPE_CONNECTION = 'connection';
const TYPE_DATA_PRODUCT = 'data_product';
const TYPE_HEALTH_DASHBOARD = 'health_dashboard';
const TYPE_NOTIFICATION = 'notification';
const TYPE_ORG = 'org';
const TYPE_PLAN = 'plan';
const TYPE_STUDIO = 'studio';
const TYPE_USAGE = 'usage';
const TYPE_USER = 'user';
const TYPE_USERS = 'users';

const ACTION_ASSIGN_CRUX_ROLES = 'assign_crux_roles';
const ACTION_ASSIGN_ORG_ROLES = 'assign_org_roles';
const ACTION_CREATE = 'create';
const ACTION_CREATE_FORGE = 'create_forge';
const ACTION_CUSTOM_NORMALIZATION = 'custom_normalization';
const ACTION_CUSTOM_REGEX = 'custom_regex';
const ACTION_DEACTIVATE = 'deactivate';
const ACTION_DELETE = 'delete';
const ACTION_IMPORT = 'import';
const ACTION_INTERNAL_DESTINATION = 'internal_destination';
const ACTION_MANAGE_CRUX_SETTINGS = 'manage_crux_settings';
const ACTION_NORMALIZERS = 'normalizers';
const ACTION_OVERRIDE = 'override';
const ACTION_UPDATE = 'update';
const ACTION_UPDATE_API_KEY = 'update_api_key';
const ACTION_VIEW = 'view';
const ACTION_VIEW_ALL_PRODUCTS = 'view_all_products';
const ACTION_VIEW_API_KEY = 'view_api_key';
const ACTION_VIEW_CADENCE_LOGS = 'view_cadence_logs';
const ACTION_VIEW_SUPPLIER_DOCUMENTATION = 'view_supplier_documentation';

const TYPE_ACTION_SEPARATOR = ':';

const entitlement = (type, action) => `${type}${TYPE_ACTION_SEPARATOR}${action}`;

// when new types are introduced
// this object must be copied into the API as well
export const ENTITLEMENTS = {
  catalog: {
    view: entitlement(TYPE_CATALOG, ACTION_VIEW),
    viewAllProducts: entitlement(TYPE_CATALOG, ACTION_VIEW_ALL_PRODUCTS),
    viewSupplierDocumentation: (TYPE_CATALOG, ACTION_VIEW_SUPPLIER_DOCUMENTATION),
  },
  connection: {
    create: entitlement(TYPE_CONNECTION, ACTION_CREATE),
    delete: entitlement(TYPE_CONNECTION, ACTION_DELETE),
    update: entitlement(TYPE_CONNECTION, ACTION_UPDATE),
    view: entitlement(TYPE_CONNECTION, ACTION_VIEW),
  },
  dataProduct: {
    create: entitlement(TYPE_DATA_PRODUCT, ACTION_CREATE),
    createForge: entitlement(TYPE_DATA_PRODUCT, ACTION_CREATE_FORGE),
    customNormalization: entitlement(TYPE_DATA_PRODUCT, ACTION_CUSTOM_NORMALIZATION),
    customRegex: entitlement(TYPE_DATA_PRODUCT, ACTION_CUSTOM_REGEX),
    deactivate: entitlement(TYPE_DATA_PRODUCT, ACTION_DEACTIVATE),
    delete: entitlement(TYPE_DATA_PRODUCT, ACTION_DELETE),
    import: entitlement(TYPE_DATA_PRODUCT, ACTION_IMPORT),
    internalDestination: entitlement(TYPE_DATA_PRODUCT, ACTION_INTERNAL_DESTINATION),
    manageCruxSettings: entitlement(TYPE_DATA_PRODUCT, ACTION_MANAGE_CRUX_SETTINGS),
    normalizers: entitlement(TYPE_DATA_PRODUCT, ACTION_NORMALIZERS),
    override: entitlement(TYPE_DATA_PRODUCT, ACTION_OVERRIDE),
    update: entitlement(TYPE_DATA_PRODUCT, ACTION_UPDATE),
    view: entitlement(TYPE_DATA_PRODUCT, ACTION_VIEW),
    viewCadenceLogs: entitlement(TYPE_DATA_PRODUCT, ACTION_VIEW_CADENCE_LOGS),
  },
  healthDashboard: {
    view: entitlement(TYPE_HEALTH_DASHBOARD, ACTION_VIEW),
  },
  notification: {
    create: entitlement(TYPE_NOTIFICATION, ACTION_CREATE),
    delete: entitlement(TYPE_NOTIFICATION, ACTION_DELETE),
    update: entitlement(TYPE_NOTIFICATION, ACTION_UPDATE),
    view: entitlement(TYPE_NOTIFICATION, ACTION_VIEW),
  },
  org: {
    create: entitlement(TYPE_ORG, ACTION_CREATE),
    deactivate: entitlement(TYPE_ORG, ACTION_DEACTIVATE),
    update: entitlement(TYPE_ORG, ACTION_UPDATE),
    view: entitlement(TYPE_ORG, ACTION_VIEW),
  },
  plan: {
    view: entitlement(TYPE_PLAN, ACTION_VIEW),
  },
  studio: {
    view: entitlement(TYPE_STUDIO, ACTION_VIEW),
  },
  usage: {
    view: entitlement(TYPE_USAGE, ACTION_VIEW),
  },
  user: {
    assignCruxRoles: entitlement(TYPE_USER, ACTION_ASSIGN_CRUX_ROLES),
    assignOrgRoles: entitlement(TYPE_USER, ACTION_ASSIGN_ORG_ROLES),
    create: entitlement(TYPE_USER, ACTION_CREATE),
    delete: entitlement(TYPE_USER, ACTION_DELETE),
    update: entitlement(TYPE_USER, ACTION_UPDATE),
    updateApiKey: entitlement(TYPE_USER, ACTION_UPDATE_API_KEY),
    viewApiKey: entitlement(TYPE_USER, ACTION_VIEW_API_KEY),
  },
  users: {
    view: entitlement(TYPE_USERS, ACTION_VIEW),
  },
};

export const ENTITLEMENT_ROLES = {
  [CRUX_ADMIN]: [ENTITLEMENTS.usage.view, ENTITLEMENTS.user.assignCruxRoles, ENTITLEMENTS.user.assignOrgRoles],
  [DATA_ENGINEER]: [ENTITLEMENTS.usage.view],
  [DATA_PRODUCT_EDITOR]: [],
  [DATA_PRODUCT_VIEWER]: [],
  [ORGANIZATION_ADMIN]: [ENTITLEMENTS.usage.view, ENTITLEMENTS.user.assignOrgRoles],
};

export const USER_SKELETON = {
  agreements: [],
  createdAt: '',
  email: '',
  entitlements: [],
  firstName: '',
  lastName: '',
  name: '',
  orgId: '',
  orgName: '',
  roles: [],
  showOnlyMyCatalogData: false,
  status: '',
  subscription: {
    endDate: 0,
    id: '',
    itemPriceId: '',
    plan: '',
    startDate: 0,
    status: '',
  },
  userId: '',
};
