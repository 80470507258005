import { CatalogService } from '~/support/services';

import { SET_BREADCRUMBS } from './mutation-types';

export default {
  addBreadcrumb({ commit, state }, breadcrumb) {
    if (!breadcrumb.label) return;

    commit(SET_BREADCRUMBS, [...state.breadcrumbs, breadcrumb]);
  },

  async contactCrux(_, { body }) {
    const service = new CatalogService(useRuntimeConfig().public.catalogBaseUrl);
    const data = await service.contactCrux(body);

    return data;
  },

  async contactSupplier(_, { supplierId, body }) {
    const service = new CatalogService(useRuntimeConfig().public.catalogBaseUrl);
    const data = await service.contactSupplier(supplierId, body);

    return data;
  },

  async fetchAllSuppliers() {
    const service = new CatalogService(useRuntimeConfig().public.catalogBaseUrl);
    const data = await service.fetchSuppliers({ all: true });

    return data;
  },

  async fetchDataProduct(_, id) {
    const service = new CatalogService(useRuntimeConfig().public.catalogBaseUrl);
    const data = await service.fetchDataProduct(id);

    return data;
  },

  async fetchDataProductFamilies(_, { filters, query, sort }) {
    const service = new CatalogService(useRuntimeConfig().public.catalogBaseUrl);
    const data = await service.fetchDataProductFamilies(filters, query, sort);

    return data;
  },

  async fetchDataProducts(_, params) {
    const service = new CatalogService(useRuntimeConfig().public.catalogBaseUrl);
    const data = await service.fetchDataProducts(params);

    return data;
  },

  async fetchFilterCounts(_, params) {
    const service = new CatalogService(useRuntimeConfig().public.catalogBaseUrl);
    const data = await service.fetchFilterCounts(params);

    return data;
  },

  async fetchFilters(_, params) {
    const service = new CatalogService(useRuntimeConfig().public.catalogBaseUrl);
    const data = await service.fetchFilters(params);

    return data;
  },

  async fetchFrameSchemaSample(_, { datasetId, frameId }) {
    const service = new CatalogService(useRuntimeConfig().public.catalogBaseUrl);
    const data = await service.fetchFrameSchemaSample(datasetId, frameId);

    return data;
  },

  async fetchFrameSchemaVersions(_, { datasetId, frameId }) {
    const service = new CatalogService(useRuntimeConfig().public.catalogBaseUrl);
    const data = await service.fetchFrameSchemaVersions(datasetId, frameId);

    return data;
  },

  async fetchFramesByDataProductAndDataset(_, { dataProductId, datasetId }) {
    const service = new CatalogService(useRuntimeConfig().public.catalogBaseUrl);
    const data = await service.fetchFramesByDataProductAndDataset(dataProductId, datasetId);

    return data;
  },

  async fetchRelatedProducts(_, dataProductId) {
    const service = new CatalogService(useRuntimeConfig().public.catalogBaseUrl);
    const data = await service.fetchRelatedProducts(dataProductId);

    return data;
  },

  async fetchSupplier(_, id) {
    const service = new CatalogService(useRuntimeConfig().public.catalogBaseUrl);
    const data = await service.fetchSupplier(id);

    return data;
  },

  async fetchSupplierProducts(_, params) {
    const service = new CatalogService(useRuntimeConfig().public.catalogBaseUrl);
    const data = await service.fetchSupplierProducts(params);

    return data;
  },

  async fetchSuppliers(_, { query, sort } = {}) {
    const service = new CatalogService(useRuntimeConfig().public.catalogBaseUrl);
    const data = await service.fetchSuppliers({ query, sort });

    return data;
  },

  removeBreadcrumb({ commit, state }, breadcrumb) {
    // TODO why do we need position here? Why can't we just compare the objects?
    commit(
      SET_BREADCRUMBS,
      state.breadcrumbs.filter(({ position }) => position !== breadcrumb.position),
    );
  },

  async requestDataProductAccess(_, { dataProductId, body }) {
    const service = new CatalogService(useRuntimeConfig().public.catalogBaseUrl);
    const data = await service.requestDataProductAccess(dataProductId, body);

    return data;
  },
};
