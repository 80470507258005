import { PipelineService } from '~/support/services';

export default {
  async fetchSchemaSampleData(_, pipelineId) {
    const service = new PipelineService(useRuntimeConfig().public.pipelinesBaseUrl);
    const { data } = await service.fetchSchemaSampleData(pipelineId);

    return data;
  },

  async updateSchema(_, payload) {
    const service = new PipelineService(useRuntimeConfig().public.pipelinesBaseUrl);
    const data = await service.updateSchema(payload);

    return data;
  },
};
