export const CDU = 'CDU';
export const DATASET = 'DATASET';

export const USAGE_CATEGORIES = {
  CDU,
  DATASET,
};

export const DAY = 'day';
export const MONTH = 'month';
export const WEEK = 'week';
export const YEAR = 'year';

export const PERIOD_MAP = {
  [MONTH]: DAY,
  [WEEK]: DAY,
  [YEAR]: MONTH,
};

export const TIME_MAP = {
  [MONTH]: MONTH,
  [WEEK]: DAY,
  [YEAR]: YEAR,
};

export const USAGE_HISTORY_MULTISELECT_OPTIONS = [
  { color: 'purple', label: 'Raw rows', value: 'raw' },
  { color: 'teal', label: 'Processed rows', value: 'processed' },
  { color: 'orange', label: 'Delivered rows', value: 'delivered' },
];
export const USAGE_HISTORY_TABLE_TIMEOUT = 1000;
export const BEGINNING_OF_YEAR = '2023-01-01';
export const USAGE_TIME_INTERVAL_OPTIONS = [
  { label: 'Last 7 Days', value: WEEK },
  { label: 'Last 1 Month', value: MONTH },
  { label: 'Last 1 Year', value: YEAR },
];
