import { createStore } from 'vuex';

import * as modules from '~/store';

export const store = createStore({
  modules,
});

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.use(store);
});
