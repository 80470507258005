<template>
  <component :is="tag" class="group/clipboard inline-flex items-center space-x-2">
    <span v-if="!!$slots.default" ref="wrapper"><slot /></span>
    <div
      class="-translate-x-2 group-focus-within/clipboard:opacity-100 group-hover/clipboard:opacity-100 group-hover/clipboard:translate-x-0 hover:text-gray-800 opacity-0 text-gray-500 transition"
    >
      <cx-tooltip :content="$t('copy')">
        <div
          ref="clipboardButton"
          role="button"
          tabindex="0"
          class="flex p-1"
          :aria-label="$t('copy')"
          @keyup.enter.stop.prevent="handleCopyClick"
          @click.stop.prevent="handleCopyClick"
        >
          <cx-icon :name="copyIcon" size="lg" />
        </div>
      </cx-tooltip>
    </div>
  </component>
</template>

<script>
import { CxIcon } from '../CxIcon';
import { CxTooltip } from '../CxTooltip';
import { COPIED_TIMEOUT } from '../constants';

export const CLICK = 'click';

export default {
  name: 'CxClipboard',

  components: { CxIcon, CxTooltip },

  props: {
    content: {
      type: String,
      default: '',
    },

    tag: {
      default: 'span',
      type: String,
    },
  },

  data() {
    return {
      copied: false,
    };
  },

  computed: {
    copyIcon() {
      return this.copied ? 'check-line' : 'file-copy-line';
    },
  },

  methods: {
    handleCopyClick(event) {
      if (this.copied) return;

      this.copied = true;

      const content = this.content || this.$refs.wrapper?.innerText;

      navigator.clipboard.writeText(content);
      setTimeout(() => (this.copied = false), COPIED_TIMEOUT);

      if (event.type === CLICK) this.$refs.clipboardButton.blur();
    },
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "copy": "Copy"
  }
}
</i18n>
