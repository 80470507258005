import ApiBase from './ApiBase';

export default class AuthService extends ApiBase {
  async getUser() {
    const { data } = await this.client.get('/me');

    return data;
  }

  async login(credentials) {
    const { data } = await this.client.post('/login', credentials);

    return data;
  }

  async refreshTokens(refreshToken) {
    const { data } = await this.client.post('/refresh', { refreshToken });

    return data;
  }

  async logout() {
    const { data } = await this.client.post('/logout');

    return data;
  }
}
