import ApiBase from './ApiBase';

export default class DocumentService extends ApiBase {
  async create(body, params) {
    const { data } = await this.client.post('', body, { params });

    return data;
  }

  async createWithContent(body, params) {
    const { data } = await this.client.post('/content', body, { params });

    return data;
  }

  async fetchOne(documentId) {
    const { data } = await this.client.get(documentId);

    return data;
  }

  async fetchContentSignedUrl(documentId) {
    const { data } = await this.client.get(`${documentId}/content-signed-url`);

    return data;
  }

  async fetchAll(params) {
    const { data } = await this.client.get('', { params });

    return data;
  }

  async update(documentId, body) {
    const { data } = await this.client.put(documentId, body);

    return data;
  }

  async deleteDocument(documentId) {
    await this.client.delete(documentId);
  }
}
