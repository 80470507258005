import { DEFAULT_TIMEZONE, SELECTED_TIMEZONE_KEY } from '~/support/constants';

import {
  DISMISS_DESTINATION_BANNER,
  DISMISS_FREE_BANNER,
  DISMISS_PRO_ACTIVATION_LIMIT_BANNER,
  DISMISS_SCHEDULE_BANNER,
  DISMISS_TRIAL_BANNER,
  SET_SELECTED_TIMEZONE,
  SET_SIDEBAR_STATUS,
  SET_STUDIO_RIGHT_PANEL_STATUS,
} from './mutation-types';

export default {
  dismissDestinationBanner({ commit }) {
    commit(DISMISS_DESTINATION_BANNER);
  },

  dismissFreeBanner({ commit }) {
    commit(DISMISS_FREE_BANNER);
  },

  dismissProActivationLimitBanner({ commit }) {
    commit(DISMISS_PRO_ACTIVATION_LIMIT_BANNER);
  },

  dismissScheduleBanner({ commit }) {
    commit(DISMISS_SCHEDULE_BANNER);
  },

  dismissTrialBanner({ commit }) {
    commit(DISMISS_TRIAL_BANNER);
  },

  getSelectedTimezone({ commit }) {
    const selectedTimezone = localStorage.getItem(SELECTED_TIMEZONE_KEY) || DEFAULT_TIMEZONE;

    commit(SET_SELECTED_TIMEZONE, selectedTimezone);
  },

  setSelectedTimezone({ commit }, timezone) {
    localStorage.setItem(SELECTED_TIMEZONE_KEY, timezone);
    commit(SET_SELECTED_TIMEZONE, timezone);
  },

  setSidebarStatus({ commit }, isOpened) {
    commit(SET_SIDEBAR_STATUS, isOpened);
  },

  toggleStudioRightPanel({ commit }, isOpened) {
    commit(SET_STUDIO_RIGHT_PANEL_STATUS, isOpened);
  },
};
