import FloatingVue from 'floating-vue';

import { DELAY_HIDE_DEFAULT, DELAY_SHOW_DEFAULT, DISTANCE_DEFAULT } from './CxTooltip.vue';

import 'floating-vue/dist/style.css';
import './CxTooltip.css';

export default {
  install(app) {
    app.use(FloatingVue, {
      distance: DISTANCE_DEFAULT,
      themes: {
        tooltip: {
          delay: {
            hide: DELAY_HIDE_DEFAULT,
            show: DELAY_SHOW_DEFAULT,
          },
        },
      },
    });
  },
};
