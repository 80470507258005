import { compact, filter, get, isArray, isEmpty, isString, map } from 'lodash-es';

import { CATALOG_FILTER_OPERATORS, OPERATORS } from '~/support/constants';

export default {
  appliedFilters(filters) {
    const appliedFilters = {};

    for (const key in filters) {
      const { value } = filters[key];
      if (value.length) appliedFilters[key] = filters[key];
    }

    return appliedFilters;
  },

  appliedFiltersCount(appliedFilters) {
    const values = map(appliedFilters, 'value');
    let total = 0;

    values.forEach((value) => {
      if (isArray(value)) return (total += compact(value).length);

      total++;
    });

    return total;
  },

  filterData(values, appliedFilters) {
    return values.filter((value) => this.prefilter(value, appliedFilters));
  },

  hasAllFalsyValues(filters) {
    return Object.keys(filters).every((key) => {
      return (isArray(filters[key]) && isEmpty(filters[key])) || filters[key] === false;
    });
  },

  prefilter(item, appliedFilters) {
    const trueFilters = filter(appliedFilters, ({ operator, value }, key) => {
      const itemValue = get(item, key);

      switch (operator) {
        case OPERATORS.contains:
          return value.every((elem) => itemValue.includes(elem));
        case OPERATORS.in:
          return value.includes(itemValue);
        case OPERATORS.is:
          return itemValue == value; // eslint-disable-line eqeqeq
        case OPERATORS.isBefore:
          return itemValue < value;
        case OPERATORS.isAfter:
          return itemValue > value;
        case OPERATORS.isOnOrBefore:
          return itemValue <= value;
        case OPERATORS.isOnOrAfter:
          return itemValue >= value;
      }
    });

    return trueFilters.length === Object.values(appliedFilters).length;
  },

  removeEmptyValues(filters) {
    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if ((isArray(value) || isString(value)) && value.length === 0) delete filters[key];
    });

    return filters;
  },

  stringify(rawFilters, options = { noQuotes: false }) {
    const filters = this.removeEmptyValues(rawFilters);

    const transformedFilters = Object.keys(filters).map((key) => {
      const value = filters[key];
      if (isArray(value)) {
        const mappedValue = value
          .map((item) => {
            return options.noQuotes ? item : `"${item}"`;
          })
          .join();

        return `${key}.${CATALOG_FILTER_OPERATORS.in}.(${mappedValue})`;
      }

      return `${key}.${CATALOG_FILTER_OPERATORS.eq}.${value}`;
    });

    return transformedFilters.join();
  },
};
