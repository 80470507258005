import { get } from 'lodash-es';

import { store } from '~/plugins/vuex';

import { EDP, ORG_INACTIVE_STATUS, SUBSCRIPTION_PLANS } from '../constants';

function hasEntitlement(entitlement) {
  const { user } = store.state.auth;
  const isLoggedIn = !!user;

  if (!isLoggedIn) return false;

  return user.entitlements.includes(entitlement);
}

function hasOnlyOwnCatalogData() {
  const { user } = store.state.auth;
  const isLoggedIn = !!user;

  if (!isLoggedIn) return false;

  return user.showOnlyMyCatalogData;
}

function hasRole(role) {
  const { user } = store.state.auth;
  const isLoggedIn = !!user;

  if (!isLoggedIn) return false;

  return user.roles.includes(role);
}

function hasSelfServicePlan() {
  const { user } = store.state.auth;
  const plan = get(user.org, `subscriptions.${EDP}.plan`);
  const status = get(user.org, `subscriptions.${EDP}.status`);

  return plan !== SUBSCRIPTION_PLANS.edpAwaiting && status !== ORG_INACTIVE_STATUS;
}

export function useAuth() {
  return {
    hasEntitlement,
    hasOnlyOwnCatalogData,
    hasRole,
    hasSelfServicePlan,
  };
}
