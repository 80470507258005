export const CRUX_ADMIN = 'ciam-role-CruxAdmin';
export const DATA_ENGINEER = 'ciam-role-CruxDataEngineer';
export const DATA_PRODUCT_EDITOR = 'ciam-role-DataProductEditor';
export const DATA_PRODUCT_VIEWER = 'ciam-role-DataProductViewer';
export const ORGANIZATION_ADMIN = 'ciam-role-OrganizationAdmin';
export const PROSPECT = 'ciam-role-Prospect';

export const USER_ROLE_OPTIONS = [
  { label: 'Prospect', value: PROSPECT },
  { label: 'Viewer', value: DATA_PRODUCT_VIEWER },
  { label: 'Editor', value: DATA_PRODUCT_EDITOR },
  { label: 'Admin', value: ORGANIZATION_ADMIN },
];

export const CRUX_ADMIN_USER_ROLE_OPTIONS = [
  { label: 'Crux Admin', value: CRUX_ADMIN },
  { label: 'Crux Data Engineer', value: DATA_ENGINEER },
  ...USER_ROLE_OPTIONS,
];

export const ORG_ADMIN_WIZARD_ROLE_OPTIONS = [
  { label: 'Admin', value: ORGANIZATION_ADMIN },
  { label: 'Crux Admin', value: CRUX_ADMIN },
];

export const USER_ROLE_MAP = CRUX_ADMIN_USER_ROLE_OPTIONS.reduce((result, { label, value }) => {
  return {
    ...result,
    [value]: label,
  };
}, {});
