<template>
  <component :is="computedTag" :to="to" class="leading-none">
    <div class="font-medium inline-block leading-tight px-2.5 py-0.5 rounded" :class="[colorClasses, sizeClasses]">
      <slot />
    </div>
  </component>
</template>

<script>
import { BASE, LG } from '../constants';

export const DEFAULT_TAG = 'span';
export const DEFAULT_COLOR = 'blue';
export const SIZES = [BASE, LG];

export const COLOR_CLASS_MAP = {
  blue: 'bg-blue-100 text-blue-800',
  darkBlue: 'bg-blue-200 text-blue-600',
  darkerBlue: 'bg-blue-600 text-white',
  ghost: 'bg-white text-gray-900',
  gray: 'bg-gray-100 text-gray-800',
  darkGray: 'bg-gray-200 text-gray-900',
  darkerGray: 'bg-gray-300 text-gray-700',
  darkestGray: 'bg-gray-400 text-white',
  disabled: 'bg-gray-100 text-gray-500',
  disabledWhite: 'bg-white text-gray-500',
  red: 'bg-red-100 text-red-800',
  darkRed: 'bg-red-200 text-red-600',
  green: 'bg-green-100 text-green-800',
  darkGreen: 'bg-green-200 text-green-600',
  yellow: 'bg-yellow-100 text-yellow-700',
  indigo: 'bg-indigo-100 text-indigo-800',
  purple: 'bg-purple-100 text-purple-800',
  pink: 'bg-pink-100 text-pink-800',
  orange: 'bg-orange-100 text-orange-800',
  darkOrange: 'bg-orange-200 text-orange-600',
  teal: 'bg-teal-100 text-teal-800',
  white: 'bg-white text-gray-900',
  whiteOutline: 'bg-white border border-gray-900 text-gray-600',
};

export const SIZE_CLASS_MAP = {
  [BASE]: 'text-xs',
  [LG]: 'text-sm',
};

export default {
  name: 'CxBadge',

  props: {
    color: {
      type: String,
      default: DEFAULT_COLOR,
      validator: (value) => Object.keys(COLOR_CLASS_MAP).includes(value),
    },

    size: {
      type: String,
      default: BASE,
      validator: (value) => SIZES.includes(value),
    },

    tag: {
      type: String,
      default: DEFAULT_TAG,
    },

    to: {
      default: () => {},
      type: [Object, String],
    },
  },

  computed: {
    colorClasses() {
      return COLOR_CLASS_MAP[this.color];
    },

    computedTag() {
      return this.to ? defineNuxtLink({}) : this.tag;
    },

    sizeClasses() {
      return SIZE_CLASS_MAP[this.size];
    },
  },
};
</script>
