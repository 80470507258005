import VueHubble from '@crishellco/vue-hubble';

const options = {
  enableDeepNamespacing: false,
  environment: ['development', 'test', 'production'],
};

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.use(VueHubble, options);
});
