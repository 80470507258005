import { filtering } from '~/support/utils';

import ApiBase from './ApiBase';

export default class CatalogService extends ApiBase {
  constructFilterParams(filters) {
    let subscribed = false;

    // We want to send subscribed as its own query param, not in `filters`
    if (filters) {
      subscribed = Boolean(filters.subscribed);
      delete filters.subscribed;
    }

    return filters ? { filter: JSON.stringify(filtering.removeEmptyValues(filters)), subscribed } : { subscribed };
  }

  async contactCrux(body) {
    const { data } = await this.client.post(`/contact-crux`, body);

    return data;
  }

  async contactSupplier(supplierId, body) {
    const { data } = await this.client.post(`/suppliers/${supplierId}/contact`, body);

    return data;
  }

  async fetchDataProduct(id) {
    const { data } = await this.client.get(`/data-products/${id}`);

    return data;
  }

  async fetchDataProductFamilies(filters, query, sort) {
    const params = filters ? { filter: JSON.stringify(filtering.removeEmptyValues(filters)) } : {};
    const { data } = await this.client.get(`/data-product-families`, { params: { ...params, query, sort } });

    return data;
  }

  async fetchDataProducts(params) {
    const { familyName, filters, includeAllProducts, includeTotalCount, limit, offset, query, sort } = params;

    const { data } = await this.client.get(`/data-products`, {
      params: {
        ...this.constructFilterParams(filters),
        familyName,
        includeAllProducts,
        includeTotalCount,
        limit,
        offset,
        query,
        sort,
      },
    });

    return data;
  }

  async fetchFilterCounts({ familyName, filters, includeAllProducts, query }) {
    const { data } = await this.client.get(`/filter-counts`, {
      params: {
        ...this.constructFilterParams(filters),
        familyName,
        includeAllProducts,
        query,
      },
    });

    return data;
  }

  async fetchFilters(params) {
    const { data } = await this.client.get(`/filters`, { params });

    return data;
  }

  async fetchFramesByDataProductAndDataset(dataProductId, datasetId) {
    const { data } = await this.client.get(`/data-products/${dataProductId}/datasets/${datasetId}/frames`);

    return data;
  }

  async fetchFrameSchemaSample(datasetId, frameId) {
    const { data } = await this.client.get(`/datasets/${datasetId}/frames/${frameId}/schema-sample`);

    return data;
  }

  async fetchFrameSchemaVersions(datasetId, frameId) {
    const { data } = await this.client.get(`/datasets/${datasetId}/frames/${frameId}/schema-versions`);

    return data;
  }

  async fetchRelatedProducts(dataProductId) {
    const { data } = await this.client.get(`/data-products/${dataProductId}/related-products`);

    return data;
  }

  async fetchSupplier(id) {
    const { data } = await this.client.get(`/suppliers/${id}`);

    return data;
  }

  async fetchSupplierProducts(params) {
    const { query, sort, supplierId } = params;
    const { data } = await this.client.get(`/suppliers/${supplierId}/data-products`, {
      params: {
        query,
        sort,
      },
    });

    return data;
  }

  async fetchSuppliers(params) {
    const { data } = await this.client.get('/suppliers', { params });

    return data;
  }

  async requestDataProductAccess(dataProductId, body) {
    const { data } = await this.client.post(`/data-products/${dataProductId}/request-access`, body);

    return data;
  }
}
