<template>
  <div>
    <cx-list-group v-if="items.length || recentSearchList.length">
      <div v-for="(section, name) in sections" :key="`autocomplete-suggestions-section-${name}`">
        <cx-list-group-section>
          <template v-if="name !== defaultSectionLabel" #label>
            <div class="-mt-2">{{ name }}</div>
          </template>
          <div
            v-for="(item, idx) in section"
            :key="`autocomplete-suggestions-section-${name}-item-${idx}`"
            @click="$emit('suggestion-click', { action: 'add', item })"
          >
            <cx-list-group-item
              color="light"
              :leading-icon="item.icon || 'search-line'"
              @focus="$emit('item-focus', item)"
            >
              <!-- eslint-disable vue/no-v-html -->
              <span v-html="highlight(item)" />
              <!-- eslint-enable vue/no-v-html -->
            </cx-list-group-item>
          </div>
        </cx-list-group-section>
      </div>
      <cx-list-group-section>
        <template v-if="recentSearchList.length" #label>
          <div class="-mt-2">Recent</div>
        </template>
        <div v-for="(recentSearchItem, rIdx) in recentSearchList" :key="`recent-${rIdx}`">
          <cx-list-group-item
            color="light"
            :leading-icon="recentSearchItem.icon || 'search-line'"
            trailing-icon="close-fill"
            @delete="
              $emit('remove-recent-search-list-item-click', {
                action: 'delete',
                item: recentSearchItem,
              })
            "
            @focus="$emit('item-focus', recentSearchItem)"
            @trailing-icon-click="
              $emit('remove-recent-search-list-item-click', {
                action: 'delete',
                item: recentSearchItem,
              })
            "
          >
            <!-- eslint-disable vue/no-v-html -->
            <span v-html="highlight(recentSearchItem)" />
            <!-- eslint-enable vue/no-v-html -->
          </cx-list-group-item>
        </div>
      </cx-list-group-section>
    </cx-list-group>
  </div>
</template>

<script>
import { CxListGroup, CxListGroupItem, CxListGroupSection } from '../../CxListGroup';

export const DEFAULT_SECTION_LABEL = '___';

export default {
  name: 'CxAutocompletePanel',

  components: {
    CxListGroup,
    CxListGroupItem,
    CxListGroupSection,
  },

  props: {
    items: {
      default: () => [],
      type: Array,
    },

    query: {
      default: '',
      type: String,
    },

    recentSearchList: {
      default: () => [],
      type: Array,
    },
  },

  data() {
    return {
      defaultSectionLabel: DEFAULT_SECTION_LABEL,
    };
  },

  computed: {
    sections() {
      if (!this.items.length) return;

      if (typeof this.items[0] === 'string') {
        return {
          [DEFAULT_SECTION_LABEL]: this.items,
        };
      }

      return this.items.reduce((result, item) => {
        const { section = DEFAULT_SECTION_LABEL, ...itemAttrs } = item;

        return {
          ...result,
          [section]: [...(result[section] || []), { ...itemAttrs }],
        };
      }, {});
    },
  },

  methods: {
    highlight(item) {
      const content = item.label || item;

      if (!this.query) return content;

      return content.replace(new RegExp(this.query, 'gi'), (match) => {
        return `<span class="text-blue-600">${match}</span>`;
      });
    },
  },
};
</script>
