export const sameWidth = {
  effect: ({ state }) => {
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
  },
  enabled: true,
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  name: 'sameWidth',

  phase: 'beforeWrite',

  requires: ['computeStyles'],
};

export const setWidth = (width) => ({
  effect: ({ state }) => {
    state.elements.popper.style.width = `${width}px`;
  },
  enabled: true,
  fn: ({ state }) => {
    state.styles.popper.width = `${width}px`;
  },
  name: 'sameWidth',

  phase: 'beforeWrite',

  requires: ['computeStyles'],
});
