function flagEnabled(flagId, not = false) {
  const app = useNuxtApp();
  const $ld = app.vueApp.config.globalProperties.$ld;

  return not ? Boolean($ld.ready && !$ld.flags[flagId]) : Boolean($ld.ready && $ld.flags[flagId]);
}

export function useFeatureFlags() {
  return {
    flagEnabled,
  };
}
