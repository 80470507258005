import { lowerFirst } from 'lodash-es';

import { USER_SKELETON } from '~/support/constants';

export default defineNuxtPlugin(({ vueApp }) => {
  // Global mixin for backwards compatibility
  vueApp.mixin({
    computed: {
      $auth() {
        const dispatchAction = (action, payload) => this.$store.dispatch(`auth/${action}`, payload);
        const user = this.$store.state.auth.user;

        return {
          getUser: () => dispatchAction('getUser'),
          login: (payload) => dispatchAction('login', payload),
          logout: () => dispatchAction('logout'),
          refreshTokens: (payload) => dispatchAction('refreshTokens', payload),
          user: user?.userId ? user : USER_SKELETON,
        };
      },

      // $listeners is deprecated in Vue 3, this mixin will live until we migrate all $listeners usage
      _$listeners() {
        return getListeners(this.$attrs) || {};
      },

      // Some components use "this._uid" but it seems like now it's not working the same way as in Vue 2 so we overwrite it
      _uid() {
        return Math.random().toString(16).slice(2);
      },
    },

    // @created hook's lifecycle point is almost the same as script setup's one on root level so we can invoke composables here ( kind of a hack )
    created() {
      const { rt, t, te, tm } = useI18n();

      // due to nuxt 3.8.0 killing html in i18n translations we now swap them here
      const htmlReplacements = {
        b: '<b>',
        bClose: '</b>',
        boldGray: '<span class="font-bold text-gray-900">',
        br: '<br />',
        breakAll: '<span class="break-all">',
        breakAllBoldGray: '<span class="break-all font-bold text-gray-900">',
        fontMedium: '<span class="font-medium">',
        i: '<i>',
        iClose: '</i>',
        mediumBlue: '<span class="font-medium text-blue-900">',
        p: '<p>',
        pClose: '</p>',
        span: '<span>',
        spanClose: '</span>',
      };

      // '$t' method which is globally injected into options API doesn't read local <i18n> block translations
      // so we replace it with the one which comes from the composable ( apparently these 2 methods work differently for some reason )
      this.$rt = rt;
      this.$t = (key, values) => t(key, { ...htmlReplacements, ...values });
      this.$tc = t; // t supports pluralization
      this.$te = te;
      this.$tm = tm;
    },

    // eslint-disable-next-line vue/order-in-components
    inject: {
      $mixpanel: 'mixpanel',
    },

    methods: {
      // Necessary replacement for `require(SOME_IMAGE)` in templates due to how we read files with Vite
      useImage(imgName) {
        return new URL(`../assets/img/${imgName}`, import.meta.url).href;
      },
    },
  });
});

const getListeners = (attrs) =>
  Object.entries(attrs).reduce((result, [key, value]) => {
    if (key.startsWith('on') && typeof value === 'function') {
      // We remove 'on' prefix, because everything which we pass to v-on="...", it gets automatically prefixed with "on"
      // If we don't do this here, events will become "onOn<something>"
      const fixedKey = lowerFirst(key.replace('on', ''));

      result[fixedKey] = value;
    }

    return result;
  }, {});
