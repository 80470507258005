import { RESOURCES } from '~/support/fixtures';

import ApiBase from './ApiBase';

export default class ResourcesService extends ApiBase {
  fetchResources() {
    /**
     * When the service exists, this method
     * will look something like this:
     *
     * return this.client('/');
     */

    return Promise.resolve({ data: RESOURCES });
  }
}
