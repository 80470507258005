<template>
  <cx-modal :title="computedTitle" width-class="w-152" @close="$emit('cancel')">
    <template #default>
      <slot />
    </template>
    <template #footer>
      <div class="flex justify-end space-x-2 w-full">
        <cx-button v-hubble="'cancel-button'" color="quaternary" type="button" @click="$emit('cancel')">
          {{ computedCancelButtonText }}
        </cx-button>
        <cx-button v-hubble="'confirm-button'" :color="confirmButtonColor" :loading="loading" @click="$emit('confirm')">
          {{ computedConfirmButtonText }}
        </cx-button>
      </div>
    </template>
  </cx-modal>
</template>

<script>
import { CxButton } from '../CxButton';
import { CxModal } from '../CxModal';

export default {
  name: 'CxConfirm',

  hubble: 'cx-confirm',

  components: { CxButton, CxModal },

  props: {
    cancelButtonText: {
      default: '',
      type: String,
    },

    // eslint-disable-next-line vue/require-default-prop
    confirmButtonColor: String,

    confirmButtonText: {
      default: '',
      type: String,
    },

    loading: {
      default: false,
      type: Boolean,
    },

    title: {
      default: '',
      type: String,
    },
  },

  computed: {
    computedCancelButtonText() {
      return this.cancelButtonText || this.$t('defaultCancelButtonText');
    },

    computedConfirmButtonText() {
      return this.confirmButtonText || this.$t('defaultConfirmButtonText');
    },

    computedTitle() {
      return this.title || this.$t('defaultTitle');
    },
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "defaultCancelButtonText": "Cancel",
    "defaultConfirmButtonText": "Continue",
    "defaultTitle": "Are you sure?"
  }
}
</i18n>
