<template>
  <div class="bg-white flex flex-col p-8 rounded-lg shadow-sm space-y-4">
    <div v-if="$slots.title || title" class="flex-none">
      <div class="flex items-center justify-between text-gray-900">
        <div class="font-bold text-xl w-full">
          <slot name="title">{{ title }}</slot>
        </div>
        <button
          v-if="expandable"
          v-hubble="'expand-toggle'"
          type="button"
          class="flex hover:text-gray-700 items-center"
          :aria-label="expandButtonAriaLabel"
          :aria-pressed="expanded"
          @click="expanded = !expanded"
        >
          <cx-icon :name="icon" size="2xl" />
        </button>
      </div>
      <div v-if="helpText" class="text-gray-500 text-sm">
        {{ helpText }}
      </div>
    </div>
    <div v-if="expanded" class="flex-1">
      <slot />
    </div>
  </div>
</template>

<script>
import { EXPAND_LESS_ICON, EXPAND_MORE_ICON } from '~/support/constants';

import { CxIcon } from '../CxIcon';

export default {
  name: 'CxCard',

  hubble: 'cx-card',

  components: {
    CxIcon,
  },

  props: {
    expandable: {
      default: false,
      type: Boolean,
    },

    helpText: {
      default: '',
      type: String,
    },

    title: {
      default: '',
      type: String,
    },
  },

  data() {
    return {
      expanded: true,
    };
  },

  computed: {
    expandButtonAriaLabel() {
      const expandedState = this.expanded ? this.$t('collapse') : this.$t('expand');
      const label = this.title ? this.title : this.$t('defaultLabel');

      return `${expandedState} ${label}`;
    },

    icon() {
      return this.expanded ? EXPAND_LESS_ICON : EXPAND_MORE_ICON;
    },
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "aria": {
      "defaultLabel": "card content",
      "collapse": "collaspe",
      "expand": "expand"
    }
  }
}
</i18n>
