import { camelCase } from 'lodash-es';
import { useRouter } from 'nuxt/app';

function flattenRoutes(children, routeNames) {
  children.forEach(({ children, name }) => {
    if (children) flattenRoutes(children, routeNames);

    routeNames.push(name);
  });
}

export default defineNuxtPlugin(() => {
  const routeNames = [];
  const router = useRouter();

  flattenRoutes(router.getRoutes(), routeNames);

  const mappedRoutes = routeNames.reduce((result, name) => {
    if (!name) return result;

    return {
      ...result,
      [camelCase(name)]: name,
    };
  }, {});

  return {
    provide: {
      routeNames: mappedRoutes,
    },
  };
});
