export const CHECKBOX_CIRCLE_FILL_ICON = 'checkbox-circle-fill';
export const CLOSE_CIRCLE_FILL_ICON = 'close-circle-fill';
export const EDIT_ICON = 'pencil-line';
export const ERROR_WARNING_FILL_ICON = 'error-warning-fill';
export const EXPAND_LESS_ICON = 'arrow-up-s-line';
export const EXPAND_LESS_RIGHT_ICON = 'arrow-right-s-line';
export const EXPAND_MORE_ICON = 'arrow-down-s-line';
export const EXPAND_RIGHT_ICON = 'arrow-right-s-line';
export const DATA_PRODUCT_ICON = 'grid-line';
export const DATA_PRODUCT_ICON_ALT = 'building-line';
export const DATASET_ICON = 'database-2-line';
export const DATASET_ICON_FILL = 'database-2-fill';
export const FRAME_ICON = 'layout-3-line';
export const HOME_ICON = 'home-5-line';
export const HOME_ICON_FILL = 'home-5-fill';
export const INDETERMINATE_CIRCLE_FILL_ICON = 'indeterminate-circle-fill';
