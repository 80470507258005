<template>
  <component
    :is="computedTag"
    v-bind="$attrs"
    :to="to"
    :disabled="disabled || loading"
    class="font-medium inline-flex items-center justify-center relative text-center text-sm transition"
    :class="[buttonClasses, colorClasses, sizeClasses]"
    @focus="$emit('focus')"
    v-on="_$listeners"
  >
    <cx-spinner v-if="loading" class="absolute" :color="spinnerColor" size="xs" />
    <span :class="loadingClasses" class="flex items-center justify-center leading-none">
      <cx-icon v-if="icon" :class="iconClasses" :name="icon" :size="iconSize" />
      <slot />
    </span>
  </component>
</template>

<script>
import { CxIcon } from '../CxIcon';
import { CxSpinner } from '../CxSpinner';
import { BASE, LG, SM } from '../constants';

export const BUTTON = 'button';
export const HREF = 'a';
export const NUXT_LINK = 'nuxt-link';
export const DANGER = 'danger';
export const DANGER_LIGHT = 'danger-light';
export const OUTLINE = 'outline';
export const PRIMARY = 'primary';
export const QUATERNARY = 'quaternary';
export const QUATERNARY_OUTLINE = 'quaternary-outline';
export const SECONDARY = 'secondary';
export const SUCCESS_OUTLINE = 'success-outline';
export const TERTIARY = 'tertiary';
export const TRANSPARENT = 'transparent';
export const WARN = 'warn';
export const WARN_OUTLINE = 'warn-outline';
export const PURPLE = 'purple';
export const PURPLE_OUTLINE = 'purple-outline';
export const ORANGE = 'orange';
export const ORANGE_OUTLINE = 'orange-outline';
export const GRAY = 'gray';
export const GRAY_OUTLINE = 'gray-outline';
export const LIGHT_GRAY = 'light-gray';
export const LIGHT_GRAY_OUTLINE = 'light-gray-outline';
export const WHITE = 'white';
export const SIZES = [BASE, LG, SM];

export const COLOR_CLASS_MAP = {
  [DANGER]: 'text-white bg-red-600 hover:bg-red-700 focus:ring-red-200 focus:ring-2',
  [DANGER_LIGHT]: 'text-white bg-red-500 hover:bg-red-600 focus:ring-red-200 focus:ring-2',
  [OUTLINE]:
    'text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-600 dark:focus:ring-blue-800',
  [PRIMARY]: 'text-white bg-blue-600 hover:bg-blue-800 focus:ring-blue-500 focus:ring-2',
  [QUATERNARY]: 'text-gray-900 bg-white hover:bg-gray-100 focus:ring-blue-200 focus:ring-2',
  [QUATERNARY_OUTLINE]:
    'text-gray-900 bg-white border border-gray-200  hover:bg-gray-100 focus:ring-blue-200 focus:ring-2',
  [SECONDARY]: 'text-white bg-gray-800 hover:bg-gray-900 focus:ring-gray-400 focus:ring-2',
  [SUCCESS_OUTLINE]:
    'text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300',
  [TERTIARY]: 'text-gray-900 bg-gray-200 hover:bg-gray-300 focus:ring-gray-100 focus:ring-2',
  [TRANSPARENT]:
    'border border-transparent text-gray-500 bg-transparent hover:bg-gray-100 focus:ring-blue-200 focus:ring-2',
  [WARN_OUTLINE]:
    'text-red-600 hover:text-white border border-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-800',
  [WARN]: 'text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-200 focus:ring-2',
  [WHITE]: 'bg-white hover hover:underline focus:ring-gray-400 focus:ring-2 text-gray-800',
  [PURPLE]: 'text-white hover:bg-purple-800 bg-purple-700 focus:ring-purple-400 focus:ring-2',
  [PURPLE_OUTLINE]:
    'text-purple-700 hover:text-white border border-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300',
  [ORANGE]: 'text-white hover:bg-orange-800 bg-orange-700 focus:ring-orange-400 focus:ring-2',
  [ORANGE_OUTLINE]:
    'text-orange-700 hover:text-white border border-orange-700 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300',
  [GRAY]: 'text-white hover:bg-gray-800 bg-gray-600 focus:ring-gray-400 focus:ring-2',
  [GRAY_OUTLINE]:
    'text-gray-700 hover:text-white border border-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300',
  [LIGHT_GRAY]: 'text-gray-900 hover:bg-gray-300 bg-gray-200 focus:ring-gray-400 focus:ring-2',
  [LIGHT_GRAY_OUTLINE]:
    'bg-white border border-gray-200 hover:bg-gray-200 hover:text-gray-900 focus:ring-gray-400 focus:ring-2 text-gray-800',
};

export const ICON_CLASSES = 'order-first mr-4';
export const ICON_TRAILING_CLASSES = 'order-last ml-4';

export const SIZE_CLASS_MAP = {
  [BASE]: 'h-11',
  [LG]: 'h-14',
  [SM]: 'h-8',
};

export const ICON_SIZE_MAP = {
  [BASE]: '2xl',
  [LG]: '3xl',
  [SM]: 'lg',
};

export const SPINNER_COLOR_MAP = {
  [DANGER]: DANGER,
  [OUTLINE]: PRIMARY,
  [PRIMARY]: PRIMARY,
  [QUATERNARY]: QUATERNARY,
  [SECONDARY]: SECONDARY,
  [SUCCESS_OUTLINE]: SUCCESS_OUTLINE,
  [TERTIARY]: TERTIARY,
  [WARN_OUTLINE]: WARN_OUTLINE,
  [WARN]: WARN,
  [WHITE]: WHITE,
};

export const ICON_BUTTON_CLASS_MAP = {
  [BASE]: 'rounded-full w-11',
  [LG]: 'rounded-full w-14',
  [SM]: 'rounded-full w-8',
};

export default {
  name: 'CxButton',

  components: {
    CxIcon,
    CxSpinner,
  },

  props: {
    color: {
      type: String,
      default: PRIMARY,
      validator: (value) => Object.keys(COLOR_CLASS_MAP).includes(value),
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    icon: {
      default: '',
      type: String,
    },

    loading: {
      default: false,
      type: Boolean,
    },

    size: {
      type: String,
      default: BASE,
      validator: (value) => SIZES.includes(value),
    },

    tag: {
      default: BUTTON,
      type: String,
      validator: (value) => [BUTTON, HREF, NUXT_LINK].includes(value),
    },

    to: {
      default: () => {},
      type: [Object, String],
    },

    trailing: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    buttonClasses() {
      return this.$slots.default ? 'px-5 rounded-lg' : ICON_BUTTON_CLASS_MAP[this.size];
    },

    colorClasses() {
      return this.disabled || this.loading
        ? 'bg-gray-100 cursor-not-allowed text-gray-400 outline-none focus:ring-0'
        : COLOR_CLASS_MAP[this.color];
    },

    computedTag() {
      return this.to && !this.disabled ? defineNuxtLink({}) : this.tag;
    },

    iconClasses() {
      if (!this.$slots.default) return;

      return this.trailing ? ICON_TRAILING_CLASSES : ICON_CLASSES;
    },

    iconSize() {
      return ICON_SIZE_MAP[this.size];
    },

    loadingClasses() {
      return { 'opacity-0': this.loading };
    },

    sizeClasses() {
      return SIZE_CLASS_MAP[this.size];
    },

    spinnerColor() {
      return SPINNER_COLOR_MAP[this.color];
    },
  },
};
</script>
