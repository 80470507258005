import { camelCase, upperFirst, startCase } from 'lodash-es';
export const DEFAULT_TRUNCATE_MAX_LENGTH = 50;
export const ELLIPSES_LENGTH = 5;

const enhancedBtoa = (str) => {
  const bytes = new TextEncoder().encode(str);
  const binaryStr = String.fromCodePoint(...bytes);

  return btoa(binaryStr);
};

const initials = (str) => {
  const names = str.split(' ');
  let initials = names[0].substring(0, 1);

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1);
  }

  return initials.toUpperCase();
};

/* eslint-disable no-control-regex */
const isAscii = (str, extended) => (extended ? /^[\x00-\xFF]*$/ : /^[\x00-\x7F]*$/).test(str);

const toPascalCase = (str) => {
  return upperFirst(camelCase(str)).replace(/ /g, '');
};

const titleCase = (str) => {
  return startCase(str.toLowerCase());
};

const truncate = (str, maxLength = DEFAULT_TRUNCATE_MAX_LENGTH) => {
  if (str.length <= maxLength) return str;

  const startLength = Math.floor((maxLength - ELLIPSES_LENGTH) / 2);
  const endLength = maxLength - ELLIPSES_LENGTH - startLength;

  return str.substr(0, startLength) + '...' + str.substr(str.length - endLength, str.length);
};

const wrapMatches = (str, matches, classes = '', tag = 'span') => {
  matches.forEach((match) => {
    const regex = new RegExp(match, 'ig');
    str = str.replace(regex, `<${tag} class="${classes}">${match}</${tag}>`);
  });

  return str;
};

export default { enhancedBtoa, initials, isAscii, titleCase, toPascalCase, truncate, wrapMatches };
