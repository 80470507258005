<template>
  <div v-hubble="baseSelector">
    <div v-if="$slots.default || !!$slots.cornerHint" class="flex justify-between">
      <label
        class="block font-medium text-gray-900 text-sm"
        :for="inputId"
        :class="{ 'sr-only': hideLabel, 'mb-2': !hideLabel }"
      >
        <slot />
      </label>

      <span class="text-gray-500 text-sm">
        <slot name="cornerHint" />
      </span>
    </div>
    <div
      class="border flex items-center px-3 relative rounded-md"
      :class="[backgroundColorClasses, disabledClasses, sizeClasses, validityClasses]"
    >
      <div v-if="$slots.prefix" class="flex flex-none h-full items-center pr-2">
        <slot name="prefix" />
      </div>
      <input
        :id="inputId"
        ref="input"
        v-hubble="'input'"
        :aria-describedby="shouldShowHintWrapper ? hintTextId : ''"
        :aria-invalid="invalid"
        :aria-required="required"
        :value="modelValue"
        v-bind="$attrs"
        class="bg-transparent border-none disabled:text-gray-400 flex-1 focus:outline-none focus:ring-0 p-0 text-gray-900 w-full"
        :class="[inputTextClasses, { 'cursor-not-allowed': disabled }]"
        :disabled="disabled"
        v-on="computedListeners"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <div v-if="$slots.suffix" class="flex flex-none h-full items-center pl-2">
        <slot name="suffix" />
      </div>
    </div>
    <div v-if="shouldShowHintWrapper" class="flex items-center mt-2 space-between">
      <div
        :id="hintTextId"
        :class="[invalid ? 'text-red-600' : 'text-gray-500']"
        class="flex-1 text-sm"
        aria-live="polite"
      >
        <slot name="hint" />
      </div>
      <div v-if="$attrs.maxlength && !hideMax" class="text-gray-500 text-xs">
        {{ modelValue.length }}/{{ $attrs.maxlength }}
      </div>
    </div>
  </div>
</template>

<script>
import { BASE, INPUT_DEFAULT_BG_COLOR, LG, SM } from '../constants';

export const SIZES = [BASE, LG, SM];

export const BACKGROUND_COLOR_CLASS_MAP = {
  gray: 'bg-gray-50',
  green: 'bg-green-50',
  red: 'bg-red-50',
  white: 'bg-white',
};
export const SIZE_CLASS_MAP = {
  [BASE]: 'h-11',
  [LG]: 'h-14',
  [SM]: 'h-8',
};
export const INPUT_TEXT_SIZE_CLASS_MAP = {
  [BASE]: 'text-sm',
  [LG]: 'text-base',
  [SM]: 'text-xs',
};

export default {
  name: 'CxInput',

  hubble: 'cx-input',

  inheritAttrs: false,

  props: {
    baseSelector: {
      default: 'wrapper',
      type: String,
    },

    bgColor: {
      default: INPUT_DEFAULT_BG_COLOR,
      type: String,
      validator: (value) => Object.keys(BACKGROUND_COLOR_CLASS_MAP).includes(value),
    },

    disabled: {
      default: false,
      type: Boolean,
    },

    hideLabel: {
      type: Boolean,
      default: false,
    },

    hideMax: {
      type: Boolean,
      default: false,
    },

    invalid: {
      default: false,
      type: Boolean,
    },

    modelValue: {
      default: '',
      type: [Number, String],
    },

    required: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: BASE,
      validator: (value) => SIZES.includes(value),
    },
  },

  computed: {
    backgroundColorClasses() {
      return BACKGROUND_COLOR_CLASS_MAP[this.bgColor];
    },

    // We want to bind all _$listeners except input
    computedListeners() {
      const { input, ...listeners } = this._$listeners;

      return listeners;
    },

    disabledClasses() {
      return this.disabled ? '!bg-gray-100 !border-gray-200 cursor-not-allowed' : null;
    },

    hintTextId() {
      return `cx-input-hint-${this._uid}`;
    },

    inputId() {
      return `cx-input-${this._uid}`;
    },

    inputTextClasses() {
      return INPUT_TEXT_SIZE_CLASS_MAP[this.size];
    },

    shouldShowHintWrapper() {
      return !!this.$slots.hint || !!this.$attrs.maxlength;
    },

    sizeClasses() {
      return SIZE_CLASS_MAP[this.size];
    },

    validityClasses() {
      return this.invalid
        ? 'bg-red-50 border-red-300 text-red-900 placeholder-red-300 focus-within:ring-red-500 focus-within:border-red-500'
        : 'border-gray-200 focus-within:border-blue-600';
    },
  },
};
</script>
