import { get, keys, orderBy, toUpper } from 'lodash-es';

export const list = (list, path, sortDirection) => {
  return orderBy(list, [(listItem) => getValueFromPath(listItem, path)], sortDirection);
};

const getValueFromPath = (listItem, path) => {
  const value = get(listItem, path);

  return typeof value === 'string' ? value.toLowerCase() : value;
};

const stringify = (sortSettings) => {
  const transformedSortSettings = keys(sortSettings).map((key) => {
    const value = sortSettings[key];

    return `${key}:${toUpper(value)}`;
  });

  return transformedSortSettings.join();
};

export default { list, stringify };
