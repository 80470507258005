export const KEYCODES = {
  DOWN: 40,
  END: 35,
  ENTER: 13,
  ESC: 27,
  HOME: 36,
  LEFT: 37,
  RIGHT: 39,
  SPACE: 32,
  TAB: 9,
  UP: 38,
};

export const KEYCODE_MAP = {
  [KEYCODES.DOWN]: 'Down',
  [KEYCODES.END]: 'End',
  [KEYCODES.ENTER]: 'Enter',
  [KEYCODES.ESC]: 'Esc',
  [KEYCODES.HOME]: 'Home',
  [KEYCODES.LEFT]: 'Left',
  [KEYCODES.RIGHT]: 'Right',
  [KEYCODES.SPACE]: 'Space',
  [KEYCODES.TAB]: 'Tab',
  [KEYCODES.UP]: 'Up',
};
