import ApiBase from './ApiBase';
import { FRAMES_ENDPOINT } from './DataProductsService';

export const DELIVERIES_ENDPOINT = 'deliveries';
export const INFERRED_HEATMAP_ENDPOINT = 'inferred-schedule-heatmap';
export const RE_RUN_ENDPOINT = 're-run';
export const HISTORY_ENDPOINT = 'history';
export const SCHEDULES_ENDPOINT = 'schedules';

export default class DatasetService extends ApiBase {
  async createSchedules(datasetId, payload) {
    const { data } = await this.client.post(`/${datasetId}/${SCHEDULES_ENDPOINT}`, payload);

    return data;
  }

  async fetchAll() {
    const { data } = await this.client.get();

    return data;
  }

  async fetchFrames(datasetId) {
    const { data } = await this.client.get(`/${datasetId}/${FRAMES_ENDPOINT}`);

    return data;
  }

  async fetchHistory(datasetId) {
    const { data } = await this.client.get(`/${datasetId}/${HISTORY_ENDPOINT}`);

    return data;
  }

  async fetchInferredScheduleHeatmap(datasetId, type, startDate, endDate) {
    const { data } = await this.client.get(
      `/${datasetId}/${INFERRED_HEATMAP_ENDPOINT}/${type}?startDate=${startDate}&endDate=${endDate}`,
    );

    return data;
  }

  async fetchOne(datasetId) {
    const { data } = await this.client.get(`/${datasetId}`);

    return data;
  }

  async fetchSchedules(datasetId) {
    const { data } = await this.client.get(`/${datasetId}/${SCHEDULES_ENDPOINT}`);

    return data;
  }

  async reRunPipeline(datasetId, deliveryId) {
    const { data } = await this.client.put(`/${datasetId}/${DELIVERIES_ENDPOINT}/${deliveryId}/${RE_RUN_ENDPOINT}`);

    return data;
  }

  async updateDataset(datasetId, payload) {
    const { data } = await this.client.put(`/${datasetId}`, payload);

    return data;
  }

  async updateSchedules(datasetId, payload) {
    const { data } = await this.client.put(`/${datasetId}/${SCHEDULES_ENDPOINT}`, payload);

    return data;
  }

  async deleteSchedules(datasetId, type) {
    const params = { params: { type } };
    await this.client.delete(`/${datasetId}/${SCHEDULES_ENDPOINT}`, params);
  }

  async deleteDataset(datasetId) {
    await this.client.delete(`/${datasetId}`);
  }
}
