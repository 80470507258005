import { request } from '~/support/utils';

import { store } from './vuex';

export default defineNuxtPlugin(async () => {
  const accessToken = request.getAccessToken();
  const refreshToken = request.getRefreshToken();

  try {
    if (accessToken) {
      await store.dispatch('auth/getUser');
    } else if (!accessToken && refreshToken) {
      await store.dispatch('auth/refreshTokens');
      await store.dispatch('auth/getUser');
    }
  } catch (error) {
    // do not let error bubble and crash app
    await store.dispatch('auth/logout');
  }
});
