import { sort } from '~/support/utils';

import ApiBase from './ApiBase';

export const CHANNELS_ENDPOINT = 'channels';
export const EMAIL_PREFERENCES = 'email-preferences';
export const RULES_ENDPOINT = 'rules';
export const TOGGLE = 'toggle';

export default class NotificationsService extends ApiBase {
  async applyRuleToAssociatedDatasets(datasetId, payload) {
    const { data } = await this.client.put(`/${RULES_ENDPOINT}/datasets/${datasetId}/all-associated`, payload);

    return data;
  }

  async createChannel(payload) {
    const { data } = await this.client.post(`/${CHANNELS_ENDPOINT}`, payload);

    return data;
  }

  async createRuleV2(payload) {
    const { data } = await this.client.post(`/${RULES_ENDPOINT}/v2`, payload);

    return data;
  }

  async deleteChannel(id) {
    const { data } = await this.client.delete(`/${CHANNELS_ENDPOINT}/${id}`);

    return data;
  }

  async deleteRuleV2(id) {
    const { data } = await this.client.delete(`/${RULES_ENDPOINT}/${id}/v2`);

    return data;
  }

  async fetchChannels(method = '') {
    const { data } = await this.client.get(`/${CHANNELS_ENDPOINT}`, { params: { method } });

    return data;
  }

  async fetchEmailPreferences() {
    const { data } = await this.client.get(`/${EMAIL_PREFERENCES}`);

    return data;
  }

  async fetchRules({ pageToken, sortSettings }) {
    const params = { pageToken, sort: sort.stringify(sortSettings) };
    const { data } = await this.client.get(`/${RULES_ENDPOINT}`, { params });

    return data;
  }

  async fetchRuleV2(id) {
    const { data } = await this.client.get(`/${RULES_ENDPOINT}/${id}/v2`);

    return data;
  }

  async fetchRuleByDataset(id) {
    const { data } = await this.client.get(`/${RULES_ENDPOINT}/dataset/${id}`);

    return data;
  }

  async toggleChannel(id) {
    const { data } = await this.client.put(`/${CHANNELS_ENDPOINT}/${id}/${TOGGLE}`);

    return data;
  }

  async toggleRuleV2(id) {
    const { data } = await this.client.put(`/${RULES_ENDPOINT}/${id}/${TOGGLE}/v2`);

    return data;
  }

  async updateChannel(id, payload) {
    const { data } = await this.client.put(`/${CHANNELS_ENDPOINT}/${id}`, payload);

    return data;
  }

  async updateEmailPreferences(payload) {
    return await this.client.put(`/${EMAIL_PREFERENCES}`, payload);
  }

  async updateRuleV2(id, payload) {
    const { data } = await this.client.put(`/${RULES_ENDPOINT}/${id}/v2`, payload);

    return data;
  }
}
