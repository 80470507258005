import { OPERATORS } from './filtering';

export const DISABLED = 'disabled';
export const EMAIL_FORMAT_OPTIONS = {
  html: 'HTML',
  plain: 'PLAIN',
};
export const EMAIL_FREQUENCY_OPTIONS = {
  allDataProducts: 'ALL_DATA_PRODUCTS',
  perDataProduct: 'PER_DATA_PRODUCT',
  realTime: 'REAL_TIME',
};
export const EMPTY_VALUE_DEFAULT = '--';
export const ENABLED = 'enabled';
export const TIME_OF_DAY_OPTIONS = [
  {
    label: '12:00 AM',
    value: 0,
  },
  {
    label: '1:00 AM',
    value: 1,
  },
  {
    label: '2:00 AM',
    value: 2,
  },
  {
    label: '3:00 AM',
    value: 3,
  },
  {
    label: '4:00 AM',
    value: 4,
  },
  {
    label: '5:00 AM',
    value: 5,
  },
  {
    label: '6:00 AM',
    value: 6,
  },
  {
    label: '7:00 AM',
    value: 7,
  },
  {
    label: '8:00 AM',
    value: 8,
  },
  {
    label: '9:00 AM',
    value: 9,
  },
  {
    label: '10:00 AM',
    value: 10,
  },
  {
    label: '11:00 AM',
    value: 11,
  },
  {
    label: '12:00 PM',
    value: 12,
  },
  {
    label: '1:00 PM',
    value: 13,
  },
  {
    label: '2:00 PM',
    value: 14,
  },
  {
    label: '3:00 PM',
    value: 15,
  },
  {
    label: '4:00 PM',
    value: 16,
  },
  {
    label: '5:00 PM',
    value: 17,
  },
  {
    label: '6:00 PM',
    value: 18,
  },
  {
    label: '7:00 PM',
    value: 19,
  },
  {
    label: '8:00 PM',
    value: 20,
  },
  {
    label: '9:00 PM',
    value: 21,
  },
  {
    label: '10:00 PM',
    value: 22,
  },
  {
    label: '11:00 PM',
    value: 23,
  },
];

export const TIMEZONE_OPTIONS = [
  {
    exampleCities: 'Samoa, Midway',
    label: 'West Samoa Time (MIT)',
    timezoneEnumeration: 'US/Samoa',
    utc: 'UTC-11',
    value: 'MIT',
  },
  {
    exampleCities: 'Honolulu, Tahiti',
    label: 'Hawaii Standard Time (HST)',
    timezoneEnumeration: 'Pacific/Honolulu',
    utc: 'UTC-10',
    value: 'HST',
  },
  {
    exampleCities: 'Taiohae',
    label: 'Pacific/Marquesas (MART)',
    timezoneEnumeration: 'Pacific/Marquesas',
    utc: 'UTC-9:30',
    value: 'MART',
  },
  {
    exampleCities: 'Anchorage, Juneau',
    label: 'Alaska Standard Time (AST)',
    timezoneEnumeration: 'America/Anchorage',
    utc: 'UTC-9',
    value: 'AST',
  },
  {
    exampleCities: 'Los Angeles, Vancouver',
    label: 'Pacific Standard Time (PST)',
    timezoneEnumeration: 'America/Los_Angeles',
    utc: 'UTC-8',
    value: 'PST',
  },
  {
    exampleCities: 'Denver, Phoenix',
    label: 'Mountain Standard Time (MST)',
    timezoneEnumeration: 'America/Denver',
    utc: 'UTC-7',
    value: 'MST',
  },
  {
    exampleCities: 'Chicago, Costa Rica, Winnipeg',
    label: 'Central Standard Time (CST)',
    timezoneEnumeration: 'America/Chicago',
    utc: 'UTC-6',
    value: 'CST',
  },
  {
    exampleCities: 'New York, Jamaica, Montreal',
    label: 'Eastern Standard Time (EST)',
    timezoneEnumeration: 'America/New_York',
    utc: 'UTC-5',
    value: 'EST',
  },
  {
    exampleCities: 'Aruba, Caracas, Halifax',
    label: 'Atlantic Standard Time (PRT)',
    timezoneEnumeration: 'America/Aruba',
    utc: 'UTC-4',
    value: 'PRT',
  },
  {
    exampleCities: "St. John's, Mount Pearl, Corner Brook, Conception Bay South, Bay Roberts",
    label: 'America/St_Johns (NDT)',
    timezoneEnumeration: 'America/St_Johns',
    utc: 'UTC-3:30',
    value: 'NDT',
  },
  {
    exampleCities: 'Buenos Aires, San Juan, Bahia',
    label: 'Brazil Time (BET)',
    timezoneEnumeration: 'America/Argentina/Buenos_Aires',
    utc: 'UTC-3',
    value: 'BET',
  },
  {
    exampleCities: 'Grytviken',
    label: 'Atlantic/South_Georgia (GST)',
    timezoneEnumeration: 'Atlantic/South_Georgia',
    utc: 'UTC-2',
    value: 'GST',
  },
  {
    exampleCities: 'Praia, Mindelo, Santa Maria, Cova Figueira, Santa Cruz',
    label: 'Atlantic/Cape_Verde (CVT)',
    timezoneEnumeration: 'Atlantic/Cape_Verde',
    utc: 'UTC-1',
    value: 'CVT',
  },
  {
    exampleCities: 'Dublin, Iceland, London',
    label: 'Greenwich Mean Time (GMT)',
    timezoneEnumeration: 'Etc/Zulu',
    utc: 'UTC',
    value: 'UTC',
  },
  {
    exampleCities: 'Amsterdam, Berlin, Rome',
    label: 'Central European Time (ECT)',
    timezoneEnumeration: 'Europe/Berlin',
    utc: 'UTC+1',
    value: 'ECT',
  },
  {
    exampleCities: 'Athens, Beirut, Kiev',
    label: 'Central African Time (CAT)',
    timezoneEnumeration: 'Europe/Athens',
    utc: 'UTC+2',
    value: 'CAT',
  },
  {
    exampleCities: 'Baghdad, Moscow, Qatar',
    label: 'Eastern African Time (EAT)',
    timezoneEnumeration: 'Africa/Nairobi',
    utc: 'UTC+3',
    value: 'EAT',
  },
  {
    exampleCities: 'Tehran, Mashhad, Isfahan, Karaj, Tabriz',
    label: 'Asia/Tehran (IRST)',
    timezoneEnumeration: 'Asia/Tehran',
    utc: 'UTC+3:30',
    value: 'IRST',
  },
  {
    exampleCities: 'Baku, Dubai, Ulyanovsk',
    label: 'Armenia Time (NET)',
    timezoneEnumeration: 'Asia/Dubai',
    utc: 'UTC+4',
    value: 'NET',
  },
  {
    exampleCities: 'Kabul, Kandahār, Mazār-e Sharīf, Herāt, Jalālābād',
    label: 'Asia/Kabul (AFT)',
    timezoneEnumeration: 'Asia/Kabul',
    utc: 'UTC+4:30',
    value: 'AFT',
  },
  {
    exampleCities: 'Mawson, Maldivies, Karachi',
    label: 'Pakistan Time (PLT)',
    timezoneEnumeration: 'Indian/Maldives',
    utc: 'UTC+5',
    value: 'PLT',
  },
  {
    exampleCities: 'Mumbai, Delhi, Bengaluru, Kolkata, Chennai',
    label: 'Asia/Kolkata (IST)',
    timezoneEnumeration: 'Asia/Kolkata',
    utc: 'UTC+5:30',
    value: 'IST',
  },
  {
    exampleCities: 'Kathmandu, Pokhara, Pātan, Biratnagar, Birgañj',
    label: 'Asia/Kathmandu (NPT)',
    timezoneEnumeration: 'Asia/Kathmandu',
    utc: 'UTC+5:45',
    value: 'NPT',
  },
  {
    exampleCities: 'Bishkek, Chagos, Urumqi',
    label: 'Bangladesh Time (BST)',
    timezoneEnumeration: 'Asia/Omsk',
    utc: 'UTC+6',
    value: 'BST',
  },
  {
    exampleCities: 'West Island',
    label: 'Indian/Cocos (CCT)',
    timezoneEnumeration: 'Indian/Cocos',
    utc: 'UTC+6:30',
    value: 'CCT',
  },
  {
    exampleCities: 'Bangkok, Jakarta, Tomsk',
    label: 'Indochina Time (VST)',
    timezoneEnumeration: 'Asia/Bangkok',
    utc: 'UTC+7',
    value: 'VST',
  },
  {
    exampleCities: 'Hong Kong, Shanghai, Singapore',
    label: 'China Standard Time (CTT)',
    timezoneEnumeration: 'Asia/Hong_Kong',
    utc: 'UTC+8',
    value: 'CTT',
  },
  {
    exampleCities: 'Eucla',
    label: 'Australia/Eucla (ACWST)',
    timezoneEnumeration: 'Australia/Eucla',
    utc: 'UTC+8:45',
    value: 'ACWST',
  },
  {
    exampleCities: 'Seoul, Tokyo',
    label: 'Japan Standard Time (JST)',
    timezoneEnumeration: 'Asia/Tokyo',
    utc: 'UTC+9',
    value: 'JST',
  },
  {
    exampleCities: 'Darwin, Alice Springs, Palmerston, Howard Springs',
    label: 'Australia/Darwin (ACST)',
    timezoneEnumeration: 'Australia/Darwin',
    utc: 'UTC+9:30',
    value: 'ACST',
  },
  {
    exampleCities: 'Sydney, Melbourne',
    label: 'Eastern Standard Time (New South Wales) (AET)',
    timezoneEnumeration: 'Australia/Sydney',
    utc: 'UTC+10',
    value: 'AET',
  },
  {
    exampleCities: 'Lord Howe',
    label: 'Australia/Lord_Howe (LHST)',
    timezoneEnumeration: 'Australia/Lord_Howe',
    utc: 'UTC+10:30',
    value: 'LHST',
  },
  {
    exampleCities: 'Magadan, Norfolk',
    label: 'Solomon Is. Time (SST)',
    timezoneEnumeration: 'Pacific/Norfolk',
    utc: 'UTC+11',
    value: 'SST',
  },
  {
    exampleCities: 'Fiji, Kamchatka',
    label: 'New Zealand Standard Time (NST)',
    timezoneEnumeration: 'Pacific/Fiji',
    utc: 'UTC+12',
    value: 'NST',
  },
  {
    exampleCities: 'Waitangi',
    label: 'Pacific/Chatham (CHAST)',
    timezoneEnumeration: 'Pacific/Chatham',
    utc: 'UTC+12:45',
    value: 'CHAST',
  },
  {
    exampleCities: 'Nuku‘alofa, Lapaha, Neiafu, Pangai, ‘Ohonua',
    label: 'Pacific/Tongatapu (TOT)',
    timezoneEnumeration: 'Pacific/Tongatapu',
    utc: 'UTC+13',
    value: 'TOT',
  },
  {
    exampleCities: 'Kiritimati',
    label: 'Pacific/Kiritimati (LINT)',
    timezoneEnumeration: 'Pacific/Kiritimati',
    utc: 'UTC+14',
    value: 'LINT',
  },
];

export const RULE_ERROR_SEVERITY = 'ERROR';
export const RULE_SUCCESS_SEVERITY = 'SUCCESS';
export const RULE_WARNING_SEVERITY = 'WARNING';

export const NOTIFICATION_CHANNEL_TYPES = {
  email: 'email',
  pubsub: 'pubsub',
  slack: 'slack',
  sqs: 'sqs',
  webhook: 'webhook',
};

export const CHANNEL_IMAGES = {
  [NOTIFICATION_CHANNEL_TYPES.email]: 'mail.svg',
  [NOTIFICATION_CHANNEL_TYPES.pubsub]: 'google-cloud-pub-sub.svg',
  [NOTIFICATION_CHANNEL_TYPES.slack]: 'slack.svg',
  [NOTIFICATION_CHANNEL_TYPES.sqs]: 'amazon-sqs.svg',
  [NOTIFICATION_CHANNEL_TYPES.webhook]: 'webhook-icon.svg',
};

export const CHANNEL_DESCRIPTION_KEYS_MAP = {
  [NOTIFICATION_CHANNEL_TYPES.email]: 'email',
  [NOTIFICATION_CHANNEL_TYPES.pubsub]: 'topic',
  [NOTIFICATION_CHANNEL_TYPES.sqs]: 'url',
  [NOTIFICATION_CHANNEL_TYPES.webhook]: 'url',
};

export const DELIVERY_CHANNEL_TYPES_OPTIONS = [
  { label: 'Email', value: 'email' },
  { label: 'Amazon SQS', value: 'sqs' },
  { label: 'Webhook', value: 'webhook' },
  { label: 'Google Cloud Pub/Sub', value: 'pubsub' },
];

export const EMPTY_NOTIFICATION_RULE = {
  channels: [],
  dataProductId: '',
  enabled: true, // hardcoded while using fixture data
  events: {
    dataIssues: {
      enabled: false,
      severity: RULE_WARNING_SEVERITY,
    },
    pipelineDelayed: {
      enabled: false,
      severity: RULE_WARNING_SEVERITY,
    },
    pipelineFailed: {
      enabled: false,
      severity: RULE_ERROR_SEVERITY,
    },
    pipelineSuccess: {
      enabled: false,
      severity: RULE_SUCCESS_SEVERITY,
    },
  },
  name: '',
};

export const EMPTY_NOTIFICATION_RULE_V2 = {
  channels: [],
  datasetId: '',
  enabled: true, // hardcoded while using fixture data
  eventTypes: {
    dataIssues: {
      enabled: false,
      severity: RULE_WARNING_SEVERITY,
    },
    datasetDelayed: {
      enabled: false,
      severity: RULE_WARNING_SEVERITY,
    },
    datasetFailed: {
      enabled: false,
      severity: RULE_ERROR_SEVERITY,
    },
    datasetSuccess: {
      enabled: false,
      severity: RULE_SUCCESS_SEVERITY,
    },
  },
  name: '',
};

export const EVENT_TYPE_OPTIONS = [
  { label: 'Dataset failed', value: 'datasetFailed' },
  { label: 'Dataset delayed', value: 'datasetDelayed' },
  { label: 'Data quality issues', value: 'dataIssues' },
  { label: 'Dataset completed successfully', value: 'datasetSuccess' },
];

export const NOTIFICATIONS_DELAY = 500;

export const NOTIFICATIONS_CENTER_VIEW_LIMIT = 5;

export const NOTIFICATION_RULES_FILTERS = {
  channelMethods: {
    operator: OPERATORS.contains,
    value: [],
  },

  enabledFilter: {
    operator: OPERATORS.in,
    value: [],
  },

  eventTypeKeys: {
    operator: OPERATORS.contains,
    value: [],
  },
};

export const NOTIFICATION_RULE_SEVERITY_MAP = {
  error: RULE_ERROR_SEVERITY,
  success: RULE_SUCCESS_SEVERITY,
  warning: RULE_WARNING_SEVERITY,
};

export const INFERRED_SCHEDULE_TRAY_DELAY = 200;
export const SETTINGS_RULES_EDIT_ROUTE = 'settings-notifications-rules-id-edit';
