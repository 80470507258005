<template>
  <v-tooltip
    :delay="delay"
    :disabled="disabled"
    :distance="distance"
    :placement="placement"
    :skidding="skidding"
    :popper-triggers="popoverTriggers"
    :auto-boundary-max-size="autoBoundaryMaxSize"
  >
    <slot />

    <template #popper>
      <div>
        <slot name="content">{{ content }}</slot>
      </div>
    </template>
  </v-tooltip>
</template>

<script>
import { Tooltip } from 'floating-vue';

export const DEFAULT_PLACEMENT = 'top';
export const DELAY_HIDE_DEFAULT = 0;
export const DELAY_SHOW_DEFAULT = 300;
export const DISTANCE_DEFAULT = 8;
export const PLACEMENTS = ['top', 'right', 'bottom', 'left'];

export default {
  name: 'CxTooltip',

  components: {
    'v-tooltip': Tooltip,
  },

  props: {
    autoBoundaryMaxSize: {
      type: Boolean,
      default: false,
    },

    content: {
      type: [Number, String],
      default: '',
    },

    delayHide: {
      type: Number,
      default: DELAY_HIDE_DEFAULT,
    },

    delayShow: {
      type: Number,
      default: DELAY_SHOW_DEFAULT,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    distance: {
      type: Number,
      default: DISTANCE_DEFAULT,
    },

    placement: {
      type: String,
      default: DEFAULT_PLACEMENT,
      validator: (value) => PLACEMENTS.includes(value),
    },

    popoverTriggers: {
      type: Array,
      default: () => [],
    },

    skidding: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    delay() {
      return { hide: this.delayHide, show: this.delayShow };
    },
  },
};
</script>

<style>
@import './CxTooltip.css';
</style>
