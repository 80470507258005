<template>
  <cx-input
    ref="input"
    :model-value="modelValue"
    bg-color="white"
    class="px-2 py-2 top-0 w-full"
    :aria-label="$t('ariaLabels.inputs.search', { name: $t('options') })"
    :placeholder="placeholder || $t('placeholder')"
    @update:model-value="$emit('update:model-value', $event)"
    @focus="$emit('search-focus')"
    @keyup.up.stop.prevent="$emit('up')"
    @keyup.down.stop.prevent="$emit('down', $event)"
    @keyup.esc.stop.prevent="$emit('esc')"
    @keydown="handleKeydown"
  >
    <template #prefix>
      <span class="flex items-center text-gray-500">
        <cx-icon name="search-line" size="xl" />
      </span>
    </template>
  </cx-input>
</template>

<script>
import { KEYCODES } from '~/support/constants';

import { CxIcon } from '../../../CxIcon';
import { CxInput } from '../../../CxInput';

export default {
  name: 'SelectSearchInput',

  components: {
    CxIcon,
    CxInput,
  },

  props: {
    modelValue: {
      default: '',
      type: String,
    },

    placeholder: {
      default: '',
      type: String,
    },
  },

  methods: {
    handleKeydown(event) {
      if (event.keyCode === KEYCODES.TAB) {
        if (event.shiftKey) {
          event.stopPropagation();
          event.preventDefault();
          this.$emit('backtab');
        } else {
          this.$emit('down', event);
        }
      }
    },
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "placeholder": "Search",
    "options": "options"
  }
}
</i18n>
