import {
  DELIVER,
  DESTINATION_TYPES_MAP,
  HEALTH_DASHBOARD_V2_DELIVERY_STEP_STATUS_MAP,
  HEALTH_DASHBOARD_DATASET_DELIVERY_TASKS_STATUS_MAP,
  HEALTH_DASHBOARD_STATUS_MAP_V2,
  INGEST,
  NORMALIZE,
  PROCESS,
} from '~/support/constants';

export const HEALTH_DASHBOARD_STATS = {
  dataProducts: {
    total: 182,
  },
  datasets: {
    latestDeliveryErrorCount: 12,
    total: 4328,
  },
  frames: {
    latestDeliveryErrorCount: 8,
    total: 6891,
  },
};

export const HEALTH_DASHBOARD_DATASETS_COUNT = {
  deliveredLate: 15,
  failed: 5,
  healthy: 22,
  missing: 33,
  total: 75,
};

export const FORMATTED_DATASET_HEALTH_DELIVERY = {
  deliveryId: '58233e34122d4d7b75e95baba135ce598b008ad12f006713c971bc0f14066192.0',
  duration: 'PT0S',
  frames: [
    {
      id: 'FrGsQoc0f3aaca',
      message:
        'Unexpected column [fieldID, fieldSTRING, fieldINT, fieldFLOAT, fieldDATE]. Expected 5 field count. Found 1.',
      name: 'WORKFLOW',
      runId: '9b2b793c-9a91-4e3c-a157-32a7f6ca16ac',
      step: 'process',
    },
    {
      deliveryId: 'cdbb2052c00a7a57b6e4004af5040f825e9a9a53714ca85ff4ca002a08285b07.0',
      duration: 'PT0S',
      frames: [
        {
          id: 'FrGsQoc0f3aaca',
          message:
            'Unexpected column [fieldID, fieldSTRING, fieldINT, fieldFLOAT, fieldDATE]. Expected 5 field count. Found 1.',
          name: 'WORKFLOW',
          runId: '6a419fdf-61df-40cd-8977-a16522444422',
          step: 'process',
        },
      ],
      rows: 23,
      size: 865,
      status: 'error',
      supplierImpliedDateTime: null,
      timestamp: '2023-04-11T07:00:00.000+00:00',
    },
    {
      deliveryId: 'cbcf16430554f0a6e2ae3389b462a513598e44e5e4e2230c9e84e504bd6654ba.0',
      duration: 'PT0S',
      frames: [
        {
          id: 'FrGsQoc0f3aaca',
          message:
            'Unexpected column [fieldID, fieldSTRING, fieldINT, fieldFLOAT, fieldDATE]. Expected 5 field count. Found 1.',
          name: 'WORKFLOW',
          runId: '95138884-27c5-4010-bab6-2892d2c6fe17',
          step: 'process',
        },
      ],
      rows: 0,
      size: 0,
      status: 'error',
      supplierImpliedDateTime: null,
      timestamp: '2023-03-29T07:00:00.000+00:00',
    },
  ],
  nextExpectedStartDate: '2023-03-12T20:47:58.000+00:00',
  rowsDelivered: 0,
  status: 'error',
  statusMessage: 'Delivery failed',
  supplierImpliedDateTime: null,
  timestamp: '2023-03-28T07:00:00.000+00:00',
  totalFileSize: 0,
};

export const DATASET_HEALTH_DELIVERY_HISTORY = {
  datasetId: 'Ds5sdCW7',
  deliveries: {
    content: [
      {
        deliveryId: '58233e34122d4d7b75e95baba135ce598b008ad12f006713c971bc0f14066192.0',
        duration: 'PT0S',
        frames: [
          {
            id: 'FrGsQoc0f3aaca',
            message:
              'Unexpected column [fieldID, fieldSTRING, fieldINT, fieldFLOAT, fieldDATE]. Expected 5 field count. Found 1.',
            name: 'WORKFLOW',
            runId: '9b2b793c-9a91-4e3c-a157-32a7f6ca16ac',
            step: 'process',
          },
          {
            deliveryId: 'cdbb2052c00a7a57b6e4004af5040f825e9a9a53714ca85ff4ca002a08285b07.0',
            duration: 'PT0S',
            frames: [
              {
                id: 'FrGsQoc0f3aaca',
                message:
                  'Unexpected column [fieldID, fieldSTRING, fieldINT, fieldFLOAT, fieldDATE]. Expected 5 field count. Found 1.',
                name: 'WORKFLOW',
                runId: '6a419fdf-61df-40cd-8977-a16522444422',
                step: 'process',
              },
            ],
            rows: 23,
            size: 865,
            status: 'error',
            supplierImpliedDateTime: null,
            timestamp: '2023-04-11T07:00:00.000+00:00',
          },
          {
            deliveryId: 'cbcf16430554f0a6e2ae3389b462a513598e44e5e4e2230c9e84e504bd6654ba.0',
            duration: 'PT0S',
            frames: [
              {
                id: 'FrGsQoc0f3aaca',
                message:
                  'Unexpected column [fieldID, fieldSTRING, fieldINT, fieldFLOAT, fieldDATE]. Expected 5 field count. Found 1.',
                name: 'WORKFLOW',
                runId: '95138884-27c5-4010-bab6-2892d2c6fe17',
                step: 'process',
              },
            ],
            rows: 0,
            size: 0,
            status: 'error',
            supplierImpliedDateTime: null,
            timestamp: '2023-03-29T07:00:00.000+00:00',
          },
        ],
        rows: 0,
        size: 0,
        status: 'error',
        supplierImpliedDateTime: null,
        timestamp: '2023-03-28T07:00:00.000+00:00',
      },
    ],
    pageNumber: 0,
    pageSize: 10000,
    totalItemsNumber: 1,
    totalPagesNumber: 1,
  },
  endDate: null,
  expectedStartDate: '2023-03-12T20:47:58.000+00:00',
  failedDeliveryCount: 3,
  nextExpectedStartDate: null,
  schedule: null,
  startDate: null,
  successfulDeliveryCount: 0,
  totalDeliveryCount: 3,
};

export const DATASETS_HEALTH_LIST = [
  {
    datasetId: 'datasetId1',
    datasetName: 'MSCI FactorLab APAC Raw Exposures CUSIP Daily API',
    latestDelivery: '2023-01-29T19:04:02',
    message: 'Pipeline run failed',
    nextDelivery: '2022-01-29T16:00:00',
    schedule: '45 8 * * * *',
    status: 'error',
    step: 'ingest',
  },
  {
    datasetId: 'datasetId2',
    datasetName: 'Barra EQINTEG ID',
    latestDelivery: '2023-03-29T19:00:00',
    message: 'Pipeline run failed',
    nextDelivery: '2022-03-29T16:00:00',
    schedule: '45 21 * * 1-5 *',
    status: 'error',
    step: 'process',
  },
  {
    datasetId: 'datasetId3',
    datasetName: 'Barra EQINTEG Common',
    latestDelivery: '2023-04-29T16:23:00',
    message: 'Pipeline run failed',
    nextDelivery: '2022-04-29T16:00:00',
    schedule: '23 12 2 * 7 *',
    status: 'error',
    step: 'deliver',
  },
  {
    datasetId: 'datasetId4',
    datasetName: 'Barra EQINTEG Long',
    latestDelivery: '2023-06-29T19:32:00',
    message: 'Pipeline delay',
    nextDelivery: '2022-06-29T16:00:00',
    schedule: '* * * * * *',
    status: 'warning',
    step: 'ingest',
  },
  {
    datasetId: 'datasetId5',
    datasetName: 'Barra EQINTEG Short',
    latestDelivery: '2022-03-29T16:00:45',
    message: 'Pipeline delay',
    nextDelivery: '2022-03-29T16:00:00',
    schedule: '00 10 * * 1,3,4',
    status: 'warning',
    step: 'process',
  },
  {
    datasetId: 'datasetId6',
    datasetName: 'Barra EQINTEG Shortlisted',
    latestDelivery: '2023-03-29T19:00:00',
    message: 'Monitor',
    nextDelivery: '2022-03-29T16:00:00',
    schedule: '41 9 1,7,14,21 * * *',
    status: 'success',
    step: '',
  },
  {
    datasetId: 'datasetId7',
    datasetName: 'MSCI FactorLab APAC Exposures CUSIP Monthly API label',
    latestDelivery: '2023-03-29T19:00:00',
    message: 'Pipeline run',
    nextDelivery: '2022-03-29T16:00:00',
    schedule: '45 8 * * * *',
    status: 'success',
    step: '',
  },
];

export const DATASET_HEALTH_V2_DELIVERIES = [
  {
    deliveredAt: null,
    deliveryId:
      'CXO12345.CXO12345.crux_testing_llc_stoxx_common_files_preliminaryfloats_p000_20230605_v2.2023-12-04T03:50:00Z.fa027d5d-dd72-4bee-8e56-1892a4f441a4',
    status: HEALTH_DASHBOARD_STATUS_MAP_V2.failed,
    timestamp: '2023-12-04T03:50:00.000+00:00',
  },
  {
    deliveredAt: null,
    deliveryDeadline: '2024-04-01T15:30:00',
    deliveryId:
      'CXO12345.CXO12345.crux_testing_llc_stoxx_common_files_preliminaryfloats_p000_20230605_v2.2023-12-03T03:50:00Z.f94a131b-859e-40bc-9974-93ef7d0b5972',
    status: HEALTH_DASHBOARD_STATUS_MAP_V2.failed,
  },
  {
    deliveredAt: null,
    deliveryDeadline: '2024-04-01T15:30:00',
    deliveryId:
      'CXO12345.CXO12345.crux_testing_llc_stoxx_common_files_preliminaryfloats_p000_20230605_v2.2023-12-02T03:50:00Z.6382ae8a-a9bd-4ef2-97f9-293d96eac745',
    status: HEALTH_DASHBOARD_STATUS_MAP_V2.failed,
  },
  {
    deliveredAt: null,
    deliveryDeadline: '2024-04-01T15:30:00',
    deliveryId:
      'CXO12345.CXO12345.crux_testing_llc_stoxx_common_files_preliminaryfloats_p000_20230605_v2.2023-12-01T03:50:00Z.ffbbe0cc-976a-4778-8db6-2da76d4e65f9',
    status: HEALTH_DASHBOARD_STATUS_MAP_V2.failed,
  },
  {
    deliveredAt: null,
    deliveryDeadline: '2024-04-01T15:30:00',
    deliveryId:
      'CXO12345.CXO12345.crux_testing_llc_stoxx_common_files_preliminaryfloats_p000_20230605_v2.2023-11-30T03:50:00Z.1abb3591-6352-4d96-aef2-a7b2d7e733bc',
    status: HEALTH_DASHBOARD_STATUS_MAP_V2.failed,
  },
  {
    deliveredAt: null,
    deliveryDeadline: '2024-04-01T15:30:00',
    deliveryId:
      'CXO12345.CXO12345.crux_testing_llc_stoxx_common_files_preliminaryfloats_p000_20230605_v2.2023-11-29T03:50:00Z.14f7c722-3096-443c-95b2-a1e3ab97d9b5',
    status: HEALTH_DASHBOARD_STATUS_MAP_V2.failed,
  },
  {
    deliveredAt: null,
    deliveryDeadline: '2024-04-01T15:30:00',
    deliveryId:
      'CXO12345.CXO12345.crux_testing_llc_stoxx_common_files_preliminaryfloats_p000_20230605_v2.2023-11-28T03:50:00Z.ae3be5a1-c101-41d5-b00d-c669e5ff7fec',
    status: HEALTH_DASHBOARD_STATUS_MAP_V2.failed,
  },
  {
    deliveredAt: null,
    deliveryDeadline: '2024-04-01T15:30:00',
    deliveryId:
      'CXO12345.CXO12345.crux_testing_llc_stoxx_common_files_preliminaryfloats_p000_20230605_v2.2023-11-27T03:50:00Z.46c96821-04c4-4ecb-9801-eeb15f9a5678',
    status: HEALTH_DASHBOARD_STATUS_MAP_V2.failed,
  },
  {
    deliveredAt: null,
    deliveryDeadline: '2024-04-01T15:30:00',
    deliveryId:
      'CXO12345.CXO12345.crux_testing_llc_stoxx_common_files_preliminaryfloats_p000_20230605_v2.2023-11-26T03:50:00Z.fcd44d3e-600e-4a3e-9fc3-77a17d7befc0',
    status: HEALTH_DASHBOARD_STATUS_MAP_V2.failed,
  },
  {
    deliveredAt: null,
    deliveryDeadline: '2024-04-01T15:30:00',
    deliveryId:
      'CXO12345.CXO12345.crux_testing_llc_stoxx_common_files_preliminaryfloats_p000_20230605_v2.2023-11-25T03:50:00Z.1327fa72-e40e-479c-a9e6-1cac19a83348',
    status: HEALTH_DASHBOARD_STATUS_MAP_V2.failed,
  },
];

export const DATASET_DELIVERY_DESTINATIONS = [
  {
    destinationName: 'Snowflake Destination',
    destinationType: DESTINATION_TYPES_MAP.snowflake,
    timestamp: '2023-11-25T04:30:00.000+00:00',
  },
  {
    destinationName: 'SFTP Destination',
    destinationType: DESTINATION_TYPES_MAP.sftp,
    timestamp: '2023-11-25T04:30:00.000+00:00',
  },
];

export const DATASET_DELIVERY_FAILED_TASK = {
  createdAt: 1676656726312,
  message: 'Error during workflow execution',
  shortCode: 'ERROR_CODE',
  status: HEALTH_DASHBOARD_DATASET_DELIVERY_TASKS_STATUS_MAP.failed,
};
// fixture built to work with UI, not from API Schema designs, so far tasks only have ERROR and FINISHED as status
export const DATASET_DELIVERY_TASKS = {
  [HEALTH_DASHBOARD_STATUS_MAP_V2.failed]: {
    dispatch: {
      status: '',
      tasks: [],
      timestamp: '',
    },
    ingest: {
      status: HEALTH_DASHBOARD_DATASET_DELIVERY_TASKS_STATUS_MAP.failed,
      tasks: [],
      timestamp: '2023-11-25T01:50:00.000+00:00',
    },
    normalize: {
      status: HEALTH_DASHBOARD_DATASET_DELIVERY_TASKS_STATUS_MAP,
      tasks: [],
      timestamp: '2023-11-25T03:30:00.000+00:00',
    },
    process: {
      status: HEALTH_DASHBOARD_V2_DELIVERY_STEP_STATUS_MAP.failed,
      tasks: [DATASET_DELIVERY_FAILED_TASK, DATASET_DELIVERY_FAILED_TASK],
      timestamp: '2023-11-25T02:50:00.000+00:00',
    },
  },
  [HEALTH_DASHBOARD_STATUS_MAP_V2.healthy]: {
    dispatch: {
      status: HEALTH_DASHBOARD_DATASET_DELIVERY_TASKS_STATUS_MAP.failed,
      tasks: [
        {
          details: {
            ...DATASET_DELIVERY_DESTINATIONS[0],
          },
        },
        {
          details: {
            ...DATASET_DELIVERY_DESTINATIONS[1],
          },
        },
      ],
      timestamp: '2023-11-25T04:30:00.000+00:00',
    },
    ingest: {
      status: HEALTH_DASHBOARD_DATASET_DELIVERY_TASKS_STATUS_MAP.success,
      tasks: [],
      timestamp: '2023-11-25T01:50:00.000+00:00',
    },
    normalize: {
      status: HEALTH_DASHBOARD_DATASET_DELIVERY_TASKS_STATUS_MAP.success,
      tasks: [],
      timestamp: '2023-11-25T03:30:00.000+00:00',
    },
    process: {
      status: HEALTH_DASHBOARD_DATASET_DELIVERY_TASKS_STATUS_MAP.success,
      tasks: [],
      timestamp: '2023-11-25T02:50:00.000+00:00',
    },
  },
  [HEALTH_DASHBOARD_STATUS_MAP_V2.missing]: {
    dispatch: {
      status: null,
      tasks: [],
      timestamp: '',
    },
    ingest: {
      status: 'ERROR',
      tasks: [
        {
          createdAt: 1676656726312,
          message: 'Error during workflow execution',
          status: HEALTH_DASHBOARD_V2_DELIVERY_STEP_STATUS_MAP.failed,
        },
      ],
      timestamp: '',
    },
    normalize: {
      status: null,
      tasks: [],
      timestamp: '',
    },
    process: {
      status: null,
      tasks: [],
      timestamp: '',
    },
  },
  [HEALTH_DASHBOARD_STATUS_MAP_V2.late]: {
    dispatch: {
      status: HEALTH_DASHBOARD_DATASET_DELIVERY_TASKS_STATUS_MAP.success,
      tasks: [
        {
          details: {
            destinationName: 'Databricks Destination',
            destinationType: DESTINATION_TYPES_MAP.databricks,
            timestamp: '2023-11-25T04:30:00.000+00:00',
          },
        },
        {
          details: {
            destinationName: 'Exabel Destination',
            destinationType: DESTINATION_TYPES_MAP.exabel,
            timestamp: '2023-11-25T04:30:00.000+00:00',
          },
        },
      ],
      timestamp: '2023-11-25T04:30:00.000+00:00',
    },
    ingest: {
      status: HEALTH_DASHBOARD_DATASET_DELIVERY_TASKS_STATUS_MAP.success,
      tasks: [],
      timestamp: '2023-11-25T01:50:00.000+00:00',
    },
    normalize: {
      status: HEALTH_DASHBOARD_DATASET_DELIVERY_TASKS_STATUS_MAP.success,
      tasks: [],
      timestamp: '2023-11-25T03:30:00.000+00:00',
    },
    process: {
      status: HEALTH_DASHBOARD_DATASET_DELIVERY_TASKS_STATUS_MAP.success,
      tasks: [],
      timestamp: '2023-11-25T02:50:00.000+00:00',
    },
  },
};

// Real IDs from STG to integrate the tasks API endpoint while having table fixture data
export const DATASETS_HEALTH_LIST_V2_FAILED_DELIVERY_ID = 'Dlac463b2b26a07977';
export const DATASETS_HEALTH_LIST_V2_HEALTHY_DELIVERY_ID = 'Dl894df181afa4e705';
export const HEALTH_DASHBOARD_DATASET_FAILED_DELIVERY = {
  deliveredAt: '2023-12-01T03:50:00.000+00:00',
  deliveryDeadline: '2024-04-01T15:30:00',
  deliveryId: DATASETS_HEALTH_LIST_V2_FAILED_DELIVERY_ID,
  processingDeadlineConfig: {
    cronExpression: '11 6 28 * *',
    dayOfTheMonth: '28',
    dayOfWeek: '*',
    hour: '6',
    minute: '11',
    month: '*',
    timezone: '',
    year: '*',
  },
  status: HEALTH_DASHBOARD_STATUS_MAP_V2.failed,
};
export const HEALTH_DASHBOARD_DATASET_HEALTHY_DELIVERY = {
  deliveredAt: '2023-12-01T03:50:00.000+00:00',
  deliveryDeadline: '2024-04-01T15:30:00',
  deliveryId: DATASETS_HEALTH_LIST_V2_HEALTHY_DELIVERY_ID,
  processingDeadlineConfig: {
    cronExpression: '11 6 28 * *',
    dayOfTheMonth: '28',
    dayOfWeek: '*',
    hour: '6',
    minute: '11',
    month: '*',
    timezone: '',
    year: '*',
  },
  status: HEALTH_DASHBOARD_STATUS_MAP_V2.healthy,
};

export const DATASETS_HEALTH_LIST_V2 = {
  items: [
    {
      currentProcessingDeadlineConfig: {
        cronExpression: '11 6 28 * *',
        dayOfTheMonth: '28',
        dayOfWeek: '*',
        hour: '6',
        minute: '11',
        month: '*',
        timezone: '',
        year: '*',
      },
      dataProductName: 'Data Product Status Healthy',
      datasetId: 'Dataset1',
      datasetName: 'dataset_status_healthy',
      deliveryEventList: [
        {
          deliveredAt: '2023-12-01T03:50:00.000+00:00',
          deliveryDeadline: '2024-04-01T15:30:00',
          deliveryId: 'delivery1',
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.healthy,
        },
        {
          deliveredAt: '2023-12-01T03:50:00.000+00:00',
          deliveryDeadline: '2024-04-01T15:30:00',
          deliveryId: 'delivery2',
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.failed,
        },
        {
          deliveredAt: '2023-12-01T03:50:00.000+00:00',

          deliveryDeadline: '2024-04-01T15:30:00',
          deliveryId: 'delivery3',
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.missing,
        },
        {
          deliveryId: 'delivery4',

          status: HEALTH_DASHBOARD_STATUS_MAP_V2.healthy,
          timestamp: '2023-12-04T03:50:00.000+00:00',
        },
        {
          deliveryId: 'delivery5',

          status: HEALTH_DASHBOARD_STATUS_MAP_V2.healthy,
          timestamp: '2023-12-05T03:50:00.000+00:00',
        },
        {
          deliveryId: 'delivery6',

          status: HEALTH_DASHBOARD_STATUS_MAP_V2.healthy,
          timestamp: '2023-12-06T03:50:00.000+00:00',
        },
        {
          deliveryId: 'delivery7',

          status: HEALTH_DASHBOARD_STATUS_MAP_V2.healthy,
          timestamp: '2023-12-07T03:50:00.000+00:00',
        },
        {
          deliveryId: 'delivery8',

          status: HEALTH_DASHBOARD_STATUS_MAP_V2.healthy,
          timestamp: '2023-12-08T03:50:00.000+00:00',
        },
        {
          deliveryId: 'delivery9',

          status: HEALTH_DASHBOARD_STATUS_MAP_V2.healthy,
          timestamp: '2023-12-09T03:50:00.000+00:00',
        },
        {
          deliveryId: 'delivery10',
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.healthy,
          timestamp: '2023-11-25T04:30:00.000+00:00',
        },
      ],
      deliveryId:
        'CXO12345.CXO12345.crux_testing_llc_stoxx_common_files_preliminaryfloats_p000_20230605_v2.2023-12-04T03:50:00Z.fa027d5d-dd72-4bee-8e56-1892a4f441a4',
      latestDeliveryStatus: HEALTH_DASHBOARD_STATUS_MAP_V2.healthy,
      latestDeliveryTimestamp: '2023-12-04T03:50:00.000+00:00',
      nextExpectedDeliveryTimestamp: '2024-03-05T03:50:00.000+00:00',
      orgName: 'Crux Testing LLC',
      schedule: '0 50 3 * * *',
      supplier: {
        id: 'supplierId1',
        name: 'supplier 1',
      },
    },
    {
      dataProductName: 'Data Product Status Failed',
      datasetId: 'Dataset2',
      datasetName: 'dataset_status_failed',
      deliveries: [
        {
          deliveryId: DATASETS_HEALTH_LIST_V2_FAILED_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.failed,
          timestamp: '2023-12-04T03:50:00.000+00:00',
        },
        {
          deliveredAt: '2023-12-01T03:50:00.000+00:00',
          deliveryDeadline: '2024-04-01T15:30:00',
          deliveryId: DATASETS_HEALTH_LIST_V2_FAILED_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.failed,
        },
        {
          deliveredAt: '2023-12-01T03:50:00.000+00:00',
          deliveryDeadline: '2024-04-01T15:30:00',
          deliveryId: DATASETS_HEALTH_LIST_V2_FAILED_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.failed,
        },
        {
          deliveredAt: '2023-12-01T03:50:00.000+00:00',
          deliveryDeadline: '2024-04-01T15:30:00',
          deliveryId: DATASETS_HEALTH_LIST_V2_FAILED_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.failed,
        },
        {
          deliveryId: DATASETS_HEALTH_LIST_V2_FAILED_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.failed,
          timestamp: '2023-11-30T03:50:00.000+00:00',
        },
        {
          deliveryId: DATASETS_HEALTH_LIST_V2_FAILED_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.failed,
          timestamp: '2023-11-29T03:50:00.000+00:00',
        },
        {
          deliveryId: DATASETS_HEALTH_LIST_V2_FAILED_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.failed,
          timestamp: '2023-11-28T03:50:00.000+00:00',
        },
        {
          deliveryId: DATASETS_HEALTH_LIST_V2_FAILED_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.failed,
          timestamp: '2023-11-27T03:50:00.000+00:00',
        },
        {
          deliveryId: DATASETS_HEALTH_LIST_V2_FAILED_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.failed,
          timestamp: '2023-11-26T03:50:00.000+00:00',
        },
        {
          deliveryId: DATASETS_HEALTH_LIST_V2_FAILED_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.failed,
          timestamp: '2023-11-25T03:50:00.000+00:00',
        },
      ],
      deliveryId:
        'CXO12345.CXO12345.crux_testing_llc_stoxx_common_files_preliminaryfloats_p000_20230605_v2.2023-12-04T03:50:00Z.fa027d5d-dd72-4bee-8e56-1892a4f441a4',
      latestDeliveryStatus: HEALTH_DASHBOARD_STATUS_MAP_V2.failed,
      latestDeliveryTimestamp: '2023-12-04T03:50:00.000+00:00',
      nextExpectedDeliveryTimestamp: '2024-03-05T03:50:00.000+00:00',
      orgName: 'Crux Testing LLC',
      schedule: '0 50 3 * * *',
      supplier: {
        id: 'supplierId2',
        name: 'supplier 2',
      },
    },
    {
      dataProductName: 'Data Product Status Late',
      datasetId: 'Dataset3',
      datasetName: 'dataset_status_late',
      deliveries: [
        {
          deliveryId: DATASETS_HEALTH_LIST_V2_HEALTHY_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.late,
          timestamp: '2023-12-04T03:50:00.000+00:00',
        },
        {
          deliveredAt: '2023-12-01T03:50:00.000+00:00',
          deliveryDeadline: '2024-04-01T15:30:00',
          deliveryId: DATASETS_HEALTH_LIST_V2_HEALTHY_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.late,
        },
        {
          deliveredAt: '2023-12-01T03:50:00.000+00:00',
          deliveryDeadline: '2024-04-01T15:30:00',
          deliveryId: DATASETS_HEALTH_LIST_V2_HEALTHY_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.late,
        },
        {
          deliveredAt: '2023-12-01T03:50:00.000+00:00',
          deliveryDeadline: '2024-04-01T15:30:00',
          deliveryId: DATASETS_HEALTH_LIST_V2_HEALTHY_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.late,
        },
        {
          deliveryId: DATASETS_HEALTH_LIST_V2_HEALTHY_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.late,
          timestamp: '2023-11-30T03:50:00.000+00:00',
        },
        {
          deliveryId: DATASETS_HEALTH_LIST_V2_HEALTHY_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.late,
          timestamp: '2023-11-29T03:50:00.000+00:00',
        },
        {
          deliveryId: DATASETS_HEALTH_LIST_V2_HEALTHY_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.late,
          timestamp: '2023-11-28T03:50:00.000+00:00',
        },
        {
          deliveryId: DATASETS_HEALTH_LIST_V2_HEALTHY_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.late,
          timestamp: '2023-11-27T03:50:00.000+00:00',
        },
        {
          deliveryId: DATASETS_HEALTH_LIST_V2_HEALTHY_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.late,
          timestamp: '2023-11-26T03:50:00.000+00:00',
        },
        {
          deliveryId: DATASETS_HEALTH_LIST_V2_HEALTHY_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.late,
          timestamp: '2023-11-25T03:50:00.000+00:00',
        },
      ],
      deliveryId:
        'CXO12345.CXO12345.crux_testing_llc_stoxx_common_files_preliminaryfloats_p000_20230605_v2.2023-12-04T03:50:00Z.fa027d5d-dd72-4bee-8e56-1892a4f441a4',
      latestDeliveryStatus: HEALTH_DASHBOARD_STATUS_MAP_V2.late,
      latestDeliveryTimestamp: '2023-12-04T03:50:00.000+00:00',
      nextExpectedDeliveryTimestamp: '2024-03-05T03:50:00.000+00:00',
      orgName: 'Crux Testing LLC',
      schedule: '0 50 3 * * *',
      supplier: {
        id: 'supplierId3',
        name: 'supplier 3',
      },
    },
    {
      dataProductName: 'Data Product Status Missing',
      datasetId: 'Dataset4',
      datasetName: 'dataset_status_missing',
      deliveries: [
        {
          deliveredAt: '2023-12-01T03:50:00.000+00:00',
          deliveryDeadline: '2024-04-01T15:30:00',
          deliveryId: DATASETS_HEALTH_LIST_V2_FAILED_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.missing,
        },
        {
          deliveredAt: '2023-12-01T03:50:00.000+00:00',
          deliveryDeadline: '2024-04-01T15:30:00',
          deliveryId: DATASETS_HEALTH_LIST_V2_FAILED_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.missing,
        },
        {
          deliveredAt: '2023-12-01T03:50:00.000+00:00',
          deliveryDeadline: '2024-04-01T15:30:00',
          deliveryId: DATASETS_HEALTH_LIST_V2_FAILED_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.missing,
        },
        {
          deliveryId: DATASETS_HEALTH_LIST_V2_FAILED_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.missing,
          timestamp: '2023-11-30T03:50:00.000+00:00',
        },
        {
          deliveryId: DATASETS_HEALTH_LIST_V2_FAILED_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.missing,
          timestamp: '2023-11-29T03:50:00.000+00:00',
        },
        {
          deliveryId: DATASETS_HEALTH_LIST_V2_FAILED_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.missing,
          timestamp: '2023-11-28T03:50:00.000+00:00',
        },
        {
          deliveryId: DATASETS_HEALTH_LIST_V2_FAILED_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.missing,
          timestamp: '2023-11-27T03:50:00.000+00:00',
        },
        {
          deliveryId: DATASETS_HEALTH_LIST_V2_FAILED_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.missing,
          timestamp: '2023-11-26T03:50:00.000+00:00',
        },
        {
          deliveryId: DATASETS_HEALTH_LIST_V2_FAILED_DELIVERY_ID,
          status: HEALTH_DASHBOARD_STATUS_MAP_V2.missing,
          timestamp: '2023-11-25T03:50:00.000+00:00',
        },
      ],
      deliveryId:
        'CXO12345.CXO12345.crux_testing_llc_stoxx_common_files_preliminaryfloats_p000_20230605_v2.2023-12-04T03:50:00Z.fa027d5d-dd72-4bee-8e56-1892a4f441a4',
      latestDeliveryStatus: HEALTH_DASHBOARD_STATUS_MAP_V2.missing,
      latestDeliveryTimestamp: '2023-12-04T03:50:00.000+00:00',
      nextExpectedDeliveryTimestamp: '2024-03-05T03:50:00.000+00:00',
      orgName: 'Crux Testing LLC',
      schedule: '0 50 3 * * *',
    },
    {
      dataProductName: 'Data Product Status None',
      datasetId: 'Dataset5',
      datasetName: 'dataset_status_none',
      deliveries: [],
      latestDeliveryStatus: null,
      latestDeliveryTimestamp: null,
      nextExpectedDeliveryTimestamp: null,
      orgName: 'Crux Testing LLC',
      schedule: null,
      supplier: {
        id: 'supplierId4',
        name: 'supplier 4',
      },
    },
  ],
  nextPageToken: '209dksfjaisdjoijwei2',
  previousPageToken: null,
};
export const DATASET_HEALTH = {
  datasetId: 'DsGsQoc0',
  datasetName: 'deoatest_test_202209_file',
  deliveryId: '58233e34122d4d7b75e95baba135ce598b008ad12f006713c971bc0f14066192.0',
  frames: {
    content: [
      {
        frameId: 'frameId1',
        isPipelineRunning: false,
        log: '[2022-10-10 00:02:54,548] {models.py:1428} WARNING - Executing <Task(CruxShortCircuitUberOperator): uber_extract> on 2022-10-09 00:00:00',
        message: 'Pipeline run failed',
        name: 'MSCI FactorLab APAC Raw Exposures CUSIP Daily API Frame 1',
        status: 'error',
        step: 'ingest',
        stepId: 'stepId1',
        timestamp: '2022-03-29T16:00:00',
      },
      {
        frameId: 'frameId2',
        isPipelineRunning: true,
        message: 'Pipeline run failed',
        name: 'MSCI FactorLab APAC Raw Exposures CUSIP Daily API Frame 2',
        status: 'error',
        step: 'ingest',
        stepId: 'stepId2',
        timestamp: '2022-03-29T16:00:00',
      },
    ],
    pageNumber: 0,
    pageSize: 10,
    totalItemsNumber: 1,
    totalPagesNumber: 1,
  },
  scheduledDate: '2022-03-29T16:00:00',
  workflowId: 'deoatest_test_202209_file',
};

export const FORMATTED_TEST_FRAME = {
  deliver: [],
  ingest: [
    {
      frameId: 'frameId1',
      isPipelineRunning: false,
      log: '[2022-10-10 00:02:54,548] {models.py:1428} WARNING - Executing <Task(CruxShortCircuitUberOperator): uber_extract> on 2022-10-09 00:00:00',
      message: 'Pipeline run failed',
      name: 'MSCI FactorLab APAC Raw Exposures CUSIP Daily API Frame 1',
      status: 'error',
      step: 'ingest',
      stepId: 'stepId1',
      timestamp: '2022-03-29T16:00:00',
    },
    {
      frameId: 'frameId2',
      isPipelineRunning: true,
      message: 'Pipeline run failed',
      name: 'MSCI FactorLab APAC Raw Exposures CUSIP Daily API Frame 2',
      status: 'error',
      step: 'ingest',
      stepId: 'stepId2',
      timestamp: '2022-03-29T16:00:00',
    },
  ],
  normalize: [],
  process: [],
};

export const FORMATTED_DATASET_HEALTH_STEP_DATA = {
  [DELIVER]: [
    {
      status: 'skipped',
      timestamp: '2022-03-29T16:00:00',
    },
  ],
  [INGEST]: [
    {
      log: '[2022-10-10 00:02:54,548] {models.py:1428} WARNING - Executing <Task(CruxShortCircuitUberOperator): uber_extract> on 2022-10-09 00:00:00',
      message: 'Unable to process files, check frame normalization settings and re-run pipeline.',
      name: 'EQINTEG_23_02_22__EQINTEG_ASSET_v1023',
      status: 'error',
      timestamp: '2022-03-29T16:00:00',
    },
    {
      log: '[2022-10-10 00:02:54,548] {models.py:1428} WARNING - Executing <Task(CruxShortCircuitUberOperator): uber_extract> on 2022-10-09 00:00:00',
      message: 'Delay occurred during the processing of files, check frame normalization settings and re-run pipeline.',
      name: 'LSR_23_02_22__EQINTEG_ASSET_LSR_20230',
      status: 'warning',
      timestamp: '2022-03-29T16:00:00',
    },
  ],
  [NORMALIZE]: [
    {
      log: '[2022-10-10 00:02:54,548] {models.py:1428} WARNING - Executing <Task(CruxShortCircuitUberOperator): uber_extract> on 2022-10-09 00:00:00',
      message: 'Unable to process files, check frame normalization settings and re-run pipeline.',
      name: 'EQINTEG_23_02_22__EQINTEG_ASSET_v1023',
      status: 'error',
      timestamp: '2022-03-29T16:00:00',
    },
  ],
  [PROCESS]: [
    {
      log: '[2022-10-10 00:02:54,548] {models.py:1428} WARNING - Executing <Task(CruxShortCircuitUberOperator): uber_extract> on 2022-10-09 00:00:00',
      message: 'Delay occurred during the processing of files, check frame normalization settings and re-run pipeline.',
      name: 'LSR_23_02_22__EQINTEG_ASSET_LSR_20230',
      status: 'warning',
      timestamp: '2022-03-29T16:00:00',
    },
  ],
};

export const DELIVERY_TASK_HISTORY = [
  {
    cduId: null,
    codeDetails: '{stacktrace}',
    createdAt: 1676656726312,
    datasetId: 'DsQpnSo7',
    deliveryId: 'deliveryId',
    details: {
      runId: 'c403b31b-e416-4677-b353-576f69ad6547',
    },
    duration: 1893,
    frameId: null,
    message: 'Error during workflow execution',
    parentId: 'TsQpnSo7-230217135500',
    processorId: 'cadence-crux',
    reportedStatus: 'ERROR',
    scheduledAt: 1676642100000,
    shortCode: 'UNCLASSIFIED_EXCEPTION',
    status: HEALTH_DASHBOARD_V2_DELIVERY_STEP_STATUS_MAP.failed,
    taskId: 'TsQpnSo7-230217135500-ff5c1d80',
    taskName: 'uberFetch',
    taskStartedAt: 1676656724419,
    tenantId: 'CXO00000',
  },
];

export const FAILED_DESTINATION_STEP_WITH_TIMESTAMP = {
  cduId: 'Cx01560',
  createdAt: 1713542145016,
  datasetId: 'Ds7fpNQE',
  deliveryId: 'Dl61955b480ca3d04e',
  details: {
    deliveryId: '8ac2305570268492ad6181e5375d808ca3e7bb1d8ee6e79f4cb1dd69591ebbfc.0',
    destinationId: 'AQYuzouVNMQ2axarRNW_mHrQ8Q',
    destinationName: 'CRUX--BIGQUERY--AVRO-CSV',
    destinationType: 'BIGQUERY',
  },
  duration: 88287,
  message: 'Failed to complete export to BIGQUERY',
  parentId: 'Ts7fpNQE-240419153000',
  reportedStatus: 'FAILURE',
  scheduledAt: 1713540600000,
  status: 'FAILURE',
  taskId: 'Ts7fpNQE-240419153000-2ffc69a7',
  taskName: 'dispatch_MQbdjG_n83LZlXZOpxY03LRU1w',
  taskStartedAt: 1713542056755,
  timestamp: '2024-04-19T16:08:34.614+00:00',
};

export const FAILED_DISPATCH_STEP = {
  status: 'FAILED',
  tasks: [
    {
      cduId: 'Cx01560',
      createdAt: 1713542127083,
      datasetId: 'Ds7fpNQE',
      deliveryId: 'Dl61955b480ca3d04e',
      details: {
        deliveryId: '8ac2305570268492ad6181e5375d808ca3e7bb1d8ee6e79f4cb1dd69591ebbfc.0',
        destinationId: 'AQZ7I17aD5iCMGHGa6CJveevSQ',
        destinationName: 'TWO_SIGMA--GCS--PARQUET-RAW',
        destinationType: 'GCS',
      },
      duration: 70521,
      message: 'Failed to complete export to BIGQUERY',
      parentId: 'Ts7fpNQE-240419153000',
      reportedStatus: 'FAILURE',
      scheduledAt: 1713540600000,
      shortCode: 'CONNECTION_EXCEPTION',
      status: 'FAILURE',
      taskId: 'Ts7fpNQE-240419153000-b79c6b01',
      taskName: 'dispatch_MQYTDQquZq_ZWH64xhopInnC2A',
      taskStartedAt: 1713542056584,
    },
    {
      cduId: 'Cx01560',
      createdAt: 1713542145016,
      datasetId: 'Ds7fpNQE',
      deliveryId: 'Dl61955b480ca3d04e',
      details: {
        deliveryId: '8ac2305570268492ad6181e5375d808ca3e7bb1d8ee6e79f4cb1dd69591ebbfc.0',
        destinationId: 'AQYuzouVNMQ2axarRNW_mHrQ8Q',
        destinationName: 'CRUX--BIGQUERY--AVRO-CSV',
        destinationType: 'BIGQUERY',
      },
      duration: 88287,
      message: 'Failed to complete export to BIGQUERY',
      parentId: 'Ts7fpNQE-240419153000',
      reportedStatus: 'FAILURE',
      scheduledAt: 1713540600000,
      status: 'FAILURE',
      taskId: 'Ts7fpNQE-240419153000-2ffc69a7',
      taskName: 'dispatch_MQbdjG_n83LZlXZOpxY03LRU1w',
      taskStartedAt: 1713542056755,
    },
    {
      cduId: 'Cx01560',
      createdAt: 1713542914594,
      datasetId: 'Ds7fpNQE',
      deliveryId: 'Dl61955b480ca3d04e',
      details: {
        deliveryId: '8ac2305570268492ad6181e5375d808ca3e7bb1d8ee6e79f4cb1dd69591ebbfc.0',
        destinationId: 'AQZdPSILiBsLHQXKUJF4kGc3KA',
        destinationName: 'CRUX--AVRO-CSV',
        destinationType: 'SNOWFLAKE',
      },
      duration: 855032,
      message: 'Successfully completed export to SNOWFLAKE',
      parentId: 'Ts7fpNQE-240419153000',
      reportedStatus: 'SUCCESS',
      scheduledAt: 1713540600000,
      status: 'SUCCESS',
      taskId: 'Ts7fpNQE-240419153000-fc80eae6',
      taskName: 'dispatch_MQa9hwHKEyTr8_QeHwzq-rosIg',
      taskStartedAt: 1713542059582,
    },
  ],
  timestamp: '2024-04-19T16:08:34.614+00:00',
};
