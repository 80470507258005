import ApiBase from './ApiBase';

export const SCHEMA_ENDPOINT = 'schema';
export const SCHEMA_SAMPLE_DATA_ENDPOINT = 'sample-data';

export default class PipelineService extends ApiBase {
  async fetchSchemaSampleData(pipelineId) {
    const data = await this.client.get(`/${pipelineId}/${SCHEMA_ENDPOINT}/${SCHEMA_SAMPLE_DATA_ENDPOINT}`);

    return data;
  }

  async updateSchema({ pipelineId, payload }) {
    const { data } = await this.client.put(`/${pipelineId}/schema`, payload);

    return data;
  }
}
