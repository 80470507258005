<template>
  <nav>
    <ul class="-space-x-px inline-flex" :aria-label="$t('pagination')">
      <li>
        <button
          v-hubble="'previous'"
          class="bg-white border border-gray-300 leading-tight ml-0 px-3 py-2 rounded-l-lg text-gray-500"
          :class="buttonClasses(previousButtonDisabled)"
          type="button"
          :disabled="previousButtonDisabled"
          :aria-label="$t('previousPage')"
          @click.prevent.stop="$emit('previous-button-click')"
        >
          {{ $t('previous') }}
        </button>
      </li>
      <slot name="pageNumberButtons" />
      <li>
        <button
          v-hubble="'next'"
          class="bg-white border border-gray-300 leading-tight px-3 py-2 rounded-r-lg text-gray-500"
          :class="buttonClasses(nextButtonDisabled)"
          type="button"
          :aria-label="$t('nextPage')"
          :disabled="nextButtonDisabled"
          @click.prevent.stop="$emit('next-button-click')"
        >
          {{ $t('next') }}
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'CxPaginationPaginationButtons',

  props: {
    nextButtonDisabled: {
      type: Boolean,
      default: false,
    },

    previousButtonDisabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    buttonClasses(isDisabled) {
      return isDisabled ? 'cursor-not-allowed opacity-50' : 'hover:bg-gray-100 hover:text-gray-700';
    },
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "next": "Next",
    "nextPage": "go to next page",
    "previous": "Previous",
    "previousPage": "go to previous page"
  }
}
</i18n>
