export const ENTERPRISE = 'ENTERPRISE';
export const FREE = 'FREE';
export const CRUX_FORGE_ANNUAL = 'CRUX_FORGE_ANNUAL';
export const PRO = 'PRO';
export const TRIAL = 'TRIAL';

export const EDP_AWAITING_SUBSCRIPTION = 'EDP_AWAITING_SUBSCRIPTION';
export const FORGE_AWAITING_SUBSCRIPTION = 'FORGE_AWAITING_SUBSCRIPTION';
export const SUPPLIER_AWAITING_SUBSCRIPTION = 'SUPPLIER_AWAITING_SUBSCRIPTION';

export const EDP = 'EDP';
export const FORGE = 'FORGE';
export const SUPPLIER = 'SUPPLIER';

export const SUBSCRIPTION_PLAN_TITLES = {
  [CRUX_FORGE_ANNUAL]: 'Forge',
  [ENTERPRISE]: 'Enterprise',
  [FREE]: 'Free',
  [PRO]: 'Pro',
  [SUPPLIER]: 'Supplier',
  [TRIAL]: 'Pro Trial',
};

export const SUBSCRIPTION_PLAN_COLOR_MAP = {
  [CRUX_FORGE_ANNUAL]: {
    bgColor: 'bg-orange-50',
    bgColorDark: 'bg-orange-400',
    bgColorSolid: 'bg-orange-700',
    borderColor: 'border-orange-200',
    borderColorDark: 'border-orange-400',
    buttonColor: 'orange',
    buttonColorOutlined: 'orange-outline',
    textColor: 'text-orange-700',
    textColorDark: 'text-orange-400',
  },
  [ENTERPRISE]: {
    bgColor: 'bg-purple-50',
    bgColorDark: 'bg-purple-400',
    bgColorSolid: 'bg-purple-700',
    borderColor: 'border-purple-200',
    borderColorDark: 'border-purple-400',
    buttonColor: 'purple',
    buttonColorOutlined: 'purple-outline',
    textColor: 'text-purple-700',
    textColorDark: 'text-purple-400',
  },
  [FREE]: {
    bgColor: 'bg-gray-100',
    bgColorDark: 'bg-gray-400',
    bgColorSolid: 'bg-gray-700',
    borderColor: 'border-gray-300',
    borderColorDark: 'border-gray-400',
    buttonColor: 'gray',
    buttonColorOutlined: 'gray-outline',
    textColor: 'text-gray-700',
    textColorDark: 'text-gray-400',
  },
  [PRO]: {
    bgColor: 'bg-blue-50',
    bgColorDark: 'bg-blue-400',
    bgColorSolid: 'bg-blue-600',
    borderColor: 'border-blue-200',
    borderColorDark: 'border-blue-400',
    buttonColor: 'primary',
    buttonColorOutlined: 'outline',
    textColor: 'text-blue-600',
    textColorDark: 'text-blue-400',
  },
  [SUPPLIER]: {
    bgColor: 'bg-white',
    bgColorDark: 'bg-gray-400',
    bgColorSolid: 'bg-gray-600',
    borderColor: 'border-gray-900',
    borderColorDark: 'border-gray-300',
    buttonColor: 'gray',
    buttonColorOutlined: 'gray-outline',
    textColor: 'text-gray-600',
    textColorDark: 'text-gray-300',
  },
  [TRIAL]: {
    bgColor: 'bg-green-50',
    bgColorDark: 'bg-green-400',
    bgColorSolid: 'bg-green-600',
    borderColor: 'border-green-200',
    borderColorDark: 'border-green-400',
    buttonColor: 'success-outline',
    buttonColorOutlined: 'success-outline',
    textColor: 'text-green-600',
    textColorDark: 'text-green-400',
  },
};

export const PLAN_BILLING_IMAGES = {
  [CRUX_FORGE_ANNUAL]: 'call',
  [ENTERPRISE]: 'robot',
  [FREE]: 'free',
  [PRO]: 'science',
  [TRIAL]: 'rocket-man',
};

export const SUBSCRIPTION_PLANS = {
  cruxForgeAnnual: CRUX_FORGE_ANNUAL,
  edpAwaiting: EDP_AWAITING_SUBSCRIPTION,
  enterprise: ENTERPRISE,
  forgeAwaiting: FORGE_AWAITING_SUBSCRIPTION,
  free: FREE,
  pro: PRO,
  supplier: SUPPLIER,
  supplierAwaiting: SUPPLIER_AWAITING_SUBSCRIPTION,
  trial: TRIAL,
};

export const SUBSCRIPTION_PLAN_ICONS = {
  cruxForgeAnnual: 'building-line',
  enterprise: 'vip-crown-line',
  free: 'verified-badge-line',
  pro: 'flashlight-line',
  supplier: 'truck-line',
  trial: 'timer-flash-line',
};

export const SUBSCRIPTION_PLAN_ICON_COLORS = {
  cruxForgeAnnual: 'text-orange-600',
  enterprise: 'text-purple-600',
  free: 'text-gray-600',
  pro: 'text-blue-600',
  supplier: 'text-gray-900',
  trial: 'text-green-500',
};

export const SUBSCRIPTION_TYPES = {
  edp: EDP,
  forge: FORGE,
  supplier: SUPPLIER,
};

export const ACTION_ACTIVATE_DATA_PRODUCT = 'activate_data_product';
export const EXPIRED_SUBSCRIPTION_ACTIONS = [ACTION_ACTIVATE_DATA_PRODUCT];

export const SUBSCRIPTION_SHOW_DAYS_LIMIT = 60;
