<template>
  <div class="bg-white flex flex-col">
    <div v-if="loading" class="flex flex-1 flex-col items-center justify-center space-y-4">
      <cx-spinner size="sm" />
      <span>{{ $t('loading') }}</span>
    </div>
    <template v-else>
      <div class="flex-1">
        <div class="border-b flex items-center justify-between p-4">
          <span class="font-semibold text-gray-800">{{ computedTitle }}</span>
          <slot name="header" />
        </div>
        <slot />
      </div>
      <slot name="footer" />
    </template>
  </div>
</template>

<script>
import { CxSpinner } from '~/components/cx';

export { default as CxDataExplorerDirectory } from './subcomponents/CxDataExplorerDirectory.vue';
export { default as CxDataExplorerLeaf } from './subcomponents/CxDataExplorerLeaf.vue';

export default {
  name: 'CxDataExplorer',

  hubble: 'cx-data-explorer',

  components: {
    CxSpinner,
  },

  props: {
    loading: {
      default: false,
      type: Boolean,
    },

    title: {
      default: '',
      type: String,
    },
  },

  computed: {
    computedTitle() {
      return this.title || this.$t('title');
    },
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "loading": "Loading data explorer",
    "title": "Data Explorer"
  }
}
</i18n>
