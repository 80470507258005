import { difference, get, map } from 'lodash-es';

import {
  MAX_SELECTED_FILENAME_PATTERNS,
  PATTERN_MAX_MATCHED_FILES,
  PATTERN_MAX_TOTAL_FILE_SIZE,
} from '~/support/constants';

export default {
  allPatterns(state) {
    return [...state.customPatterns, ...map(state.foundPatterns, 'filenameRegex')];
  },

  allPatternsSelected(state, getters) {
    return (
      Boolean(state.selectedPatterns.length) && difference(getters.allPatterns, state.selectedPatterns).length === 0
    );
  },

  maxSelectedFilenamePatternsExceeded(state) {
    return state.selectedPatterns.length > MAX_SELECTED_FILENAME_PATTERNS;
  },

  patternData(state) {
    return (pattern) => get(state.matchingFiles, pattern, {});
  },

  patternFiles(state, getters) {
    return (pattern) => getters.patternData(pattern).files || [];
  },

  patternTotalArchivesOverSizeLimit(state, getters) {
    return (pattern) => getters.patternData(pattern).totalArchivesOverSizeLimit;
  },

  patternTotalFiles(state, getters) {
    return (pattern) => getters.patternData(pattern).totalFiles || 0;
  },

  patternTotalFilesOverSizeLimit(state, getters) {
    return (pattern) => getters.patternData(pattern).totalFilesOverSizeLimit;
  },

  patternTotalFilesSize(state, getters) {
    return (pattern) => getters.patternData(pattern).totalSizeOfFiles || 0;
  },

  shouldDisableModelDataButton(state, getters) {
    return (
      !state.selectedPatterns.length ||
      getters.maxSelectedFilenamePatternsExceeded ||
      state.selectedPatterns.some(
        (pattern) =>
          getters.patternTotalFilesSize(pattern) > PATTERN_MAX_TOTAL_FILE_SIZE ||
          getters.patternTotalFiles(pattern) > PATTERN_MAX_MATCHED_FILES ||
          getters.patternTotalFilesOverSizeLimit(pattern) ||
          getters.patternTotalArchivesOverSizeLimit(pattern),
      )
    );
  },
};
