export const ASC = 'asc';
export const BASE = 'base';
export const COPIED_TIMEOUT = 1000;
export const COLOR_BLUE_50 = 'bg-blue-50';
export const COLOR_GRAY_50 = 'bg-gray-50';
export const COLOR_LIME_50 = 'bg-lime-50';
export const COLOR_ORANGE_50 = 'bg-orange-50';
export const COLOR_PINK_50 = 'bg-pink-50';
export const COLOR_PURPLE_50 = 'bg-purple-50';
export const COLOR_RED_50 = 'bg-red-50';
export const COLOR_TEAL_50 = 'bg-teal-50';
export const COLOR_LIME_300 = 'bg-lime-300';
export const COLOR_ORANGE_300 = 'bg-orange-300';
export const COLOR_PINK_300 = 'bg-pink-300';
export const COLOR_PURPLE_300 = 'bg-purple-300';
export const COLOR_RED_300 = 'bg-red-300';
export const COLOR_TEAL_300 = 'bg-teal-300';
export const COLOR_LIME_500 = 'bg-lime-500';
export const COLOR_ORANGE_500 = 'bg-orange-500';
export const COLOR_PINK_500 = 'bg-pink-500';
export const COLOR_PURPLE_500 = 'bg-purple-500';
export const COLOR_RED_500 = 'bg-red-500';
export const COLOR_TEAL_500 = 'bg-teal-500';
export const COLOR_LIME_700 = 'bg-lime-700';
export const COLOR_ORANGE_700 = 'bg-orange-700';
export const COLOR_PINK_700 = 'bg-pink-700';
export const COLOR_PURPLE_700 = 'bg-purple-700';
export const COLOR_RED_700 = 'bg-red-700';
export const COLOR_TEAL_700 = 'bg-teal-700';
export const COLOR_WHITE = 'bg-white';
export const DESC = 'desc';
export const DISABLED = 'disabled';
export const ERROR = 'error';
export const FULL = 'full';
export const ICON = 'icon';
export const INFO = 'info';
export const INPUT_DEFAULT_BG_COLOR = 'gray';
export const LG = 'lg';
export const MD = 'md';
export const MODAL_MIN_WIDTH = 'w-1/2';
export const OUTLINE = 'outline';
export const PENDING = 'pending';
export const PRIMARY = 'primary';
export const SM = 'sm';
export const SELECTED = 'selected';
export const SUCCESS = 'success';
export const BG_HOVER_COLORS = {
  lime: COLOR_LIME_50,
  orange: COLOR_ORANGE_50,
  pink: COLOR_PINK_50,
  purple: COLOR_PURPLE_50,
  red: COLOR_RED_50,
  teal: COLOR_TEAL_50,
};
export const TIMELINE_COLORS = [
  COLOR_PURPLE_300,
  COLOR_TEAL_300,
  COLOR_PINK_300,
  COLOR_ORANGE_300,
  COLOR_LIME_300,
  COLOR_RED_300,
  // 500
  COLOR_PURPLE_500,
  COLOR_TEAL_500,
  COLOR_PINK_500,
  COLOR_ORANGE_500,
  COLOR_LIME_500,
  COLOR_RED_500,
  // 700
  COLOR_PURPLE_700,
  COLOR_TEAL_700,
  COLOR_PINK_700,
  COLOR_ORANGE_700,
  COLOR_LIME_700,
  COLOR_RED_700,
];
export const WARNING = 'warning';
export const XL = 'xl';
export const XS = 'xs';
export const XXL = '2xl';
export const XXXL = '3xl';
export const XXXXL = '4xl';
export const XXXXXL = '5xl';
export const XXXXXXL = '6xl';
export const XXXXXXXL = '7xl';
export const XXXXXXXXL = '8xl';
export const TEXT_SIZES = [XS, SM, MD, LG, XL, XXL, XXXL, XXXXL, XXXXXL, XXXXXXL, XXXXXXXL, XXXXXXXXL];
