import { CLEAR_USERS, SET_USERS } from './mutation-types';

export default {
  [CLEAR_USERS](state) {
    state.users = [];
  },

  [SET_USERS](state, users) {
    state.users = users;
  },
};
