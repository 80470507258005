<template>
  <span class="flex items-center" :class="{ disabled }" @click="handleToggle">
    <input
      ref="checkbox"
      type="checkbox"
      class="focus:ring-2 focus:ring-blue-500 rounded"
      :class="[validityClasses, disabledClass, loadingClass, sizeClasses]"
      :checked="modelValue"
      :value="modelValue"
      :disabled="disabled"
      :indeterminate.prop="indeterminate"
      :aria-checked="modelValue"
      :aria-labelledby="$slots.default ? labelId : ''"
      @blur="$emit('blur', $event)"
      @focus="$emit('focus', $event)"
      @keyup.space="handleToggle"
    />
    <cx-spinner v-if="loading" size="xs" />
    <label v-if="$slots.default" :id="labelId" class="block ml-2 text-gray-500 text-xs truncate">
      <slot />
    </label>
  </span>
</template>

<script>
import { CxSpinner } from '../CxSpinner';
import { LG, BASE } from '../constants';
export const SIZES = [BASE, LG];
export const SIZE_CLASS_MAP = {
  [BASE]: 'h-4 w-4',
  [LG]: 'h-6 w-6 text-xl',
};
export default {
  name: 'CxCheckbox',

  components: {
    CxSpinner,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    indeterminate: {
      type: Boolean,
      default: false,
    },

    invalid: {
      default: false,
      type: Boolean,
    },

    loading: {
      default: false,
      type: Boolean,
    },

    modelValue: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: BASE,
      validator: (value) => SIZES.includes(value),
    },
  },

  computed: {
    disabledClass() {
      return this.disabled ? 'opacity-50 cursor-not-allowed' : '';
    },

    labelId() {
      return `cx-checkbox-label-${this._uid}`;
    },

    loadingClass() {
      return this.loading ? 'hidden' : '';
    },

    sizeClasses() {
      return SIZE_CLASS_MAP[this.size];
    },

    validityClasses() {
      return this.invalid ? 'border-red-600 bg-red-50' : 'border-gray-300 bg-gray-100';
    },
  },

  methods: {
    handleToggle() {
      if (this.disabled) return;

      this.$emit('update:modelValue', !this.modelValue);
    },
  },
};
</script>
