import { ACCESS_TOKEN, REFRESH_TOKEN } from '~/support/constants';
import { AuthService } from '~/support/services';

import { SET_USER } from './mutation-types';

export default {
  async getUser({ commit }) {
    const service = new AuthService(useRuntimeConfig().public.usersBaseUrl);
    const user = await service.getUser();

    commit(SET_USER, user);
  },

  async login({ dispatch, commit }, credentials) {
    const service = new AuthService(useRuntimeConfig().public.authBaseUrl);
    const response = await service.login(credentials);
    const { accessToken, refreshToken, user } = response;

    await dispatch('setTokens', { accessToken, refreshToken });

    commit(SET_USER, user);

    return { accessToken, refreshToken, user };
  },

  logout({ commit }) {
    const cookieAccessToken = useCookie(`auth.${ACCESS_TOKEN}`);
    const cookieRefreshToken = useCookie(`auth.${REFRESH_TOKEN}`);

    cookieAccessToken.value = null;
    cookieRefreshToken.value = null;

    /**
     * Without the nextTick we don't remove the cookies on time and we have a bug
     * where after we have been logged out and we refresh the page,
     * the cookies are still there so we got auto logged in.
     * The nextTick probaby fixes it by taking it out of the
     * event loop, but i don't understand why it works.
     */
    nextTick(() => commit(SET_USER, null));
  },

  async refreshTokens() {
    const cookieRefreshToken = useCookie(`auth.${REFRESH_TOKEN}`);

    const service = new AuthService(useRuntimeConfig().public.authBaseUrl);
    const { accessToken, refreshToken } = await service.refreshTokens(cookieRefreshToken.value);

    return { accessToken, refreshToken };
  },

  setTokens(_, { accessToken, refreshToken }) {
    const cookieAccessToken = useCookie(`auth.${ACCESS_TOKEN}`);
    const cookieRefreshToken = useCookie(`auth.${REFRESH_TOKEN}`);

    cookieAccessToken.value = accessToken;
    cookieRefreshToken.value = refreshToken;
  },
};
