import Fuse from 'fuse.js';

export const FUSE_DEFAULT_SETTINGS = {
  findAllMatches: true,
  ignoreLocation: true,
  shouldSort: true,
  threshold: 0.2,
};

export class CxSearch {
  #keys;
  #list;
  #overrides;

  constructor(list = [], keys = [], overrides = {}) {
    this.#keys = keys;
    this.#list = list;
    this.#overrides = overrides;

    this.#init();
  }

  find(query) {
    const result = this.instance.search(query);

    return result.map(({ item }) => item);
  }

  #init() {
    const options = {
      ...FUSE_DEFAULT_SETTINGS,
      ...this.#overrides,
      keys: this.#keys,
    };

    const instance = new Fuse(this.#list, options);

    this.instance = instance;
  }

  updateKeys(keys) {
    this.#keys = keys;
    this.#init();

    return this;
  }

  updateList(list) {
    this.#list = list;
    this.#init();

    return this;
  }

  updateOverrides(overrides) {
    this.#overrides = overrides;
    this.#init();

    return this;
  }
}

export default (list, keys, overrides) => {
  return new CxSearch(list, keys, overrides);
};
