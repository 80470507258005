import ApiBase from './ApiBase';

export default class OrgsService extends ApiBase {
  async create(body) {
    const { data } = await this.client.post('', body);

    return data;
  }

  async createEntitlements(body) {
    const { data } = await this.client.post(`${body.orgId}/entitlements/bulk`, body);

    return data;
  }

  async createImage(body, orgId, params) {
    const { data } = await this.client.post(`${orgId}/image`, body, { params });

    return data;
  }

  async deactivate(orgId) {
    const { data } = await this.client.put(`${orgId}/deactivate`);

    return data;
  }

  async fetchAll() {
    const { data } = await this.client.get();

    return data;
  }

  async fetchAllStats() {
    const { data } = await this.client.get('all/stats');

    return data;
  }

  async fetchOne(orgId) {
    const { data } = await this.client.get(orgId);

    return data;
  }

  async fetchStatsByOrg(orgId) {
    const { data } = await this.client.get(`${orgId}/stats`);

    return data;
  }

  async fetchStatsForOwnOrg() {
    const { data } = await this.client.get('me/stats');

    return data;
  }

  async fetchUsers(orgId) {
    const { data } = await this.client.get(`${orgId}/users`);

    return data;
  }

  async inviteUser(orgId, user) {
    const { data } = await this.client.post(`${orgId}/users`, user);

    return data;
  }

  async update(orgId, body) {
    const { data } = await this.client.put(orgId, body);

    return data;
  }

  async updateImage(body, orgId, params) {
    const { data } = await this.client.put(`${orgId}/image`, body, { params });

    return data;
  }

  async updateSubscriptionPlan(orgId, plan) {
    const { data } = await this.client.put(`${orgId}/subscription-plan/${plan}`);

    return data;
  }

  async updateSubscriptionPlans(orgId, body) {
    const { data } = await this.client.put(`${orgId}/subscription-plans/`, body);

    return data;
  }

  async updateSupplier(orgId, body) {
    const { data } = await this.client.put(`${orgId}/supplier`, body);

    return data;
  }

  async updateUser(orgId, userId, body) {
    const { data } = await this.client.put(`${orgId}/users/${userId}`, body);

    return data;
  }
}
