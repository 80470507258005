import ApiBase from './ApiBase';

export const FILE_SCHEDULE_ENDPOINT = 'file-schedule';
export const STATUS_ENDPOINT = 'status';

export default class WorkflowService extends ApiBase {
  async fetchFileSchedule(workflowId) {
    const { data } = await this.client.get(`/${workflowId}/${FILE_SCHEDULE_ENDPOINT}`);

    return data;
  }

  async fetchStatus(workflowId) {
    const { data } = await this.client.get(`/${workflowId}/${STATUS_ENDPOINT}`);

    return data;
  }

  async updateSchedule(workflowId, body) {
    return await this.client.put(`/${workflowId}/schedule`, body);
  }
}
