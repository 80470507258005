import ApiBase from './ApiBase';

export const CUSTOM_CONNECTORS_ENDPOINT = 'custom-connectors';
export const CUSTOM_NORMALIZERS_ENDPOINT = 'custom-normalizers';

export default class AdaptersService extends ApiBase {
  async createCustomConnector(payload) {
    const { data } = await this.client.post(`/${CUSTOM_CONNECTORS_ENDPOINT}`, payload);

    return data;
  }

  async fetchCustomConnectors() {
    const { data } = await this.client.get(`/${CUSTOM_CONNECTORS_ENDPOINT}`);

    return data;
  }

  async fetchCustomNormalizers() {
    const { data } = await this.client.get(`/${CUSTOM_NORMALIZERS_ENDPOINT}`);

    return data;
  }
}
