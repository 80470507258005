import { find, get, groupBy, keyBy, orderBy } from 'lodash-es';

import {
  PROFILING_COMPLETED,
  PROFILING_ERROR,
  PROFILING_PENDING,
  PROFILING_SUBSCRIBED,
  PROFILING_SUBSCRIBING,
  PROFILING_SUCCESS,
  PROFILING_UNSUBSCRIBED,
  PROFILING_UNSUBSCRIBING,
} from '~/support/constants';

export default {
  connectionSelectOptions(state) {
    return orderBy(
      state.connections.map(({ id: value, name: label, method, type }) => {
        return {
          label,
          method,
          type,
          value,
        };
      }),
      [({ label }) => label.toLowerCase()],
    );
  },

  connectionsById(state) {
    return keyBy(state.connections, 'id');
  },

  datasets(state) {
    return get(state.dataProduct, 'datasets', []);
  },

  datastoreIdsList(state) {
    const datastoresMap = get(state.dataProduct, 'datastoresMap', {});

    return Object.keys(datastoresMap);
  },

  flattenedFrameVersions(state, getters) {
    return getters.workflows.reduce((result, { frames }) => {
      frames.forEach(({ versions }) => {
        result = [...result, ...versions];
      });

      return result;
    }, []);
  },

  flattenedFrames(state) {
    if (!state.dataProduct?.datastoresMap) return [];

    return Object.values(state.dataProduct.datastoresMap).flat();
  },

  flattenedInferredFrameVersions(state, getters) {
    return getters.inferredDatasets.reduce((result, { frames }) => {
      frames.forEach(({ versions = [] }) => {
        result = [...result, ...versions];
      });

      return result;
    }, []);
  },

  flattenedInferredFrames(state, getters) {
    return getters.inferredDatasets.reduce((result, { frames }) => {
      result = [...result, ...frames];

      return result;
    }, []);
  },

  frameIds(state) {
    const inferredDatasets = get(state.dataProduct, 'inferredDatasets', []);

    return inferredDatasets.map((inferredDataset) => inferredDataset.frames.map((frame) => frame.id)).flat();
  },

  frameStatuses(state, getters) {
    return getters.flattenedFrames.map((frame) => frame.status);
  },

  framesCount(state, getters) {
    return getters.datasets.reduce((total, dataset) => total + dataset.numberOfFrames, 0);
  },

  groupedFrames(state) {
    if (!state.dataProduct || !state.dataProduct.workflows.length) return [];

    const flattenedFrames = state.dataProduct.workflows
      .map((workflow) =>
        workflow.frames.map((frame) => {
          return { ...frame, datasetCron: workflow.cron, datasetId: workflow.id };
        }),
      )
      .reduce((previous, current) => previous.concat(current));

    return groupBy(flattenedFrames, 'pattern');
  },

  includedDatastoreIds: (state) => (includedFrames) => {
    const datastoresMap = { ...state.dataProduct.datastoresMap };
    const datastoreIds = Object.keys(datastoresMap);
    const includedDatastoreIds = [];

    datastoreIds.forEach((datastoreId) => {
      const frames = datastoresMap[datastoreId];
      const hasExcludedFrames = frames.some((frame) => !includedFrames.includes(frame.id));

      if (!hasExcludedFrames) includedDatastoreIds.push(datastoreId);
    });

    return includedDatastoreIds.map((datastoreId) => parseInt(datastoreId));
  },

  inferredDatasets(state) {
    return get(state.dataProduct, 'inferredDatasets', []);
  },

  newlyDetectedFilenamePatterns(state, getters) {
    return getters.flattenedFrames.reduce((result, { steps }) => {
      return result.concat(get(steps, 'downloading.meta.discoveredPatterns', []));
    }, []);
  },

  profileCompleted(state, getters) {
    if (!getters.flattenedFrames?.length) return false;

    return getters.flattenedFrames.every(
      (frame) => frame.isStopped || [PROFILING_SUCCESS, PROFILING_ERROR].includes(frame.status),
    );
  },

  profilePending(state, getters) {
    return getters.frameStatuses.includes(PROFILING_PENDING);
  },

  profiledFrame: (state, getters) => (id) => {
    return find(getters.flattenedFrames, { datastoreId: parseInt(id) });
  },

  profilingNotificationStatus(state) {
    if (state.profilingNotificationLoading) {
      if (state.profilingNotificationActive) return PROFILING_UNSUBSCRIBING;

      return PROFILING_SUBSCRIBING;
    }

    if (state.profilingNotificationActive) return PROFILING_SUBSCRIBED;

    return PROFILING_UNSUBSCRIBED;
  },

  profilingStatus(state, getters) {
    switch (true) {
      case getters.profileCompleted:
        return PROFILING_COMPLETED;
      case getters.profilePending:
        return PROFILING_PENDING;
      default:
    }
  },

  selectedDatasetFrames: (state, getters) => (dataset) => {
    const datastoreIds = dataset.frames.map((frame) => frame.datastoreId);

    return getters.flattenedFrames.filter((frame) => datastoreIds.includes(frame.datastoreId));
  },

  selectedFrame: (state, getters) => (id) => {
    return find(getters.flattenedFrames, { id }) || getters.profiledFrame(id);
  },

  selectedPatterns(state, getters) {
    if (!getters.inferredDatasets) return [];

    return getters.inferredDatasets.reduce((result, dataset) => {
      return result.concat(dataset.selectedPatterns);
    }, []);
  },

  workflows(state) {
    return get(state.dataProduct, 'workflows', []);
  },
};
