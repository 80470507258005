import Rollbar from 'rollbar';

export default defineNuxtPlugin(({ vueApp, $config }) => {
  vueApp.config.globalProperties.$rollbar = new Rollbar({
    accessToken: $config.public.rollbarAccessToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      client: {
        javascript: {
          code_version: $config.public.buildNumber || 'local',
          guess_uncaught_frames: true,
          source_map_enabled: true,
        },
      },
      environment: $config.public.rollbarEnvironment,
    },
  });
  vueApp.config.errorHandler = (error, vm) => {
    vm.$rollbar.error(error);
    // eslint-disable-next-line no-console
    console.error(error);
  };
  vueApp.config.warnHandler = (warn) => {
    // eslint-disable-next-line no-console
    console.warn(warn);
  };
});
