export const FILTER_FULL_MATCH = 'filter_full_match';
export const FILTER_REGEX_MATCH = 'filter_regex_match';
export const FILTER_REGEX_DATERANGE_MATCH = 'filter_regex_daterange_match';
export const FILTER_FULL_MATCH_ITERATE = 'filter_full_match_iterate';
export const GENERATE_ITEM_SUBSTITUTION = 'generate_item_substitution';
export const FILTER_TYPES = [
  FILTER_FULL_MATCH,
  FILTER_REGEX_MATCH,
  FILTER_REGEX_DATERANGE_MATCH,
  FILTER_FULL_MATCH_ITERATE,
  GENERATE_ITEM_SUBSTITUTION,
];

export const ASCII = 'ascii';
export const CP1250 = 'cp1250';
export const CP1251 = 'cp1251';
export const CP1252 = 'cp1252';
export const CP1254 = 'cp1254';
export const CP1256 = 'cp1256';
export const CP437 = 'cp437';
export const LATIN_1 = 'latin-1';
export const ISO_8859_1 = 'iso-8859-1';
export const UTF_16 = 'utf-16';
export const UTF_16_LE = 'utf-16-le';
export const UTF_8 = 'utf-8';
export const UTF_8_SIG = 'utf-8-sig';
export const WINDOWS_1251 = 'Windows-1251';
export const WINDOWS_1252 = 'Windows-1252';
export const WINDOWS_1256 = 'Windows-1256';

export const FILE_ENCODING_OPTIONS = [
  { label: ASCII, value: ASCII },
  { label: CP1250, value: CP1250 },
  { label: CP1251, value: CP1251 },
  { label: CP1252, value: CP1252 },
  { label: CP1254, value: CP1254 },
  { label: CP1256, value: CP1256 },
  { label: CP437, value: CP437 },
  { label: LATIN_1, value: LATIN_1 },
  { label: ISO_8859_1, value: ISO_8859_1 },
  { label: UTF_16, value: UTF_16 },
  { label: UTF_16_LE, value: UTF_16_LE },
  { label: UTF_8, value: UTF_8 },
  { label: UTF_8_SIG, value: UTF_8_SIG },
  { label: WINDOWS_1251, value: WINDOWS_1251 },
  { label: WINDOWS_1252, value: WINDOWS_1252 },
  { label: WINDOWS_1256, value: WINDOWS_1256 },
];

export const DEFAULT_FILE_FUNCTION = FILTER_REGEX_MATCH;
export const DEFAULT_FILE_FUNCTION_VALUE = '.*';

const KB = 1000;
const MB = KB * 1000;
const GB = MB * 1000;

export const MAX_ARCHIVED_FILE_SIZE = 5 * GB;
export const MAX_FILE_SIZE = 1.5 * GB;
export const MAX_SELECTED_FILENAME_PATTERNS = 25;
export const PATTERN_MAX_MATCHED_FILES = 5000;
export const PATTERN_MAX_TOTAL_FILE_SIZE = 50 * GB;
