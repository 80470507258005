import axios from 'axios';
import { defaultsDeep } from 'lodash-es';
// import { store } from '~/plugins/vuex';

import { ACCESS_TOKEN, REFRESH_TOKEN, DEFAULT_HEADERS } from '~/support/constants';

// const HTTP_STATUS_UNAUTHORIZED = 401;

export default {
  create(config = {}) {
    const httpClient = axios.create(
      defaultsDeep(config, {
        headers: {
          ...DEFAULT_HEADERS,
          Authorization: `Bearer ${this.getAccessToken()}`,
        },
      }),
    );

    // TODO: test properly whether successful refresh leads to re-attempting initial request
    // httpClient.interceptors.response.use(
    //   (response) => response,
    //   async (error) => {
    //     const originalRequest = error.config;

    //     try {
    //       if (error.response.status === HTTP_STATUS_UNAUTHORIZED) {
    //         originalRequest._retry = true;

    //         await store.dispatch('auth/refreshTokens');

    //         httpClient.defaults.headers.common.Authorization = `Bearer ${this.getAccessToken()}`;

    //         return axiosApiInstance(originalRequest);
    //       }

    //       return Promise.reject(error);
    //     } catch (error) {
    //       return Promise.reject(error);
    //     }
    //   }
    // );

    return httpClient;
  },

  getAccessToken() {
    const cookie = useCookie(`auth.${ACCESS_TOKEN}`);

    return cookie.value;
  },

  getRefreshToken() {
    const cookie = useCookie(`auth.${REFRESH_TOKEN}`);

    return cookie.value;
  },
};
