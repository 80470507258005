import { filtering, sort } from '~/support/utils';

import ApiBase from './ApiBase';

export const DATASETS_ENDPOINT = 'datasets';
export const DATASETS_COUNT_ENDPOINT = 'count';
export const DELIVERIES_ENDPOINT = 'deliveries';
export const LATEST_DELIVERY = 'latest-delivery';
export const STATISTICS_ENDPOINT = 'statistics';
export default class HealthService extends ApiBase {
  async fetchDataset(id) {
    const { data } = await this.client.get(`/${DATASETS_ENDPOINT}/${id}`);

    return data;
  }

  async fetchDatasetV2(id) {
    const { data } = await this.client.get(`/${DATASETS_ENDPOINT}/${id}/v2`);

    return data;
  }

  async fetchDatasetDelivery(datasetId, deliveryId) {
    const { data } = await this.client.get(`/${DATASETS_ENDPOINT}/${datasetId}/${DELIVERIES_ENDPOINT}/${deliveryId}`);

    return data;
  }

  async fetchDatasetDeliveryV2(deliveryId) {
    const { data } = await this.client.get(`/${DELIVERIES_ENDPOINT}/${deliveryId}`);

    return data;
  }

  async fetchDatasetLatestDelivery(id) {
    const { data } = await this.client.get(`/${DATASETS_ENDPOINT}/${id}/${LATEST_DELIVERY}`);

    return data;
  }

  async fetchDatasets() {
    const { data } = await this.client.get(`/${DATASETS_ENDPOINT}`);

    return data;
  }

  async fetchDatasetsCount() {
    const { data } = await this.client.get(`/${DATASETS_ENDPOINT}/${DATASETS_COUNT_ENDPOINT}`);

    return data;
  }

  async fetchDatasetsV2({ filters, pageToken, query, sortSettings }) {
    const params = filters ? { filters: filtering.stringify(filters, { noQuotes: true }) } : {};
    const { data } = await this.client.get(`/${DATASETS_ENDPOINT}/v2`, {
      params: { ...params, pageToken, query, sort: sort.stringify(sortSettings) },
    });

    return data;
  }

  async fetchDeliveryTaskHistory(deliveryId) {
    const { data } = await this.client.get(`/${DELIVERIES_ENDPOINT}/${deliveryId}/tasks`);

    return data;
  }

  async fetchStatistics() {
    const { data } = await this.client.get(`/${STATISTICS_ENDPOINT}`);

    return data;
  }
}
