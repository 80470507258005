import { UsersService } from '~/support/services';

import { CLEAR_USERS, SET_USERS } from './mutation-types';

export default {
  async acceptAgreement(_, userId) {
    const service = new UsersService(useRuntimeConfig().public.usersBaseUrl);

    await service.acceptAgreement({ userId });
  },

  clearUsers({ commit }) {
    commit(CLEAR_USERS);
  },

  async createUser({ dispatch }, { email, name, roles }) {
    const service = new UsersService(useRuntimeConfig().public.usersBaseUrl);

    await service.createUser({ email, name, roles });
    await dispatch('fetchUsers');
  },

  async deleteUser(_, userId) {
    const service = new UsersService(useRuntimeConfig().public.usersBaseUrl);

    await service.deleteUser(userId);
  },

  async fetchApiKey(_) {
    const service = new UsersService(useRuntimeConfig().public.usersBaseUrl);

    return await service.fetchApiKey();
  },

  async fetchUsers({ commit }) {
    const service = new UsersService(useRuntimeConfig().public.usersBaseUrl);

    const { data } = await service.fetchUsers();

    commit(SET_USERS, data);

    return data;
  },

  async forgotPassword(_, email) {
    const service = new UsersService(useRuntimeConfig().public.authBaseUrl);

    return await service.forgotPassword(email);
  },

  async regenerateApiKey(_) {
    const service = new UsersService(useRuntimeConfig().public.usersBaseUrl);

    return await service.regenerateApiKey();
  },

  async registerUser(_, { password, token }) {
    const service = new UsersService(useRuntimeConfig().public.usersBaseUrl);

    return await service.registerUser({ password, token });
  },

  async resendInvite(_, userId) {
    const service = new UsersService(useRuntimeConfig().public.usersBaseUrl);

    await service.resendInvite(userId);
  },

  async resetPassword(_, { password, token }) {
    const service = new UsersService(useRuntimeConfig().public.authBaseUrl);

    return await service.resetPassword({ password, token });
  },

  async updateUser(_, { userId, payload }) {
    const service = new UsersService(useRuntimeConfig().public.usersBaseUrl);

    return await service.updateUser({ payload, userId });
  },
};
