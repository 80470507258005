<template>
  <div class="flex flex-col items-center pointer-events-none px-4 py-2 space-y-1">
    <img class="h-32 w-32" :src="useImage('no-search-results.svg')" :alt="$t('imageAlt')" />
    <div class="font-semibold text-gray-900 text-lg">{{ $t('searchEmptyState.title', { query }) }}</div>
    <div class="text-gray-600">{{ $t('searchEmptyState.helpText') }}</div>
  </div>
</template>

<script>
export default {
  name: 'SelectSearchEmptyState',

  props: {
    query: {
      type: String,
      default: '',
    },
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "imageAlt": "No search results",
    "searchEmptyState": {
      "helpText": "Try using a different keyword to find what you're looking for.",
      "title": "No results for \"{query}\""
    }
  }
}
</i18n>
