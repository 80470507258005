import { OPERATORS } from './filtering';
import { DATA_PRODUCT_EDITOR, DATA_PRODUCT_VIEWER, ORGANIZATION_ADMIN } from './roles';
import { ENTERPRISE, FREE, CRUX_FORGE_ANNUAL, SUBSCRIPTION_PLAN_TITLES, PRO, SUPPLIER, TRIAL } from './subscriptions';

export const ORG_ACTIVE_STATUS = 'ACTIVE';
export const ORG_CANCELLED_STATUS = 'CANCELLED';
export const ORG_INACTIVE_STATUS = 'INACTIVE';

export const ORG_CONSUMER = 'consumer';
export const ORG_SUPPLIER = 'supplier';

export const ORG_TYPES = {
  consumer: ORG_CONSUMER,
  supplier: ORG_SUPPLIER,
};

export const ORG_ID_PREFIX = 'Or';
export const ORG_DATA_PRODUCT_LOGO_URL_SUFFIX = '/assets/logo?idType=id';

export const CREATE_ORG_NAME_ROUTE_NAME = 'orgs-create-type-name';
export const CREATE_ORG_DETAILS_ROUTE_NAME = 'orgs-create-type-details';
export const CREATE_ORG_PLANS_ROUTE_NAME = 'orgs-create-type-plans';
export const CREATE_ORG_USERS_ROUTE_NAME = 'orgs-create-id-users';

export const ORG_DATA_PRODUCT_ROUTES = {
  settings: 'orgs-id-data-products-dataProductId-settings',
  subscription: 'orgs-id-data-products-dataProductId-subscription',
};

export const ORG_CREATE_CONSUMER_STEPS = [
  {
    label: 'Describe Your Org',
    to: {
      name: CREATE_ORG_NAME_ROUTE_NAME,
      params: { type: ORG_TYPES.consumer },
    },
  },
  {
    label: 'Add Org Details',
    to: {
      name: CREATE_ORG_DETAILS_ROUTE_NAME,
      params: { type: ORG_TYPES.consumer },
    },
  },
  {
    label: 'Choose Plans',
    to: {
      name: CREATE_ORG_PLANS_ROUTE_NAME,
      params: { type: ORG_TYPES.consumer },
    },
  },
  {
    label: 'Add Users',
    to: {
      name: CREATE_ORG_USERS_ROUTE_NAME,
    },
  },
];

export const ORG_CREATE_SUPPLIER_STEPS = [
  {
    label: 'Describe Your Org',
    to: {
      name: CREATE_ORG_NAME_ROUTE_NAME,
      params: { type: ORG_TYPES.supplier },
    },
  },
  {
    label: 'Add Org Details',
    to: {
      name: CREATE_ORG_DETAILS_ROUTE_NAME,
      params: { type: ORG_TYPES.supplier },
    },
  },
  {
    label: 'Add Users',
    to: {
      name: CREATE_ORG_USERS_ROUTE_NAME,
    },
  },
];

export const ORG_FILTER_SUBSCRIPTION_OPTIONS = [
  { label: SUBSCRIPTION_PLAN_TITLES[FREE], value: FREE },
  { label: SUBSCRIPTION_PLAN_TITLES[TRIAL], value: TRIAL },
  { label: SUBSCRIPTION_PLAN_TITLES[PRO], value: PRO },
  { label: SUBSCRIPTION_PLAN_TITLES[CRUX_FORGE_ANNUAL], value: CRUX_FORGE_ANNUAL },
  { label: SUBSCRIPTION_PLAN_TITLES[ENTERPRISE], value: ENTERPRISE },
  { label: SUBSCRIPTION_PLAN_TITLES[SUPPLIER], value: SUPPLIER },
];

export const ORG_LEGAL_MULTISELECT_OPTIONS = [
  { label: 'SNA', value: 'SNA' },
  { label: 'MSA', value: 'MSA' },
  { label: 'Supplier Legal Paperwork', value: 'Supplier Legal Paperwork' },
  { label: 'Other', value: 'Other' },
];

export const ORG_STATUS_DISPLAY_MAP = {
  [ORG_ACTIVE_STATUS]: 'Active',
  [ORG_INACTIVE_STATUS]: 'Deactivated',
};

export const ORG_STATUS_ICON_COLOR_MAP = {
  [ORG_ACTIVE_STATUS]: 'text-green-500',
  [ORG_INACTIVE_STATUS]: 'text-gray-400',
};

export const ORG_STATUS_ICON_MAP = {
  [ORG_ACTIVE_STATUS]: 'checkbox-circle-fill',
  [ORG_INACTIVE_STATUS]: 'indeterminate-circle-fill',
};

export const ORG_SUPPLIER_TYPE_MULTISELECT_OPTIONS = [
  { label: 'Tier 1', value: 'Tier 1' },
  { label: 'Tier 2', value: 'Tier 2' },
  { label: 'Prospect', value: 'Prospect' },
  { label: 'Client Proprietary', value: 'Client Proprietary' },
  { label: 'Public', value: 'Public' },
];

export const ORGS_TABLE_FILTERS = {
  createdAt: {
    operator: '',
    value: '',
  },

  status: {
    operator: OPERATORS.in,
    value: [],
  },

  type: {
    operator: OPERATORS.in,
    value: [],
  },
};

export const EMPTY_USER = {
  email: '',
  name: '',
  role: DATA_PRODUCT_VIEWER,
};

export const INITIAL_USERS = [
  {
    email: '',
    name: '',
    role: ORGANIZATION_ADMIN,
  },
  {
    email: '',
    name: '',
    role: DATA_PRODUCT_EDITOR,
  },
  {
    email: '',
    name: '',
    role: DATA_PRODUCT_VIEWER,
  },
];

export const EMPTY_ORG = {
  attr: {
    address: '',
    city: '',
    country: '',
    state: '',
    zip: '',
  },
  code: '',
  description: '',
  name: '',
  requestedPlans: {},
  website: '',
};

export const EMPTY_ORG_ADDRESS = {
  attr: {
    address: '',
    city: '',
    country: '',
    state: '',
    zip: '',
  },
  website: '',
};

export const COVER = 'coverImage';
export const LOGO = 'logo';

export const ORG_IMAGE_TYPES = {
  cover: COVER,
  logo: LOGO,
};

export const ORG_ROUTE_NAME = 'orgs-id';
export const ORG_ID_ENTITLEMENTS_SELECT_ROUTE = 'orgs-id-index-entitlements';
