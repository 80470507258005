<template>
  <div :class="$slots.label ? 'pb-2' : ''">
    <div v-if="$slots.label" class="font-semibold pb-1 pt-4 px-4 text-sm">
      <slot name="label" />
    </div>
    <ul>
      <slot />
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CxListGroupSection',
};
</script>
