import formatting from '~/support/utils/formatting';
import str from '~/support/utils/str';

export default defineNuxtPlugin(() => {
  return {
    provide: {
      formatting,
      str,
    },
  };
});
