import { cloneDeep } from 'lodash-es';

import { RESET_ORGANIZATION, SET_ORGANIZATION } from './mutation-types';

export default {
  [RESET_ORGANIZATION](state) {
    state.org = null;
  },

  [SET_ORGANIZATION](state, dataProduct) {
    state.org = cloneDeep(dataProduct);
  },
};
