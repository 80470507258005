import DocumentService from '~/support/services/DocumentService';

export default {
  createDocument(_, { body, params }) {
    const service = new DocumentService(useRuntimeConfig().public.documentsBaseUrl);

    return service.create(body, params);
  },

  deleteDocument(_, documentId) {
    const service = new DocumentService(useRuntimeConfig().public.documentsBaseUrl);

    return service.deleteDocument(documentId);
  },

  fetchContentSignedUrl(_, documentId) {
    const service = new DocumentService(useRuntimeConfig().public.documentsBaseUrl);

    return service.fetchContentSignedUrl(documentId);
  },

  fetchDocument(_, documentId) {
    const service = new DocumentService(useRuntimeConfig().public.documentsBaseUrl);

    return service.fetchOne(documentId);
  },

  fetchDocuments(_, params) {
    const service = new DocumentService(useRuntimeConfig().public.documentsBaseUrl);

    return service.fetchAll(params);
  },

  updateDocument(_, { documentId, body }) {
    const service = new DocumentService(useRuntimeConfig().public.documentsBaseUrl);

    return service.update(documentId, body);
  },
};
