import {
  DISABLED,
  EMAIL_FORMAT_OPTIONS,
  EMAIL_FREQUENCY_OPTIONS,
  ENABLED,
  NOTIFICATION_CHANNEL_TYPES,
  RULE_ERROR_SEVERITY,
  RULE_SUCCESS_SEVERITY,
  RULE_WARNING_SEVERITY,
} from '~/support/constants';

import { DATA_PRODUCT } from './data-products';
import { CATALOG_DATASET } from './datasets';

export const DEFAULT_EMAIL_PREFERENCE = {
  coverage: EMAIL_FREQUENCY_OPTIONS.allDataProducts,
  format: EMAIL_FORMAT_OPTIONS.html,
  hourOfDay: 0,
  timezone: 'GMT',
};
export const EMAIL_NOTIFICATION_CHANNEL = {
  email: 'ifUrNotFirstUrLast@talladega.com',
  enabled: true,
  id: 'NPPrCECFJppLm6rMrmU1xBF0SUyVLTqO0n',
  method: NOTIFICATION_CHANNEL_TYPES.email,
  modifiedAt: '2022-04-25T12:56:58Z',
  name: 'Ricky Bobby',
};

export const PUB_SUB_NOTIFICATION_CHANNEL = {
  enabled: true,
  id: 'NPSxTlZ60PZgDHKuyMBTEqMa0rC8vng1O6',
  method: NOTIFICATION_CHANNEL_TYPES.pubsub,
  modifiedAt: '2022-04-25T12:56:58Z',
  name: 'pub sub channel',
  topic: 'projects/crux-dev-megaphone-outbound/topics/notification-delivery-updates2',
};

export const SQS_NOTIFICATION_CHANNEL = {
  enabled: true,
  id: 'NPPLo19LuCLojkT03sxgaeQxX7oQh5phjo',
  method: NOTIFICATION_CHANNEL_TYPES.sqs,
  modifiedAt: '2022-04-25T12:56:58Z',
  name: 'sqs channel',
  roleArn: '8675309',
  url: 'https://www.your-example-site.com/SQS',
};

export const WEBHOOK_NOTIFICATION_CHANNEL = {
  enabled: true,
  id: 'NPh7V2MNhaE4AYPrqYPzNW8u1DGQJxqpCZ',
  method: NOTIFICATION_CHANNEL_TYPES.webhook,
  modifiedAt: '2022-04-25T12:56:58Z',
  name: 'webhook channel',
  secretParameter: '8675309',
  url: 'https://www.your-example-site.com/webhook',
};

export const NOTIFICATION_CHANNELS = {
  emails: [
    {
      email: 'ifUrNotFirstUrLast@talladega.com',
      enabled: true,
      id: 'NcgCSigs17x12u0fiKBR4bd8rcOtlzMn',
      modifiedAt: '2022-04-25T12:56:58Z',
      name: 'Ricky Bobby',
    },
    {
      email: 'MikeHoncho@talladega.com',
      enabled: false,
      id: 'Nc6n6JZ3btZVDgeTYUBZZhVaK7j03tFY',
      modifiedAt: '2022-04-25T12:56:58Z',
      name: 'Cal Naughton Jr.',
    },
    {
      email: 'iLoveCrepes@talladega.com',
      enabled: true,
      id: 'NcuvlIwtPRGsxaFDqtuHH2MnuL6d3CTI',
      modifiedAt: '2022-04-25T12:56:58Z',
      name: 'Jean Girard',
    },
    {
      email: 'walkerBobby@talladega.com',
      enabled: true,
      id: 'NchuSCH0NIN2qowgufOhMbLjfl5dkHHx',
      modifiedAt: '2022-04-25T12:56:58Z',
      name: 'Walker Bobby',
    },
    {
      email: 'texasRanger@talladega.com',
      enabled: false,
      id: 'NckUovEJoVrpAGLUYIzo8Z69yFD2DDZq',
      modifiedAt: '2022-04-25T12:56:58Z',
      name: 'Texas Ranger Bobby',
    },
  ],
  pubSub: [
    {
      enabled: true,
      id: 'NcR7RxM9WiLzbnWfE8xFOV9sId0U5ImN',
      modifiedAt: '2022-04-25T12:56:58Z',
      name: 'PubSub 1',
      topic: 'PubSub 1 topic',
    },
    {
      enabled: true,
      id: 'NcRiu7p5C9HD0ZTeAIEyCYuaq6X59Kzh',
      modifiedAt: '2022-05-23T12:56:58Z',
      name: 'PubSub 2',
      topic: 'PubSub 2 topic',
    },
    {
      enabled: true,
      id: 'NcQ7hUhTic8rqeuT3GxxVjeD1wyQlHT4',
      modifiedAt: '2022-01-20T12:56:58Z',
      name: 'PubSub 3',
      topic: 'PubSub 3 topic',
    },
    {
      enabled: false,
      id: 'Nc6SFbsLnzXUBMwd6M7nVbqFPtjjWsuf',
      modifiedAt: '2022-11-13T12:56:58Z',
      name: 'PubSub 4',
      topic: 'PubSub 4 topic',
    },
    {
      enabled: false,
      id: 'NcMaqOdsbMlyTJueypXiXlfS42AbzZM6',
      modifiedAt: '2022-04-06T12:56:58Z',
      name: 'PubSub 5',
      topic: 'PubSub 5 topic',
    },
  ],
  sqs: [
    {
      enabled: true,
      id: 'Nc0cS2dC5GdcXvnWrJwyyqh7qfbo3lvy',
      modifiedAt: '2022-04-25T12:56:58Z',
      name: 'Crux App Worker Queue',
      role: 'arn:aws:iam::PRODUCTION-ACCOUNT-ID',
      url: 'https://sqs.us-east-2.amazonaws.com/',
    },
    {
      enabled: true,
      id: 'NcAgOHeDqI25Y5NM4oQIYBX3nqLmMPQE',
      modifiedAt: '2022-08-20T12:56:58Z',
      name: 'Crux Quality Events Subscription',
      role: 'arn:aws:iam::PRODUCTION-ACCOUNT-ID',
      url: 'projects/crux-data-platform/topics/',
    },
    {
      enabled: true,
      id: 'Nc8Z9JWwZdHR2Bb5S0RKXSVm5mJ06dhz',
      modifiedAt: '2022-06-15T12:56:58Z',
      name: 'Ric Flair Worker Queue',
      role: 'woo:woo:iam::ROLEX-WEARIN',
      url: 'https://sqs.us-west-2.amazonaws.com/',
    },
    {
      enabled: false,
      id: 'NcVAGHtNKP3g3HTPqVi9WfTSh37cUAeL',
      modifiedAt: '2022-01-18T12:56:58Z',
      name: 'More Events Subscription',
      role: 'arn:aws:iam::ANOTHER-ACCOUNT-ID',
      url: 'projects/crux-data-platform/topics/',
    },
    {
      enabled: true,
      id: 'Nc90VciVTk1FMAvdbQ4TxpZh1ciPLXt3',
      modifiedAt: '2022-12-25T12:56:58Z',
      name: 'Working Hard Queue',
      role: 'arn:aws:iam::WORK-ACCOUNT-ID',
      url: 'https://sqs.us-east-2.amazonaws.com/',
    },
  ],
  webhooks: [
    {
      enabled: true,
      id: 'Nc4BMaflTvdbv6ps0Ghi5J2S2v1H6jWF',
      modifiedAt: '2022-04-25T12:56:58Z',
      name: 'Crux App Worker Queue 1',
      secretParameter: '8675309',
      url: 'https://www.your-example-site.com/webhooks1',
    },
    {
      enabled: true,
      id: 'NcF6aKRl9cuvird3z90nXCV8CwNIjJkh',
      modifiedAt: '2022-04-25T12:56:58Z',
      name: 'Crux App Worker Queue 2',
      secretParameter: '8675309',
      url: 'https://www.your-example-site.com/webhooks2',
    },
    {
      enabled: false,
      id: 'NcFLLrZji7K6T4fQsVtcrSRmJI6ad4AF',
      modifiedAt: '2022-04-25T12:56:58Z',
      name: 'Crux App Worker Queue 3',
      secretParameter: '8675309',
      url: 'https://www.your-example-site.com/webhooks3',
    },
    {
      enabled: true,
      id: 'NcSdM0q9cajINwtRZKQvzwOVZZ9HXK6I',
      modifiedAt: '2022-04-25T12:56:58Z',
      name: 'Crux App Worker Queue 4',
      secretParameter: '8675309',
      url: 'https://www.your-example-site.com/webhooks4',
    },
    {
      enabled: true,
      id: 'NcnwBSvrrGBjxtthnuFyTOemUA3XWQn4',
      modifiedAt: '2022-04-25T12:56:58Z',
      name: 'Crux App Worker Queue 5',
      secretParameter: '8675309',
      url: 'https://www.your-example-site.com/webhooks5',
    },
  ],
};

export const NOTIFICATION_RULE_V2 = {
  channels: [
    EMAIL_NOTIFICATION_CHANNEL,
    EMAIL_NOTIFICATION_CHANNEL,
    PUB_SUB_NOTIFICATION_CHANNEL,
    SQS_NOTIFICATION_CHANNEL,
    WEBHOOK_NOTIFICATION_CHANNEL,
    WEBHOOK_NOTIFICATION_CHANNEL,
  ],
  createdAt: '2021-11-16T20:23:36.152+0000',
  dataProduct: DATA_PRODUCT,
  dataset: CATALOG_DATASET,
  datasetId: 'datasetId1',
  enabled: true,
  eventTypes: {
    dataIssues: {
      enabled: true,
      severity: RULE_WARNING_SEVERITY,
    },
    datasetDelayed: {
      enabled: false,
      severity: RULE_WARNING_SEVERITY,
    },
    datasetFailed: {
      enabled: true,
      severity: RULE_ERROR_SEVERITY,
    },
    datasetSuccess: {
      enabled: true,
      severity: RULE_SUCCESS_SEVERITY,
    },
  },

  id: 'NS8RNj69BQVrbljn6jHnRrjII3sOy8uK39',
  name: 'This rule sends all events',
};

export const NORMALIZED_NOTIFICATION_RULES = [
  {
    ...NOTIFICATION_RULE_V2,
    channelMethods: [
      NOTIFICATION_CHANNEL_TYPES.email,
      NOTIFICATION_CHANNEL_TYPES.email,
      NOTIFICATION_CHANNEL_TYPES.pubsub,
      NOTIFICATION_CHANNEL_TYPES.sqs,
      NOTIFICATION_CHANNEL_TYPES.webhook,
      NOTIFICATION_CHANNEL_TYPES.webhook,
    ],
    enabledFilter: ENABLED,
    eventTypeKeys: ['dataIssues', 'datasetFailed', 'datasetSuccess'],
  },
];

export const DISABLED_NORMALIZED_NOTIFICATION_RULES = [
  {
    ...NOTIFICATION_RULE_V2,
    channelMethods: [
      NOTIFICATION_CHANNEL_TYPES.email,
      NOTIFICATION_CHANNEL_TYPES.email,
      NOTIFICATION_CHANNEL_TYPES.pubsub,
      NOTIFICATION_CHANNEL_TYPES.sqs,
      NOTIFICATION_CHANNEL_TYPES.webhook,
      NOTIFICATION_CHANNEL_TYPES.webhook,
    ],
    enabled: false,
    enabledFilter: DISABLED,
    eventTypeKeys: ['dataIssues', 'datasetFailed', 'datasetSuccess'],
  },
];

export const CREATE_NOTIFICATION_RULE_V2_PAYLOAD = {
  channels: [EMAIL_NOTIFICATION_CHANNEL.id, SQS_NOTIFICATION_CHANNEL.id],
  datasetId: 'datasetId1',
  enabled: true,
  eventTypes: {
    dataIssues: {
      enabled: true,
      severity: RULE_WARNING_SEVERITY,
    },
    datasetDelayed: {
      enabled: false,
      severity: RULE_WARNING_SEVERITY,
    },
    datasetFailed: {
      enabled: true,
      severity: RULE_ERROR_SEVERITY,
    },
    datasetSuccess: {
      enabled: true,
      severity: RULE_SUCCESS_SEVERITY,
    },
  },
  name: 'Creating a rule',
};

export const NOTIFICATION_RULES_V2 = [
  {
    channels: [
      EMAIL_NOTIFICATION_CHANNEL,
      EMAIL_NOTIFICATION_CHANNEL,
      PUB_SUB_NOTIFICATION_CHANNEL,
      SQS_NOTIFICATION_CHANNEL,
      WEBHOOK_NOTIFICATION_CHANNEL,
      WEBHOOK_NOTIFICATION_CHANNEL,
    ],
    createdAt: '2021-11-16T20:23:36.152+0000',
    datasetId: 'datasetId1',
    enabled: true,
    eventTypes: {
      dataIssues: {
        enabled: true,
        severity: RULE_WARNING_SEVERITY,
      },
      datasetDelayed: {
        enabled: true,
        severity: RULE_WARNING_SEVERITY,
      },
      datasetFailed: {
        enabled: true,
        severity: RULE_ERROR_SEVERITY,
      },
      datasetSuccess: {
        enabled: true,
        severity: RULE_SUCCESS_SEVERITY,
      },
    },
    id: 'NS8RNj69BQVrbljn6jHnRrjII3sOy8uK39',
    name: 'This rule sends all events',
  },
  {
    channels: [PUB_SUB_NOTIFICATION_CHANNEL, SQS_NOTIFICATION_CHANNEL],
    createdAt: '2021-11-17T20:23:36.152+0000',
    datasetId: 'datasetId2',
    enabled: false,
    eventTypes: {
      dataIssues: {
        enabled: true,
        severity: RULE_WARNING_SEVERITY,
      },
      datasetDelayed: {
        enabled: true,
        severity: RULE_WARNING_SEVERITY,
      },
      datasetFailed: {
        enabled: true,
        severity: RULE_ERROR_SEVERITY,
      },
      datasetSuccess: {
        enabled: false,
        severity: RULE_SUCCESS_SEVERITY,
      },
    },
    id: 'NS8RNj69BQVrbljn6jHnRrjII3sOdfs980d',
    name: 'This is a very long rule name that should be truncated',
  },
];

export const MOCK_NOTIFICATIONS = [
  {
    category: 'Data Quality',
    datasetId: '1234',
    details: 'The pipeline has begun ingestion.',
    id: '1',
    isRead: false,
    severity: 'info',
    timestamp: '2022-12-11T21:50:56+0000',
    title: 'Barra All United States Equity Model',
  },
  {
    category: 'Data Quality',
    datasetId: '1234',
    details: 'The pipeline has begun ingestion.',
    id: '2',
    isRead: false,
    severity: 'info',
    timestamp: '2022-12-12T21:50:56+0000',
    title: 'Barra All Europe Equity Model',
  },
  {
    category: 'Pipeline Updated',
    datasetId: '1234',
    details: 'The pipeline has been updated successfully!',
    id: '3',
    isRead: false,
    severity: RULE_SUCCESS_SEVERITY,
    timestamp: '2022-12-13T21:50:56+0000',
    title: 'Real Estate Model',
  },
  {
    category: 'Pipeline Delayed',
    datasetId: '1234',
    details: 'Supplier delayed making data available to Crux.',
    id: '4',
    isRead: false,
    severity: RULE_WARNING_SEVERITY,
    timestamp: '2022-12-14T21:50:56+0000',
    title: 'Fintech December Model',
  },
  {
    category: 'Pipeline Failed',
    datasetId: '1234',
    details: 'Failed to connect to source due to invalid credentials.',
    id: '5',
    isRead: false,
    severity: RULE_ERROR_SEVERITY,
    timestamp: '2022-12-15T21:50:56+0000',
    title: 'Barra All China Equity Model 2022',
  },
];

export const MOCK_NOTIFICATIONS_LONG = [
  {
    category: 'Data Quality',
    datasetId: '1234',
    details: 'The pipeline has begun ingestion.',
    id: '1',
    isRead: false,
    severity: 'info',
    timestamp: '2022-12-06T21:50:56+0000',
    title: 'Barra All United States Equity Model',
  },
  {
    category: 'Data Quality',
    datasetId: '1234',
    details: 'The pipeline has begun ingestion.',
    id: '2',
    isRead: false,
    severity: 'info',
    timestamp: '2022-12-07T21:50:56+0000',
    title: 'Barra All Europe Equity Model',
  },
  {
    category: 'Pipeline Updated',
    datasetId: '1234',
    details: 'The pipeline has been updated successfully!',
    id: '3',
    isRead: false,
    severity: 'success',
    timestamp: '2022-12-08T21:50:56+0000',
    title: 'Real Estate Model',
  },
  {
    category: 'Pipeline Delayed',
    datasetId: '1234',
    details: 'Supplier delayed making data available to Crux.',
    id: '4',
    isRead: false,
    severity: 'success',
    timestamp: '2022-12-09T21:50:56+0000',
    title: 'Fintech December Model',
  },
  {
    category: 'Pipeline Failed',
    datasetId: '1234',
    details: 'Failed to connect to source due to invalid credentials.',
    id: '5',
    isRead: false,
    severity: 'success',
    timestamp: '2022-12-10T21:50:56+0000',
    title: 'Barra All China Equity Model 2022',
  },
  {
    category: 'Data Quality',
    datasetId: '1234',
    details: 'The pipeline has begun ingestion.',
    id: '6',
    isRead: false,
    severity: 'info',
    timestamp: '2022-12-11T21:50:56+0000',
    title: 'Barra All United States Equity Model',
  },
  {
    category: 'Data Quality',
    datasetId: '1234',
    details: 'The pipeline has begun ingestion.',
    id: '7',
    isRead: false,
    severity: 'info',
    timestamp: '2022-12-12T21:50:56+0000',
    title: 'Barra All Europe Equity Model',
  },
  {
    category: 'Pipeline Updated',
    datasetId: '1234',
    details: 'The pipeline has been updated successfully!',
    id: '8',
    isRead: false,
    severity: 'success',
    timestamp: '2022-12-13T21:50:56+0000',
    title: 'Real Estate Model',
  },
  {
    category: 'Pipeline Delayed',
    datasetId: '1234',
    details: 'Supplier delayed making data available to Crux.',
    id: '9',
    isRead: false,
    severity: 'success',
    timestamp: '2022-12-14T21:50:56+0000',
    title: 'Fintech December Model',
  },
  {
    category: 'Pipeline Failed',
    datasetId: '1234',
    details: 'Failed to connect to source due to invalid credentials.',
    id: '10',
    isRead: false,
    severity: 'success',
    timestamp: '2022-12-15T21:50:56+0000',
    title: 'Barra All China Equity Model 2022',
  },
];
