import { InferredDatasetService } from '~/support/services';

export default {
  async applyOverrides(_, { inferredDatasetId, payload }) {
    const service = new InferredDatasetService(useRuntimeConfig().public.inferredDatasetsBaseUrl);

    return await service.applyOverrides(inferredDatasetId, payload);
  },

  async createWorkflow(_, inferredDatasetId) {
    const service = new InferredDatasetService(useRuntimeConfig().public.inferredDatasetsBaseUrl);
    const data = await service.createWorkflow(inferredDatasetId);

    return data;
  },

  async fetchOne(_, inferredDatasetId) {
    const service = new InferredDatasetService(useRuntimeConfig().public.inferredDatasetsBaseUrl);

    return await service.fetchOne(inferredDatasetId);
  },

  async fetchStatus(_, inferredDatasetId) {
    const service = new InferredDatasetService(useRuntimeConfig().public.inferredDatasetsBaseUrl);

    return await service.fetchStatus(inferredDatasetId);
  },

  async fetchWorkflowConfig(_, inferredDatasetId) {
    const service = new InferredDatasetService(useRuntimeConfig().public.inferredDatasetsBaseUrl);

    return await service.fetchWorkflowConfig(inferredDatasetId);
  },

  async fetchWorkflows(_, inferredDatasetId) {
    const service = new InferredDatasetService(useRuntimeConfig().public.inferredDatasetsBaseUrl);

    return await service.fetchWorkflows(inferredDatasetId);
  },

  async startOverride(_, inferredDatasetId) {
    const service = new InferredDatasetService(useRuntimeConfig().public.inferredDatasetsBaseUrl);

    return await service.startOverride(inferredDatasetId);
  },

  async updateName(_, { inferredDatasetId, name }) {
    const service = new InferredDatasetService(useRuntimeConfig().public.inferredDatasetsBaseUrl);
    const data = await service.updateName(inferredDatasetId, name);

    return data;
  },
};
