import { SUBSCRIPTION_PLANS } from '~/support/constants';

export const USER_AGREEMENTS = [
  {
    createdAt: '2022-06-27T21:26:07.883+00:00',
    idAddress: '0.0.0.banana.0.12',
    location: 'Nature, TN',
    userId: 'ric-flair-id',
  },
  {
    createdAt: '2022-07-27T21:26:07.883+00:00',
    idAddress: '0.0.0.banana.0.12',
    location: 'Airplane, Hell',
    userId: 'ric-flair-id',
  },
];

export const USER_LIST = [
  {
    createdAt: '2022-06-27T21:26:07.883+00:00',
    email: 'ric.flair@cruxinformatics.com',
    firstName: 'Ric',
    lastName: 'Flair',
    name: 'Ric Flair',
    orgId: 'ric-org',
    orgName: 'Crux Testing LLC',
    roles: ['ciam-role-OrganizationAdmin'],
    status: 'unverified',
    subscription: {
      plan: SUBSCRIPTION_PLANS.enterprise,
    },
    userId: 'ric-flair',
  },
  {
    createdAt: '2022-06-27T21:26:07.883+00:00',
    email: 'ric.not@cruxinformatics.com',
    firstName: 'Ric',
    lastName: 'Not, Other',
    name: 'Ric Not, Other',
    orgId: 'ric-org',
    orgName: 'Crux Testing LLC',
    roles: ['ciam-role-OrganizationAdmin'],
    status: 'unverified',
    subscription: {
      plan: SUBSCRIPTION_PLANS.enterprise,
    },
    userId: 'ric-not',
  },
];

export const TEST_USER = USER_LIST[0];
