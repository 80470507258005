import { cloneDeep } from 'lodash-es';

import { SET_CONNECTIONS, SET_CONNECTION_CONFIG, SET_CONNECTIONS_CACHE } from './mutation-types';

export default {
  [SET_CONNECTIONS](state, connections) {
    state.connections = cloneDeep(connections);
  },
  [SET_CONNECTIONS_CACHE](state, connectionsCache) {
    state.connectionsCache = cloneDeep(connectionsCache);
  },
  [SET_CONNECTION_CONFIG](state, connectionConfig) {
    state.connectionConfig = cloneDeep(connectionConfig);
  },
};
