import { default as agreementY82GsiR81fMeta } from "/app/pages/agreement.vue?macro=true";
import { default as _91frameId_93z2MZCEd859Meta } from "/app/pages/catalog/data-products/[dataProductId]/datasets/[datasetId]/frames/[frameId].vue?macro=true";
import { default as _91datasetId_93xbo0f2qaA0Meta } from "/app/pages/catalog/data-products/[dataProductId]/datasets/[datasetId].vue?macro=true";
import { default as _91dataProductId_93THKp6A0lFNMeta } from "/app/pages/catalog/data-products/[dataProductId].vue?macro=true";
import { default as indexETrUrt5Zz3Meta } from "/app/pages/catalog/data-products/index.vue?macro=true";
import { default as data_45productsOG14AKOwg7Meta } from "/app/pages/catalog/data-products.vue?macro=true";
import { default as indexdGpzbD6lZrMeta } from "/app/pages/catalog/families/index.vue?macro=true";
import { default as familiesDUKwlFbHc8Meta } from "/app/pages/catalog/families.vue?macro=true";
import { default as indexvJKqjSbZLzMeta } from "/app/pages/catalog/index.vue?macro=true";
import { default as indexpGvfXeF4yPMeta } from "/app/pages/catalog/suppliers/[supplierId]/index.vue?macro=true";
import { default as index8HgT1nGNymMeta } from "/app/pages/catalog/suppliers/index.vue?macro=true";
import { default as suppliersZfYqbO0v0oMeta } from "/app/pages/catalog/suppliers.vue?macro=true";
import { default as catalogmbyzFSoe6qMeta } from "/app/pages/catalog.vue?macro=true";
import { default as index7lAEebvypIMeta } from "/app/pages/connection-settings/index.vue?macro=true";
import { default as connection_45settingsFNhlhsKhgOMeta } from "/app/pages/connection-settings.vue?macro=true";
import { default as indexhg2nWJrbRRMeta } from "/app/pages/dashboard/index.vue?macro=true";
import { default as ExploreiH59Pex9OkMeta } from "/app/pages/dashboard/subcomponents/Explore.vue?macro=true";
import { default as ExploreCard8fgeEVgGnKMeta } from "/app/pages/dashboard/subcomponents/ExploreCard.vue?macro=true";
import { default as GettingStarted5vIjHQWLmmMeta } from "/app/pages/dashboard/subcomponents/GettingStarted.vue?macro=true";
import { default as GettingStartedCard80CAvnsVG5Meta } from "/app/pages/dashboard/subcomponents/GettingStartedCard.vue?macro=true";
import { default as ShortcutCardRESEVA0NWOMeta } from "/app/pages/dashboard/subcomponents/ShortcutCard.vue?macro=true";
import { default as ShortcutsPTQoEcfB36Meta } from "/app/pages/dashboard/subcomponents/Shortcuts.vue?macro=true";
import { default as dashboardJu37u7k0toMeta } from "/app/pages/dashboard.vue?macro=true";
import { default as documentationSB3sVzqu6eMeta } from "/app/pages/data-products/[id]/details/datasets/[datasetId]/documentation.vue?macro=true";
import { default as framesgtkNfOIe4wMeta } from "/app/pages/data-products/[id]/details/datasets/[datasetId]/frames.vue?macro=true";
import { default as historynkdj2mYaiFMeta } from "/app/pages/data-products/[id]/details/datasets/[datasetId]/history.vue?macro=true";
import { default as indexyfZqSZhOKAMeta } from "/app/pages/data-products/[id]/details/datasets/[datasetId]/index.vue?macro=true";
import { default as notification_45ruleLlJtYUpU0dMeta } from "/app/pages/data-products/[id]/details/datasets/[datasetId]/notification-rule.vue?macro=true";
import { default as resultsBTXtg3u4fYMeta } from "/app/pages/data-products/[id]/details/datasets/[datasetId]/results.vue?macro=true";
import { default as scheduleYjp5eHxNpwMeta } from "/app/pages/data-products/[id]/details/datasets/[datasetId]/schedule.vue?macro=true";
import { default as index9zJZEB0jG1Meta } from "/app/pages/data-products/[id]/details/datasets/index.vue?macro=true";
import { default as historycqzmq5kJx8Meta } from "/app/pages/data-products/[id]/details/history.vue?macro=true";
import { default as index7yTHezPipUMeta } from "/app/pages/data-products/[id]/details/index.vue?macro=true";
import { default as index0QROvc3LbHMeta } from "/app/pages/data-products/[id]/details/results/index.vue?macro=true";
import { default as summary6veJsaEMofMeta } from "/app/pages/data-products/[id]/details/summary.vue?macro=true";
import { default as detailsySbTGOyxcIMeta } from "/app/pages/data-products/[id]/details.vue?macro=true";
import { default as datasets0iqI9MzvUsMeta } from "/app/pages/data-products/[id]/edit/datasets.vue?macro=true";
import { default as destinationssQR0qXZHGEMeta } from "/app/pages/data-products/[id]/edit/destinations.vue?macro=true";
import { default as indexeJBeo05DY6Meta } from "/app/pages/data-products/[id]/edit/index.vue?macro=true";
import { default as schemasU320h7uYpRMeta } from "/app/pages/data-products/[id]/edit/schemas.vue?macro=true";
import { default as editBLrexcbMJYMeta } from "/app/pages/data-products/[id]/edit.vue?macro=true";
import { default as indexK1dzq5JKozMeta } from "/app/pages/data-products/[id]/index.vue?macro=true";
import { default as indexUkAlVXacdSMeta } from "/app/pages/data-products/[id]/manage/index.vue?macro=true";
import { default as settingsrt84SCsY4VMeta } from "/app/pages/data-products/[id]/manage/settings.vue?macro=true";
import { default as subscriptionYJ1XcBqeeLMeta } from "/app/pages/data-products/[id]/manage/subscription.vue?macro=true";
import { default as managezhSM21OJHzMeta } from "/app/pages/data-products/[id]/manage.vue?macro=true";
import { default as connectionENGaQnamwAMeta } from "/app/pages/data-products/[id]/scratch/connection.vue?macro=true";
import { default as index4wozJ0ddbjMeta } from "/app/pages/data-products/[id]/scratch/data-modeling/datasets/[datasetId]/frames/[frameId]/index.vue?macro=true";
import { default as indexJ6UFKd4LWQMeta } from "/app/pages/data-products/[id]/scratch/data-modeling/datasets/[datasetId]/frames/index.vue?macro=true";
import { default as indexjpbDtdZRmrMeta } from "/app/pages/data-products/[id]/scratch/data-modeling/datasets/[datasetId]/index.vue?macro=true";
import { default as indexSwwjKcPue7Meta } from "/app/pages/data-products/[id]/scratch/data-modeling/datasets/index.vue?macro=true";
import { default as indexbqrCVeJbUOMeta } from "/app/pages/data-products/[id]/scratch/data-modeling/index.vue?macro=true";
import { default as data_45modelingP8fX3XtLSdMeta } from "/app/pages/data-products/[id]/scratch/data-modeling.vue?macro=true";
import { default as destinationskq4U6KTEU8Meta } from "/app/pages/data-products/[id]/scratch/destinations.vue?macro=true";
import { default as finalizeSC5HgtJuMkMeta } from "/app/pages/data-products/[id]/scratch/finalize.vue?macro=true";
import { default as indexKvenRT6i11Meta } from "/app/pages/data-products/[id]/scratch/notification-rules/datasets/[datasetId]/index.vue?macro=true";
import { default as index3Hs9ls4GF3Meta } from "/app/pages/data-products/[id]/scratch/notification-rules/datasets/index.vue?macro=true";
import { default as notification_45rulesFVIxsCkHrZMeta } from "/app/pages/data-products/[id]/scratch/notification-rules.vue?macro=true";
import { default as profileJLUj5GtjbPMeta } from "/app/pages/data-products/[id]/scratch/profile.vue?macro=true";
import { default as scheduleN1kXVPCprAMeta } from "/app/pages/data-products/[id]/scratch/schedule.vue?macro=true";
import { default as scratch06k8wkTd4tMeta } from "/app/pages/data-products/[id]/scratch.vue?macro=true";
import { default as _91id_933HxNXI5qt6Meta } from "/app/pages/data-products/[id].vue?macro=true";
import { default as entitlementsWlBiIrjWVpMeta } from "/app/pages/data-products/index/entitlements.vue?macro=true";
import { default as indexeH0okA8DSJMeta } from "/app/pages/data-products/index.vue?macro=true";
import { default as data_45productswATmH8QrEuMeta } from "/app/pages/data-products.vue?macro=true";
import { default as indexz1CkRA7HxDMeta } from "/app/pages/health-dashboard/index/datasets/[datasetId]/deliveries/[deliveryId]/index.vue?macro=true";
import { default as _91datasetId_93QqxJvXhzL9Meta } from "/app/pages/health-dashboard/index/datasets/[datasetId].vue?macro=true";
import { default as indexRc45tXpioqMeta } from "/app/pages/health-dashboard/index.vue?macro=true";
import { default as health_45dashboarduKP3YD6NTIMeta } from "/app/pages/health-dashboard.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as logoutHaVXWqg8UAMeta } from "/app/pages/logout.vue?macro=true";
import { default as settingsvwJZzkXsMxMeta } from "/app/pages/orgs/[id]/data-products/[dataProductId]/settings.vue?macro=true";
import { default as subscriptionf4UOb57ZvBMeta } from "/app/pages/orgs/[id]/data-products/[dataProductId]/subscription.vue?macro=true";
import { default as _91dataProductId_93FwtbWQ0xqqMeta } from "/app/pages/orgs/[id]/data-products/[dataProductId].vue?macro=true";
import { default as data_45productsCIEzKEH1xVMeta } from "/app/pages/orgs/[id]/data-products.vue?macro=true";
import { default as entitlementsdSFCHgajzwMeta } from "/app/pages/orgs/[id]/index/entitlements.vue?macro=true";
import { default as indexMXN3VSUzk9Meta } from "/app/pages/orgs/[id]/index.vue?macro=true";
import { default as planWXEMrbJsHAMeta } from "/app/pages/orgs/[id]/plan.vue?macro=true";
import { default as _91id_934o8ocNME0FMeta } from "/app/pages/orgs/[id].vue?macro=true";
import { default as usersdJm5dKrN5DMeta } from "/app/pages/orgs/create/[id]/users.vue?macro=true";
import { default as detailsNERmXDwwxHMeta } from "/app/pages/orgs/create/[type]/details.vue?macro=true";
import { default as nameHbxyXXyV5vMeta } from "/app/pages/orgs/create/[type]/name.vue?macro=true";
import { default as plansMIRGVTT5WsMeta } from "/app/pages/orgs/create/[type]/plans.vue?macro=true";
import { default as indexz93q7se4BFMeta } from "/app/pages/orgs/create/index.vue?macro=true";
import { default as create4muSqvBn4VMeta } from "/app/pages/orgs/create.vue?macro=true";
import { default as indexF331mkLKAPMeta } from "/app/pages/orgs/index.vue?macro=true";
import { default as typeRSoqmqNQfnMeta } from "/app/pages/orgs/type.vue?macro=true";
import { default as orgsMF7Scv4SIcMeta } from "/app/pages/orgs.vue?macro=true";
import { default as index7lupYtO9UwMeta } from "/app/pages/password/[action]/[token]/[email]/index.vue?macro=true";
import { default as recover_45passwordFzhfEITEoUMeta } from "/app/pages/recover-password.vue?macro=true";
import { default as api_45keysOPkVJFbql7Meta } from "/app/pages/settings/api-keys.vue?macro=true";
import { default as generalTBeWhysYVLMeta } from "/app/pages/settings/general.vue?macro=true";
import { default as index11eXGkSJBcMeta } from "/app/pages/settings/index.vue?macro=true";
import { default as emailfzsAt6EdroMeta } from "/app/pages/settings/notifications/channels/email.vue?macro=true";
import { default as indexnl571opJf3Meta } from "/app/pages/settings/notifications/channels/index.vue?macro=true";
import { default as pub_45subeRJpIHhiVoMeta } from "/app/pages/settings/notifications/channels/pub-sub.vue?macro=true";
import { default as sqsykvW2zXVoBMeta } from "/app/pages/settings/notifications/channels/sqs.vue?macro=true";
import { default as webhookA7DeN2kyhnMeta } from "/app/pages/settings/notifications/channels/webhook.vue?macro=true";
import { default as channels1pdq4LMJIUMeta } from "/app/pages/settings/notifications/channels.vue?macro=true";
import { default as index98zCyyEGM5Meta } from "/app/pages/settings/notifications/index.vue?macro=true";
import { default as preferencesnkz4YeqFldMeta } from "/app/pages/settings/notifications/preferences.vue?macro=true";
import { default as editxa8a7gc3FzMeta } from "/app/pages/settings/notifications/rules/[id]/edit.vue?macro=true";
import { default as indexaSgsRjD42zMeta } from "/app/pages/settings/notifications/rules/[id]/index.vue?macro=true";
import { default as createIZgECtdVJXMeta } from "/app/pages/settings/notifications/rules/create.vue?macro=true";
import { default as indexlMBqU4uvQ9Meta } from "/app/pages/settings/notifications/rules/index.vue?macro=true";
import { default as notifications2EXqO5sHnDMeta } from "/app/pages/settings/notifications.vue?macro=true";
import { default as indexZnsbpzMDVSMeta } from "/app/pages/settings/plan-details/index.vue?macro=true";
import { default as upgradeUsqLjinHuqMeta } from "/app/pages/settings/plan-details/upgrade.vue?macro=true";
import { default as your_45plan4qLY13B55nMeta } from "/app/pages/settings/plan-details/your-plan.vue?macro=true";
import { default as plan_45details1y8jXKABDOMeta } from "/app/pages/settings/plan-details.vue?macro=true";
import { default as history8i4dFxIa5kMeta } from "/app/pages/settings/usage/history.vue?macro=true";
import { default as indexlKLobfYM9eMeta } from "/app/pages/settings/usage/index.vue?macro=true";
import { default as usagelSxxSINus2Meta } from "/app/pages/settings/usage.vue?macro=true";
import { default as add_45user3it1rpHlobMeta } from "/app/pages/settings/user-management/add-user.vue?macro=true";
import { default as user_45managementNlGkd4mCWzMeta } from "/app/pages/settings/user-management.vue?macro=true";
import { default as settingsLwEYOlkQxrMeta } from "/app/pages/settings.vue?macro=true";
import { default as studio_45exampleTcLXkS2rbWMeta } from "/app/pages/studio-example.vue?macro=true";
import { default as connectSes7UOs9sdMeta } from "/app/pages/studio/data-products/[dataProductId]/connect.vue?macro=true";
import { default as destinationsm8Vwv93kXGMeta } from "/app/pages/studio/data-products/[dataProductId]/destinations.vue?macro=true";
import { default as indexaW3CFKLIrFMeta } from "/app/pages/studio/data-products/[dataProductId]/model/index.vue?macro=true";
import { default as modelVmGhcA8jHUMeta } from "/app/pages/studio/data-products/[dataProductId]/model.vue?macro=true";
import { default as reviewGPAZAO3lE0Meta } from "/app/pages/studio/data-products/[dataProductId]/review.vue?macro=true";
import { default as indexVkn0fQ7CVtMeta } from "/app/pages/studio/data-products/[dataProductId]/schedule/datasets/[datasetId]/index.vue?macro=true";
import { default as indextF1bcGhB2iMeta } from "/app/pages/studio/data-products/[dataProductId]/schedule/datasets/index.vue?macro=true";
import { default as schedulekxcIBbWnzzMeta } from "/app/pages/studio/data-products/[dataProductId]/schedule.vue?macro=true";
import { default as select_45dataMpThs0wqvoMeta } from "/app/pages/studio/data-products/[dataProductId]/select-data.vue?macro=true";
import { default as _91dataProductId_93MZnh1RgD3RMeta } from "/app/pages/studio/data-products/[dataProductId].vue?macro=true";
import { default as data_45productsibXhjkc3KuMeta } from "/app/pages/studio/data-products.vue?macro=true";
import { default as studioyhbHREGAZRMeta } from "/app/pages/studio.vue?macro=true";
import { default as terms_45and_45conditionszK6eZNsNEXMeta } from "/app/pages/terms-and-conditions.vue?macro=true";
export default [
  {
    name: "agreement",
    path: "/agreement",
    meta: agreementY82GsiR81fMeta || {},
    component: () => import("/app/pages/agreement.vue").then(m => m.default || m)
  },
  {
    name: catalogmbyzFSoe6qMeta?.name,
    path: "/catalog",
    meta: catalogmbyzFSoe6qMeta || {},
    component: () => import("/app/pages/catalog.vue").then(m => m.default || m),
    children: [
  {
    name: data_45productsOG14AKOwg7Meta?.name,
    path: "data-products",
    component: () => import("/app/pages/catalog/data-products.vue").then(m => m.default || m),
    children: [
  {
    name: "catalog-data-products-dataProductId",
    path: ":dataProductId()",
    component: () => import("/app/pages/catalog/data-products/[dataProductId].vue").then(m => m.default || m),
    children: [
  {
    name: "catalog-data-products-dataProductId-datasets-datasetId",
    path: "datasets/:datasetId()",
    component: () => import("/app/pages/catalog/data-products/[dataProductId]/datasets/[datasetId].vue").then(m => m.default || m),
    children: [
  {
    name: "catalog-data-products-dataProductId-datasets-datasetId-frames-frameId",
    path: "frames/:frameId()",
    component: () => import("/app/pages/catalog/data-products/[dataProductId]/datasets/[datasetId]/frames/[frameId].vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "catalog-data-products",
    path: "",
    component: () => import("/app/pages/catalog/data-products/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: familiesDUKwlFbHc8Meta?.name,
    path: "families",
    component: () => import("/app/pages/catalog/families.vue").then(m => m.default || m),
    children: [
  {
    name: "catalog-families",
    path: "",
    meta: indexdGpzbD6lZrMeta || {},
    component: () => import("/app/pages/catalog/families/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "catalog",
    path: "",
    component: () => import("/app/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: suppliersZfYqbO0v0oMeta?.name,
    path: "suppliers",
    component: () => import("/app/pages/catalog/suppliers.vue").then(m => m.default || m),
    children: [
  {
    name: "catalog-suppliers-supplierId",
    path: ":supplierId()",
    component: () => import("/app/pages/catalog/suppliers/[supplierId]/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-suppliers",
    path: "",
    meta: index8HgT1nGNymMeta || {},
    component: () => import("/app/pages/catalog/suppliers/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: connection_45settingsFNhlhsKhgOMeta?.name,
    path: "/connection-settings",
    meta: connection_45settingsFNhlhsKhgOMeta || {},
    component: () => import("/app/pages/connection-settings.vue").then(m => m.default || m),
    children: [
  {
    name: "connection-settings",
    path: "",
    component: () => import("/app/pages/connection-settings/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: dashboardJu37u7k0toMeta?.name,
    path: "/dashboard",
    meta: dashboardJu37u7k0toMeta || {},
    component: () => import("/app/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard",
    path: "",
    component: () => import("/app/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-subcomponents-Explore",
    path: "subcomponents/Explore",
    component: () => import("/app/pages/dashboard/subcomponents/Explore.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-subcomponents-ExploreCard",
    path: "subcomponents/ExploreCard",
    component: () => import("/app/pages/dashboard/subcomponents/ExploreCard.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-subcomponents-GettingStarted",
    path: "subcomponents/GettingStarted",
    component: () => import("/app/pages/dashboard/subcomponents/GettingStarted.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-subcomponents-GettingStartedCard",
    path: "subcomponents/GettingStartedCard",
    component: () => import("/app/pages/dashboard/subcomponents/GettingStartedCard.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-subcomponents-ShortcutCard",
    path: "subcomponents/ShortcutCard",
    component: () => import("/app/pages/dashboard/subcomponents/ShortcutCard.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-subcomponents-Shortcuts",
    path: "subcomponents/Shortcuts",
    component: () => import("/app/pages/dashboard/subcomponents/Shortcuts.vue").then(m => m.default || m)
  }
]
  },
  {
    name: data_45productswATmH8QrEuMeta?.name,
    path: "/data-products",
    meta: data_45productswATmH8QrEuMeta || {},
    component: () => import("/app/pages/data-products.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_933HxNXI5qt6Meta?.name,
    path: ":id()",
    meta: _91id_933HxNXI5qt6Meta || {},
    component: () => import("/app/pages/data-products/[id].vue").then(m => m.default || m),
    children: [
  {
    name: detailsySbTGOyxcIMeta?.name,
    path: "details",
    component: () => import("/app/pages/data-products/[id]/details.vue").then(m => m.default || m),
    children: [
  {
    name: "data-products-id-details-datasets-datasetId-documentation",
    path: "datasets/:datasetId()/documentation",
    component: () => import("/app/pages/data-products/[id]/details/datasets/[datasetId]/documentation.vue").then(m => m.default || m)
  },
  {
    name: "data-products-id-details-datasets-datasetId-frames",
    path: "datasets/:datasetId()/frames",
    component: () => import("/app/pages/data-products/[id]/details/datasets/[datasetId]/frames.vue").then(m => m.default || m)
  },
  {
    name: "data-products-id-details-datasets-datasetId-history",
    path: "datasets/:datasetId()/history",
    component: () => import("/app/pages/data-products/[id]/details/datasets/[datasetId]/history.vue").then(m => m.default || m)
  },
  {
    name: "data-products-id-details-datasets-datasetId",
    path: "datasets/:datasetId()",
    meta: indexyfZqSZhOKAMeta || {},
    component: () => import("/app/pages/data-products/[id]/details/datasets/[datasetId]/index.vue").then(m => m.default || m)
  },
  {
    name: "data-products-id-details-datasets-datasetId-notification-rule",
    path: "datasets/:datasetId()/notification-rule",
    component: () => import("/app/pages/data-products/[id]/details/datasets/[datasetId]/notification-rule.vue").then(m => m.default || m)
  },
  {
    name: "data-products-id-details-datasets-datasetId-results",
    path: "datasets/:datasetId()/results",
    component: () => import("/app/pages/data-products/[id]/details/datasets/[datasetId]/results.vue").then(m => m.default || m)
  },
  {
    name: "data-products-id-details-datasets-datasetId-schedule",
    path: "datasets/:datasetId()/schedule",
    component: () => import("/app/pages/data-products/[id]/details/datasets/[datasetId]/schedule.vue").then(m => m.default || m)
  },
  {
    name: "data-products-id-details-datasets",
    path: "datasets",
    component: () => import("/app/pages/data-products/[id]/details/datasets/index.vue").then(m => m.default || m)
  },
  {
    name: "data-products-id-details-history",
    path: "history",
    component: () => import("/app/pages/data-products/[id]/details/history.vue").then(m => m.default || m)
  },
  {
    name: "data-products-id-details",
    path: "",
    meta: index7yTHezPipUMeta || {},
    component: () => import("/app/pages/data-products/[id]/details/index.vue").then(m => m.default || m)
  },
  {
    name: "data-products-id-details-results",
    path: "results",
    meta: index0QROvc3LbHMeta || {},
    component: () => import("/app/pages/data-products/[id]/details/results/index.vue").then(m => m.default || m)
  },
  {
    name: "data-products-id-details-summary",
    path: "summary",
    component: () => import("/app/pages/data-products/[id]/details/summary.vue").then(m => m.default || m)
  }
]
  },
  {
    name: editBLrexcbMJYMeta?.name,
    path: "edit",
    component: () => import("/app/pages/data-products/[id]/edit.vue").then(m => m.default || m),
    children: [
  {
    name: "data-products-id-edit-datasets",
    path: "datasets",
    component: () => import("/app/pages/data-products/[id]/edit/datasets.vue").then(m => m.default || m)
  },
  {
    name: "data-products-id-edit-destinations",
    path: "destinations",
    component: () => import("/app/pages/data-products/[id]/edit/destinations.vue").then(m => m.default || m)
  },
  {
    name: "data-products-id-edit",
    path: "",
    meta: indexeJBeo05DY6Meta || {},
    component: () => import("/app/pages/data-products/[id]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "data-products-id-edit-schemas",
    path: "schemas",
    component: () => import("/app/pages/data-products/[id]/edit/schemas.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "data-products-id",
    path: "",
    meta: indexK1dzq5JKozMeta || {},
    component: () => import("/app/pages/data-products/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: managezhSM21OJHzMeta?.name,
    path: "manage",
    meta: managezhSM21OJHzMeta || {},
    component: () => import("/app/pages/data-products/[id]/manage.vue").then(m => m.default || m),
    children: [
  {
    name: "data-products-id-manage",
    path: "",
    meta: indexUkAlVXacdSMeta || {},
    component: () => import("/app/pages/data-products/[id]/manage/index.vue").then(m => m.default || m)
  },
  {
    name: "data-products-id-manage-settings",
    path: "settings",
    component: () => import("/app/pages/data-products/[id]/manage/settings.vue").then(m => m.default || m)
  },
  {
    name: "data-products-id-manage-subscription",
    path: "subscription",
    component: () => import("/app/pages/data-products/[id]/manage/subscription.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "data-products-id-scratch",
    path: "scratch",
    meta: scratch06k8wkTd4tMeta || {},
    component: () => import("/app/pages/data-products/[id]/scratch.vue").then(m => m.default || m),
    children: [
  {
    name: "data-products-id-scratch-connection",
    path: "connection",
    component: () => import("/app/pages/data-products/[id]/scratch/connection.vue").then(m => m.default || m)
  },
  {
    name: data_45modelingP8fX3XtLSdMeta?.name,
    path: "data-modeling",
    component: () => import("/app/pages/data-products/[id]/scratch/data-modeling.vue").then(m => m.default || m),
    children: [
  {
    name: "data-products-id-scratch-data-modeling-datasets-datasetId-frames-frameId",
    path: "datasets/:datasetId()/frames/:frameId()",
    component: () => import("/app/pages/data-products/[id]/scratch/data-modeling/datasets/[datasetId]/frames/[frameId]/index.vue").then(m => m.default || m)
  },
  {
    name: "data-products-id-scratch-data-modeling-datasets-datasetId-frames",
    path: "datasets/:datasetId()/frames",
    component: () => import("/app/pages/data-products/[id]/scratch/data-modeling/datasets/[datasetId]/frames/index.vue").then(m => m.default || m)
  },
  {
    name: "data-products-id-scratch-data-modeling-datasets-datasetId",
    path: "datasets/:datasetId()",
    component: () => import("/app/pages/data-products/[id]/scratch/data-modeling/datasets/[datasetId]/index.vue").then(m => m.default || m)
  },
  {
    name: "data-products-id-scratch-data-modeling-datasets",
    path: "datasets",
    component: () => import("/app/pages/data-products/[id]/scratch/data-modeling/datasets/index.vue").then(m => m.default || m)
  },
  {
    name: "data-products-id-scratch-data-modeling",
    path: "",
    component: () => import("/app/pages/data-products/[id]/scratch/data-modeling/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "data-products-id-scratch-destinations",
    path: "destinations",
    component: () => import("/app/pages/data-products/[id]/scratch/destinations.vue").then(m => m.default || m)
  },
  {
    name: "data-products-id-scratch-finalize",
    path: "finalize",
    component: () => import("/app/pages/data-products/[id]/scratch/finalize.vue").then(m => m.default || m)
  },
  {
    name: "data-products-id-scratch-notification-rules",
    path: "notification-rules",
    component: () => import("/app/pages/data-products/[id]/scratch/notification-rules.vue").then(m => m.default || m),
    children: [
  {
    name: "data-products-id-scratch-notification-rules-datasets-datasetId",
    path: "datasets/:datasetId()",
    component: () => import("/app/pages/data-products/[id]/scratch/notification-rules/datasets/[datasetId]/index.vue").then(m => m.default || m)
  },
  {
    name: "data-products-id-scratch-notification-rules-datasets",
    path: "datasets",
    component: () => import("/app/pages/data-products/[id]/scratch/notification-rules/datasets/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "data-products-id-scratch-profile",
    path: "profile",
    component: () => import("/app/pages/data-products/[id]/scratch/profile.vue").then(m => m.default || m)
  },
  {
    name: "data-products-id-scratch-schedule",
    path: "schedule",
    component: () => import("/app/pages/data-products/[id]/scratch/schedule.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "data-products",
    path: "",
    component: () => import("/app/pages/data-products/index.vue").then(m => m.default || m),
    children: [
  {
    name: "data-products-index-entitlements",
    path: "entitlements",
    component: () => import("/app/pages/data-products/index/entitlements.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: health_45dashboarduKP3YD6NTIMeta?.name,
    path: "/health-dashboard",
    meta: health_45dashboarduKP3YD6NTIMeta || {},
    component: () => import("/app/pages/health-dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: "health-dashboard",
    path: "",
    component: () => import("/app/pages/health-dashboard/index.vue").then(m => m.default || m),
    children: [
  {
    name: "health-dashboard-index-datasets-datasetId",
    path: "datasets/:datasetId()",
    component: () => import("/app/pages/health-dashboard/index/datasets/[datasetId].vue").then(m => m.default || m),
    children: [
  {
    name: "health-dashboard-index-datasets-datasetId-deliveries-deliveryId",
    path: "deliveries/:deliveryId()",
    component: () => import("/app/pages/health-dashboard/index/datasets/[datasetId]/deliveries/[deliveryId]/index.vue").then(m => m.default || m)
  }
]
  }
]
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutHaVXWqg8UAMeta || {},
    component: () => import("/app/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: orgsMF7Scv4SIcMeta?.name,
    path: "/orgs",
    meta: orgsMF7Scv4SIcMeta || {},
    component: () => import("/app/pages/orgs.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_934o8ocNME0FMeta?.name,
    path: ":id()",
    meta: _91id_934o8ocNME0FMeta || {},
    component: () => import("/app/pages/orgs/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "orgs-id-data-products",
    path: "data-products",
    component: () => import("/app/pages/orgs/[id]/data-products.vue").then(m => m.default || m),
    children: [
  {
    name: "orgs-id-data-products-dataProductId",
    path: ":dataProductId()",
    meta: _91dataProductId_93FwtbWQ0xqqMeta || {},
    component: () => import("/app/pages/orgs/[id]/data-products/[dataProductId].vue").then(m => m.default || m),
    children: [
  {
    name: "orgs-id-data-products-dataProductId-settings",
    path: "settings",
    component: () => import("/app/pages/orgs/[id]/data-products/[dataProductId]/settings.vue").then(m => m.default || m)
  },
  {
    name: "orgs-id-data-products-dataProductId-subscription",
    path: "subscription",
    component: () => import("/app/pages/orgs/[id]/data-products/[dataProductId]/subscription.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "orgs-id",
    path: "",
    component: () => import("/app/pages/orgs/[id]/index.vue").then(m => m.default || m),
    children: [
  {
    name: "orgs-id-index-entitlements",
    path: "entitlements",
    component: () => import("/app/pages/orgs/[id]/index/entitlements.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "orgs-id-plan",
    path: "plan",
    component: () => import("/app/pages/orgs/[id]/plan.vue").then(m => m.default || m)
  }
]
  },
  {
    name: create4muSqvBn4VMeta?.name,
    path: "create",
    component: () => import("/app/pages/orgs/create.vue").then(m => m.default || m),
    children: [
  {
    name: "orgs-create-id-users",
    path: ":id()/users",
    component: () => import("/app/pages/orgs/create/[id]/users.vue").then(m => m.default || m)
  },
  {
    name: "orgs-create-type-details",
    path: ":type()/details",
    component: () => import("/app/pages/orgs/create/[type]/details.vue").then(m => m.default || m)
  },
  {
    name: "orgs-create-type-name",
    path: ":type()/name",
    component: () => import("/app/pages/orgs/create/[type]/name.vue").then(m => m.default || m)
  },
  {
    name: "orgs-create-type-plans",
    path: ":type()/plans",
    component: () => import("/app/pages/orgs/create/[type]/plans.vue").then(m => m.default || m)
  },
  {
    name: "orgs-create",
    path: "",
    component: () => import("/app/pages/orgs/create/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "orgs",
    path: "",
    component: () => import("/app/pages/orgs/index.vue").then(m => m.default || m)
  },
  {
    name: "orgs-type",
    path: "type",
    component: () => import("/app/pages/orgs/type.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "password-action-token-email",
    path: "/password/:action()/:token()/:email()",
    meta: index7lupYtO9UwMeta || {},
    component: () => import("/app/pages/password/[action]/[token]/[email]/index.vue").then(m => m.default || m)
  },
  {
    name: "recover-password",
    path: "/recover-password",
    meta: recover_45passwordFzhfEITEoUMeta || {},
    component: () => import("/app/pages/recover-password.vue").then(m => m.default || m)
  },
  {
    name: settingsLwEYOlkQxrMeta?.name,
    path: "/settings",
    meta: settingsLwEYOlkQxrMeta || {},
    component: () => import("/app/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-api-keys",
    path: "api-keys",
    component: () => import("/app/pages/settings/api-keys.vue").then(m => m.default || m)
  },
  {
    name: "settings-general",
    path: "general",
    component: () => import("/app/pages/settings/general.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "",
    meta: index11eXGkSJBcMeta || {},
    component: () => import("/app/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: notifications2EXqO5sHnDMeta?.name,
    path: "notifications",
    meta: notifications2EXqO5sHnDMeta || {},
    component: () => import("/app/pages/settings/notifications.vue").then(m => m.default || m),
    children: [
  {
    name: channels1pdq4LMJIUMeta?.name,
    path: "channels",
    component: () => import("/app/pages/settings/notifications/channels.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-notifications-channels-email",
    path: "email",
    component: () => import("/app/pages/settings/notifications/channels/email.vue").then(m => m.default || m)
  },
  {
    name: "settings-notifications-channels",
    path: "",
    meta: indexnl571opJf3Meta || {},
    component: () => import("/app/pages/settings/notifications/channels/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-notifications-channels-pub-sub",
    path: "pub-sub",
    component: () => import("/app/pages/settings/notifications/channels/pub-sub.vue").then(m => m.default || m)
  },
  {
    name: "settings-notifications-channels-sqs",
    path: "sqs",
    component: () => import("/app/pages/settings/notifications/channels/sqs.vue").then(m => m.default || m)
  },
  {
    name: "settings-notifications-channels-webhook",
    path: "webhook",
    component: () => import("/app/pages/settings/notifications/channels/webhook.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings-notifications",
    path: "",
    meta: index98zCyyEGM5Meta || {},
    component: () => import("/app/pages/settings/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-notifications-preferences",
    path: "preferences",
    component: () => import("/app/pages/settings/notifications/preferences.vue").then(m => m.default || m)
  },
  {
    name: "settings-notifications-rules-id-edit",
    path: "rules/:id()/edit",
    component: () => import("/app/pages/settings/notifications/rules/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "settings-notifications-rules-id",
    path: "rules/:id()",
    component: () => import("/app/pages/settings/notifications/rules/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-notifications-rules-create",
    path: "rules/create",
    component: () => import("/app/pages/settings/notifications/rules/create.vue").then(m => m.default || m)
  },
  {
    name: "settings-notifications-rules",
    path: "rules",
    component: () => import("/app/pages/settings/notifications/rules/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: plan_45details1y8jXKABDOMeta?.name,
    path: "plan-details",
    meta: plan_45details1y8jXKABDOMeta || {},
    component: () => import("/app/pages/settings/plan-details.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-plan-details",
    path: "",
    meta: indexZnsbpzMDVSMeta || {},
    component: () => import("/app/pages/settings/plan-details/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-plan-details-upgrade",
    path: "upgrade",
    component: () => import("/app/pages/settings/plan-details/upgrade.vue").then(m => m.default || m)
  },
  {
    name: "settings-plan-details-your-plan",
    path: "your-plan",
    component: () => import("/app/pages/settings/plan-details/your-plan.vue").then(m => m.default || m)
  }
]
  },
  {
    name: usagelSxxSINus2Meta?.name,
    path: "usage",
    meta: usagelSxxSINus2Meta || {},
    component: () => import("/app/pages/settings/usage.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-usage-history",
    path: "history",
    component: () => import("/app/pages/settings/usage/history.vue").then(m => m.default || m)
  },
  {
    name: "settings-usage",
    path: "",
    meta: indexlKLobfYM9eMeta || {},
    component: () => import("/app/pages/settings/usage/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings-user-management",
    path: "user-management",
    meta: user_45managementNlGkd4mCWzMeta || {},
    component: () => import("/app/pages/settings/user-management.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-user-management-add-user",
    path: "add-user",
    component: () => import("/app/pages/settings/user-management/add-user.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "studio-example",
    path: "/studio-example",
    meta: studio_45exampleTcLXkS2rbWMeta || {},
    component: () => import("/app/pages/studio-example.vue").then(m => m.default || m)
  },
  {
    name: "studio",
    path: "/studio",
    meta: studioyhbHREGAZRMeta || {},
    component: () => import("/app/pages/studio.vue").then(m => m.default || m),
    children: [
  {
    name: "studio-data-products",
    path: "data-products",
    component: () => import("/app/pages/studio/data-products.vue").then(m => m.default || m),
    children: [
  {
    name: "studio-data-products-dataProductId",
    path: ":dataProductId()",
    component: () => import("/app/pages/studio/data-products/[dataProductId].vue").then(m => m.default || m),
    children: [
  {
    name: "studio-data-products-dataProductId-connect",
    path: "connect",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/connect.vue").then(m => m.default || m)
  },
  {
    name: "studio-data-products-dataProductId-destinations",
    path: "destinations",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/destinations.vue").then(m => m.default || m)
  },
  {
    name: modelVmGhcA8jHUMeta?.name,
    path: "model",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/model.vue").then(m => m.default || m),
    children: [
  {
    name: "studio-data-products-dataProductId-model",
    path: "",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/model/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "studio-data-products-dataProductId-review",
    path: "review",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/review.vue").then(m => m.default || m)
  },
  {
    name: "studio-data-products-dataProductId-schedule",
    path: "schedule",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/schedule.vue").then(m => m.default || m),
    children: [
  {
    name: "studio-data-products-dataProductId-schedule-datasets-datasetId",
    path: "datasets/:datasetId()",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/schedule/datasets/[datasetId]/index.vue").then(m => m.default || m)
  },
  {
    name: "studio-data-products-dataProductId-schedule-datasets",
    path: "datasets",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/schedule/datasets/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "studio-data-products-dataProductId-select-data",
    path: "select-data",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/select-data.vue").then(m => m.default || m)
  }
]
  }
]
  }
]
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    meta: terms_45and_45conditionszK6eZNsNEXMeta || {},
    component: () => import("/app/pages/terms-and-conditions.vue").then(m => m.default || m)
  }
]