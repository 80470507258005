import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import legacy_VInWsa8joX from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/legacy.mjs";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import index_q36VSRreUY from "/app/plugins/launch-darkly/index.js";
import _1_init_esu1kCOl8P from "/app/plugins/1.init.js";
import compatibility_vue2_Hodx8ogad4 from "/app/plugins/compatibility-vue2.js";
import cx_components_lzssUPTS79 from "/app/plugins/cx-components.js";
import entitlements_xcwoW1XgxJ from "/app/plugins/entitlements.js";
import hubble_ZhZhlCuj9J from "/app/plugins/hubble.js";
import intercom_WPYf78IneK from "/app/plugins/intercom.js";
import mixpanel_flxmgVYaFE from "/app/plugins/mixpanel.js";
import portal_vue_OfeK7RygIH from "/app/plugins/portal-vue.js";
import rollbar_NnZoFdA7so from "/app/plugins/rollbar.js";
import route_names_QXFj0mC4uE from "/app/plugins/route-names.js";
import utils_EeQrN1BEbD from "/app/plugins/utils.js";
import vee_validate_K3WwmJMPDb from "/app/plugins/vee-validate.js";
import vue_virtual_scroller_Z24eVKxrOq from "/app/plugins/vue-virtual-scroller.js";
import vuex_owYp5qnaH8 from "/app/plugins/vuex.js";
import websockets_I4gQrInwM6 from "/app/plugins/websockets.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  legacy_VInWsa8joX,
  i18n_yfWm7jX06p,
  index_q36VSRreUY,
  _1_init_esu1kCOl8P,
  compatibility_vue2_Hodx8ogad4,
  cx_components_lzssUPTS79,
  entitlements_xcwoW1XgxJ,
  hubble_ZhZhlCuj9J,
  intercom_WPYf78IneK,
  mixpanel_flxmgVYaFE,
  portal_vue_OfeK7RygIH,
  rollbar_NnZoFdA7so,
  route_names_QXFj0mC4uE,
  utils_EeQrN1BEbD,
  vee_validate_K3WwmJMPDb,
  vue_virtual_scroller_Z24eVKxrOq,
  vuex_owYp5qnaH8,
  websockets_I4gQrInwM6
]