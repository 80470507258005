import { flatten, map } from 'lodash-es';

import {
  DRAFT,
  PROFILING_ERROR,
  PROFILING_PENDING,
  PROFILING_QUEUED,
  PROFILING_SUCCESS,
  SUBSCRIPTION_TYPES,
  TESTING,
} from '~/support/constants';

export const CDU = {
  description: 'Ric Flair datasets',
  id: 'Cx00000',
  name: 'Ric Flair CDU',
  status: 'Candidate',
  supplier: 'Ric Flair Co.',
};

export const CONNECTION = {
  config: {
    address: 'banana-target',
    id: 'banana12345',
    password: 'banana',
    port: '22',
    username: 'banana-dev',
  },
  createdAt: '2022-12-22T18:49:08.191406',
  createdBy: 'jonathan.tam+stg@cruxinformatics.com',
  id: 123,
  method: 'sftp',
  name: 'Banana Test Connection #1',
  type: 'source',
};

export const CONNECTIONS = [
  {
    config: {
      address: '125.45.5.54',
      id: 'ingestion-engine-msci-sftp',
      password: 'rflair@msci.com',
      port: '5548',
      username: 'woowoowoo123',
    },
    createdAt: '2022-12-22T18:49:08.191406',
    createdBy: 'jonathan.tam+stg@cruxinformatics.com',
    id: 123,
    method: 'sftp',
    name: 'MSCI, Inc',
    productId: 456,
    type: 'source',
  },
  {
    config: {
      address: '125.45.5.55',
      id: 'ingestion-engine-etfglobal-sftp',
      password: 'rflair@etf.com',
      port: '8888',
      username: 'etfg',
    },
    createdAt: '2022-12-22T18:49:08.191406',
    createdBy: 'jonathan.tam+stg@cruxinformatics.com',
    id: 65,
    method: 'sftp',
    name: 'ETFG - SFTP',
    productId: 456,
    type: 'source',
  },
];

export const CONNECTION_EMPTY_CONFIG = {
  config: {},
  createdAt: '2022-12-22T18:49:08.191406',
  createdBy: 'jonathan.tam+stg@cruxinformatics.com',
  id: 81,
  method: 'sftp',
  name: 'ETFG - SFTP',
  productId: 456,
  type: 'source',
};

export const CUSTOM_PATTERNS = ['cmitchell/kims_convenience.zip/kims_convenience/suppliers/acme.csv'];

export const GROUPED_FRAME = {
  datasetId: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_v8',
  endDate: '2022-03-01T16:00:00',
  id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60',
  name: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60',
  pattern: 'aue4/FPD_AUE4L_%Y%m%d.zip/FP_AUE4L_Factor_POR.%Y%m%D',
  regex: '(?:aue4/FPD_AUE4L_)(%Y)(%m)(%d)(?:.zip/FP_AUE4L_Factor_POR.)(%Y)(%m)(%D)',
  schema: [
    { dataType: 'STRING', name: 'product_name' },
    { dataType: 'STRING', name: 'sheet_name' },
    { dataType: 'STRING', name: 'grouping_name' },
    { dataType: 'STRING', name: 'grouping_subheader' },
    { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_start' },
    { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_end' },
    { dataType: 'STRING', name: 'description' },
    { dataType: 'STRING', name: 'descriptor_1' },
    { dataType: 'STRING', name: 'descriptor_2' },
    { dataType: 'FLOAT', name: 'value' },
    { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'reported_publish_date' },
    { dataType: 'STRING', name: 'kugou_super_vip_' },
    { dataType: 'STRING', name: 'kugou_vip_' },
    { dataType: 'STRING', name: 'kugou_live' },
    { dataType: 'STRING', name: 'kuwo_luxury_vip_' },
    { dataType: 'STRING', name: 'kuwo_music_pack_' },
    { dataType: 'STRING', name: 'kuwo_live' },
    { dataType: 'STRING', name: 'netease_music_pack_' },
    { dataType: 'STRING', name: 'netease_vinyl_vip_' },
    { dataType: 'STRING', name: 'qq_green_diamond_annual' },
    { dataType: 'STRING', name: 'qq_green_diamond_monthly' },
    { dataType: 'STRING', name: 'qq_paid_music_annual' },
    { dataType: 'STRING', name: 'qq_paid_music_monthly' },
    { dataType: 'STRING', name: 'wesing_ktv' },
    { dataType: 'STRING', name: 'wesing_live' },
  ],
  startDate: '2022-03-29T16:00:00',
};

export const FLATTENED_FRAME_VERSIONS = [
  {
    id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_latest',
    name: 'Latest schema',
    pattern: 'aue4/FPD_AUE4L_%Y%m%d.zip/FP_AUE4L_Factor_POR.%Y%m%D',
    regex: '(?:aue4/FPD_AUE4L_)(%Y)(%m)(%d)(?:.zip/FP_AUE4L_Factor_POR.)(%Y)(%m)(%D)',
    startDate: '2022-03-29T16:00:00',
    status: 'success',
  },
  {
    id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_latest',
    name: 'Latest schema',
    pattern: 'aue4/FPD_AUE4L_%Y%m%d.zip/FP_AUE4L_Factor_POR.%Y%m%D',
    regex: '(?:aue4/FPD_AUE4L_)(%Y)(%m)(%d)(?:.zip/FP_AUE4L_Factor_POR.)(%Y)(%m)(%D)',
    startDate: '2022-03-29T16:00:00',
    status: 'success',
  },
  {
    id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_latest',
    name: 'Latest schema',
    pattern: 'epukv(?<YY>d{2})(?<MM>d{2})',
    regex: 'epukv(?<YY>d{2})(?<MM>d{2})',
    startDate: '2022-03-29T16:00:00',
    status: 'error',
  },
  {
    id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_2022-10-05',
    name: '2022-10-05',
    pattern: 'epukv(?<YY>d{2})(?<MM>d{2})',
    regex: 'epukv(?<YY>d{2})(?<MM>d{2})',
    startDate: '2022-03-29T16:00:00',
    status: 'success',
  },
  {
    id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_latest',
    name: 'Latest schema',
    pattern: 'epukv(?<YY>d{2})(?<MM>d{2})',
    regex: 'epukv(?<YY>d{2})(?<MM>d{2})',
    startDate: '2022-03-29T16:00:00',
    status: 'success',
  },
  {
    id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_2022-10-01',
    name: '2022-10-01',
    pattern: 'epukv(?<YY>d{2})(?<MM>d{2})',
    regex: 'epukv(?<YY>d{2})(?<MM>d{2})',
    startDate: '2022-03-29T16:00:00',
    status: 'pending',
  },
  {
    id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_latest',
    name: 'Latest schema',
    pattern: 'epukv(?<YY>d{2})(?<MM>d{2})_v1',
    regex: 'epukv(?<YY>d{2})(?<MM>d{2})_v1',
    startDate: '2022-03-29T16:00:00',
    status: 'queued',
  },
];

export const FUZZY_SEARCH_RESULTS = [
  {
    filenamePattern: 'cmitchell/soccer/england-premier-league-league-%Y-to-%Y-stats.csv',
    filenameRegex: 'cmitchell/soccer/england-premier-league-league-\\d\\d\\d\\d-to-\\d\\d\\d\\d-stats.csv',
    files: [
      {
        name: 'england-premier-league-league-2018-to-2019-stats.csv',
        path: 'cmitchell/soccer/england-premier-league-league-2018-to-2019-stats.csv',
        size: 1801,
        totalFiles: null,
        totalSizeOfFiles: null,
        type: 'file',
        updatedAt: '2022-08-12T05:52:17',
      },
    ],
    matchScore: 77,
    schedule: '@once',
    totalFiles: 1,
    totalSizeOfFiles: 1801,
  },
];

export const GROUPED_FRAMES = {
  'aue4/FPD_AUE4L_%Y%m%d.zip/FP_AUE4L_Factor_POR.%Y%m%D': [
    {
      datasetCron: '0 0 16-23 * * *',
      datasetId: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_v8',
      endDate: '2022-03-01T16:00:00',
      id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60',
      name: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60',
      pattern: 'aue4/FPD_AUE4L_%Y%m%d.zip/FP_AUE4L_Factor_POR.%Y%m%D',
      regex: '(?:aue4/FPD_AUE4L_)(%Y)(%m)(%d)(?:.zip/FP_AUE4L_Factor_POR.)(%Y)(%m)(%D)',
      schema: [
        { dataType: 'STRING', name: 'product_name' },
        { dataType: 'STRING', name: 'sheet_name' },
        { dataType: 'STRING', name: 'grouping_name' },
        { dataType: 'STRING', name: 'grouping_subheader' },
        { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_start' },
        { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_end' },
        { dataType: 'STRING', name: 'description' },
        { dataType: 'STRING', name: 'descriptor_1' },
        { dataType: 'STRING', name: 'descriptor_2' },
        { dataType: 'FLOAT', name: 'value' },
        { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'reported_publish_date' },
        { dataType: 'STRING', name: 'kugou_super_vip_' },
        { dataType: 'STRING', name: 'kugou_vip_' },
        { dataType: 'STRING', name: 'kugou_live' },
        { dataType: 'STRING', name: 'kuwo_luxury_vip_' },
        { dataType: 'STRING', name: 'kuwo_music_pack_' },
        { dataType: 'STRING', name: 'kuwo_live' },
        { dataType: 'STRING', name: 'netease_music_pack_' },
        { dataType: 'STRING', name: 'netease_vinyl_vip_' },
        { dataType: 'STRING', name: 'qq_green_diamond_annual' },
        { dataType: 'STRING', name: 'qq_green_diamond_monthly' },
        { dataType: 'STRING', name: 'qq_paid_music_annual' },
        { dataType: 'STRING', name: 'qq_paid_music_monthly' },
        { dataType: 'STRING', name: 'wesing_ktv' },
        { dataType: 'STRING', name: 'wesing_live' },
      ],
      startDate: '2022-03-29T16:00:00',
      status: 'success',
      totalFiles: 10,
      versions: [
        {
          id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_latest',
          name: 'Latest schema',
          pattern: 'aue4/FPD_AUE4L_%Y%m%d.zip/FP_AUE4L_Factor_POR.%Y%m%D',
          regex: '(?:aue4/FPD_AUE4L_)(%Y)(%m)(%d)(?:.zip/FP_AUE4L_Factor_POR.)(%Y)(%m)(%D)',
          startDate: '2022-03-29T16:00:00',
          status: 'success',
        },
      ],
    },
    {
      datasetCron: '0 0 16-23 * * *',
      datasetId: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_v8',
      endDate: '2022-03-01T16:00:00',
      id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_2',
      name: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_2',
      pattern: 'aue4/FPD_AUE4L_%Y%m%d.zip/FP_AUE4L_Factor_POR.%Y%m%D',
      regex: '(?:aue4/FPD_AUE4L_)(%Y)(%m)(%d)(?:.zip/FP_AUE4L_Factor_POR.)(%Y)(%m)(%D)',
      schema: [
        { dataType: 'STRING', name: 'product_name' },
        { dataType: 'STRING', name: 'sheet_name' },
        { dataType: 'STRING', name: 'grouping_name' },
        { dataType: 'STRING', name: 'grouping_subheader' },
        { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_start' },
        { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_end' },
        { dataType: 'STRING', name: 'description' },
        { dataType: 'STRING', name: 'descriptor_1' },
        { dataType: 'STRING', name: 'descriptor_2' },
        { dataType: 'FLOAT', name: 'value' },
        { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'reported_publish_date' },
        { dataType: 'STRING', name: 'kugou_super_vip_' },
        { dataType: 'STRING', name: 'kugou_vip_' },
        { dataType: 'STRING', name: 'kugou_live' },
        { dataType: 'STRING', name: 'kuwo_luxury_vip_' },
        { dataType: 'STRING', name: 'kuwo_music_pack_' },
        { dataType: 'STRING', name: 'kuwo_live' },
        { dataType: 'STRING', name: 'netease_music_pack_' },
        { dataType: 'STRING', name: 'netease_vinyl_vip_' },
        { dataType: 'STRING', name: 'qq_green_diamond_annual' },
        { dataType: 'STRING', name: 'qq_green_diamond_monthly' },
        { dataType: 'STRING', name: 'qq_paid_music_annual' },
        { dataType: 'STRING', name: 'qq_paid_music_monthly' },
        { dataType: 'STRING', name: 'wesing_ktv' },
        { dataType: 'STRING', name: 'wesing_live' },
      ],
      startDate: '2022-03-29T16:00:00',
      status: 'success',
      totalFiles: 20,
      versions: [
        {
          id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_latest',
          name: 'Latest schema',
          pattern: 'aue4/FPD_AUE4L_%Y%m%d.zip/FP_AUE4L_Factor_POR.%Y%m%D',
          regex: '(?:aue4/FPD_AUE4L_)(%Y)(%m)(%d)(?:.zip/FP_AUE4L_Factor_POR.)(%Y)(%m)(%D)',
          startDate: '2022-03-29T16:00:00',
          status: 'success',
        },
      ],
    },
  ],
  'epukv(?<YY>d{2})(?<MM>d{2})': [
    {
      datasetCron: '0 0 16-23 * * *',
      datasetId: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_v6',
      endDate: null,
      id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s59',
      name: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s59',
      pattern: 'epukv(?<YY>d{2})(?<MM>d{2})',
      regex: 'epukv(?<YY>d{2})(?<MM>d{2})',
      schema: [
        { dataType: 'STRING', name: 'product_name' },
        { dataType: 'STRING', name: 'sheet_name' },
        { dataType: 'STRING', name: 'grouping_name' },
        { dataType: 'STRING', name: 'grouping_subheader' },
        { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_start' },
        { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_end' },
        { dataType: 'STRING', name: 'description' },
        { dataType: 'STRING', name: 'descriptor_1' },
        { dataType: 'STRING', name: 'descriptor_2' },
        { dataType: 'FLOAT', name: 'value' },
        { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'reported_publish_date' },
        { dataType: 'STRING', name: 'kugou_super_vip_' },
        { dataType: 'STRING', name: 'kugou_vip_' },
        { dataType: 'STRING', name: 'kugou_live' },
        { dataType: 'STRING', name: 'kuwo_luxury_vip_' },
        { dataType: 'STRING', name: 'kuwo_music_pack_' },
        { dataType: 'STRING', name: 'kuwo_live' },
        { dataType: 'STRING', name: 'netease_music_pack_' },
        { dataType: 'STRING', name: 'netease_vinyl_vip_' },
        { dataType: 'STRING', name: 'qq_green_diamond_annual' },
        { dataType: 'STRING', name: 'qq_green_diamond_monthly' },
        { dataType: 'STRING', name: 'qq_paid_music_annual' },
        { dataType: 'STRING', name: 'qq_paid_music_monthly' },
        { dataType: 'STRING', name: 'wesing_ktv' },
        { dataType: 'STRING', name: 'wesing_live' },
      ],
      startDate: '2022-03-29T16:00:00',
      status: 'error',
      versions: [
        {
          id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_latest',
          name: 'Latest schema',
          pattern: 'epukv(?<YY>d{2})(?<MM>d{2})',
          regex: 'epukv(?<YY>d{2})(?<MM>d{2})',
          startDate: '2022-03-29T16:00:00',
          status: 'error',
        },
        {
          id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_2022-10-05',
          name: '2022-10-05',
          pattern: 'epukv(?<YY>d{2})(?<MM>d{2})',
          regex: 'epukv(?<YY>d{2})(?<MM>d{2})',
          startDate: '2022-03-29T16:00:00',
          status: 'success',
        },
      ],
    },
    {
      datasetCron: '0 0 16-23 * * *',
      datasetId: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_v5',
      endDate: null,
      id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s58',
      name: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s58',
      pattern: 'epukv(?<YY>d{2})(?<MM>d{2})',
      regex: 'epukv(?<YY>d{2})(?<MM>d{2})',
      schema: [
        { dataType: 'STRING', name: 'product_name' },
        { dataType: 'STRING', name: 'sheet_name' },
        { dataType: 'STRING', name: 'grouping_name' },
        { dataType: 'STRING', name: 'grouping_subheader' },
        { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_start' },
        { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_end' },
        { dataType: 'STRING', name: 'description' },
        { dataType: 'STRING', name: 'descriptor_1' },
        { dataType: 'STRING', name: 'descriptor_2' },
        { dataType: 'FLOAT', name: 'value' },
        { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'reported_publish_date' },
        { dataType: 'STRING', name: 'kugou_super_vip_' },
        { dataType: 'STRING', name: 'kugou_vip_' },
        { dataType: 'STRING', name: 'kugou_live' },
        { dataType: 'STRING', name: 'kuwo_luxury_vip_' },
        { dataType: 'STRING', name: 'kuwo_music_pack_' },
        { dataType: 'STRING', name: 'kuwo_live' },
        { dataType: 'STRING', name: 'netease_music_pack_' },
        { dataType: 'STRING', name: 'netease_vinyl_vip_' },
        { dataType: 'STRING', name: 'qq_green_diamond_annual' },
        { dataType: 'STRING', name: 'qq_green_diamond_monthly' },
        { dataType: 'STRING', name: 'qq_paid_music_annual' },
        { dataType: 'STRING', name: 'qq_paid_music_monthly' },
        { dataType: 'STRING', name: 'wesing_ktv' },
        { dataType: 'STRING', name: 'wesing_live' },
      ],
      startDate: '2022-03-29T16:00:00',
      status: 'pending',
      versions: [
        {
          id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_latest',
          name: 'Latest schema',
          pattern: 'epukv(?<YY>d{2})(?<MM>d{2})',
          regex: 'epukv(?<YY>d{2})(?<MM>d{2})',
          startDate: '2022-03-29T16:00:00',
          status: 'success',
        },
        {
          id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_2022-10-01',
          name: '2022-10-01',
          pattern: 'epukv(?<YY>d{2})(?<MM>d{2})',
          regex: 'epukv(?<YY>d{2})(?<MM>d{2})',
          startDate: '2022-03-29T16:00:00',
          status: 'pending',
        },
      ],
    },
  ],
  'epukv(?<YY>d{2})(?<MM>d{2})_v1': [
    {
      datasetCron: '@once',
      datasetId: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_v1_v2',
      endDate: null,
      id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_v1_csv_v2',
      name: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_v1_csv_v2',
      pattern: 'epukv(?<YY>d{2})(?<MM>d{2})_v1',
      regex: 'epukv(?<YY>d{2})(?<MM>d{2})_v1',
      schema: [
        { dataType: 'STRING', name: 'product_name' },
        { dataType: 'STRING', name: 'sheet_name' },
        { dataType: 'STRING', name: 'grouping_name' },
        { dataType: 'STRING', name: 'grouping_subheader' },
        { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_start' },
        { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_end' },
        { dataType: 'STRING', name: 'description' },
        { dataType: 'STRING', name: 'descriptor_1' },
        { dataType: 'STRING', name: 'descriptor_2' },
        { dataType: 'FLOAT', name: 'value' },
        { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'reported_publish_date' },
        { dataType: 'STRING', name: 'kugou_super_vip_' },
        { dataType: 'STRING', name: 'kugou_vip_' },
        { dataType: 'STRING', name: 'kugou_live' },
        { dataType: 'STRING', name: 'kuwo_luxury_vip_' },
        { dataType: 'STRING', name: 'kuwo_music_pack_' },
        { dataType: 'STRING', name: 'kuwo_live' },
        { dataType: 'STRING', name: 'netease_music_pack_' },
        { dataType: 'STRING', name: 'netease_vinyl_vip_' },
        { dataType: 'STRING', name: 'qq_green_diamond_annual' },
        { dataType: 'STRING', name: 'qq_green_diamond_monthly' },
        { dataType: 'STRING', name: 'qq_paid_music_annual' },
        { dataType: 'STRING', name: 'qq_paid_music_monthly' },
        { dataType: 'STRING', name: 'wesing_ktv' },
        { dataType: 'STRING', name: 'wesing_live' },
      ],
      startDate: '2022-03-29T16:00:00',
      status: 'queued',
      versions: [
        {
          id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_latest',
          name: 'Latest schema',
          pattern: 'epukv(?<YY>d{2})(?<MM>d{2})_v1',
          regex: 'epukv(?<YY>d{2})(?<MM>d{2})_v1',
          startDate: '2022-03-29T16:00:00',
          status: 'queued',
        },
      ],
    },
  ],
};

// used in DATA_PRODUCT
export const DATA_PRODUCT_DATASETS = [
  {
    cron: '0 0 0 1 * *',
    id: 'Ds7qFx6T',
    lastUpdated: '2023-08-01T13:24:08.400+00:00',
    name: 'crux_testing_llc_0_test_dir_integ_4126_fasttest_v28',
    nextDelivery: '2023-09-01T00:00:00.000+00:00',
    numberOfFrames: 1,
  },
  {
    cron: '@once',
    id: 'testId',
    lastUpdated: '2023-07-01T13:24:08.400+00:00',
    name: 'random_name',
    numberOfFrames: 2,
  },
];

// used in DATA_PRODUCT
export const DATASETS = [
  {
    cron: '0 0 16-23 * * *',
    datasetId: 'Ds7qFx6T',
    frames: [
      {
        endDate: '2022-03-01T16:00:00',
        id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60',
        name: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60',
        pattern: 'aue4/FPD_AUE4L_%Y%m%d.zip/FP_AUE4L_Factor_POR.%Y%m%D',
        regex: '(?:aue4/FPD_AUE4L_)(%Y)(%m)(%d)(?:.zip/FP_AUE4L_Factor_POR.)(%Y)(%m)(%D)',
        schema: [
          { dataType: 'STRING', name: 'product_name' },
          { dataType: 'STRING', name: 'sheet_name' },
          { dataType: 'STRING', name: 'grouping_name' },
          { dataType: 'STRING', name: 'grouping_subheader' },
          { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_start' },
          { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_end' },
          { dataType: 'STRING', name: 'description' },
          { dataType: 'STRING', name: 'descriptor_1' },
          { dataType: 'STRING', name: 'descriptor_2' },
          { dataType: 'FLOAT', name: 'value' },
          { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'reported_publish_date' },
          { dataType: 'STRING', name: 'kugou_super_vip_' },
          { dataType: 'STRING', name: 'kugou_vip_' },
          { dataType: 'STRING', name: 'kugou_live' },
          { dataType: 'STRING', name: 'kuwo_luxury_vip_' },
          { dataType: 'STRING', name: 'kuwo_music_pack_' },
          { dataType: 'STRING', name: 'kuwo_live' },
          { dataType: 'STRING', name: 'netease_music_pack_' },
          { dataType: 'STRING', name: 'netease_vinyl_vip_' },
          { dataType: 'STRING', name: 'qq_green_diamond_annual' },
          { dataType: 'STRING', name: 'qq_green_diamond_monthly' },
          { dataType: 'STRING', name: 'qq_paid_music_annual' },
          { dataType: 'STRING', name: 'qq_paid_music_monthly' },
          { dataType: 'STRING', name: 'wesing_ktv' },
          { dataType: 'STRING', name: 'wesing_live' },
        ],
        startDate: '2022-03-29T16:00:00',
        status: PROFILING_SUCCESS,
        totalFiles: 10,
        versions: [
          {
            id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_latest',
            name: 'Latest schema',
            pattern: 'aue4/FPD_AUE4L_%Y%m%d.zip/FP_AUE4L_Factor_POR.%Y%m%D',
            regex: '(?:aue4/FPD_AUE4L_)(%Y)(%m)(%d)(?:.zip/FP_AUE4L_Factor_POR.)(%Y)(%m)(%D)',
            startDate: '2022-03-29T16:00:00',
            status: PROFILING_SUCCESS,
          },
        ],
      },
      {
        endDate: '2022-03-01T16:00:00',
        id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_2',
        name: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_2',
        pattern: 'aue4/FPD_AUE4L_%Y%m%d.zip/FP_AUE4L_Factor_POR.%Y%m%D',
        regex: '(?:aue4/FPD_AUE4L_)(%Y)(%m)(%d)(?:.zip/FP_AUE4L_Factor_POR.)(%Y)(%m)(%D)',
        schema: [
          { dataType: 'STRING', name: 'product_name' },
          { dataType: 'STRING', name: 'sheet_name' },
          { dataType: 'STRING', name: 'grouping_name' },
          { dataType: 'STRING', name: 'grouping_subheader' },
          { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_start' },
          { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_end' },
          { dataType: 'STRING', name: 'description' },
          { dataType: 'STRING', name: 'descriptor_1' },
          { dataType: 'STRING', name: 'descriptor_2' },
          { dataType: 'FLOAT', name: 'value' },
          { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'reported_publish_date' },
          { dataType: 'STRING', name: 'kugou_super_vip_' },
          { dataType: 'STRING', name: 'kugou_vip_' },
          { dataType: 'STRING', name: 'kugou_live' },
          { dataType: 'STRING', name: 'kuwo_luxury_vip_' },
          { dataType: 'STRING', name: 'kuwo_music_pack_' },
          { dataType: 'STRING', name: 'kuwo_live' },
          { dataType: 'STRING', name: 'netease_music_pack_' },
          { dataType: 'STRING', name: 'netease_vinyl_vip_' },
          { dataType: 'STRING', name: 'qq_green_diamond_annual' },
          { dataType: 'STRING', name: 'qq_green_diamond_monthly' },
          { dataType: 'STRING', name: 'qq_paid_music_annual' },
          { dataType: 'STRING', name: 'qq_paid_music_monthly' },
          { dataType: 'STRING', name: 'wesing_ktv' },
          { dataType: 'STRING', name: 'wesing_live' },
        ],
        startDate: '2022-03-29T16:00:00',
        status: PROFILING_SUCCESS,
        totalFiles: 20,
        versions: [
          {
            id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_latest',
            name: 'Latest schema',
            pattern: 'aue4/FPD_AUE4L_%Y%m%d.zip/FP_AUE4L_Factor_POR.%Y%m%D',
            regex: '(?:aue4/FPD_AUE4L_)(%Y)(%m)(%d)(?:.zip/FP_AUE4L_Factor_POR.)(%Y)(%m)(%D)',
            startDate: '2022-03-29T16:00:00',
            status: PROFILING_SUCCESS,
          },
        ],
      },
    ],
    id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_v8',
    name: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_v8',
    startDate: '2022-03-23T12:00:00',
    status: PROFILING_SUCCESS,
  },
  {
    cron: '0 0 16-23 * * *',
    datasetId: 'testId',
    frames: [
      {
        endDate: null,
        id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s59',
        name: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s59',
        pattern: 'epukv(?<YY>d{2})(?<MM>d{2})',
        regex: 'epukv(?<YY>d{2})(?<MM>d{2})',
        schema: [
          { dataType: 'STRING', name: 'product_name' },
          { dataType: 'STRING', name: 'sheet_name' },
          { dataType: 'STRING', name: 'grouping_name' },
          { dataType: 'STRING', name: 'grouping_subheader' },
          { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_start' },
          { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_end' },
          { dataType: 'STRING', name: 'description' },
          { dataType: 'STRING', name: 'descriptor_1' },
          { dataType: 'STRING', name: 'descriptor_2' },
          { dataType: 'FLOAT', name: 'value' },
          { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'reported_publish_date' },
          { dataType: 'STRING', name: 'kugou_super_vip_' },
          { dataType: 'STRING', name: 'kugou_vip_' },
          { dataType: 'STRING', name: 'kugou_live' },
          { dataType: 'STRING', name: 'kuwo_luxury_vip_' },
          { dataType: 'STRING', name: 'kuwo_music_pack_' },
          { dataType: 'STRING', name: 'kuwo_live' },
          { dataType: 'STRING', name: 'netease_music_pack_' },
          { dataType: 'STRING', name: 'netease_vinyl_vip_' },
          { dataType: 'STRING', name: 'qq_green_diamond_annual' },
          { dataType: 'STRING', name: 'qq_green_diamond_monthly' },
          { dataType: 'STRING', name: 'qq_paid_music_annual' },
          { dataType: 'STRING', name: 'qq_paid_music_monthly' },
          { dataType: 'STRING', name: 'wesing_ktv' },
          { dataType: 'STRING', name: 'wesing_live' },
        ],
        startDate: '2022-03-29T16:00:00',
        status: PROFILING_ERROR,
        versions: [
          {
            id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_latest',
            name: 'Latest schema',
            pattern: 'epukv(?<YY>d{2})(?<MM>d{2})',
            regex: 'epukv(?<YY>d{2})(?<MM>d{2})',
            startDate: '2022-03-29T16:00:00',
            status: PROFILING_ERROR,
          },
          {
            id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_2022-10-05',
            name: '2022-10-05',
            pattern: 'epukv(?<YY>d{2})(?<MM>d{2})',
            regex: 'epukv(?<YY>d{2})(?<MM>d{2})',
            startDate: '2022-03-29T16:00:00',
            status: PROFILING_SUCCESS,
          },
        ],
      },
    ],
    id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_v6',
    name: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_v6',
    startDate: '2022-03-23T12:00:00',
    status: PROFILING_ERROR,
  },
  {
    cron: '0 0 16-23 * * *',
    datasetId: 'DsRicFlair1',
    frames: [
      {
        endDate: null,
        id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s58',
        name: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s58',
        pattern: 'epukv(?<YY>d{2})(?<MM>d{2})',
        regex: 'epukv(?<YY>d{2})(?<MM>d{2})',
        schema: [
          { dataType: 'STRING', name: 'product_name' },
          { dataType: 'STRING', name: 'sheet_name' },
          { dataType: 'STRING', name: 'grouping_name' },
          { dataType: 'STRING', name: 'grouping_subheader' },
          { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_start' },
          { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_end' },
          { dataType: 'STRING', name: 'description' },
          { dataType: 'STRING', name: 'descriptor_1' },
          { dataType: 'STRING', name: 'descriptor_2' },
          { dataType: 'FLOAT', name: 'value' },
          { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'reported_publish_date' },
          { dataType: 'STRING', name: 'kugou_super_vip_' },
          { dataType: 'STRING', name: 'kugou_vip_' },
          { dataType: 'STRING', name: 'kugou_live' },
          { dataType: 'STRING', name: 'kuwo_luxury_vip_' },
          { dataType: 'STRING', name: 'kuwo_music_pack_' },
          { dataType: 'STRING', name: 'kuwo_live' },
          { dataType: 'STRING', name: 'netease_music_pack_' },
          { dataType: 'STRING', name: 'netease_vinyl_vip_' },
          { dataType: 'STRING', name: 'qq_green_diamond_annual' },
          { dataType: 'STRING', name: 'qq_green_diamond_monthly' },
          { dataType: 'STRING', name: 'qq_paid_music_annual' },
          { dataType: 'STRING', name: 'qq_paid_music_monthly' },
          { dataType: 'STRING', name: 'wesing_ktv' },
          { dataType: 'STRING', name: 'wesing_live' },
        ],
        startDate: '2022-03-29T16:00:00',
        status: PROFILING_PENDING,
        versions: [
          {
            id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_latest',
            name: 'Latest schema',
            pattern: 'epukv(?<YY>d{2})(?<MM>d{2})',
            regex: 'epukv(?<YY>d{2})(?<MM>d{2})',
            startDate: '2022-03-29T16:00:00',
            status: PROFILING_SUCCESS,
          },
          {
            id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_2022-10-01',
            name: '2022-10-01',
            pattern: 'epukv(?<YY>d{2})(?<MM>d{2})',
            regex: 'epukv(?<YY>d{2})(?<MM>d{2})',
            startDate: '2022-03-29T16:00:00',
            status: PROFILING_PENDING,
          },
        ],
      },
    ],
    id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_v5',
    name: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_v5',
    startDate: '2022-03-23T12:00:00',
    status: PROFILING_PENDING,
  },
  {
    cron: '@once',
    datasetId: 'DsRicFlair2',
    frames: [
      {
        endDate: null,
        id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_v1_csv_v2',
        name: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_v1_csv_v2',
        pattern: 'epukv(?<YY>d{2})(?<MM>d{2})_v1',
        regex: 'epukv(?<YY>d{2})(?<MM>d{2})_v1',
        schema: [
          { dataType: 'STRING', name: 'product_name' },
          { dataType: 'STRING', name: 'sheet_name' },
          { dataType: 'STRING', name: 'grouping_name' },
          { dataType: 'STRING', name: 'grouping_subheader' },
          { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_start' },
          { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_end' },
          { dataType: 'STRING', name: 'description' },
          { dataType: 'STRING', name: 'descriptor_1' },
          { dataType: 'STRING', name: 'descriptor_2' },
          { dataType: 'FLOAT', name: 'value' },
          { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'reported_publish_date' },
          { dataType: 'STRING', name: 'kugou_super_vip_' },
          { dataType: 'STRING', name: 'kugou_vip_' },
          { dataType: 'STRING', name: 'kugou_live' },
          { dataType: 'STRING', name: 'kuwo_luxury_vip_' },
          { dataType: 'STRING', name: 'kuwo_music_pack_' },
          { dataType: 'STRING', name: 'kuwo_live' },
          { dataType: 'STRING', name: 'netease_music_pack_' },
          { dataType: 'STRING', name: 'netease_vinyl_vip_' },
          { dataType: 'STRING', name: 'qq_green_diamond_annual' },
          { dataType: 'STRING', name: 'qq_green_diamond_monthly' },
          { dataType: 'STRING', name: 'qq_paid_music_annual' },
          { dataType: 'STRING', name: 'qq_paid_music_monthly' },
          { dataType: 'STRING', name: 'wesing_ktv' },
          { dataType: 'STRING', name: 'wesing_live' },
        ],
        startDate: '2022-03-29T16:00:00',
        status: PROFILING_QUEUED,
        versions: [
          {
            id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_latest',
            name: 'Latest schema',
            pattern: 'epukv(?<YY>d{2})(?<MM>d{2})_v1',
            regex: 'epukv(?<YY>d{2})(?<MM>d{2})_v1',
            startDate: '2022-03-29T16:00:00',
            status: PROFILING_QUEUED,
          },
        ],
      },
    ],
    id: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_v1_v2',
    name: 'test_supplier_data_epra_nareit_uk_valuation_history_epukv_v1_v2',
    startDate: '2022-03-23T12:00:00',
    status: PROFILING_QUEUED,
  },
];
export const INFERRED_DATASETS = [
  {
    cron: '0 0 14 * * *',
    frames: [
      {
        datastoreId: 8824,
        id: '8824',
        name: 'crux_testing_llc_ssy_230413_4_products_becrs_trucostiddaily_trucostiddailychange_001',
      },
    ],
    id: 'Ds9-X-jcSK4n',
    name: 'ssy_0217_6_dssnpuc3_products_becrs_trucostiddaily_trucostiddailychange_001',
    selectedPatterns: [
      '/Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d-001.zip',
    ],
  },
  {
    cron: '0 0 14 * * *',
    frames: [
      {
        datastoreId: 8825,
        id: '8824',
        name: 'crux_testing_llc_ssy_230413_4_products_becrs_trucostiddaily_trucostiddailychange_001',
      },
    ],
    id: 'Ds9-X-jcSK4n',
    name: 'ssy_0217_6_dssnpuc3_products_becrs_trucostiddaily_trucostiddailychange_001',
    selectedPatterns: ['/Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChanged-001.zip'],
  },
];

export const INFERRED_DATASETS_SELECTED_PATTERNS = [
  '/Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d-001.zip',
  '/Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChanged-001.zip',
];
export const PROFILING_LOADER_STEPS = {
  creatingSchemas: {
    endDate: '2023-01-19T18:25:18.026422',
    info: 'Service completed appropriately',
    processedFiles: 8,
    sizeOfProcessedFiles: 184888,
    startDate: '2023-01-19T18:24:19.767326',
    status: 'COMPLETED',
    timeCompleted: 58.259096,
    totalFiles: 9,
    totalSizeOfFiles: 5845473,
  },
  downloading: {
    endDate: '2023-01-19T18:22:51.727646',
    info: 'Service completed appropriately',
    processedFiles: 7,
    sizeOfProcessedFiles: 5699166,
    startDate: '2023-01-19T18:22:42.386070',
    status: 'COMPLETED',
    timeCompleted: 9.341576,
    totalFiles: 7,
    totalSizeOfFiles: 5699166,
  },
  generatingDataPipelines: {
    endDate: null,
    info: null,
    startDate: null,
    status: 'STARTED',
    timeCompleted: null,
  },
  preparing: {
    endDate: '2023-01-19T18:22:40.843291',
    info: 'Service completed appropriately',
    processedFiles: 7,
    sizeOfProcessedFiles: 5699166,
    startDate: '2023-01-19T18:22:40.101538',
    status: 'COMPLETED',
    timeCompleted: 0.741753,
    totalFiles: 7,
    totalSizeOfFiles: 5699166,
  },
  profilingContents: {
    endDate: '2023-01-19T18:24:17.827299',
    info: 'Service completed appropriately',
    processedFiles: 9,
    sizeOfProcessedFiles: 5862745,
    startDate: '2023-01-19T18:23:10.979016',
    status: 'COMPLETED',
    timeCompleted: 66.848283,
    totalFiles: 9,
    totalSizeOfFiles: 5862745,
  },
};

export const FINISHED_PROFILING_LOADER_STEPS = {
  creatingSchemas: {
    endDate: '2023-01-19T18:25:18.026422',
    info: 'Service completed appropriately',
    processedFiles: 8,
    sizeOfProcessedFiles: 184888,
    startDate: '2023-01-19T18:24:19.767326',
    status: 'COMPLETED',
    timeCompleted: 58.259096,
    totalFiles: 9,
    totalSizeOfFiles: 5845473,
  },
  downloading: {
    endDate: '2023-01-19T18:22:51.727646',
    info: 'Service completed appropriately',
    processedFiles: 7,
    sizeOfProcessedFiles: 5699166,
    startDate: '2023-01-19T18:22:42.386070',
    status: 'COMPLETED',
    timeCompleted: 9.341576,
    totalFiles: 7,
    totalSizeOfFiles: 5699166,
  },
  generatingDataPipelines: {
    endDate: '2023-01-19T18:25:27.631214',
    info: 'Service completed appropriately',
    startDate: '2023-01-19T18:25:19.555473',
    status: 'COMPLETED',
    timeCompleted: 8.075741,
  },
  preparing: {
    endDate: '2023-01-19T18:22:40.843291',
    info: 'Service completed appropriately',
    processedFiles: 7,
    sizeOfProcessedFiles: 5699166,
    startDate: '2023-01-19T18:22:40.101538',
    status: 'COMPLETED',
    timeCompleted: 0.741753,
    totalFiles: 7,
    totalSizeOfFiles: 5699166,
  },
  profilingContents: {
    endDate: '2023-01-19T18:24:17.827299',
    info: 'Service completed appropriately',
    processedFiles: 9,
    sizeOfProcessedFiles: 5862745,
    startDate: '2023-01-19T18:23:10.979016',
    status: 'COMPLETED',
    timeCompleted: 66.848283,
    totalFiles: 9,
    totalSizeOfFiles: 5862745,
  },
};

export const DATA_PRODUCT = {
  assetClass: 'Financial',
  attributes: {
    containsCusipSedol: false,
    cruxCatalogListingOnly: false,
    displayNotes: 'notes',
    historyEnabled: false,
    sensitiveNotes: 'sensitive notes',
  },
  availability: 'Available',
  cduId: 'Cx00001',
  common: true,
  connectionId: 123,
  country: '',
  createdAt: '2022-04-25T12:56:58Z',
  createdBy: 'ric.flair@natureboy.com',
  dataCategory: 'NOT APPLICABLE',
  datasets: DATA_PRODUCT_DATASETS,
  datastoreId: 999,
  deliverAllRaw: false,
  description: 'This is a test data product',
  destinationIds: ['dest001', 'dest002'],
  frames: [
    {
      datastoreId: 54024,
      id: '54024',
      name: 'crux_testing_llc_rd_091024_1_integrate_fasttest',
    },
    {
      datastoreId: 54025,
      id: '54025',
      name: 'crux_testing_llc_rd_091024_1_integrate_fastertest',
    },
  ],
  id: 1,
  inferredDatasets: INFERRED_DATASETS,
  isDeleted: false,
  jiraKey: '',
  lastStep: 0,
  marketVertical: 'FINANCIAL',
  name: 'Product 001',
  productFamily: 'Crux',
  productStatus: 'Coming Soon',
  profilingStatus: {
    countFilesProfiled: 1,
    profileCompleted: false,
    profileFailed: false,
    profileStarted: true,
    status: PROFILING_PENDING,
    steps: PROFILING_LOADER_STEPS,
    totalFilesToProfile: 100,
  },
  region: 'Asia',
  sensitive: true,
  sourceType: 'Commercial',
  status: {
    state: TESTING,
    status: DRAFT,
  },
  subscriptionType: SUBSCRIPTION_TYPES.edp,
  supplier: {
    id: 456,
    name: 'Crux Integrate App for Dev',
  },
  type: 'Data Product',
  workflows: DATASETS,
};

export const DELETED_DATA_PRODUCT = {
  cduId: 'Cx00001',
  connectionId: 123,
  createdAt: '2022-04-25T12:56:58Z',
  createdBy: 'ric.flair@natureboy.com',
  datasets: DATA_PRODUCT_DATASETS,
  datastoreId: 999,
  deletedOn: '2022-04-26T12:56:58Z',
  deliverAllRaw: false,
  destinationIds: ['dest001', 'dest002'],
  id: 1,
  isDeleted: true,
  lastStep: 0,
  name: 'Product 001',
  profilingStatus: {
    countFilesProfiled: 1,
    profileCompleted: false,
    profileFailed: false,
    profileStarted: true,
    status: PROFILING_PENDING,
    steps: PROFILING_LOADER_STEPS,
    totalFilesToProfile: 100,
  },
  status: {
    state: TESTING,
    status: DRAFT,
  },
  supplier: {
    id: 456,
    name: 'Crux Integrate App for Dev',
  },
  workflows: DATASETS,
};

export const DATA_PRODUCTS = [
  {
    connectionId: 123,
    createdAt: '2022-04-25T12:56:58Z',
    createdBy: 'ric.flair@natureboy.com',
    datastoreId: 111,
    id: 1,
    lastStep: 0,
    name: 'Ric Flair Product',
    profilingStatus: {
      profileCompleted: false,
      profileStarted: true,
    },
    supplier: {
      id: 456,
      name: 'Crux Integrate App for Dev',
    },
  },
  {
    connectionId: 456,
    createdAt: '2022-04-25T12:56:58Z',
    createdBy: 'michael.scott@michaelscottpapercompany.com',
    datastoreId: 222,
    id: 2,
    lastStep: 0,
    name: 'Product 002',
    profilingStatus: {
      profileCompleted: false,
      profileStarted: true,
    },
    supplier: {
      id: 456,
      name: 'Crux Integrate App for Dev',
    },
  },
  {
    connectionId: 789,
    createdAt: '2022-04-25T12:56:58Z',
    createdBy: 'dwight.k.schrute@schrutefarms.com',
    datastoreId: 333,
    id: 3,
    lastStep: 0,
    name: 'Product 003',
    profilingStatus: {
      profileCompleted: false,
      profileStarted: true,
    },
    supplier: {
      id: 456,
      name: 'Crux Integrate App for Dev',
    },
  },
];

export const DATA_PRODUCTS_RESPONSE = {
  items: DATA_PRODUCTS,
  nextPageToken: '209dksfjaisdjoijwei2',
  previousPageToken: null,
};

export const UPDATE_DATA_PRODUCT_PAYLOAD = {
  dataProductId: 1,
  payload: {
    assetClass: 'Financial',
    attributes: {
      clientId: '123',
      containsCusipSedol: false,
      cruxCatalogListingOnly: false,
      dataHistoryFrom: '2022-04-25T12:56:58Z',
      dataHistoryNotes: 'history notes',
      dataHistoryTo: '2022-04-25T12:56:58Z',
      displayNotes: 'notes',
      historyEnabled: false,
      sensitiveNotes: 'sensitive notes',
    },
    availability: 'Available',
    common: true,
    country: '',
    dataCategory: 'NOT APPLICABLE',
    description: 'This is a test data product',
    jiraKey: '',
    marketVertical: 'FINANCIAL',
    name: 'Product 001',
    productFamily: 'Crux',
    productStatus: 'Coming Soon',
    region: 'Asia',
    sensitive: true,
    sourceType: 'Commercial',
    supplier: {
      id: 456,
      name: 'Crux Integrate App for Dev',
    },
    type: 'Data Product',
  },
};

export const DATA_PRODUCT_DEPLOYMENT_CHECKS = [
  {
    id: 'msci_inc_aee2_smd_aee2l_100_daily_asset_price',
  },
  {
    'All deliveries are unique check': {
      check_info: {},
      check_status: true,
    },
    'All expected labels are presented': {
      check_info: {},
      check_status: true,
    },
    'All inventory files have raw resource': {
      check_info: {},
      check_status: true,
    },
    'All resources has a unique filename/filesize across all of the deliveries': {
      check_info: {
        'Deliveries with not unique resources': [
          {
            delivery_ids: [
              '016b3cf508b05282e197824b3bd56be36748144e035f32ecee9c40020696229d.0',
              '7b448d19b32cc78f6a96a7a34dfc0f2b585992e6b1e4bfcf852e8048e108cad7.0',
            ],
            duplicate_filename: 'SMD_AEE2L_100_211109.zip',
            filesize: 1079951,
            metadata: 'raw_metadata',
          },
          {
            delivery_ids: [
              '016b3cf508b05282e197824b3bd56be36748144e035f32ecee9c40020696229d.0',
              '3eb12315a2d6723a72676952b286c0e9a43932d3620c6db540a68dd3a71a44e6.0',
            ],
            duplicate_filename: 'SMD_AEE2L_100_210908.zip',
            filesize: 1069341,
            metadata: 'raw_metadata',
          },
        ],
      },
      check_status: false,
    },
    'All schedule periods have the expected number of deliveries': {
      check_info: {
        'There are no deliveries in period': [
          {
            period_end: '2021-11-11T11:00:00',
            period_start: '2021-11-11T10:00:00',
          },
          {
            period_end: '2021-11-12T06:00:00',
            period_start: '2021-11-11T11:00:00',
          },
        ],
      },
      check_status: true,
      soft_fail: true,
    },
    'Check delta resouces exist for raw': {
      check_info: {},
      check_status: true,
    },
    'Delta files has non empty string raw resource id': {
      check_info: {},
      check_status: true,
    },
    'Difference between dates': {
      check_info: {},
      check_status: true,
    },
    'Match the number of extracted resources with the number of processed resources': {
      check_info: {
        'Deliveries with mismatched delta and raw resource number': [
          {
            actual_avro_num: 3,
            actual_csv_num: 3,
            actual_parquet_num: 3,
            delivery_id: 'edce6e3035d3822955990da4cd6a1172b111f2e73c5e09ade001b3d359677233.0',
            expected_delta_num: 1,
            raw_resource_id: 'EQMDsT7aqxV211118d1127714b',
          },
          {
            actual_avro_num: 3,
            actual_csv_num: 3,
            actual_parquet_num: 3,
            delivery_id: 'edce6e3035d3822955990da4cd6a1172b111f2e73c5e09ade001b3d359677233.0',
            expected_delta_num: 1,
            raw_resource_id: 'EQMDsT7aqxV211118d724012ea',
          },
          {
            actual_avro_num: 3,
            actual_csv_num: 3,
            actual_parquet_num: 3,
            delivery_id: 'edce6e3035d3822955990da4cd6a1172b111f2e73c5e09ade001b3d359677233.0',
            expected_delta_num: 1,
            raw_resource_id: 'EQMDsT7aqxV211118dadc13137',
          },
        ],
      },
      check_status: false,
    },
    'Successful deliveries number check': {
      'SIDT is set correctly': {
        check_info: {},
        check_status: true,
      },
      check_info: {
        'Missed deliveries': ['2021-11-11T10:00:00Z', '2021-11-11T11:00:00Z'],
        'Missed deliveries intraday': ['2021-11-11T10:00:00Z', '2021-11-11T11:00:00Z'],
      },
      check_status: true,
      soft_fail: true,
    },
    id: 'msci_inc_aee2_smd_aee2l_100_asset_exposure',
  },
  {
    id: 'msci_inc_aee2_smd_aee2l_100_covariance',
  },
  {
    id: 'msci_inc_aee2_smd_aee2l_100_asset_dlyspecret',
  },
];

export const DATA_PRODUCT_ID = 167;

export const DATA_PRODUCT_STATUS = {
  profilingStatus: {
    profileCompleted: false,
    profileStarted: false,
  },
  status: 'draft',
  workflowIds: ['workflowOne', 'workflowTwo', 'workflowThree'],
};

export const FILE_PATTERNS = [
  {
    endDate: '2022-03-23T10:31:17',
    identifier: 'analytics_us/ytd/%Y%m%d_analytics_v2.cs',
    startDate: '2022-03-02T11:31:19',
  },
];

export const DAYS_OF_WEEK = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

export const HEATMAP_TIMES = ['12am', '2am', '4am', '6am', '8am', '10am', '12pm', '2pm', '4pm', '6pm', '8pm', '10pm'];

export const FILE_SCHEDULE_MAP = {
  data: {
    0: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
    },
    1: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
    },
    2: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
    },
    3: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
    },
    4: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
    },
    5: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
    },
    6: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
    },
  },
  xAxis: HEATMAP_TIMES,
  yAxis: DAYS_OF_WEEK,
};

export const FRAME = {
  endDate: '',
  id: '44a95578-7133-4d2a-9365-a5a3d4e5adf3',
  name: 'Frame 2',
  pattern: 'AUS_Asset_Identity.220118',
  regex: '(?<=AUS_Asset_Identity.)([0-9]{2})([0-9]{2})([0-9]{2})',
  schema: [
    {
      dataType: 'INTEGER',
      name: 'field_0',
    },
    {
      dataType: 'STRING',
      name: 'field_1',
    },
    {
      dataType: 'STRING',
      name: 'field_2',
    },
    {
      dataType: 'INTEGER',
      name: 'field_3',
    },
    {
      dataType: 'STRING',
      name: 'field_4',
    },
    {
      dataType: 'STRING',
      name: 'field_5',
    },
  ],
  startDate: '2022-01-23T18:21:24.933Z',
  totalFiles: 25,
  totalSizeOfFiles: 1048576,
};

export const PATTERNS = [
  {
    filenamePattern: 'ssy-test-dev/%Y-%m-%d/17:37:%m.%y6892Z/crux_onboarding--testDataset--testResource.txt',
    filenameRegex: 'ssy-test-dev/dddd-dd-dd/17:37:dd.dd6892Z/crux_onboarding--testDataset--testResource.txt',
    files: [
      {
        name: 'crux_onboarding--testDataset--testResource.txt',
        path: 'ssy-test-dev/2022-07-01/17:37:06.906892Z/crux_onboarding--testDataset--testResource.txt',
        size: 230,
        type: 'file',
        updatedAt: '2022-07-01T17:37:08+00:00',
        walkedAt: null,
      },
    ],
    totalArchivesOverSizeLimit: 0,
    totalFiles: 1,
    totalFilesOverSizeLimit: 0,
    totalSizeOfFiles: 230,
  },
  {
    filenamePattern: 'ssy-test-dev/%Y-%m-%d/17:44:37.8%y%m1Z/crux_onboarding--testDataset--testResource.txt',
    filenameRegex: 'ssy-test-dev/dddd-dd-dd/17:44:37.8dddd1Z/crux_onboarding--testDataset--testResource.txt',
    files: [
      {
        name: 'crux_onboarding--testDataset--testResource.txt',
        path: 'ssy-test-dev/2022-07-01/17:44:37.830081Z/crux_onboarding--testDataset--testResource.txt',
        size: 230,
        type: 'file',
        updatedAt: '2022-07-01T17:44:39+00:00',
        walkedAt: null,
      },
    ],
    totalArchivesOverSizeLimit: 0,
    totalFiles: 1,
    totalFilesOverSizeLimit: 0,
    totalSizeOfFiles: 460,
  },
];

export const RESOURCES = [
  {
    createdAt: '2021-04-18T11:13:31.969Z',
    id: '0a3e0fbe-0331-4107-8f1a-79bc045ac285',
    listing: [
      {
        createdAt: '2021-06-25T09:17:07.071Z',
        id: '8cf9c60b-e26c-4f06-a00a-0e03acb6b6f1',
        listing: [
          {
            createdAt: '2021-09-16T09:28:56.880Z',
            id: '8c4adc18-9f49-4d20-936b-ef9191571061',
            listing: [
              {
                createdAt: '2021-11-14T13:44:44.219Z',
                id: 'c6a0ce59-ca87-4ce3-8a36-39939e9d22ce',
                listing: [],
                name: 'pants_agp.m1v',
                size: null,
              },
            ],
            name: 'cliff.html',
            size: null,
          },
          {
            createdAt: '2021-12-31T18:21:24.933Z',
            id: '460668f8-4e47-459c-862d-cacc51cf59e5',
            listing: [
              {
                createdAt: '2022-01-31T13:38:39.438Z',
                id: '1cf439ee-4a5d-4499-9244-b0bc88d5f9c2',
                listing: [],
                name: 'oklahoma.m1v',
                size: null,
              },
              {
                createdAt: '2021-03-16T16:44:17.758Z',
                id: '055f5f48-3eca-4f7a-b3fd-840d96118c90',
                listing: [],
                name: 'creek_local_systemic.wav',
                size: null,
              },
            ],
            name: 'tools.shtml',
            size: null,
          },
          {
            createdAt: '2021-12-24T20:11:05.308Z',
            id: 'd8b91a72-0203-47af-8e7f-55e86eb03bd7',
            listing: [
              {
                createdAt: '2021-10-11T00:18:44.716Z',
                id: 'd426102b-f0bb-4c72-b71a-46323de4ebce',
                listing: [],
                name: 'steel.m2a',
                size: null,
              },
            ],
            name: 'web_readiness.shtml',
            size: null,
          },
          {
            createdAt: '2021-06-17T16:14:53.122Z',
            id: '3555f064-0f34-4bfa-8f19-66ca648ab967',
            listing: [
              {
                createdAt: '2021-11-09T02:44:09.346Z',
                id: '0ec0151a-05fe-4e7f-a081-5da41e4219b2',
                listing: [],
                name: 'purple_backing_pound.gif',
                size: null,
              },
            ],
            name: 'villages.wav',
            size: null,
          },
        ],
        name: 'shoes_balboa_chief.mp2a',
        size: null,
      },
      {
        createdAt: '2021-09-05T04:22:44.791Z',
        id: '6d7bf968-f1a0-4ea7-8f36-015b932b2d47',
        listing: [
          {
            createdAt: '2021-12-23T08:31:21.360Z',
            id: '5d447233-1318-4342-a453-12dd97626b7c',
            listing: [
              {
                createdAt: '2021-04-08T18:05:52.252Z',
                id: '95e49b46-3c10-4db1-81bc-d53af15dafe4',
                listing: [],
                name: 'branch.png',
                size: null,
              },
              {
                createdAt: '2021-11-17T10:51:35.810Z',
                id: '7e942fec-fe05-4eed-9ad7-dd0ba7a66d02',
                listing: [],
                name: 'toys_withdrawal.pdf',
                size: null,
              },
            ],
            name: 'minnesota.mpg4',
            size: null,
          },
          {
            createdAt: '2021-07-10T04:25:25.017Z',
            id: 'da7eab3b-5073-4eb8-82b9-5eaa0711423b',
            listing: [
              {
                createdAt: '2021-11-22T04:23:06.943Z',
                id: '81a4c3ef-8561-406c-9d74-508719874db3',
                listing: [],
                name: 'ergonomic_ability_sms.shtml',
                size: null,
              },
              {
                createdAt: '2022-01-30T06:53:32.049Z',
                id: '343cc63e-25e8-4ea8-9ae6-40eb9286b09c',
                listing: [],
                name: 'strategist_chair.gif',
                size: null,
              },
              {
                createdAt: '2021-03-16T22:10:04.902Z',
                id: '53adf2a1-0c2f-457a-aa97-42dca2b1ffea',
                listing: [],
                name: 'sports_small_hat.wav',
                size: null,
              },
              {
                createdAt: '2021-09-30T00:31:52.193Z',
                id: '46965de8-24b3-4c52-8d5a-d6d52a9ffbd6',
                listing: [],
                name: 'account_visionary_fuchsia.mpg',
                size: null,
              },
              {
                createdAt: '2021-05-16T21:57:08.607Z',
                id: '38db9d91-d5fe-491f-9439-c62190954599',
                listing: [],
                name: 'well_modulated_awesome.gif',
                size: null,
              },
            ],
            name: 'ruble_capacitor.wav',
            size: null,
          },
          {
            createdAt: '2021-06-11T18:15:26.331Z',
            id: '59dc3845-c1dd-48eb-bcae-abe19c04a9c5',
            listing: [
              {
                createdAt: '2021-03-11T15:33:25.870Z',
                id: '555e1e12-c44e-48af-a78e-2e143c862f57',
                listing: [],
                name: 'intelligent_computers_bedfordshire.mp2a',
                size: null,
              },
              {
                createdAt: '2021-11-30T06:39:17.464Z',
                id: 'fb4d3be0-72fd-4573-ae75-0a43b992f321',
                listing: [],
                name: 'facilitator.wav',
                size: null,
              },
              {
                createdAt: '2021-08-06T18:09:16.400Z',
                id: '32db1476-bb37-4ae1-b5cb-8c0f485cc470',
                listing: [],
                name: 'mississippi_adp_incredible.mp4v',
                size: null,
              },
            ],
            name: 'lake_central_array.pdf',
            size: null,
          },
          {
            createdAt: '2021-10-15T05:47:27.439Z',
            id: '4b842821-1cd8-409a-b17d-27f444cc6d58',
            listing: [
              {
                createdAt: '2021-12-01T09:02:17.612Z',
                id: 'c30ce63f-5a5d-4016-bc75-ca51aac7236b',
                listing: [],
                name: 'gloves_front_line.gif',
                size: null,
              },
              {
                createdAt: '2021-05-29T01:18:16.960Z',
                id: '642244aa-c05e-49f0-b6d0-85548f9813c1',
                listing: [],
                name: 'unit.mp4',
                size: null,
              },
              {
                createdAt: '2021-11-20T17:36:54.775Z',
                id: '75c1cd54-394e-42db-87f2-68fc1dcc4bb0',
                listing: [],
                name: 'connect.shtml',
                size: null,
              },
              {
                createdAt: '2021-12-30T20:10:29.669Z',
                id: '874859cd-9c34-4604-bedd-ee2e1ec76d48',
                listing: [],
                name: 'monitor_cambridgeshire.htm',
                size: null,
              },
              {
                createdAt: '2021-10-22T19:31:39.455Z',
                id: 'e371cfff-6932-4224-beca-2c17a3239213',
                listing: [],
                name: 'solutions.wav',
                size: null,
              },
            ],
            name: 'css_rss.jpg',
            size: null,
          },
        ],
        name: 'accounts_core_wooden.html',
        size: null,
      },
      {
        createdAt: '2021-04-26T18:50:09.470Z',
        id: '477a8336-5456-4562-839d-6c76fd94deb2',
        listing: [
          {
            createdAt: '2022-01-07T11:06:02.427Z',
            id: 'f947bafd-ec83-491d-b2d5-ac56d56c4ced',
            listing: [
              {
                createdAt: '2021-02-23T16:04:21.985Z',
                id: '04d1e410-0baf-4337-b393-94aeeb08ce17',
                listing: [],
                name: 'hack_ram.wav',
                size: null,
              },
              {
                createdAt: '2021-06-09T06:08:22.770Z',
                id: '1ffdf19b-2411-4314-98b7-de4df4335118',
                listing: [],
                name: 'internal.png',
                size: null,
              },
              {
                createdAt: '2021-08-16T01:58:56.942Z',
                id: 'c938f309-7c0e-4cf2-862a-4a30a37078f7',
                listing: [],
                name: 'incentivize.mpga',
                size: null,
              },
              {
                createdAt: '2021-07-09T07:24:30.512Z',
                id: 'e2eb0e87-ce1d-4644-8cf9-64878bd01896',
                listing: [],
                name: 'zero.mp2',
                size: null,
              },
              {
                createdAt: '2021-10-02T04:14:57.567Z',
                id: 'd678db52-e90b-4bc7-b3cb-ac6d6e9d1cf6',
                listing: [],
                name: 'handmade_homogeneous_flats.png',
                size: null,
              },
            ],
            name: 'guarani_ball_barbados.html',
            size: null,
          },
          {
            createdAt: '2021-08-08T11:19:44.208Z',
            id: 'c5c4a7de-d495-4b9c-b754-c483e3c7568a',
            listing: [
              {
                createdAt: '2022-02-07T09:51:51.290Z',
                id: 'ea219be7-2591-49e9-a48c-7915d5b3cb69',
                listing: [],
                name: 'pants_ball_gloves.wav',
                size: null,
              },
              {
                createdAt: '2021-07-08T08:51:56.763Z',
                id: '51636503-b37e-42a9-959b-00d26a30f586',
                listing: [],
                name: 'applications_metal_loan.png',
                size: null,
              },
              {
                createdAt: '2021-09-10T10:56:37.560Z',
                id: '028a7f23-14e6-4bf7-af3d-3c50e14b8293',
                listing: [],
                name: 'multi_lateral_compressing_compress.gif',
                size: null,
              },
            ],
            name: 'seychelles_skyway.png',
            size: null,
          },
          {
            createdAt: '2021-12-03T03:03:16.800Z',
            id: 'abad86f2-2759-450a-ad33-1082e26340e6',
            listing: [
              {
                createdAt: '2021-09-15T06:50:32.761Z',
                id: '4de56325-a85e-4a36-8663-da1e62b47727',
                listing: [],
                name: 'mindshare_avon.mp4v',
                size: null,
              },
              {
                createdAt: '2021-07-06T01:13:43.366Z',
                id: '58e28439-339a-4966-a787-d1962dc6dccd',
                listing: [],
                name: 'kentucky.png',
                size: null,
              },
              {
                createdAt: '2021-11-29T11:44:10.271Z',
                id: '981f8309-d2a9-403e-8ff6-2851d5597001',
                listing: [],
                name: 'green_health_web.jpg',
                size: null,
              },
              {
                createdAt: '2021-06-23T21:30:19.513Z',
                id: '710aa16e-04b3-483b-a7b7-c0d986e82ee2',
                listing: [],
                name: 'calculating_empower.jpeg',
                size: null,
              },
            ],
            name: 'up_automotive.mp2a',
            size: null,
          },
          {
            createdAt: '2021-10-07T09:44:27.499Z',
            id: 'c83c55de-d0ad-4057-88e9-a79719bac4ac',
            listing: [
              {
                createdAt: '2021-08-26T10:43:14.662Z',
                id: '2e42bf5d-17cf-4591-88f7-49b22494a234',
                listing: [],
                name: 'bahamas_bluetooth.jpe',
                size: null,
              },
              {
                createdAt: '2021-04-30T22:25:07.815Z',
                id: '18f16eab-6bb4-4e83-9b77-9d11524b5187',
                listing: [],
                name: 'vortals_architectures.wav',
                size: null,
              },
              {
                createdAt: '2021-08-01T11:03:36.322Z',
                id: '2f95fb25-bc5a-4433-81bc-6f2313fbe71a',
                listing: [],
                name: 'platinum_adapter_valley.gif',
                size: null,
              },
            ],
            name: 'el.mp3',
            size: null,
          },
          {
            createdAt: '2021-05-13T01:01:49.805Z',
            id: '8a60efda-049c-4b95-bdd3-e000112bfa55',
            listing: [
              {
                createdAt: '2021-06-24T13:14:09.204Z',
                id: '4049e3a5-703e-4227-8c4c-22f0e786eedd',
                listing: [],
                name: 'buckinghamshire_bandwidth.mp4',
                size: null,
              },
            ],
            name: 'forward_array.gif',
            size: null,
          },
        ],
        name: 'deposit_cross_group_compress.htm',
        size: null,
      },
      {
        createdAt: '2021-10-16T14:50:12.316Z',
        id: 'e925d93f-8c6d-4d94-8c47-47941b605f6e',
        listing: [
          {
            createdAt: '2021-11-03T16:23:10.258Z',
            id: '553285fa-3add-4b6c-8c70-d2afd1d7216c',
            listing: [
              {
                createdAt: '2021-03-14T10:17:43.625Z',
                id: '6b8df553-27e5-4b1e-8348-c71587a3eb9b',
                listing: [],
                name: 'garden_total_horizontal.pdf',
                size: null,
              },
              {
                createdAt: '2021-12-10T20:25:25.545Z',
                id: '6a996886-4564-411c-aebf-a9a673616cd7',
                listing: [],
                name: 'viral_solution_andorra.m3a',
                size: null,
              },
              {
                createdAt: '2021-03-22T23:11:40.142Z',
                id: 'c4e7b2ab-6bc9-49e4-85db-0de2145e86d3',
                listing: [],
                name: 'administrator.jpeg',
                size: null,
              },
            ],
            name: 'strategist_hawaii.mpg4',
            size: null,
          },
        ],
        name: 'chile.jpe',
        size: null,
      },
      {
        createdAt: '2021-11-04T23:37:58.708Z',
        id: 'c7ba8ee0-2858-46cf-9ff2-c6820cc3291c',
        listing: [
          {
            createdAt: '2021-08-01T02:01:13.256Z',
            id: '5cbf93c6-6ca7-4fd5-b2dd-2837aed81925',
            listing: [
              {
                createdAt: '2021-04-25T11:48:38.528Z',
                id: '86d33188-cce3-4224-91d2-4d1fd29e5fec',
                listing: [],
                name: 'yellow.html',
                size: null,
              },
            ],
            name: 'sdd_investment_loop.gif',
            size: null,
          },
        ],
        name: 'instruction_chips_deposit.gif',
        size: null,
      },
    ],
    name: 'computer_array.png',
    size: null,
  },
  {
    createdAt: '2021-04-21T17:34:28.374Z',
    id: '6ede2056-0deb-4c04-9181-39895f50d854',
    listing: [
      {
        createdAt: '2021-09-21T16:15:55.702Z',
        id: '6f3b28a1-f905-4aff-b045-55271fe9d6d7',
        listing: [
          {
            createdAt: '2021-10-31T10:20:01.003Z',
            id: '9fce2770-c6a4-42a2-8a8c-bdccee022aec',
            listing: [
              {
                createdAt: '2021-09-23T16:47:54.981Z',
                id: '7807523c-c758-4e8e-9586-7bc915bf7cfa',
                listing: [],
                name: '1080p.wav',
                size: null,
              },
            ],
            name: 'deposit_exuding.mpg4',
            size: null,
          },
          {
            createdAt: '2021-07-30T10:39:22.651Z',
            id: '9eb84de5-d1db-4a58-857f-71ab3651bc2f',
            listing: [
              {
                createdAt: '2022-01-15T00:36:39.565Z',
                id: '51d73931-f896-49f0-bcfe-2560c733750f',
                listing: [],
                name: 'up_pink.gif',
                size: null,
              },
              {
                createdAt: '2021-07-24T02:04:02.734Z',
                id: '8c6acbec-03bf-4ad0-a708-dc2f7160ad18',
                listing: [],
                name: 'input.jpg',
                size: null,
              },
              {
                createdAt: '2021-04-19T08:01:07.508Z',
                id: '822c4cc4-ba51-45b7-aebc-a31c0950ebf0',
                listing: [],
                name: 'nebraska_monitoring_nevada.shtml',
                size: null,
              },
            ],
            name: 'capacity_capacitor_books.m1v',
            size: null,
          },
          {
            createdAt: '2021-06-29T11:24:25.877Z',
            id: 'ac523d8e-7794-4b5b-9240-5e9ec9a3edd9',
            listing: [
              {
                createdAt: '2021-09-12T09:17:20.372Z',
                id: 'aea7964f-99eb-4d73-916d-dccb43935382',
                listing: [],
                name: 'incubate_withdrawal_practical.jpg',
                size: null,
              },
              {
                createdAt: '2021-03-20T10:18:46.549Z',
                id: 'd652c771-3ebc-448f-b4f4-67fcc5aabf76',
                listing: [],
                name: 'metal_moldovan.pdf',
                size: null,
              },
              {
                createdAt: '2022-01-30T11:31:23.118Z',
                id: '7f0bd5c7-ca9c-4bac-b2b1-caba2ea80c2c',
                listing: [],
                name: 'lead.png',
                size: null,
              },
            ],
            name: 'director_optimize.jpe',
            size: null,
          },
          {
            createdAt: '2021-11-25T17:40:35.404Z',
            id: '6093e478-cc57-49c3-90b3-261a712263dc',
            listing: [
              {
                createdAt: '2021-11-20T16:35:17.186Z',
                id: 'f1f81ef4-dafe-433c-a5c3-f6071d9c3b6c',
                listing: [],
                name: 'teal_computer.mpe',
                size: null,
              },
              {
                createdAt: '2021-08-21T02:12:17.888Z',
                id: 'a1ef08fe-970f-4add-9219-6a06b7282c18',
                listing: [],
                name: 'index_platforms_re_engineered.mp4v',
                size: null,
              },
              {
                createdAt: '2021-11-10T17:58:28.539Z',
                id: '86f02060-3ef0-41ac-aa34-8b9f5aaf5f20',
                listing: [],
                name: 'ram_road.png',
                size: null,
              },
            ],
            name: 'keyboard_jewelery.htm',
            size: null,
          },
          {
            createdAt: '2021-10-21T04:28:32.807Z',
            id: '732aa973-03bc-4709-abdb-a51a3362b0cc',
            listing: [
              {
                createdAt: '2021-10-13T15:50:40.202Z',
                id: '8233c1fa-0850-43b9-9f1b-3705bce28d26',
                listing: [],
                name: 'panel_games.mpga',
                size: null,
              },
              {
                createdAt: '2021-06-20T10:11:06.858Z',
                id: '4e456d3f-11f7-44c6-a014-ffd5116ef130',
                listing: [],
                name: 'indexing_union_group.wav',
                size: null,
              },
              {
                createdAt: '2021-04-07T15:31:39.092Z',
                id: '1c812f89-eef7-4f63-9d8b-d7d524c22d24',
                listing: [],
                name: 'deliver.gif',
                size: null,
              },
            ],
            name: 'systems_back_end.wav',
            size: null,
          },
        ],
        name: 'synthesize.pdf',
        size: null,
      },
      {
        createdAt: '2021-08-28T15:02:48.771Z',
        id: '9212fcb6-95c7-4eaf-afe9-4ae7325ff573',
        listing: [
          {
            createdAt: '2021-12-04T03:19:19.365Z',
            id: '3f46ce45-5fce-43d8-a5ac-d95c703a2f21',
            listing: [
              {
                createdAt: '2022-02-16T15:13:42.568Z',
                id: 'b008b3ec-611d-4fd3-85d8-119fbd6a5cb3',
                listing: [],
                name: 'metrics_loan_loan.pdf',
                size: null,
              },
              {
                createdAt: '2021-07-27T21:15:15.974Z',
                id: 'ca5f4176-fc14-45b5-bab5-0e662569f1cb',
                listing: [],
                name: 'human_resource_human_cross_platform.mp4',
                size: null,
              },
              {
                createdAt: '2021-07-17T04:50:07.877Z',
                id: 'dd19b687-2ae8-4873-b45f-a5ffdabb75da',
                listing: [],
                name: 'ball.htm',
                size: null,
              },
              {
                createdAt: '2021-12-23T07:27:46.875Z',
                id: 'cde40e7a-912e-41c5-b77b-5f611aa858ab',
                listing: [],
                name: 'auxiliary_profit_focused_proactive.gif',
                size: null,
              },
              {
                createdAt: '2021-08-20T00:57:30.192Z',
                id: '7d2eb4f4-db88-4c77-93ef-f31bc0b7a498',
                listing: [],
                name: 'feed_taka_computers.htm',
                size: null,
              },
            ],
            name: 'array_liaison_knowledge.wav',
            size: null,
          },
          {
            createdAt: '2021-09-29T04:52:07.544Z',
            id: 'e5d60bfd-075e-4cb7-b8a3-b84bbe10adbb',
            listing: [
              {
                createdAt: '2021-02-27T09:13:32.193Z',
                id: '667ec960-2d30-46b9-9b3f-d2a2005bae9f',
                listing: [],
                name: 'rustic.pdf',
                size: null,
              },
              {
                createdAt: '2022-02-13T13:27:15.252Z',
                id: 'e14a5cbb-6ad1-4130-bd69-0894d3217886',
                listing: [],
                name: 'transmit_dirham_security.png',
                size: null,
              },
            ],
            name: 'checking.mp4',
            size: null,
          },
          {
            createdAt: '2021-08-05T00:21:13.015Z',
            id: '79a97fa6-5dfc-4ccd-b66f-fa6f8fe5ba43',
            listing: [
              {
                createdAt: '2021-09-28T22:04:40.737Z',
                id: '2b1c4e3f-f686-4f39-8526-5127ceca97bf',
                listing: [],
                name: 'district_mayotte.html',
                size: null,
              },
              {
                createdAt: '2021-05-02T23:51:25.742Z',
                id: '1b2d2501-8627-4289-aa07-2b4cde939874',
                listing: [],
                name: 'incredible_multi_byte_wireless.wav',
                size: null,
              },
            ],
            name: 'bluetooth_superstructure_pennsylvania.m2v',
            size: null,
          },
        ],
        name: 'ohio_schemas.png',
        size: null,
      },
      {
        createdAt: '2022-01-21T02:15:30.259Z',
        id: '51234195-80dd-4178-a9cd-5324c5dd03e6',
        listing: [
          {
            createdAt: '2021-12-29T02:28:57.230Z',
            id: '9494c550-f1da-4e51-93a5-89c68a7dfb86',
            listing: [
              {
                createdAt: '2021-08-15T16:26:30.566Z',
                id: '6118355c-0b32-4003-8ffc-32ef1de1868c',
                listing: [],
                name: 'granite_monitor_configuration.mpg4',
                size: null,
              },
              {
                createdAt: '2021-07-10T21:58:15.204Z',
                id: '13c71182-8a3b-4934-89a8-aa605ef6de6e',
                listing: [],
                name: 'recontextualize_intranet_line.m1v',
                size: null,
              },
              {
                createdAt: '2021-08-06T10:38:32.763Z',
                id: '056a63d9-591b-403d-9b77-d40b08db1226',
                listing: [],
                name: 'coordinator.mp4v',
                size: null,
              },
              {
                createdAt: '2022-02-16T16:56:08.241Z',
                id: 'e9ed8f81-3658-4036-b71a-1c9c442343d7',
                listing: [],
                name: 'tuna_liaison_extensions.gif',
                size: null,
              },
              {
                createdAt: '2021-09-17T05:57:57.395Z',
                id: '473a04d6-fed9-4204-bfdd-020d9da65c64',
                listing: [],
                name: 'accountability_red_vista.m2v',
                size: null,
              },
            ],
            name: 'guernsey_navigate_grocery.gif',
            size: null,
          },
          {
            createdAt: '2021-05-26T08:04:41.304Z',
            id: '83988492-6c3a-47cf-8b63-f6911bb5840f',
            listing: [
              {
                createdAt: '2021-12-22T10:51:58.257Z',
                id: '1942afc8-3227-44aa-930f-0642976a57e3',
                listing: [],
                name: 'reunion.html',
                size: null,
              },
              {
                createdAt: '2021-10-18T17:40:06.620Z',
                id: 'e13f3987-dd77-425d-bc26-19902c788797',
                listing: [],
                name: 'central.gif',
                size: null,
              },
            ],
            name: 'circuit.wav',
            size: null,
          },
          {
            createdAt: '2021-07-01T23:09:23.066Z',
            id: 'e9c06b7f-6feb-4444-9a0f-9a81c190e586',
            listing: [
              {
                createdAt: '2021-04-10T20:01:25.971Z',
                id: '112f06e2-0be8-4975-89c2-8c3a883a4304',
                listing: [],
                name: 'washington_automotive_metrics.jpg',
                size: null,
              },
            ],
            name: 'payment_parsing_steel.jpg',
            size: null,
          },
          {
            createdAt: '2021-12-03T16:53:21.049Z',
            id: '82fe9684-e302-406f-975b-d84a51627ccd',
            listing: [
              {
                createdAt: '2022-01-22T16:32:45.697Z',
                id: '43c8d042-5d7c-482f-b938-6155574303b0',
                listing: [],
                name: 'computer.png',
                size: null,
              },
              {
                createdAt: '2021-03-29T20:38:06.360Z',
                id: '3ebd4d8f-ee05-449c-98ca-d7022ff8b64c',
                listing: [],
                name: 'wyoming_market.jpg',
                size: null,
              },
            ],
            name: 'override_lebanon_account.png',
            size: null,
          },
        ],
        name: 'stravenue_alarm_payment.mpga',
        size: null,
      },
      {
        createdAt: '2021-12-21T16:47:45.675Z',
        id: 'd092517e-a04e-4bde-a3b1-6a2f7466b3d0',
        listing: [
          {
            createdAt: '2021-06-29T22:29:10.341Z',
            id: '5c724e97-eb6a-4bbe-bab6-d457383cd7ee',
            listing: [
              {
                createdAt: '2021-04-28T16:31:51.255Z',
                id: '3e9372f9-a6cd-40ca-a101-719fca667b26',
                listing: [],
                name: 'teal_robust_strategize.mpeg',
                size: null,
              },
              {
                createdAt: '2021-06-19T23:21:55.382Z',
                id: '46025c11-f753-4638-a606-509730cbf2df',
                listing: [],
                name: 'shilling_cross_platform.m2a',
                size: null,
              },
              {
                createdAt: '2021-05-24T03:20:23.638Z',
                id: '930c597b-a258-4a6a-b0d9-73c0c0a88f2f',
                listing: [],
                name: 'soft_tcp_virtual.mp3',
                size: null,
              },
              {
                createdAt: '2021-04-01T06:49:35.276Z',
                id: 'a2d2b5bd-9dc1-4bfc-a706-124da435a298',
                listing: [],
                name: 'island_executive.png',
                size: null,
              },
              {
                createdAt: '2022-02-16T01:57:26.444Z',
                id: '5644c38d-bfc7-44ed-8806-cc5a507c82ea',
                listing: [],
                name: 'harness_area_virginia.png',
                size: null,
              },
            ],
            name: 'policy_unbranded_calculate.gif',
            size: null,
          },
          {
            createdAt: '2021-09-01T20:59:31.477Z',
            id: '66e85041-8d37-4124-98d4-643cd0c7b2eb',
            listing: [
              {
                createdAt: '2021-05-27T15:49:22.368Z',
                id: 'cc8e2b0f-31e7-42da-b8b7-457b285c988e',
                listing: [],
                name: 'rufiyaa_deposit_wireless.png',
                size: null,
              },
              {
                createdAt: '2021-12-07T14:30:38.009Z',
                id: '63d58ffc-5716-4643-9627-70fbda13e031',
                listing: [],
                name: 'applications_silver.mpeg',
                size: null,
              },
              {
                createdAt: '2021-12-25T14:22:27.874Z',
                id: '137fd15c-ba55-4dd0-a44a-00f9d2978365',
                listing: [],
                name: 'belize_deposit_input.pdf',
                size: null,
              },
              {
                createdAt: '2021-04-01T00:53:01.346Z',
                id: '721157f4-0245-441a-8d85-2f98171cfacb',
                listing: [],
                name: 'transmitting.mpga',
                size: null,
              },
              {
                createdAt: '2021-04-12T13:00:46.541Z',
                id: '963a286b-6c98-4d46-8ec3-994b609f512e',
                listing: [],
                name: 'surinam_practical.png',
                size: null,
              },
            ],
            name: 'moldova.pdf',
            size: null,
          },
          {
            createdAt: '2021-08-22T02:15:10.866Z',
            id: '907f0e10-2efd-4a4e-8376-d16a7d74fbda',
            listing: [
              {
                createdAt: '2021-12-09T17:20:57.568Z',
                id: '2e82f5f2-b6e4-49ea-9824-26c654d89c9f',
                listing: [],
                name: 'hard_firmware_synthesizing.jpeg',
                size: null,
              },
              {
                createdAt: '2021-04-11T23:32:41.721Z',
                id: '7c17d057-b406-461c-af34-de184076f68d',
                listing: [],
                name: 'soft_gorgeous.pdf',
                size: null,
              },
            ],
            name: 'cheese.jpeg',
            size: null,
          },
          {
            createdAt: '2021-06-22T13:19:07.298Z',
            id: '63fd440d-15f0-4c28-96f3-21c99e5a1dd8',
            listing: [
              {
                createdAt: '2021-12-11T03:29:13.836Z',
                id: 'fbec64a7-3719-4fcd-88a7-b160efa9d653',
                listing: [],
                name: 'tools_soft_dalasi.png',
                size: null,
              },
              {
                createdAt: '2021-09-28T09:08:50.619Z',
                id: '14057826-680a-49bb-88e3-bccec49b3a49',
                listing: [],
                name: 'arab.jpg',
                size: null,
              },
            ],
            name: 'egyptian.shtml',
            size: null,
          },
        ],
        name: 'harness_account.mp3',
        size: null,
      },
    ],
    name: 'manager.wav',
    size: null,
  },
  {
    createdAt: '2021-11-27T05:58:12.258Z',
    id: 'ae61fdc3-7e70-4c7b-891a-8bd6c251aa69',
    listing: [
      {
        createdAt: '2021-06-08T07:07:36.604Z',
        id: '0b27d278-b617-44cd-8505-68333a345aa9',
        listing: [
          {
            createdAt: '2022-01-15T02:10:33.922Z',
            id: '4311d9e9-3bba-45c4-8c3d-da0774b888a2',
            listing: [
              {
                createdAt: '2021-05-03T16:12:36.095Z',
                id: 'daa49cad-1be9-4e16-a1da-5fdbaa78abb8',
                listing: [],
                name: 'sterling_baby.wav',
                size: null,
              },
              {
                createdAt: '2021-06-05T14:45:53.094Z',
                id: 'b67a9f8b-43e2-497b-9a87-7ed67e126441',
                listing: [],
                name: 'teal_granite_agp.mpe',
                size: null,
              },
              {
                createdAt: '2021-08-03T10:19:38.259Z',
                id: '5b9fb55e-81a1-4d2e-9d38-0d022aa1b9d7',
                listing: [],
                name: 'health_account_vista.pdf',
                size: null,
              },
              {
                createdAt: '2021-10-03T05:16:26.933Z',
                id: '8379e6f7-dae5-4992-b4e5-6f707ea5dcf5',
                listing: [],
                name: 'personal_loan.mpe',
                size: null,
              },
            ],
            name: 'frozen_cambridgeshire.jpe',
            size: null,
          },
          {
            createdAt: '2021-12-12T17:10:35.845Z',
            id: '56c6f5f4-423b-4751-8278-1d32bac32ac2',
            listing: [
              {
                createdAt: '2021-05-29T21:28:53.816Z',
                id: 'fac6a53e-379e-4f9c-ad9a-2fd78cabccfd',
                listing: [],
                name: 'south_savings.pdf',
                size: null,
              },
              {
                createdAt: '2021-06-08T20:29:40.486Z',
                id: 'efcfdaad-8351-466f-9344-63f802e3411a',
                listing: [],
                name: 'towels.mp4v',
                size: null,
              },
            ],
            name: 'shilling_transmitting_ssl.jpg',
            size: null,
          },
          {
            createdAt: '2021-12-18T14:35:29.423Z',
            id: 'b5f4325d-a49b-4002-a500-5f7fc2cc6ae0',
            listing: [
              {
                createdAt: '2021-10-31T14:23:37.210Z',
                id: '4118f01a-12ba-4389-8f34-2872e92be690',
                listing: [],
                name: 'cuban_violet_jbod.jpg',
                size: null,
              },
              {
                createdAt: '2021-04-14T08:10:42.485Z',
                id: '3bee7862-4b36-409f-81f3-d061bef77508',
                listing: [],
                name: 'louisiana.mp4',
                size: null,
              },
              {
                createdAt: '2021-05-10T01:15:46.482Z',
                id: 'b4fa452f-c996-45d0-8e82-0c70cfcbde09',
                listing: [],
                name: 'infrastructures.gif',
                size: null,
              },
              {
                createdAt: '2021-08-25T07:20:33.093Z',
                id: '35cf8eb9-6724-4f79-b680-c97b216de397',
                listing: [],
                name: 'rubber_matrix.pdf',
                size: null,
              },
            ],
            name: 'wisconsin_b2c.jpg',
            size: null,
          },
        ],
        name: 'sdd_tuna.png',
        size: null,
      },
      {
        createdAt: '2021-10-08T05:37:13.421Z',
        id: 'cab62272-eb60-4407-a71c-db8a2bbbf4fa',
        listing: [
          {
            createdAt: '2022-01-15T20:36:49.396Z',
            id: 'd2b28dd6-26df-4863-b4b8-b483ce1afca3',
            listing: [
              {
                createdAt: '2021-12-09T10:13:23.950Z',
                id: '9cc399de-659e-4997-b6b2-81198be11fa2',
                listing: [],
                name: 'open_source_data.png',
                size: null,
              },
              {
                createdAt: '2021-03-21T19:11:35.485Z',
                id: '07d98d6a-43fb-47fe-b051-be202c1646d9',
                listing: [],
                name: 'input.wav',
                size: null,
              },
            ],
            name: 'ghana_licensed_systemic.shtml',
            size: null,
          },
          {
            createdAt: '2021-07-12T14:00:43.064Z',
            id: '4ba0a224-ce15-4996-bed7-9468ac530668',
            listing: [
              {
                createdAt: '2021-10-18T16:31:32.754Z',
                id: 'eaec7a8e-d268-463f-8a11-5505bfd16b78',
                listing: [],
                name: 'legacy_officer_transmitter.htm',
                size: null,
              },
              {
                createdAt: '2021-11-27T22:46:11.779Z',
                id: '1b789a57-e041-47e6-b27d-718d3d23e85b',
                listing: [],
                name: 'up.png',
                size: null,
              },
              {
                createdAt: '2021-05-03T23:30:22.353Z',
                id: '2a261e32-1e10-4326-9e92-60fd8e1918a5',
                listing: [],
                name: 'monitoring.mpeg',
                size: null,
              },
              {
                createdAt: '2021-03-02T14:03:37.527Z',
                id: '58bf692d-746a-4274-8da2-1bbb2b977f3d',
                listing: [],
                name: 'enterprise_wide_cambridgeshire_quantify.m2a',
                size: null,
              },
              {
                createdAt: '2021-12-05T17:25:37.198Z',
                id: 'ea5e7a6a-4d53-4e4e-88a5-fade62e28356',
                listing: [],
                name: 'red_colombia_future.jpe',
                size: null,
              },
            ],
            name: 'magenta.gif',
            size: null,
          },
          {
            createdAt: '2022-01-05T22:37:32.303Z',
            id: 'd1b01c6e-bba0-4ea0-9817-2fa9540ed0bd',
            listing: [
              {
                createdAt: '2021-07-21T16:41:24.994Z',
                id: '063550d2-99a3-44de-a144-ef60ede04e4a',
                listing: [],
                name: 'bacon.jpe',
                size: null,
              },
              {
                createdAt: '2021-06-19T20:36:47.479Z',
                id: 'f69c84d2-5865-4ea4-a0cc-60b8802bca28',
                listing: [],
                name: 'plastic_salmon_infrastructure.wav',
                size: null,
              },
            ],
            name: 'table_scsi.html',
            size: null,
          },
          {
            createdAt: '2021-10-03T21:34:16.072Z',
            id: 'ee0d041b-0e1d-4816-b61f-6dda1b8e40e9',
            listing: [
              {
                createdAt: '2021-11-28T02:10:39.850Z',
                id: '9c769781-fd48-448c-97f4-33df782a4ed6',
                listing: [],
                name: 'transmitting_electronics_handmade.png',
                size: null,
              },
              {
                createdAt: '2021-06-15T17:53:17.090Z',
                id: '0bf029a9-5fa6-4665-a8ca-51a65dd3f2a0',
                listing: [],
                name: 'tuna_strategize_innovative.m2v',
                size: null,
              },
              {
                createdAt: '2021-05-27T12:30:16.895Z',
                id: '2760eddf-4f99-48fb-b489-70459e90fe53',
                listing: [],
                name: 'concrete.gif',
                size: null,
              },
            ],
            name: 'leading_edge.wav',
            size: null,
          },
        ],
        name: 'progressive_isle_purple.png',
        size: null,
      },
    ],
    name: 'green_integration.png',
    size: null,
  },
  {
    createdAt: '2022-02-09T13:26:40.120Z',
    id: '4d90649d-4602-4359-9b49-6ec7bf49f70d',
    listing: [
      {
        createdAt: '2021-03-18T17:42:45.300Z',
        id: '0485e1bc-0d15-490f-9ca1-8aa17b98aa77',
        listing: [
          {
            createdAt: '2022-02-04T03:48:57.530Z',
            id: '5bad7336-f2ba-45f0-9741-6657dea27075',
            listing: [
              {
                createdAt: '2021-05-06T05:50:53.999Z',
                id: '9da8ec9a-a7b1-4ebc-a858-217bc9cccc71',
                listing: [],
                name: 'gloves_integration_mint.jpe',
                size: null,
              },
              {
                createdAt: '2021-06-09T15:36:47.345Z',
                id: '575310b2-f67b-4e46-aad6-8ef05692e58b',
                listing: [],
                name: 'consultant_locks.mp3',
                size: null,
              },
              {
                createdAt: '2022-02-18T04:21:20.423Z',
                id: '653df30a-bce8-494d-a3f2-a8b88fbbd479',
                listing: [],
                name: 'mountains_communities.gif',
                size: null,
              },
            ],
            name: 'generic_way_alarm.m1v',
            size: null,
          },
        ],
        name: 'steel.m2v',
        size: null,
      },
      {
        createdAt: '2021-07-06T17:33:31.067Z',
        id: 'f393c91d-b772-4e1c-ae96-c7bb5f0092c8',
        listing: [
          {
            createdAt: '2022-01-16T01:57:13.384Z',
            id: '82f2f93e-3409-45c6-9c8c-91d92ce7de12',
            listing: [
              {
                createdAt: '2021-12-02T14:28:11.775Z',
                id: 'e6944b47-16e2-4c18-87a7-e1f6c650ddd0',
                listing: [],
                name: 'profit_focused_deposit_islands_.shtml',
                size: null,
              },
              {
                createdAt: '2021-05-26T05:31:44.071Z',
                id: 'ce5aad4a-0cf4-4b48-9516-e8aaa9463e8a',
                listing: [],
                name: 'motivating.png',
                size: null,
              },
              {
                createdAt: '2021-05-04T17:18:48.216Z',
                id: 'c23944ea-56ef-47ea-963a-abfc646c9095',
                listing: [],
                name: 'fundamental.jpg',
                size: null,
              },
              {
                createdAt: '2021-06-05T13:12:00.853Z',
                id: '152c885f-6ace-4752-aade-7ce5d4fe41b3',
                listing: [],
                name: 'mouse_automated_system.png',
                size: null,
              },
            ],
            name: 'integrated_hour.mp4v',
            size: null,
          },
          {
            createdAt: '2021-07-04T22:51:10.736Z',
            id: '7dde3aa2-44e5-4342-9581-77a7f450d091',
            listing: [
              {
                createdAt: '2021-09-13T01:19:41.267Z',
                id: '2d2b172c-66d0-4e29-bed2-e6b7b4ac8866',
                listing: [],
                name: 'tactics.gif',
                size: null,
              },
              {
                createdAt: '2022-01-23T20:55:42.186Z',
                id: '5f52004e-c90f-4d3c-ac2a-5cda3a26240a',
                listing: [],
                name: 'multi_channelled_repurpose.html',
                size: null,
              },
              {
                createdAt: '2021-11-22T01:56:03.368Z',
                id: '1c03ed13-ee89-4120-a035-8286b0ba2d71',
                listing: [],
                name: 'wooden_revolutionize.png',
                size: null,
              },
            ],
            name: 'hat.m3a',
            size: null,
          },
          {
            createdAt: '2021-10-16T18:41:17.952Z',
            id: 'a055a7c9-03ac-4afb-9d11-10e7fb6c470e',
            listing: [
              {
                createdAt: '2021-11-16T16:53:13.798Z',
                id: '9f72c39a-c4c4-46f5-8254-f7b0335268a7',
                listing: [],
                name: 'metal_payment.mpeg',
                size: null,
              },
              {
                createdAt: '2021-10-04T03:19:09.265Z',
                id: 'c2f2a024-7a75-4590-9218-803ef5440f39',
                listing: [],
                name: 'rest.pdf',
                size: null,
              },
              {
                createdAt: '2021-06-17T17:43:15.391Z',
                id: '8147b556-4590-499c-9c87-3c9f392d5541',
                listing: [],
                name: 'paradigm.png',
                size: null,
              },
              {
                createdAt: '2022-01-08T19:07:46.689Z',
                id: '8a3c48c5-e980-455a-9730-16bea2feb715',
                listing: [],
                name: 'concrete_client_driven_missouri.m1v',
                size: null,
              },
            ],
            name: 'input_primary_lead.pdf',
            size: null,
          },
        ],
        name: 'plastic_monitor_neural.gif',
        size: null,
      },
      {
        createdAt: '2021-04-21T03:52:56.748Z',
        id: '29d71344-d67c-4f63-adfc-6af7f1b63c2b',
        listing: [
          {
            createdAt: '2021-10-19T07:16:37.319Z',
            id: '9082fe8d-fead-4840-a026-755a26207da1',
            listing: [
              {
                createdAt: '2022-02-04T10:43:22.660Z',
                id: '2a94f21c-a3bd-4c6b-838a-57bdec02e30a',
                listing: [],
                name: 'value_added_pound_visionary.gif',
                size: null,
              },
              {
                createdAt: '2021-05-01T16:43:12.318Z',
                id: '876d9419-02cc-4a6e-8d03-eac81901287a',
                listing: [],
                name: 'incubate_usb.mpga',
                size: null,
              },
              {
                createdAt: '2021-10-16T23:12:30.914Z',
                id: '04869790-388e-4e68-bdc9-a1baf39e635b',
                listing: [],
                name: 'deposit_wisconsin.html',
                size: null,
              },
            ],
            name: 'designer_metal_refined.htm',
            size: null,
          },
        ],
        name: 'connect_exe_ford.png',
        size: null,
      },
      {
        createdAt: '2021-04-15T06:40:55.708Z',
        id: '8e55486f-b61a-4886-9e5e-0fc64a14b03c',
        listing: [
          {
            createdAt: '2021-06-16T18:44:24.718Z',
            id: '70191a34-1089-4fa8-8d94-e55533dafc62',
            listing: [
              {
                createdAt: '2021-04-20T05:07:15.182Z',
                id: '4b1a222e-0303-466c-bcf0-ec8e9f38c1c6',
                listing: [],
                name: 'integrate.png',
                size: null,
              },
            ],
            name: 'purple.jpg',
            size: null,
          },
          {
            createdAt: '2022-01-13T02:05:33.266Z',
            id: '04849ba9-d660-4e5e-a353-76a20eee4a26',
            listing: [
              {
                createdAt: '2021-08-29T19:55:25.853Z',
                id: '4a6a7e1d-a386-4c16-a890-8fa65260bda4',
                listing: [],
                name: 'sas_accountability_hdd.mpe',
                size: null,
              },
            ],
            name: 'concrete.m1v',
            size: null,
          },
        ],
        name: 'plastic.mpeg',
        size: null,
      },
    ],
    name: 'small.mpg4',
    size: null,
  },
  {
    createdAt: '2021-09-01T13:00:43.842Z',
    id: 'f0e138a0-68f5-4c17-b328-385a0eb3adf7',
    listing: [
      {
        createdAt: '2021-07-22T22:51:22.714Z',
        id: 'eb9e294b-8198-4681-8342-8c43c6428d66',
        listing: [
          {
            createdAt: '2021-09-03T20:15:48.732Z',
            id: '3d8de29f-cef7-4e3b-b8d3-9fba6e4cb146',
            listing: [
              {
                createdAt: '2021-05-10T19:22:52.265Z',
                id: 'abeabb96-82e4-43dc-8f28-f98c72e53bd1',
                listing: [],
                name: 'bedfordshire_deposit.gif',
                size: null,
              },
              {
                createdAt: '2021-07-03T15:09:16.632Z',
                id: 'cde3729c-dee3-4ece-86ad-7ad0fcd05fc1',
                listing: [],
                name: 'bluetooth_olive.mp4',
                size: null,
              },
              {
                createdAt: '2021-04-09T17:44:06.963Z',
                id: 'd6723788-2a50-422b-bc81-632dd4e69e84',
                listing: [],
                name: 'eyeballs_credit.m2v',
                size: null,
              },
              {
                createdAt: '2022-02-03T22:49:32.152Z',
                id: '30684b10-0937-422b-8b5b-05c9946512af',
                listing: [],
                name: 'mouse.jpeg',
                size: null,
              },
              {
                createdAt: '2021-12-20T19:13:47.744Z',
                id: '0950c2e4-55ab-44a8-9bcb-cfc07185fd3d',
                listing: [],
                name: 'district_toys.png',
                size: null,
              },
            ],
            name: 'methodologies_bifurcated.html',
            size: null,
          },
          {
            createdAt: '2022-02-14T12:56:54.727Z',
            id: 'c30893c9-4179-4001-aa12-878c8498e641',
            listing: [
              {
                createdAt: '2021-06-18T18:23:05.579Z',
                id: 'e2ef2aa9-0638-4df8-bc4d-7045f0d3752e',
                listing: [],
                name: 'data_berkshire.wav',
                size: null,
              },
              {
                createdAt: '2022-02-05T09:01:11.235Z',
                id: 'b34fd596-9f6a-4a7f-ab22-44aa40e5ee23',
                listing: [],
                name: 'hack_system.mpeg',
                size: null,
              },
              {
                createdAt: '2021-03-16T05:00:08.938Z',
                id: '3f7244d5-ce2e-4617-b880-06dfd24f4199',
                listing: [],
                name: 'ville_universal.pdf',
                size: null,
              },
              {
                createdAt: '2021-02-22T01:57:32.159Z',
                id: 'ccd3c6b7-4133-486d-9954-51c5fe12ac2e',
                listing: [],
                name: 'payment.gif',
                size: null,
              },
            ],
            name: 'applications_azerbaijan.shtml',
            size: null,
          },
          {
            createdAt: '2021-10-25T13:42:35.853Z',
            id: '6c7f9e79-0fc8-4b98-8711-e14dacd53df0',
            listing: [
              {
                createdAt: '2021-11-08T12:30:28.119Z',
                id: '167385f6-468c-45a0-83c8-447a37106015',
                listing: [],
                name: 'forges_kentucky_cheese.pdf',
                size: null,
              },
              {
                createdAt: '2021-10-27T05:40:04.805Z',
                id: '28c68581-8b2d-48c2-9d70-0394f8a75d35',
                listing: [],
                name: 'chicken_philippines.gif',
                size: null,
              },
              {
                createdAt: '2021-06-22T05:58:19.183Z',
                id: '950fe315-e166-4b46-a38f-1a3aab14a972',
                listing: [],
                name: 'frozen_monitor_solid.pdf',
                size: null,
              },
              {
                createdAt: '2021-12-19T22:20:28.538Z',
                id: '356e8191-72c9-41ee-bd63-5138d4e9d937',
                listing: [],
                name: 'payment_books_dollar.html',
                size: null,
              },
              {
                createdAt: '2022-01-03T09:33:14.443Z',
                id: '7f1e4c44-dd94-47a1-a812-a5fe9123920f',
                listing: [],
                name: 'administrator_mouse_generation.mpg',
                size: null,
              },
            ],
            name: 'navigate_com.jpe',
            size: null,
          },
          {
            createdAt: '2021-12-20T16:14:04.837Z',
            id: '6e45c193-51e2-4c22-84f4-558d600f2865',
            listing: [
              {
                createdAt: '2021-07-22T16:20:32.438Z',
                id: 'c7329ef1-fbed-46b4-93d2-5e21b5ecebe2',
                listing: [],
                name: 'tasty_stream_bacon.mpg',
                size: null,
              },
              {
                createdAt: '2021-04-25T03:41:51.652Z',
                id: 'b1008dee-3241-41b2-91f4-2bdf58d55ef2',
                listing: [],
                name: 'berkshire_tasty_persistent.wav',
                size: null,
              },
              {
                createdAt: '2021-10-09T02:20:35.924Z',
                id: '99dce580-5e10-40cc-8f46-b1c32c43b854',
                listing: [],
                name: 'comoros_thx_portals.wav',
                size: null,
              },
            ],
            name: 'tangible.jpg',
            size: null,
          },
        ],
        name: '_slovak.pdf',
        size: null,
      },
      {
        createdAt: '2022-01-09T19:47:12.419Z',
        id: '327e3518-68f0-4858-8b7f-c40b5847d763',
        listing: [
          {
            createdAt: '2022-01-26T17:01:48.925Z',
            id: '72c6c0df-3747-4ba6-a1b2-940281ed350e',
            listing: [
              {
                createdAt: '2021-04-16T21:02:30.878Z',
                id: '54815a50-7a77-4650-9d11-1e2ae205bc55',
                listing: [],
                name: 'associate_withdrawal.html',
                size: null,
              },
              {
                createdAt: '2021-12-01T02:50:42.934Z',
                id: 'b30e4b88-b2d3-41f5-bf2a-f6fce6af80ba',
                listing: [],
                name: 'towels_facilitate.mp4v',
                size: null,
              },
              {
                createdAt: '2021-02-27T18:49:16.152Z',
                id: '3e9b71f8-b267-4973-ae54-ce4c3955052c',
                listing: [],
                name: 'operations_multi_lateral_interfaces.png',
                size: null,
              },
            ],
            name: 'sql_array.mp4v',
            size: null,
          },
          {
            createdAt: '2021-12-21T23:42:28.606Z',
            id: 'd308a600-b3ef-40a5-9b2c-44fb5ad3cce0',
            listing: [
              {
                createdAt: '2021-02-22T21:09:17.561Z',
                id: '1ad86119-031d-491b-ba4a-6cebcf5dc567',
                listing: [],
                name: 'steel_virtual.png',
                size: null,
              },
              {
                createdAt: '2021-08-03T04:00:25.184Z',
                id: '2a6b336c-eba9-4204-9503-2e34d83f414a',
                listing: [],
                name: 'bedfordshire.wav',
                size: null,
              },
              {
                createdAt: '2021-06-06T01:32:56.880Z',
                id: 'a277ca1c-1f8a-4cfd-bdf4-01f29ef1d466',
                listing: [],
                name: 'deposit_salad_music.png',
                size: null,
              },
              {
                createdAt: '2021-12-23T02:10:28.695Z',
                id: '6d042e2c-3cd4-4c31-bb76-d5adf923b900',
                listing: [],
                name: 'global_fish.m1v',
                size: null,
              },
            ],
            name: 'denar.mpg4',
            size: null,
          },
          {
            createdAt: '2021-05-20T22:53:38.354Z',
            id: '7a0157f0-318c-46c9-a141-09e7bca5d267',
            listing: [
              {
                createdAt: '2022-02-14T12:44:58.188Z',
                id: 'b2400a04-350a-4b36-ab8d-9c975f2f5c8b',
                listing: [],
                name: 'target.png',
                size: null,
              },
              {
                createdAt: '2021-06-08T20:53:28.358Z',
                id: 'f86391e8-5309-4d66-ac02-5800c004279d',
                listing: [],
                name: 'engineer.pdf',
                size: null,
              },
            ],
            name: 'rubber_customer.pdf',
            size: null,
          },
        ],
        name: 'multi_byte.pdf',
        size: null,
      },
      {
        createdAt: '2021-05-25T04:56:44.311Z',
        id: 'd8b633ba-25e7-471a-b555-401196000cf7',
        listing: [
          {
            createdAt: '2021-06-11T01:38:32.769Z',
            id: '17e140d5-395d-47f4-9ffc-130ed523ad6d',
            listing: [
              {
                createdAt: '2021-12-06T08:06:14.645Z',
                id: 'ef00bca3-0343-4727-b620-830188fc708a',
                listing: [],
                name: 'ftp_marketing.wav',
                size: null,
              },
              {
                createdAt: '2021-06-26T18:16:37.123Z',
                id: '392baef8-7684-4425-9b99-7e4df7d49ad7',
                listing: [],
                name: 'grocery.pdf',
                size: null,
              },
              {
                createdAt: '2021-05-18T16:00:30.519Z',
                id: '37a1ad6e-d34b-4a38-94da-35f961172324',
                listing: [],
                name: 'implementation_interactions.gif',
                size: null,
              },
            ],
            name: 'content_based.jpe',
            size: null,
          },
        ],
        name: 'south_infomediaries_transmit.wav',
        size: null,
      },
    ],
    name: 'account_initiative.mp4',
    size: null,
  },
  {
    createdAt: '2021-05-02T09:56:02.112Z',
    id: '91ba5f11-de6b-4356-8a48-f4ad2a39968f',
    listing: [
      {
        createdAt: '2021-10-08T21:01:43.076Z',
        id: '77430ef3-fac6-4700-822c-7808e655efda',
        listing: [
          {
            createdAt: '2021-05-03T21:05:21.330Z',
            id: '8e43c042-88c5-4dec-b86c-c31516e014d3',
            listing: [
              {
                createdAt: '2021-10-12T19:45:28.210Z',
                id: 'f172c032-f7a4-408d-9948-4e4178203bb7',
                listing: [],
                name: 'engine_swiss_toys.m2a',
                size: null,
              },
              {
                createdAt: '2021-03-07T11:17:34.036Z',
                id: '88e42dfb-496d-4393-b7de-b04c3a590821',
                listing: [],
                name: 'leverage_generating.mp4',
                size: null,
              },
            ],
            name: 'transmitter_wooden_connect.htm',
            size: null,
          },
          {
            createdAt: '2021-04-10T10:31:52.640Z',
            id: '0aec195a-ac5d-4354-af8c-22db1fb22400',
            listing: [
              {
                createdAt: '2022-01-30T11:33:17.454Z',
                id: '7d257b0d-e3cd-4af0-a54a-ae4164797525',
                listing: [],
                name: 'fish_bacon.mp4v',
                size: null,
              },
            ],
            name: 'innovative_clothing_ib.mpga',
            size: null,
          },
          {
            createdAt: '2021-08-07T05:57:53.021Z',
            id: 'bb648623-1066-490b-a359-d5817382963d',
            listing: [
              {
                createdAt: '2021-12-01T20:51:10.991Z',
                id: 'da118623-e7c7-4e78-a5e0-a3464220f223',
                listing: [],
                name: 'roi_calculating.png',
                size: null,
              },
              {
                createdAt: '2021-10-21T00:43:02.568Z',
                id: '6ad87dea-ce21-422a-88cb-4af59b882f4d',
                listing: [],
                name: 'vortals.htm',
                size: null,
              },
            ],
            name: 'ai_account_transmit.png',
            size: null,
          },
          {
            createdAt: '2021-08-03T08:21:02.476Z',
            id: '80e1e92a-ac3e-48c9-b011-f589a2c99aae',
            listing: [
              {
                createdAt: '2021-03-10T22:10:13.929Z',
                id: 'b5ec9aae-9844-4fb8-98b2-ac353d4b1d97',
                listing: [],
                name: 'bus.gif',
                size: null,
              },
              {
                createdAt: '2021-05-19T04:13:57.824Z',
                id: '4cab9b8a-a710-439a-b993-e1f97c8a7135',
                listing: [],
                name: 'program.m1v',
                size: null,
              },
              {
                createdAt: '2021-09-16T14:38:02.576Z',
                id: '0de6a3fe-d1a6-4f7c-b4e1-527bb176a9e9',
                listing: [],
                name: 'money.jpg',
                size: null,
              },
            ],
            name: 'transmit_public_key.m2a',
            size: null,
          },
          {
            createdAt: '2021-07-14T03:45:32.771Z',
            id: '7b22495b-09bc-481e-9dd5-fbf6bc14ea66',
            listing: [
              {
                createdAt: '2021-09-23T05:55:19.005Z',
                id: '5f7ce411-7ea5-436d-ad8b-148734afc50c',
                listing: [],
                name: 'administrator_calculate_virginia.mp4',
                size: null,
              },
            ],
            name: 'intranet_buckinghamshire_berkshire.mpg',
            size: null,
          },
        ],
        name: 'forward_withdrawal_facilitator.shtml',
        size: null,
      },
      {
        createdAt: '2021-11-04T13:43:08.383Z',
        id: '2ba1dfbf-5cea-480e-9a19-c9a4c3a9d51c',
        listing: [
          {
            createdAt: '2021-08-22T20:56:48.478Z',
            id: '0f99aceb-65d3-480e-a151-854a029ea3a1',
            listing: [
              {
                createdAt: '2021-12-31T11:34:50.464Z',
                id: 'fba7eb11-cce1-42a6-8f3a-1c3da0227c8e',
                listing: [],
                name: 'clothing_representative.m2v',
                size: null,
              },
            ],
            name: 'port_foreground.m1v',
            size: null,
          },
          {
            createdAt: '2021-10-12T05:07:27.672Z',
            id: 'dc49c047-4b3e-41e9-8f82-a9b00f6653ad',
            listing: [
              {
                createdAt: '2021-12-11T17:59:28.577Z',
                id: '0450edf7-373b-4121-95ae-a9e14f751b97',
                listing: [],
                name: 'engineer.m3a',
                size: null,
              },
            ],
            name: 'e_business_kentucky.wav',
            size: null,
          },
        ],
        name: 'invoice_empower.jpe',
        size: null,
      },
    ],
    name: 'dynamic.gif',
    size: null,
  },
  {
    createdAt: '2021-05-16T15:36:26.899Z',
    id: 'c429ee5f-f3f0-4a82-90e5-4a3a890d8c68',
    listing: [
      {
        createdAt: '2021-11-17T15:18:40.760Z',
        id: '61e7c5a4-8742-43bc-84cb-5fb011c6cbcd',
        listing: [
          {
            createdAt: '2021-03-09T13:23:59.917Z',
            id: 'a9e643f1-fde6-4e48-8265-2a0415f62ab3',
            listing: [
              {
                createdAt: '2021-03-29T14:59:45.465Z',
                id: '1ff12e51-f606-48f3-9b16-df97599fbef8',
                listing: [],
                name: 'tunnel_teal_program.gif',
                size: null,
              },
              {
                createdAt: '2021-03-06T22:10:44.142Z',
                id: '7ff2ed4e-69cb-41f5-a43c-89c18740a44c',
                listing: [],
                name: 'indiana_calculating.wav',
                size: null,
              },
              {
                createdAt: '2021-05-31T06:47:16.701Z',
                id: 'a25de7b6-5325-494a-b3a2-f56b64b00822',
                listing: [],
                name: 'deposit_state.png',
                size: null,
              },
              {
                createdAt: '2021-02-19T11:37:08.124Z',
                id: '041b7fce-504e-4da9-9736-c822c6e90dd5',
                listing: [],
                name: 'withdrawal.html',
                size: null,
              },
              {
                createdAt: '2022-02-02T08:12:13.361Z',
                id: 'a9b9e986-51af-4594-b079-e2d266386569',
                listing: [],
                name: 'exuding_redundant.gif',
                size: null,
              },
            ],
            name: 'deposit_movies.wav',
            size: null,
          },
          {
            createdAt: '2021-10-30T13:21:51.601Z',
            id: 'b11e4cc5-cdcb-4153-816c-b725556d6cc8',
            listing: [
              {
                createdAt: '2021-10-01T00:42:02.670Z',
                id: 'a34fc145-6236-4e91-9cde-f41e7c9d1347',
                listing: [],
                name: 'metal_generic_24_7.pdf',
                size: null,
              },
              {
                createdAt: '2022-02-02T02:23:46.947Z',
                id: '37326064-a8f4-4d44-a359-81fec6691290',
                listing: [],
                name: 'investor_tasty_synthesize.mp4v',
                size: null,
              },
              {
                createdAt: '2021-03-01T15:45:23.119Z',
                id: '4b95df81-00d3-4c86-8233-0d3c5258e885',
                listing: [],
                name: 'quality_driver.png',
                size: null,
              },
            ],
            name: 'bacon_hack_global.m1v',
            size: null,
          },
          {
            createdAt: '2021-08-03T12:36:34.240Z',
            id: 'd12f2c7f-b505-4574-9f99-5576c05d11d5',
            listing: [
              {
                createdAt: '2022-02-07T16:55:02.509Z',
                id: '4f0bf3c3-66ef-45eb-9cbb-d949a3311099',
                listing: [],
                name: 'systemic_orange.png',
                size: null,
              },
              {
                createdAt: '2021-08-24T16:00:19.030Z',
                id: 'd1479e6b-2ad3-4c04-8e0d-dda0b05ab2a7',
                listing: [],
                name: 'data.mp4v',
                size: null,
              },
              {
                createdAt: '2021-03-16T13:11:19.614Z',
                id: '0884201d-1a98-4529-ba69-24c1c7b444c4',
                listing: [],
                name: 'manat_upgradable_scsi.jpe',
                size: null,
              },
              {
                createdAt: '2021-03-29T00:42:51.374Z',
                id: '68c28124-fbf1-4707-9226-7332b893e42e',
                listing: [],
                name: 'balanced.pdf',
                size: null,
              },
            ],
            name: 'facilitator_engineer.htm',
            size: null,
          },
        ],
        name: 'smtp_savings_user_centric.wav',
        size: null,
      },
      {
        createdAt: '2022-02-02T19:06:30.979Z',
        id: '574ae3bb-948f-4a25-a0b3-3821c10ddf54',
        listing: [
          {
            createdAt: '2021-09-13T09:06:41.120Z',
            id: '62f235a1-78bd-4a81-a3e9-34df649d7db6',
            listing: [
              {
                createdAt: '2021-03-29T05:48:11.135Z',
                id: 'ee99edb7-1794-46b6-bdb3-55b5f1536a22',
                listing: [],
                name: 'invoice.mp2',
                size: null,
              },
              {
                createdAt: '2021-06-21T13:20:17.934Z',
                id: '3e4aeea3-0688-462c-a208-d361aa91ced5',
                listing: [],
                name: 'isle_regional.html',
                size: null,
              },
              {
                createdAt: '2021-11-04T03:25:02.997Z',
                id: 'fe7dede0-6e07-441f-8473-8ba79e24a628',
                listing: [],
                name: 'payment.mp4v',
                size: null,
              },
              {
                createdAt: '2021-11-18T23:38:12.694Z',
                id: 'f4dcf5b5-4f02-4a91-8117-5463eaf181bb',
                listing: [],
                name: 'e_business_internal.png',
                size: null,
              },
            ],
            name: 'transmitting_gold.mp2',
            size: null,
          },
          {
            createdAt: '2021-05-18T14:43:25.785Z',
            id: 'b39bc18a-6739-4f08-bd7c-cb27f59b6256',
            listing: [
              {
                createdAt: '2022-02-13T23:49:47.870Z',
                id: 'abed624a-08cd-4c5e-9b8a-530a1d0a6862',
                listing: [],
                name: 'cross_platform_adp.png',
                size: null,
              },
            ],
            name: 'producer_payment_assurance.png',
            size: null,
          },
          {
            createdAt: '2021-08-15T14:00:58.956Z',
            id: '3212750d-76a6-459e-a83d-6a1f93e24e65',
            listing: [
              {
                createdAt: '2021-05-21T18:46:41.544Z',
                id: '40251d8b-5362-493a-a352-d7247ce0dd5c',
                listing: [],
                name: 'microchip_interfaces.shtml',
                size: null,
              },
              {
                createdAt: '2021-11-26T12:17:41.845Z',
                id: '1087ab36-1edd-4089-829b-0bf43831b651',
                listing: [],
                name: 'metal_group_wisconsin.pdf',
                size: null,
              },
            ],
            name: 'deliverables.jpeg',
            size: null,
          },
        ],
        name: 'd_ivoire_incredible_somali.htm',
        size: null,
      },
      {
        createdAt: '2021-12-25T15:00:54.963Z',
        id: 'ac1bcb87-1135-4570-a10d-e837934581f7',
        listing: [
          {
            createdAt: '2021-10-11T16:53:21.989Z',
            id: '6c3ca0f3-7ec5-4c39-9b3d-f9331e91e6b0',
            listing: [
              {
                createdAt: '2021-10-24T12:18:18.837Z',
                id: '0728d338-9858-4484-8545-bf9e52f781d8',
                listing: [],
                name: 'agp_empower_sql.mp2a',
                size: null,
              },
              {
                createdAt: '2021-08-31T12:52:53.174Z',
                id: 'b9632ee6-0f02-44a3-aeb1-790b1a60a60b',
                listing: [],
                name: 'parsing.htm',
                size: null,
              },
              {
                createdAt: '2022-02-10T19:40:33.326Z',
                id: '2e2d14e1-ab81-48bc-97a0-d6e0d7e69eb2',
                listing: [],
                name: 'tcp_azure_personal.html',
                size: null,
              },
            ],
            name: 'personal_programming.mp4',
            size: null,
          },
          {
            createdAt: '2021-06-26T09:43:23.457Z',
            id: '4d9cba9f-a31b-46af-983d-a90819112317',
            listing: [
              {
                createdAt: '2021-04-10T17:52:23.874Z',
                id: '02406d68-5017-427f-8396-918ae1f624a0',
                listing: [],
                name: 'innovative.mpg',
                size: null,
              },
              {
                createdAt: '2021-05-16T11:38:03.303Z',
                id: 'eb6c473f-b0d6-4b2e-a584-2027571ef551',
                listing: [],
                name: 'specialist_methodologies_gorgeous.pdf',
                size: null,
              },
              {
                createdAt: '2021-02-27T02:01:06.409Z',
                id: '883e01fe-500e-4fc2-9153-1b8b2d9fc89d',
                listing: [],
                name: 'soft_toolset.pdf',
                size: null,
              },
            ],
            name: 'comoro.pdf',
            size: null,
          },
          {
            createdAt: '2021-11-13T18:39:25.492Z',
            id: '8eb470e1-f586-4e24-a600-24fec035fa80',
            listing: [
              {
                createdAt: '2021-08-05T12:48:09.612Z',
                id: '69b52ec1-d243-45c2-8e79-27b2f15f0c49',
                listing: [],
                name: 'reboot_brooks.gif',
                size: null,
              },
              {
                createdAt: '2021-03-20T16:11:31.264Z',
                id: '9fe2db2f-93f9-437e-b117-cbbfee7ae2c2',
                listing: [],
                name: 'yellow_implemented_administration.mp4',
                size: null,
              },
              {
                createdAt: '2022-01-19T15:49:36.253Z',
                id: '8fd14108-9409-4cc0-aea0-0860bc8b45db',
                listing: [],
                name: 'partnerships.htm',
                size: null,
              },
            ],
            name: 'deploy.jpeg',
            size: null,
          },
          {
            createdAt: '2021-08-18T23:26:51.366Z',
            id: '83835f74-6e00-4916-b1b8-4e615bae35a9',
            listing: [
              {
                createdAt: '2021-10-24T03:55:15.798Z',
                id: 'b019d0d2-035a-4304-bdfe-765673c54191',
                listing: [],
                name: 'communities.mp4',
                size: null,
              },
              {
                createdAt: '2022-01-19T18:06:16.148Z',
                id: '279cc94b-872c-43a4-bcd7-bffc102b168d',
                listing: [],
                name: 'account.wav',
                size: null,
              },
              {
                createdAt: '2022-02-13T10:46:28.803Z',
                id: '5753087a-d23c-4142-b2da-d3b05c5b60f2',
                listing: [],
                name: '24_7_synthesizing.mpg4',
                size: null,
              },
            ],
            name: 'hard_glen.html',
            size: null,
          },
          {
            createdAt: '2021-03-14T13:34:30.089Z',
            id: '6976930c-e2f2-44b2-afd7-b1f51faddd1a',
            listing: [
              {
                createdAt: '2021-05-29T01:31:50.849Z',
                id: '1c64ad9f-4b2e-4859-9ed0-290a788ea593',
                listing: [],
                name: 'bedfordshire_rial.mpg4',
                size: null,
              },
            ],
            name: 'checking_chicken.mp3',
            size: null,
          },
        ],
        name: 'frozen_payment.mpg4',
        size: null,
      },
      {
        createdAt: '2022-01-16T22:33:39.498Z',
        id: 'c3021040-544b-4496-bc95-b8977050bb5a',
        listing: [
          {
            createdAt: '2021-12-08T03:18:37.959Z',
            id: 'f80efea7-0a2f-430e-915e-1452b97f2800',
            listing: [
              {
                createdAt: '2021-09-23T00:11:43.197Z',
                id: '579b4c15-1cb1-4c4c-994c-b2d08a1d1e0d',
                listing: [],
                name: 'intelligent_montana_kids.mpg',
                size: null,
              },
              {
                createdAt: '2021-11-16T17:55:22.446Z',
                id: 'a731ff8e-3c4a-4de2-bcc3-e103cf5450ee',
                listing: [],
                name: 'invoice.pdf',
                size: null,
              },
              {
                createdAt: '2022-02-01T11:59:44.914Z',
                id: 'c95b3f55-a1fb-4730-973f-a88ff361ae4a',
                listing: [],
                name: 'hacking_multi_byte_calculating.wav',
                size: null,
              },
            ],
            name: 'shoal_dynamic.m2v',
            size: null,
          },
          {
            createdAt: '2021-10-17T06:40:13.018Z',
            id: '7927124b-3c76-4550-92f3-586adf19a9a7',
            listing: [
              {
                createdAt: '2022-01-21T13:12:31.663Z',
                id: '331e6a15-b769-42bd-8566-6b0188931fdf',
                listing: [],
                name: '1080p_ram_bacon.gif',
                size: null,
              },
              {
                createdAt: '2021-12-29T09:03:31.211Z',
                id: '1bc53147-0984-4a89-b836-3456acb8188a',
                listing: [],
                name: 'bike_engineer_unbranded.pdf',
                size: null,
              },
              {
                createdAt: '2021-04-29T06:51:18.833Z',
                id: '87adb69b-a0a2-45e9-a6a9-6800a92a1205',
                listing: [],
                name: 'keyboard.wav',
                size: null,
              },
              {
                createdAt: '2021-07-20T19:18:04.811Z',
                id: '9a394198-eed7-4fd6-802f-ef91d0af83c5',
                listing: [],
                name: 'synergized_facilitate.jpe',
                size: null,
              },
            ],
            name: 'ergonomic_ib_navigating.htm',
            size: null,
          },
          {
            createdAt: '2021-06-09T05:01:25.929Z',
            id: '2fd6c6db-0f78-4864-b16e-7154ebd2b0c0',
            listing: [
              {
                createdAt: '2021-05-16T05:40:09.191Z',
                id: 'cfb54f45-d683-4b12-9f3e-f1073533aea1',
                listing: [],
                name: 'parsing.mp4v',
                size: null,
              },
              {
                createdAt: '2021-06-01T17:14:39.265Z',
                id: 'b8e67228-548f-42ca-b8e8-733ba73756d8',
                listing: [],
                name: 'knoll.mp4',
                size: null,
              },
              {
                createdAt: '2021-11-15T23:29:37.758Z',
                id: '3e02878b-2b1a-4dec-99e5-b27eba3d418d',
                listing: [],
                name: 'senior_health.gif',
                size: null,
              },
            ],
            name: 'music_bacon.mp4v',
            size: null,
          },
          {
            createdAt: '2021-06-04T04:01:10.760Z',
            id: 'b3b4ec26-d474-4fd8-8c43-148555909be5',
            listing: [
              {
                createdAt: '2022-01-08T15:24:44.766Z',
                id: '18d67ce8-a3ec-4d1d-a254-36bbbfbdba2a',
                listing: [],
                name: 'generation_payment.shtml',
                size: null,
              },
              {
                createdAt: '2021-08-19T10:47:11.225Z',
                id: '81d6d836-75d4-425c-b1be-ab67eafbb3d1',
                listing: [],
                name: 'tuna_utilize_massachusetts.png',
                size: null,
              },
              {
                createdAt: '2022-02-04T10:36:57.667Z',
                id: '3c83606d-a178-4bbd-8bba-4a6e5762971f',
                listing: [],
                name: 'towels_invoice_home.mp4',
                size: null,
              },
              {
                createdAt: '2021-08-20T14:27:21.880Z',
                id: '5c43f8af-6b9a-4aa5-b425-a56bd4d30cbb',
                listing: [],
                name: 'payment_calculate_communications.htm',
                size: null,
              },
            ],
            name: 'connect_deposit.mpg4',
            size: null,
          },
          {
            createdAt: '2021-04-21T11:05:06.222Z',
            id: '897be033-bd02-4335-aa5e-f35dd8e33a11',
            listing: [
              {
                createdAt: '2021-05-25T11:14:45.733Z',
                id: '2df0835b-c222-4fcd-8faf-b2f09fabb115',
                listing: [],
                name: 'payment_multi_byte.mpg',
                size: null,
              },
            ],
            name: 'camp_refined_lead.mpeg',
            size: null,
          },
        ],
        name: 'scsi.png',
        size: null,
      },
      {
        createdAt: '2021-10-17T01:29:15.129Z',
        id: 'd10a0651-ecf3-416f-b0ff-a1eb0f471540',
        listing: [
          {
            createdAt: '2021-04-02T05:33:42.746Z',
            id: '127682aa-8be7-4240-aecc-002adc8ae714',
            listing: [
              {
                createdAt: '2021-12-10T08:31:55.036Z',
                id: '60ce6a7f-7f2a-470f-8917-6f284f2f13a5',
                listing: [],
                name: 'killer.pdf',
                size: null,
              },
              {
                createdAt: '2021-11-19T08:44:01.749Z',
                id: '380a5e56-4f7b-46fb-bbc3-6885b07beb83',
                listing: [],
                name: 'virtual.pdf',
                size: null,
              },
            ],
            name: 'enable_sticky_avon.mpeg',
            size: null,
          },
          {
            createdAt: '2021-02-20T03:16:08.192Z',
            id: '4c0b115b-9e21-4b52-bd33-b64c7a98c1ac',
            listing: [
              {
                createdAt: '2022-01-15T07:10:08.686Z',
                id: 'e28e930f-ccfd-4fe6-ae9b-ff7358b04d46',
                listing: [],
                name: 'oman.mpga',
                size: null,
              },
              {
                createdAt: '2021-03-27T01:13:28.091Z',
                id: '7f3ab0b4-b783-4276-bd4d-2f1622b0e441',
                listing: [],
                name: 'loan_payment_turkey.jpg',
                size: null,
              },
              {
                createdAt: '2021-04-04T15:09:16.314Z',
                id: '80d5733a-2397-4d09-a426-9127040f558b',
                listing: [],
                name: 'quantifying.mpg4',
                size: null,
              },
              {
                createdAt: '2021-05-19T05:09:21.258Z',
                id: '2bc82ba8-f137-4009-b6ed-227ed5283d4e',
                listing: [],
                name: 'online_automotive_account.wav',
                size: null,
              },
            ],
            name: 'overriding_hdd.wav',
            size: null,
          },
          {
            createdAt: '2021-04-06T20:45:28.984Z',
            id: '7cee1f64-0768-402e-ba7a-a52eeafce240',
            listing: [
              {
                createdAt: '2021-03-14T15:53:13.354Z',
                id: '10444827-dcc5-4bcb-b709-a489a1b0caa9',
                listing: [],
                name: 'hacking.gif',
                size: null,
              },
              {
                createdAt: '2021-03-21T16:32:31.158Z',
                id: '7fa5b43c-106c-4de2-95ee-3e5f1a6ee8a5',
                listing: [],
                name: 'wisconsin_turquoise_streets.png',
                size: null,
              },
            ],
            name: 'avon_invoice.m1v',
            size: null,
          },
          {
            createdAt: '2021-05-29T20:17:32.842Z',
            id: 'ba92fd84-5faa-4b40-8779-ffad1eed362d',
            listing: [
              {
                createdAt: '2021-03-22T23:34:03.149Z',
                id: '9ae61b40-b78e-4857-8c04-e82abe896c76',
                listing: [],
                name: 'granite.mp2',
                size: null,
              },
              {
                createdAt: '2021-07-17T10:16:09.802Z',
                id: 'ea20b813-73ef-4b2b-af7c-3520f81da3fa',
                listing: [],
                name: 'utilisation_generic_specialist.png',
                size: null,
              },
              {
                createdAt: '2021-06-20T03:13:56.176Z',
                id: '9f8e3974-4083-480e-8bc6-90d00532e149',
                listing: [],
                name: 'account_officer.mpg4',
                size: null,
              },
              {
                createdAt: '2021-07-24T04:43:01.327Z',
                id: 'bfc14882-2cf1-401f-aa8e-f2b9d5b274f6',
                listing: [],
                name: 'bosnia_soft_saudi.shtml',
                size: null,
              },
              {
                createdAt: '2021-09-22T22:46:57.647Z',
                id: 'c506a921-635a-49ad-9acb-9c087758a6fa',
                listing: [],
                name: 'forward.mpg4',
                size: null,
              },
            ],
            name: 'parsing.mpg',
            size: null,
          },
        ],
        name: 'niger_bedfordshire.mpe',
        size: null,
      },
    ],
    name: 'ecuador_solutions_grass_roots.gif',
    size: null,
  },
  {
    createdAt: '2021-11-01T01:10:35.536Z',
    id: '411a4198-2a98-42a0-a24b-881a0fb72d94',
    listing: [
      {
        createdAt: '2021-05-29T12:06:55.843Z',
        id: '384dd725-d774-420f-b67b-7cf6923cde92',
        listing: [
          {
            createdAt: '2021-12-15T16:23:50.985Z',
            id: 'dd49000a-05a7-4300-a3a0-bd788d0f9dd4',
            listing: [
              {
                createdAt: '2022-01-19T07:57:16.855Z',
                id: '91bf660d-146a-4a86-a9ad-2c9abb532f33',
                listing: [],
                name: 'vietnam.wav',
                size: null,
              },
              {
                createdAt: '2021-04-07T00:11:02.844Z',
                id: '54d1f897-0cc0-4034-a0a8-c591dbc6fc82',
                listing: [],
                name: 'capacitor_michigan.png',
                size: null,
              },
              {
                createdAt: '2021-12-13T01:33:35.092Z',
                id: 'd216f94f-db5b-4b5a-851d-a5a85ef1f4e1',
                listing: [],
                name: 'leone.png',
                size: null,
              },
              {
                createdAt: '2021-03-17T06:39:33.246Z',
                id: '50822e73-caaf-4138-844f-5f007bee7bbd',
                listing: [],
                name: 'djibouti_fish.wav',
                size: null,
              },
              {
                createdAt: '2022-02-13T16:58:55.542Z',
                id: '0595b1bb-1db8-4fdc-925b-13e3eac8a77d',
                listing: [],
                name: 'rustic_knoll_asymmetric.m2a',
                size: null,
              },
            ],
            name: 'investor_dynamic.mpg4',
            size: null,
          },
          {
            createdAt: '2021-09-14T02:06:57.198Z',
            id: '00fc9db3-864e-4680-9a3c-5a82c3fe2707',
            listing: [
              {
                createdAt: '2021-09-26T17:38:17.026Z',
                id: '49392d77-2716-4f33-98ff-d81755927b38',
                listing: [],
                name: 'non_volatile_turquoise.pdf',
                size: null,
              },
              {
                createdAt: '2021-12-14T15:46:31.120Z',
                id: '43bb8f63-bd35-4f6d-be60-e33c1abb3318',
                listing: [],
                name: 'program_streamlined.mp4',
                size: null,
              },
              {
                createdAt: '2021-07-17T17:03:23.586Z',
                id: '5f05729b-6c9a-4590-87e1-13a50fd6d2d6',
                listing: [],
                name: 'bike_steel_poland.mp4',
                size: null,
              },
            ],
            name: 'open_source.png',
            size: null,
          },
          {
            createdAt: '2021-11-05T16:57:07.017Z',
            id: 'c5945509-8fc6-455c-8dc5-940cd36c2326',
            listing: [
              {
                createdAt: '2021-08-30T02:08:23.986Z',
                id: '12cd4942-bb06-451d-a679-19b82eb10f0c',
                listing: [],
                name: 'relationships.gif',
                size: null,
              },
              {
                createdAt: '2021-10-11T19:39:11.690Z',
                id: '758dc94d-53c2-4cc5-8a84-8a9db36151b8',
                listing: [],
                name: 'account_configuration.jpg',
                size: null,
              },
            ],
            name: 'regional.jpg',
            size: null,
          },
        ],
        name: 'taiwan_orchestrator.m2v',
        size: null,
      },
      {
        createdAt: '2021-09-03T14:03:28.344Z',
        id: '97b0a9bd-9a13-4e74-a0ff-289e59f4cb30',
        listing: [
          {
            createdAt: '2021-07-23T17:06:35.240Z',
            id: '173d863e-24bb-43f3-839d-a556300951a1',
            listing: [
              {
                createdAt: '2022-02-11T01:32:49.503Z',
                id: '29217f8d-1341-4ee2-bad9-dde4201a54e3',
                listing: [],
                name: 'ohio_borders.gif',
                size: null,
              },
              {
                createdAt: '2021-11-06T15:58:14.752Z',
                id: '0af6164f-8278-4048-9339-545369b7d3a8',
                listing: [],
                name: 'index.png',
                size: null,
              },
              {
                createdAt: '2021-04-25T04:38:35.604Z',
                id: '9a039b2b-e215-4506-854f-2309887e34ea',
                listing: [],
                name: 'gloves_incentivize.wav',
                size: null,
              },
            ],
            name: 'direct_relationships_coordinator.gif',
            size: null,
          },
          {
            createdAt: '2021-05-11T03:02:05.338Z',
            id: '50bb00cc-70cb-4004-9f16-5e127a95d9e2',
            listing: [
              {
                createdAt: '2021-11-22T07:01:21.819Z',
                id: '284a3869-56eb-479a-a05a-d4adf6c037ee',
                listing: [],
                name: 'ram_international.mpeg',
                size: null,
              },
              {
                createdAt: '2022-01-07T03:14:49.361Z',
                id: '83daee9f-e41e-4d93-8fcf-c5ee5bd637a3',
                listing: [],
                name: 'payment_and.mp3',
                size: null,
              },
              {
                createdAt: '2021-05-29T07:08:18.441Z',
                id: '7a99847b-e8be-49ec-acaa-461460189071',
                listing: [],
                name: 'best_of_breed_pass.gif',
                size: null,
              },
              {
                createdAt: '2021-06-02T22:45:03.440Z',
                id: '5257ee32-a0a6-4c59-bdcd-1cbc34c41ee6',
                listing: [],
                name: 'drive.jpe',
                size: null,
              },
            ],
            name: 'alabama_efficient_strategic.pdf',
            size: null,
          },
          {
            createdAt: '2021-05-04T08:58:37.564Z',
            id: '7612645a-0ae1-4df4-ab6c-2adfd0dd6010',
            listing: [
              {
                createdAt: '2021-05-10T23:34:56.138Z',
                id: '34d7cfae-b0b4-4c89-8a2e-22b567bed07d',
                listing: [],
                name: 'sports_metal.png',
                size: null,
              },
              {
                createdAt: '2021-03-24T08:57:14.602Z',
                id: 'b4360d62-7675-43f6-bc6a-3c5e971fc9c6',
                listing: [],
                name: 'bandwidth_generic_backing.mp2a',
                size: null,
              },
              {
                createdAt: '2021-12-15T13:04:57.923Z',
                id: '86d96cff-6ae0-41e1-bb75-786c5015eacd',
                listing: [],
                name: 'cambridgeshire_circuit_latvia.wav',
                size: null,
              },
              {
                createdAt: '2021-08-13T09:28:06.495Z',
                id: '7cde12b4-7444-44be-97f9-1f5e897c1656',
                listing: [],
                name: 'account_fresh_thinking_quantify.mp4v',
                size: null,
              },
              {
                createdAt: '2021-08-25T20:51:27.318Z',
                id: 'ecc2a966-063f-4709-ad24-9c7fc6fa2e45',
                listing: [],
                name: 'array_buckinghamshire.jpeg',
                size: null,
              },
            ],
            name: 'fresh.png',
            size: null,
          },
          {
            createdAt: '2022-01-21T23:34:23.629Z',
            id: 'dc40a33b-a948-4c9d-8074-907d824c5f72',
            listing: [
              {
                createdAt: '2021-08-18T20:24:08.565Z',
                id: 'd5674f9b-7e26-429e-8068-afa8765d157d',
                listing: [],
                name: 'alabama_ocean.png',
                size: null,
              },
              {
                createdAt: '2021-03-16T07:20:09.284Z',
                id: '7820f416-cd61-4e30-bed6-3ac696a2468a',
                listing: [],
                name: 'static_viaduct.mp2a',
                size: null,
              },
              {
                createdAt: '2021-03-14T12:00:54.766Z',
                id: '236fae95-ba0c-48b7-b1ba-8ca806e05399',
                listing: [],
                name: 'ssl_personal.shtml',
                size: null,
              },
              {
                createdAt: '2021-03-16T22:15:50.244Z',
                id: 'c1c91cc9-3e27-4297-9842-80580be208fa',
                listing: [],
                name: 'concrete_navigate_anguilla.png',
                size: null,
              },
            ],
            name: 'feed.htm',
            size: null,
          },
        ],
        name: 'chips.jpg',
        size: null,
      },
      {
        createdAt: '2021-10-18T05:54:46.336Z',
        id: '156d0c3e-db33-4826-8181-7f5f81b132b2',
        listing: [
          {
            createdAt: '2021-06-10T01:59:00.905Z',
            id: 'b15f8100-cbfc-4f3e-8950-d94b8e5aec5b',
            listing: [
              {
                createdAt: '2021-10-15T10:43:55.401Z',
                id: '2052cfad-013f-4508-bcd3-a207ae604b40',
                listing: [],
                name: 'pre_emptive_smtp_cambridgeshire.jpe',
                size: null,
              },
              {
                createdAt: '2021-05-04T08:46:42.543Z',
                id: '2f1b1916-0626-40f5-9af0-725ee09e3421',
                listing: [],
                name: 'digitized.m2v',
                size: null,
              },
              {
                createdAt: '2021-10-30T01:13:28.036Z',
                id: '2b419fd4-200b-4004-923e-348e06d75a36',
                listing: [],
                name: 'kansas_planner_usb.mp2a',
                size: null,
              },
              {
                createdAt: '2021-09-25T05:22:32.319Z',
                id: 'ac8d0c08-147d-4059-b5f1-b9fe450e2113',
                listing: [],
                name: 'planner.png',
                size: null,
              },
              {
                createdAt: '2021-09-16T19:45:20.513Z',
                id: '0a00c845-9e14-4db6-9a23-c797558b7f3f',
                listing: [],
                name: 'computer_wall.wav',
                size: null,
              },
            ],
            name: 'granite_mobile.html',
            size: null,
          },
          {
            createdAt: '2021-07-01T20:05:33.913Z',
            id: '707256c8-5fed-434d-a623-51e4bde50e8e',
            listing: [
              {
                createdAt: '2021-03-29T07:52:52.567Z',
                id: 'f1be44aa-d277-4d9e-9bad-113f59115698',
                listing: [],
                name: 'withdrawal_supervisor.wav',
                size: null,
              },
              {
                createdAt: '2021-08-11T16:56:48.546Z',
                id: '90c152af-6ed3-42f4-94a9-707cc05b6478',
                listing: [],
                name: 'pink_plastic_branding.jpe',
                size: null,
              },
              {
                createdAt: '2021-11-30T17:15:24.181Z',
                id: '001cf23f-b795-476c-b497-154078cdbdb7',
                listing: [],
                name: 'grocery_supervisor.mpg',
                size: null,
              },
              {
                createdAt: '2021-03-25T11:22:59.267Z',
                id: '3e176594-ba81-40dc-87d4-c77ab082be13',
                listing: [],
                name: 'carolina.mp4v',
                size: null,
              },
            ],
            name: 'indexing.htm',
            size: null,
          },
          {
            createdAt: '2021-07-08T23:15:35.108Z',
            id: '317f31a9-22d3-476d-8348-4c0b5ae9b6e1',
            listing: [
              {
                createdAt: '2021-09-30T11:33:39.025Z',
                id: '55e52868-b79b-4e74-affe-1b2235b65089',
                listing: [],
                name: 'representative_chair_circuit.jpg',
                size: null,
              },
              {
                createdAt: '2021-04-18T11:07:37.954Z',
                id: 'ac8d237a-f536-4fff-8324-fe58042c8158',
                listing: [],
                name: 'research_bike_industrial.mpe',
                size: null,
              },
              {
                createdAt: '2021-05-24T20:08:16.795Z',
                id: '914ffc7f-4142-40b2-8196-a0a797eca5b3',
                listing: [],
                name: 'shirt.mpga',
                size: null,
              },
              {
                createdAt: '2021-10-13T12:45:29.657Z',
                id: '2c8dfd67-a96e-49b3-966c-812980b5e363',
                listing: [],
                name: 'carolina.png',
                size: null,
              },
              {
                createdAt: '2021-08-18T02:10:23.659Z',
                id: '8b03830a-c761-4dee-88e9-fa5344df6050',
                listing: [],
                name: 'savings.mpg4',
                size: null,
              },
            ],
            name: 'market_content_based.wav',
            size: null,
          },
        ],
        name: 'representative_via.pdf',
        size: null,
      },
      {
        createdAt: '2021-06-23T18:51:18.217Z',
        id: '3a4ab638-cba2-47af-b60e-a4073e83c934',
        listing: [
          {
            createdAt: '2021-06-27T20:40:43.977Z',
            id: 'cc5e6fb3-75b6-4716-9d42-986d80c84407',
            listing: [
              {
                createdAt: '2021-05-04T16:06:57.602Z',
                id: '2b869c50-7f1f-48a9-bce7-be905c12fe74',
                listing: [],
                name: 'account.wav',
                size: null,
              },
              {
                createdAt: '2021-07-15T12:51:11.497Z',
                id: '3860fef9-153b-4a6b-a925-cda7590ed228',
                listing: [],
                name: 'toolset.gif',
                size: null,
              },
              {
                createdAt: '2021-06-07T22:21:44.384Z',
                id: '2f3a0d2d-38df-44a3-98d0-b2dbeb707613',
                listing: [],
                name: 'account_matrix_integrated.mpga',
                size: null,
              },
            ],
            name: 'magenta_sheqel.wav',
            size: null,
          },
          {
            createdAt: '2021-04-01T20:30:18.542Z',
            id: '99a2096d-b1e2-4c9e-991f-fb73b710518e',
            listing: [
              {
                createdAt: '2022-01-03T23:02:27.575Z',
                id: 'ad3dc1bc-aa9e-4ec3-bc30-bb145b8d0c6f',
                listing: [],
                name: 'dynamic.mpeg',
                size: null,
              },
              {
                createdAt: '2021-06-03T11:52:45.220Z',
                id: '01e023dc-b264-4e22-97ec-0b4b50346a4a',
                listing: [],
                name: 'internal.wav',
                size: null,
              },
              {
                createdAt: '2021-02-21T00:41:32.094Z',
                id: 'f0c936e6-6e66-4d2f-b041-0c223aa2b6aa',
                listing: [],
                name: 'input_mouse.png',
                size: null,
              },
            ],
            name: 'http_health.mpeg',
            size: null,
          },
        ],
        name: 'gloves_ergonomic.html',
        size: null,
      },
    ],
    name: 'purple.wav',
    size: null,
  },
  {
    createdAt: '2021-12-07T15:42:06.041Z',
    id: 'b63ff97f-2595-49a9-9619-e5c4a75e8aee',
    listing: [
      {
        createdAt: '2021-09-22T06:35:59.099Z',
        id: '09d6a7d8-082a-40bd-b582-0ab33daf3fb4',
        listing: [
          {
            createdAt: '2021-06-15T08:03:54.264Z',
            id: '20b9ed0d-5a20-442e-aa90-96ff3c0883a8',
            listing: [
              {
                createdAt: '2021-06-17T10:38:17.659Z',
                id: '80c8c41e-b29c-4b04-bf54-14f9699716b4',
                listing: [],
                name: 'avon_tuna.mp4',
                size: null,
              },
              {
                createdAt: '2021-07-27T22:33:49.870Z',
                id: 'b96879c7-7fe9-43b3-84ae-cdeb0e190707',
                listing: [],
                name: 'neutral_locks_users.m1v',
                size: null,
              },
            ],
            name: 'berkshire.shtml',
            size: null,
          },
        ],
        name: 'forecast_payment_soft.mpe',
        size: null,
      },
      {
        createdAt: '2022-01-10T15:00:40.247Z',
        id: 'a8d10a1c-a42c-4b3c-96a1-27b13ea68aa2',
        listing: [
          {
            createdAt: '2021-08-17T11:11:58.553Z',
            id: '5c665890-bdf9-485d-a909-aa27d080b584',
            listing: [
              {
                createdAt: '2021-06-17T07:31:31.626Z',
                id: '7d10d376-f7cc-41d7-919b-deab7026fb3d',
                listing: [],
                name: 'plastic.wav',
                size: null,
              },
              {
                createdAt: '2021-12-04T18:04:34.304Z',
                id: '33a7ccdc-9040-4646-9861-dd8ecebd59da',
                listing: [],
                name: 'computer.wav',
                size: null,
              },
              {
                createdAt: '2021-03-15T15:30:21.051Z',
                id: '680fe4aa-90ae-4753-add1-09110fc084f2',
                listing: [],
                name: 'sms.wav',
                size: null,
              },
            ],
            name: 'quantifying_streets_developer.mpeg',
            size: null,
          },
          {
            createdAt: '2021-08-16T21:47:08.795Z',
            id: 'd52a1a56-1b1a-4189-8618-3fad9dd1cccf',
            listing: [
              {
                createdAt: '2021-08-01T13:27:02.943Z',
                id: '2027ba2f-4310-4dfa-be86-078b5d0398a3',
                listing: [],
                name: 'smtp_heights.pdf',
                size: null,
              },
              {
                createdAt: '2021-02-26T03:35:43.932Z',
                id: 'b7c70010-e6b8-41b0-8792-5d8f90195686',
                listing: [],
                name: 'rubber_district_oklahoma.pdf',
                size: null,
              },
            ],
            name: 'usability.mp4',
            size: null,
          },
          {
            createdAt: '2021-10-22T16:23:25.370Z',
            id: '7e186330-469a-4525-95e4-747327484599',
            listing: [
              {
                createdAt: '2021-12-15T08:25:19.395Z',
                id: 'b3333a25-5b23-4790-b6d8-c7544f0b3e69',
                listing: [],
                name: 'invoice_rico.shtml',
                size: null,
              },
              {
                createdAt: '2021-07-10T18:22:44.305Z',
                id: '27a93af6-e275-46e9-8d24-a94613141d60',
                listing: [],
                name: 'buckinghamshire_sas_approach.gif',
                size: null,
              },
            ],
            name: 'applications_tolerance_rubber.png',
            size: null,
          },
          {
            createdAt: '2021-05-28T20:09:31.509Z',
            id: 'f818dd9a-f68c-438a-91df-4709f44bf2c4',
            listing: [
              {
                createdAt: '2021-09-29T09:24:19.248Z',
                id: 'fe58c5c3-25cf-4d9c-a6c6-712f61febb54',
                listing: [],
                name: 'programming.wav',
                size: null,
              },
            ],
            name: 'synthesizing_networked.mp4v',
            size: null,
          },
        ],
        name: 'hat_mission_critical_out_of_the_box.mp4v',
        size: null,
      },
    ],
    name: 'experiences_seychelles_smtp.png',
    size: null,
  },
  {
    createdAt: '2021-04-18T21:07:57.785Z',
    id: 'a8f2c5e2-9083-4534-9186-23167ba5c009',
    listing: [
      {
        createdAt: '2021-05-27T09:10:08.999Z',
        id: 'eff09eb8-28e4-4132-b89d-f6aa70d5702e',
        listing: [
          {
            createdAt: '2021-06-13T20:51:45.809Z',
            id: 'a7af6a8c-2325-4ecf-a67b-565eafcb0948',
            listing: [
              {
                createdAt: '2021-06-16T04:13:24.937Z',
                id: '84bda1c2-457f-440f-b7e7-52cb54cc5430',
                listing: [],
                name: 'engage.m2v',
                size: null,
              },
              {
                createdAt: '2022-02-10T03:53:18.546Z',
                id: '1e3bcd17-be0c-49ad-868c-ed94ddefee92',
                listing: [],
                name: 'sql_front_end_shirt.png',
                size: null,
              },
              {
                createdAt: '2021-03-10T09:40:35.409Z',
                id: '07a36865-439a-4833-84c4-36017ee53538',
                listing: [],
                name: 'azure.mp4v',
                size: null,
              },
            ],
            name: 'quality_focused.mp4v',
            size: null,
          },
        ],
        name: 'cultivate_adp_exe.wav',
        size: null,
      },
      {
        createdAt: '2021-06-24T22:30:14.410Z',
        id: '7654f144-3497-422f-9467-1ec018461d1a',
        listing: [
          {
            createdAt: '2021-04-07T07:42:02.163Z',
            id: '04eaa449-68c0-48f3-9445-b154a25f594c',
            listing: [
              {
                createdAt: '2021-07-18T06:25:24.620Z',
                id: '5d3cac8e-60e3-4bc8-b032-7f3712b0af7f',
                listing: [],
                name: 'bleeding_edge_villages.mp4v',
                size: null,
              },
              {
                createdAt: '2021-08-15T00:01:36.881Z',
                id: 'ed18d4b7-7e77-412f-aa3e-df7334818757',
                listing: [],
                name: 'synergistic.jpe',
                size: null,
              },
              {
                createdAt: '2021-05-28T17:36:09.690Z',
                id: '92f29019-14c1-4a56-8ccf-2b225f5bcbc1',
                listing: [],
                name: 'avon_personal.mpe',
                size: null,
              },
              {
                createdAt: '2021-06-30T16:45:46.243Z',
                id: 'a821d827-5db0-4be8-8c14-e40617176853',
                listing: [],
                name: 'peso.mp4v',
                size: null,
              },
            ],
            name: 'producer_polarised.jpeg',
            size: null,
          },
          {
            createdAt: '2021-11-27T12:52:12.731Z',
            id: '73869445-918f-419d-bd03-01b5bf92c642',
            listing: [
              {
                createdAt: '2022-01-14T06:08:44.804Z',
                id: '1296b840-f94c-4e0a-a2b4-e4e2eb11fb50',
                listing: [],
                name: 'bacon_sdd_clicks_and_mortar.wav',
                size: null,
              },
              {
                createdAt: '2022-01-11T06:54:31.826Z',
                id: '7e150de6-9d93-40eb-acfa-cf2f2fde3e10',
                listing: [],
                name: 'designer_berkshire.png',
                size: null,
              },
            ],
            name: 'enterprise_green.jpg',
            size: null,
          },
          {
            createdAt: '2021-03-29T04:53:10.311Z',
            id: 'e5efbf89-c446-44fe-affc-ede653431f62',
            listing: [
              {
                createdAt: '2021-03-05T20:11:42.118Z',
                id: 'afa12416-76ff-4866-be79-a1724fe5ba4f',
                listing: [],
                name: 'soft_outdoors.gif',
                size: null,
              },
              {
                createdAt: '2021-08-08T12:40:33.417Z',
                id: '2865e606-36a2-48cc-aa7b-5aa7876123e8',
                listing: [],
                name: 'primary_iran.pdf',
                size: null,
              },
              {
                createdAt: '2022-01-05T10:49:11.043Z',
                id: '4c124581-3e0f-4a0d-9126-4cd4d211010c',
                listing: [],
                name: 'intelligent_garden.wav',
                size: null,
              },
            ],
            name: 'computer_accountability_sleek.m2v',
            size: null,
          },
        ],
        name: 'guam_harbor.mp4',
        size: null,
      },
      {
        createdAt: '2021-03-11T13:01:33.172Z',
        id: 'e8b247bc-2142-4005-9fbb-ee30d4d4983d',
        listing: [
          {
            createdAt: '2021-03-30T02:54:17.218Z',
            id: '52ea3521-b8d9-4ffb-a721-aecedeb72729',
            listing: [
              {
                createdAt: '2021-10-26T04:00:18.255Z',
                id: '4f01b847-9156-4686-beb7-1d87e2657170',
                listing: [],
                name: 'timor_leste.mp4',
                size: null,
              },
            ],
            name: 'developer_indiana.mpg4',
            size: null,
          },
          {
            createdAt: '2022-01-31T15:29:15.899Z',
            id: '3d8a8b13-cf19-456f-bd9d-7b16b905a1b6',
            listing: [
              {
                createdAt: '2021-12-24T17:43:15.172Z',
                id: '4192cf92-b035-4ba9-afe2-d53bc0cac308',
                listing: [],
                name: 'vertical.jpg',
                size: null,
              },
              {
                createdAt: '2021-08-19T00:37:23.448Z',
                id: 'bf7198a9-d15e-4798-82a4-067b5da91537',
                listing: [],
                name: 'metal.mpg',
                size: null,
              },
              {
                createdAt: '2021-09-05T09:07:06.986Z',
                id: '97fca698-b016-4da4-a129-866349109f5e',
                listing: [],
                name: 'installation_calculating.gif',
                size: null,
              },
              {
                createdAt: '2021-09-01T17:10:09.156Z',
                id: '5dd0a9f1-6c93-4fd4-8275-b1872b2365cc',
                listing: [],
                name: 'account_orange_beauty.mp4',
                size: null,
              },
              {
                createdAt: '2021-05-15T21:05:59.098Z',
                id: '342e1f1e-6f2f-4d67-8846-a89884d0392e',
                listing: [],
                name: 'granite.gif',
                size: null,
              },
            ],
            name: 'legacy.mp3',
            size: null,
          },
        ],
        name: 'methodologies_connect_migration.mp2',
        size: null,
      },
      {
        createdAt: '2021-09-03T10:21:01.807Z',
        id: 'c0e33b29-27d9-46f7-b46b-2e6627d6dbfe',
        listing: [
          {
            createdAt: '2021-08-27T13:30:48.073Z',
            id: '02f69a5a-fe4d-4e7c-afe1-cc1581280b56',
            listing: [
              {
                createdAt: '2021-05-07T12:31:09.911Z',
                id: '990c5950-24a0-4e94-a07c-38862067e985',
                listing: [],
                name: 'mobile_internal_investment.mp4v',
                size: null,
              },
              {
                createdAt: '2021-05-11T13:17:12.946Z',
                id: 'c67e9b92-6323-4340-b37c-bfd259d9139f',
                listing: [],
                name: 'guernsey_multi_byte.mp4',
                size: null,
              },
              {
                createdAt: '2021-09-03T15:21:37.173Z',
                id: '27fa65ad-69cd-4120-87d2-96a20295d261',
                listing: [],
                name: 'avon_connecting_rubber.mp2',
                size: null,
              },
              {
                createdAt: '2021-02-24T23:27:42.315Z',
                id: 'f08ffabc-9eca-4a5e-ae31-577cf2e36d08',
                listing: [],
                name: 'optimizing_ouguiya.html',
                size: null,
              },
            ],
            name: 'neck_manor.jpg',
            size: null,
          },
          {
            createdAt: '2021-07-20T21:15:58.827Z',
            id: '7fdf6e70-140b-40b2-a8bd-27a62d344c75',
            listing: [
              {
                createdAt: '2021-05-22T06:00:43.942Z',
                id: 'da347b1c-441a-41d4-a54e-ca2c0e6c49f0',
                listing: [],
                name: 'microchip_chief.mpe',
                size: null,
              },
            ],
            name: 'upward_trending_best_of_breed.m2v',
            size: null,
          },
          {
            createdAt: '2021-08-21T19:08:27.289Z',
            id: '40e4523e-6fb1-4459-b596-377a83a8942b',
            listing: [
              {
                createdAt: '2021-08-25T13:03:30.572Z',
                id: '94d51f02-0857-4ccf-b9e0-8477537e6966',
                listing: [],
                name: 'accountability.html',
                size: null,
              },
            ],
            name: 'distributed_killer.m2v',
            size: null,
          },
          {
            createdAt: '2021-12-07T23:35:09.125Z',
            id: '07192bdb-33a1-4ce2-942a-e3c790cd2a6f',
            listing: [
              {
                createdAt: '2021-03-06T18:01:17.413Z',
                id: 'af025be3-a5c0-40f8-8a3e-89c157064b17',
                listing: [],
                name: 'override.m1v',
                size: null,
              },
              {
                createdAt: '2021-10-27T12:44:23.696Z',
                id: '930f52a5-e012-4092-aa35-b57d82f98183',
                listing: [],
                name: '24_7_e_commerce.gif',
                size: null,
              },
            ],
            name: 'auto.m3a',
            size: null,
          },
        ],
        name: 'disintermediate.mpg4',
        size: null,
      },
      {
        createdAt: '2021-08-26T21:02:01.068Z',
        id: '451f05f3-1b6d-4611-b4b5-5677859a5a22',
        listing: [
          {
            createdAt: '2021-05-31T11:42:51.422Z',
            id: '186562c8-2720-406f-99c6-1467bc0c48c2',
            listing: [
              {
                createdAt: '2022-02-16T16:20:26.914Z',
                id: '0f1d446b-917c-47a1-a65a-04cdc614078a',
                listing: [],
                name: 'tan_polarised.jpg',
                size: null,
              },
              {
                createdAt: '2021-07-12T18:29:37.799Z',
                id: '3d4d1e50-b56e-40fe-9daa-0e091a075404',
                listing: [],
                name: 'borders_marketing_innovative.htm',
                size: null,
              },
              {
                createdAt: '2021-03-13T07:47:04.195Z',
                id: '43f963d2-006e-4b7c-9e38-d98c0a3e8acd',
                listing: [],
                name: 'set_table_fish.mp4',
                size: null,
              },
              {
                createdAt: '2021-04-13T11:22:30.710Z',
                id: '506fb19b-387a-46fb-9d59-3d3018ac849d',
                listing: [],
                name: 'automotive_prairie_rwanda.m1v',
                size: null,
              },
              {
                createdAt: '2021-11-18T04:50:10.931Z',
                id: 'b397677b-9e4f-4b1a-942e-0a2cd3461266',
                listing: [],
                name: 'azure_corporate.gif',
                size: null,
              },
            ],
            name: 'interface_monitored.gif',
            size: null,
          },
        ],
        name: 'health_chair_digitized.pdf',
        size: null,
      },
    ],
    name: 'toys_impactful.html',
    size: null,
  },
  {
    createdAt: '2021-03-07T10:52:07.477Z',
    id: '2a61d108-0383-4fc6-a6e2-4f8698e65f05',
    listing: [
      {
        createdAt: '2021-03-17T02:32:36.087Z',
        id: 'c1a1b823-dd87-4e6c-9fd3-2ca5145c734a',
        listing: [
          {
            createdAt: '2022-01-30T03:16:01.738Z',
            id: '523cd013-dd15-48d2-88af-773d7f4535b3',
            listing: [
              {
                createdAt: '2021-07-21T08:03:50.480Z',
                id: '89727233-09c6-4ff4-be6e-4ede36c47ee6',
                listing: [],
                name: 'partnerships_virtual.mp4',
                size: null,
              },
            ],
            name: 'wireless_beauty.pdf',
            size: null,
          },
          {
            createdAt: '2021-05-26T06:48:22.501Z',
            id: '060b3d4c-72b6-4e9f-a693-228891289a8b',
            listing: [
              {
                createdAt: '2021-06-24T13:58:25.931Z',
                id: '809f1b20-9cb0-480b-be9d-a353b567760d',
                listing: [],
                name: 'revolutionize_transmitting.htm',
                size: null,
              },
              {
                createdAt: '2021-07-05T01:19:35.242Z',
                id: '9761708c-3c56-4b0e-ba9d-d8c963d55c76',
                listing: [],
                name: 'exuding.jpe',
                size: null,
              },
              {
                createdAt: '2022-02-15T17:13:43.876Z',
                id: 'd14b7e0b-1adc-4172-bbb1-831729610133',
                listing: [],
                name: 'steel_antigua.html',
                size: null,
              },
              {
                createdAt: '2022-01-10T12:46:04.233Z',
                id: '0c037806-6b19-4cce-ae3d-2d83b8d0be36',
                listing: [],
                name: 'account_concrete_montserrat.pdf',
                size: null,
              },
            ],
            name: 'lithuania_bluetooth.m2v',
            size: null,
          },
        ],
        name: 'islands_application.mp4v',
        size: null,
      },
      {
        createdAt: '2021-10-02T16:04:02.968Z',
        id: '8a94b47e-e7b0-4478-a479-a67006d2b176',
        listing: [
          {
            createdAt: '2021-05-31T09:01:34.558Z',
            id: '9856bc69-8c07-4e01-ae18-7317dd107018',
            listing: [
              {
                createdAt: '2021-08-31T11:56:35.389Z',
                id: 'fed77614-a330-4155-8b06-6084f5201524',
                listing: [],
                name: 'bedfordshire_extensible_fantastic.mpg4',
                size: null,
              },
              {
                createdAt: '2021-06-05T05:07:34.643Z',
                id: '2e4484cb-ca52-4200-b5ed-01f60775c37e',
                listing: [],
                name: 'small_loan_multi_byte.mp2',
                size: null,
              },
            ],
            name: 'monitor_awesome_architect.wav',
            size: null,
          },
        ],
        name: 'account_advanced.jpeg',
        size: null,
      },
    ],
    name: 'intuitive_borders.wav',
    size: null,
  },
  {
    createdAt: '2021-05-10T06:55:01.215Z',
    id: '7767de8a-211d-4619-967a-b95d57d9e270',
    listing: [
      {
        createdAt: '2021-07-20T05:51:58.876Z',
        id: '1973631d-1340-4a3a-914b-022dd99c5871',
        listing: [
          {
            createdAt: '2021-08-14T19:45:17.288Z',
            id: '742f7ccf-06ac-45cb-ba29-2c48f995374c',
            listing: [
              {
                createdAt: '2021-07-03T02:04:48.507Z',
                id: '03ac95a3-7f3b-4e58-857b-74448e7991aa',
                listing: [],
                name: 'markets_pci.m2v',
                size: null,
              },
              {
                createdAt: '2021-12-23T07:22:09.504Z',
                id: '00788fda-b502-4a1f-b567-86f8b5b15bbd',
                listing: [],
                name: 'borders.shtml',
                size: null,
              },
              {
                createdAt: '2022-01-12T13:56:10.395Z',
                id: '9792a631-ed66-4ebd-b2d4-d0bb723913f2',
                listing: [],
                name: 'account_transmitting_vortals.png',
                size: null,
              },
              {
                createdAt: '2021-10-25T23:49:47.801Z',
                id: '2efa28fc-544e-4d30-a9f0-e9d68354871d',
                listing: [],
                name: 'personal.jpg',
                size: null,
              },
            ],
            name: 'small_oman.gif',
            size: null,
          },
          {
            createdAt: '2022-02-02T06:41:46.397Z',
            id: 'ca0c826c-067c-4f37-8305-35c39952d0bc',
            listing: [
              {
                createdAt: '2021-02-23T08:39:54.277Z',
                id: 'a2cb9c65-d00b-4237-9db7-88285e13bc93',
                listing: [],
                name: 'next_generation.htm',
                size: null,
              },
              {
                createdAt: '2021-09-30T08:43:46.213Z',
                id: '8a9a0f8a-955e-4ea4-83cd-2338e8ff8437',
                listing: [],
                name: 'copying_central.pdf',
                size: null,
              },
            ],
            name: 'refined_multi_byte_experiences.wav',
            size: null,
          },
          {
            createdAt: '2021-10-15T23:08:16.759Z',
            id: 'b19fc2f7-ce72-4705-be1a-2d378a7d6db3',
            listing: [
              {
                createdAt: '2021-06-14T05:33:57.563Z',
                id: '6b2704cb-540e-4880-81ce-90baf59cad5e',
                listing: [],
                name: 'avon_kwacha_strategist.pdf',
                size: null,
              },
              {
                createdAt: '2021-10-16T00:31:07.080Z',
                id: '686813eb-d9cf-4982-b031-70401f9ab67a',
                listing: [],
                name: 'withdrawal.htm',
                size: null,
              },
              {
                createdAt: '2021-05-05T09:20:25.913Z',
                id: 'dc8af0dc-a0df-48b8-a40e-204f90d2962a',
                listing: [],
                name: 'target_generating_carolina.mp3',
                size: null,
              },
            ],
            name: 'baby_monaco_tcp.shtml',
            size: null,
          },
          {
            createdAt: '2021-02-25T06:29:30.154Z',
            id: '411823ca-af23-4530-be76-93372cd04976',
            listing: [
              {
                createdAt: '2021-11-18T01:35:58.455Z',
                id: '2c5d3958-43d1-4069-855a-0fc48843e10a',
                listing: [],
                name: 'account_maine.jpeg',
                size: null,
              },
              {
                createdAt: '2021-04-06T19:26:52.095Z',
                id: '470b5c82-3cee-49fd-b2e0-2a6030bb4aa6',
                listing: [],
                name: 'cotton_front_end_public_key.jpeg',
                size: null,
              },
              {
                createdAt: '2021-08-08T15:20:44.292Z',
                id: 'f3c88b94-c075-4310-88ee-4d7d393a026f',
                listing: [],
                name: 'up_multi_layered.pdf',
                size: null,
              },
              {
                createdAt: '2021-05-08T16:26:25.313Z',
                id: '569bab9b-15a5-4098-8678-9b35def47dc3',
                listing: [],
                name: 'compressing_open_source.mpg',
                size: null,
              },
            ],
            name: 'matrix_invoice_rubber.pdf',
            size: null,
          },
          {
            createdAt: '2021-04-13T15:02:29.712Z',
            id: '9dfd669a-76e3-47c0-97d8-5e050784d570',
            listing: [
              {
                createdAt: '2021-03-29T17:23:06.396Z',
                id: '5a4aebd1-2077-4a1e-a6d6-4f428f1a41ea',
                listing: [],
                name: 'content.pdf',
                size: null,
              },
              {
                createdAt: '2021-11-15T03:08:26.204Z',
                id: 'e384a0ef-1c38-4f18-a197-931aacced107',
                listing: [],
                name: 'functionality_navigating.mpg4',
                size: null,
              },
              {
                createdAt: '2021-09-06T06:08:12.300Z',
                id: '983b60e6-583f-4fd9-b571-a97fd01edc46',
                listing: [],
                name: 'bedfordshire_deliverables.mpg',
                size: null,
              },
            ],
            name: 'bricks_and_clicks.htm',
            size: null,
          },
        ],
        name: 'upward_trending_bottom_line.png',
        size: null,
      },
      {
        createdAt: '2021-04-06T07:31:13.072Z',
        id: '6780844c-bd8b-489d-8452-77ad3e4d960b',
        listing: [
          {
            createdAt: '2021-11-01T01:38:59.871Z',
            id: '6e84b9a9-123a-4829-a5d7-d6b4e01cfd1e',
            listing: [
              {
                createdAt: '2021-11-30T17:15:50.978Z',
                id: 'fb7c9bf7-7c66-43d2-9295-debd150b0643',
                listing: [],
                name: 'ram.mpg',
                size: null,
              },
              {
                createdAt: '2022-01-24T00:40:26.336Z',
                id: 'c2b52497-e583-4703-a31b-71a31a7bfdf1',
                listing: [],
                name: 'technician_metrics.gif',
                size: null,
              },
              {
                createdAt: '2021-07-13T23:36:10.463Z',
                id: 'b64b72c3-5355-4c6d-b9ef-03b58924e095',
                listing: [],
                name: 'relationships_senior_jamahiriya.jpeg',
                size: null,
              },
              {
                createdAt: '2021-04-15T23:48:43.025Z',
                id: 'd0096899-9a90-4b20-b015-3812db780941',
                listing: [],
                name: 'open_source_architecture.pdf',
                size: null,
              },
              {
                createdAt: '2021-11-11T14:37:34.188Z',
                id: 'e687dc8c-8adf-4e7c-9396-8f61241c935e',
                listing: [],
                name: 'sausages_direct_multi_tasking.png',
                size: null,
              },
            ],
            name: 'reintermediate_assistant.mp2a',
            size: null,
          },
          {
            createdAt: '2022-01-06T04:36:52.719Z',
            id: '3c35acaf-20cf-4e45-a4b9-3c34802fe1ae',
            listing: [
              {
                createdAt: '2021-03-15T04:43:32.271Z',
                id: '9b3425c7-a323-4b54-a797-231fa7933b27',
                listing: [],
                name: 'mobility_hack_tcp.pdf',
                size: null,
              },
              {
                createdAt: '2021-04-03T08:48:17.954Z',
                id: '6f72b6e7-598b-4163-88c7-959350d6ced6',
                listing: [],
                name: 'officer_white.wav',
                size: null,
              },
              {
                createdAt: '2021-12-25T13:39:04.056Z',
                id: 'c64d08db-9f25-4203-b2bf-9949807e87df',
                listing: [],
                name: '3rd_concrete_bifurcated.png',
                size: null,
              },
            ],
            name: 'communications_officer_deposit.mpe',
            size: null,
          },
        ],
        name: 'personal_auto.jpg',
        size: null,
      },
    ],
    name: 'south_orchestration_integrated.png',
    size: null,
  },
  {
    createdAt: '2021-06-15T02:46:06.674Z',
    id: 'ec7f4f17-3789-43b8-992f-983ddb2fcbb7',
    listing: [
      {
        createdAt: '2021-07-23T18:27:25.770Z',
        id: '61a917e9-b03f-4d8a-996c-eac1347b1ff4',
        listing: [
          {
            createdAt: '2021-11-01T05:20:31.655Z',
            id: 'f8ed9dc9-5243-403b-a821-973e4c4d3b35',
            listing: [
              {
                createdAt: '2021-02-24T21:36:20.553Z',
                id: '655c7f86-87a8-4d9e-9edb-f4fe9f5a7fd9',
                listing: [],
                name: 'georgia_nevada_sri.pdf',
                size: null,
              },
              {
                createdAt: '2021-03-09T12:23:13.543Z',
                id: '63d9227e-0d2b-4e22-aaee-625d9d7fb1cd',
                listing: [],
                name: 'mauritius_microchip_invoice.pdf',
                size: null,
              },
              {
                createdAt: '2021-04-21T13:37:40.503Z',
                id: 'f8cf068a-cb16-423c-b5a1-0057206da522',
                listing: [],
                name: 'transition.mp2a',
                size: null,
              },
              {
                createdAt: '2022-02-14T16:12:32.230Z',
                id: 'e9c41adf-9fd0-437b-ae63-31e08c6de819',
                listing: [],
                name: 'asynchronous_infomediaries_embrace.gif',
                size: null,
              },
              {
                createdAt: '2021-12-16T17:09:32.625Z',
                id: '9d3eaba5-ccdd-4366-95b0-88ee78855bca',
                listing: [],
                name: 'chad_corporate.shtml',
                size: null,
              },
            ],
            name: 'representative.mp4v',
            size: null,
          },
          {
            createdAt: '2021-07-30T03:32:30.521Z',
            id: '3afab4e0-05a5-4334-b25a-b5bc01e7eb8a',
            listing: [
              {
                createdAt: '2021-03-23T20:11:46.118Z',
                id: 'b38f2b3b-1f9e-4d85-bf3d-c85faf0ef226',
                listing: [],
                name: 'parsing.html',
                size: null,
              },
              {
                createdAt: '2021-09-13T09:21:00.264Z',
                id: '74b50e12-b0b1-4f90-9e61-6db027698e65',
                listing: [],
                name: 'chips.mp4v',
                size: null,
              },
              {
                createdAt: '2021-07-07T16:26:21.965Z',
                id: 'cefa110d-7c1f-4c9b-a89b-8701b6435f56',
                listing: [],
                name: 'compelling_1080p.pdf',
                size: null,
              },
              {
                createdAt: '2022-01-26T15:32:05.693Z',
                id: 'f77f7dc6-0eed-46d7-a42f-3760077de692',
                listing: [],
                name: 'indexing_peso_leading_edge.png',
                size: null,
              },
            ],
            name: 'solutions_licensed_macao.png',
            size: null,
          },
          {
            createdAt: '2022-01-02T15:14:25.641Z',
            id: '129c5a3f-a86a-4de4-a5da-268aa720cfc8',
            listing: [
              {
                createdAt: '2021-12-18T08:58:41.808Z',
                id: '243ec1c9-7b77-4208-a2e1-6d9e4c6ac76b',
                listing: [],
                name: 'music_computers_purple.png',
                size: null,
              },
              {
                createdAt: '2021-05-31T20:26:02.340Z',
                id: 'a60bceda-b30f-4f3d-bd04-f64be9ef29e0',
                listing: [],
                name: 'interactions_cross_platform_integration.pdf',
                size: null,
              },
              {
                createdAt: '2021-04-22T04:42:34.430Z',
                id: 'd3ddbe6e-8536-43f1-885d-17bb73f22805',
                listing: [],
                name: 'response.png',
                size: null,
              },
              {
                createdAt: '2021-07-24T20:23:07.213Z',
                id: '136651e7-7120-4ba5-8ad5-9c5a577c68f0',
                listing: [],
                name: 'avon.wav',
                size: null,
              },
              {
                createdAt: '2021-04-04T18:15:00.038Z',
                id: '83cb15a4-118e-44ca-9444-ff4529599ff5',
                listing: [],
                name: 'corporate.mp4',
                size: null,
              },
            ],
            name: 'deposit_innovate.wav',
            size: null,
          },
          {
            createdAt: '2021-03-08T19:49:37.148Z',
            id: 'edd84ae1-c969-4af3-804f-f95dd75790bf',
            listing: [
              {
                createdAt: '2021-09-21T08:30:18.707Z',
                id: '52c4383d-beef-4b64-bc15-381274b02062',
                listing: [],
                name: 'soap_next_generation_robust.m2a',
                size: null,
              },
              {
                createdAt: '2021-04-27T08:15:16.087Z',
                id: 'ff665049-2f73-4b03-ae5b-d893c3274bba',
                listing: [],
                name: 'convergence_curve_transition.mp4',
                size: null,
              },
            ],
            name: 'white_identity.jpe',
            size: null,
          },
        ],
        name: 'communities_multi_byte_denmark.shtml',
        size: null,
      },
      {
        createdAt: '2021-03-23T19:23:57.973Z',
        id: '547b8470-ae22-4bc9-9f94-c898641502fd',
        listing: [
          {
            createdAt: '2021-11-30T02:46:24.548Z',
            id: '3c48ba98-2e14-458f-8caa-d7cc2e230569',
            listing: [
              {
                createdAt: '2021-12-04T22:44:04.427Z',
                id: 'ed428c32-72fe-495e-85cd-dddd56f19b47',
                listing: [],
                name: 'loan_clothing_blue.m1v',
                size: null,
              },
              {
                createdAt: '2021-03-13T11:45:10.513Z',
                id: '19e1da74-2f84-4ed2-bcda-3aeb7406424f',
                listing: [],
                name: 'practical_proactive.gif',
                size: null,
              },
              {
                createdAt: '2021-10-11T13:07:45.599Z',
                id: '4a5552e6-13d5-4f6c-97a0-8097f238d60e',
                listing: [],
                name: 'agp_css_triple_buffered.png',
                size: null,
              },
            ],
            name: 'reboot.mp4v',
            size: null,
          },
          {
            createdAt: '2021-10-11T02:41:30.885Z',
            id: '497eba71-8458-4e0f-ad43-e2bd3b1a11b0',
            listing: [
              {
                createdAt: '2021-12-14T02:08:13.786Z',
                id: '4169228e-3a6d-409a-90ba-642942e155da',
                listing: [],
                name: 'music.pdf',
                size: null,
              },
              {
                createdAt: '2021-12-10T19:19:15.919Z',
                id: 'f00dc5be-a3f3-4515-ad3f-f5f5995a4889',
                listing: [],
                name: 'awesome.png',
                size: null,
              },
            ],
            name: 'uganda_function.m3a',
            size: null,
          },
          {
            createdAt: '2021-06-15T23:18:14.206Z',
            id: 'cbd5c369-07d8-43b0-af24-8dcd528ab8ef',
            listing: [
              {
                createdAt: '2021-09-09T03:28:40.751Z',
                id: '9db25698-89b0-4587-936f-0f96f5188a55',
                listing: [],
                name: 'matrices.gif',
                size: null,
              },
              {
                createdAt: '2021-06-07T07:46:57.230Z',
                id: '67550f2f-f62d-49c6-bbc0-ca08547ad685',
                listing: [],
                name: 'horizontal_account.shtml',
                size: null,
              },
              {
                createdAt: '2021-09-19T16:24:37.726Z',
                id: 'f8e0aafc-0089-433e-9ec9-0a6ac5bdb43a',
                listing: [],
                name: 'engineer.mpga',
                size: null,
              },
              {
                createdAt: '2021-09-09T07:29:43.067Z',
                id: '8ea832b2-97cf-4579-a3ef-54f71eb37f67',
                listing: [],
                name: 'peso_kentucky.jpe',
                size: null,
              },
              {
                createdAt: '2022-02-03T22:46:29.859Z',
                id: 'e79d34ae-3fe4-4cd9-b410-85a07eda3151',
                listing: [],
                name: 'sausages_wooden.htm',
                size: null,
              },
            ],
            name: 'ergonomic.mpe',
            size: null,
          },
          {
            createdAt: '2021-06-20T20:05:28.461Z',
            id: 'd6190e32-7e55-4fc5-8b15-720b9d3fdda3',
            listing: [
              {
                createdAt: '2021-08-22T22:03:13.659Z',
                id: 'b0e5a2b0-1ac2-415a-bf50-7584bfc62e95',
                listing: [],
                name: 'reactive.jpg',
                size: null,
              },
              {
                createdAt: '2021-09-15T16:13:25.524Z',
                id: '40775fa6-c6c6-46c3-b05e-6884c1183e0f',
                listing: [],
                name: 'indigo_dynamic_paradigm.jpg',
                size: null,
              },
              {
                createdAt: '2021-10-13T22:55:26.537Z',
                id: '378110a6-5307-413c-82cf-8ab78773b128',
                listing: [],
                name: 'chad.mp4v',
                size: null,
              },
              {
                createdAt: '2021-05-19T16:16:33.433Z',
                id: '0cc98e6e-422e-461e-9cc4-9b4e0a0afc7c',
                listing: [],
                name: 'handmade.gif',
                size: null,
              },
            ],
            name: 'hdd.mp2',
            size: null,
          },
        ],
        name: 'salmon_parsing_technician.png',
        size: null,
      },
      {
        createdAt: '2021-04-22T19:06:51.607Z',
        id: '9d0634bd-0e0c-43b8-b322-e0d247fa15eb',
        listing: [
          {
            createdAt: '2021-04-04T03:02:36.285Z',
            id: '034c8db1-6685-4e7b-b31e-e1f77a97f3f6',
            listing: [
              {
                createdAt: '2022-01-11T12:13:03.941Z',
                id: '47d64bf0-2ffe-4be7-8b6c-f61aa5bf1d1e',
                listing: [],
                name: 'haptic_engage_index.html',
                size: null,
              },
            ],
            name: 'denar.html',
            size: null,
          },
          {
            createdAt: '2021-10-05T18:28:54.242Z',
            id: '1b326ef3-2fde-470c-add8-ff11eda78c9a',
            listing: [
              {
                createdAt: '2021-09-21T00:49:47.018Z',
                id: 'ad173352-935b-4767-89ad-4a6bd1989734',
                listing: [],
                name: 'manager.htm',
                size: null,
              },
            ],
            name: 'haptic_incredible_methodical.mp2a',
            size: null,
          },
          {
            createdAt: '2021-06-13T13:52:55.041Z',
            id: '1d0c5cdb-2568-4d84-b55f-795ad11e3e65',
            listing: [
              {
                createdAt: '2021-06-09T21:28:00.727Z',
                id: 'f57921a8-d887-4851-a26f-824099cab2b1',
                listing: [],
                name: 'specialist_attitude.mp3',
                size: null,
              },
              {
                createdAt: '2021-09-03T17:15:28.324Z',
                id: '59b67282-436f-4445-9c8e-f8c515a12490',
                listing: [],
                name: 'pitcairn_soft_response.pdf',
                size: null,
              },
            ],
            name: 'rubber.pdf',
            size: null,
          },
        ],
        name: 'online_avon_movies.png',
        size: null,
      },
    ],
    name: 'circuit.wav',
    size: null,
  },
  {
    createdAt: '2021-08-30T14:28:39.597Z',
    id: 'e6263354-304b-4cd1-8f8e-b13a73ced0b1',
    listing: [
      {
        createdAt: '2021-06-08T08:39:50.701Z',
        id: '0e460cea-c9a8-4c54-b8fb-342dc43fdd9d',
        listing: [
          {
            createdAt: '2021-03-28T01:00:28.664Z',
            id: '71503fa4-92ee-431a-b211-e2c26bafe4d1',
            listing: [
              {
                createdAt: '2021-11-26T12:11:01.706Z',
                id: '779ab71c-028d-4180-b9be-11ae6b433b43',
                listing: [],
                name: 'refined.jpe',
                size: null,
              },
              {
                createdAt: '2021-05-19T16:08:20.782Z',
                id: '7553980b-8a8a-481c-9abd-245666fb9f42',
                listing: [],
                name: 'ib.gif',
                size: null,
              },
            ],
            name: 'plastic_soft_seamless.gif',
            size: null,
          },
          {
            createdAt: '2021-03-18T04:43:53.623Z',
            id: 'ae97d543-5da9-426b-99cf-170fc07318be',
            listing: [
              {
                createdAt: '2021-04-27T11:22:44.692Z',
                id: 'db5135b4-da18-4787-bafe-76b0c79e0ce8',
                listing: [],
                name: 'won.jpe',
                size: null,
              },
              {
                createdAt: '2021-03-09T05:11:28.947Z',
                id: '3e96cad6-927f-4bcd-951a-22010c76de97',
                listing: [],
                name: 'ram.gif',
                size: null,
              },
              {
                createdAt: '2021-08-24T03:07:40.208Z',
                id: '8e13f5f6-49df-458f-961a-6c0fbe4f6c8c',
                listing: [],
                name: 'port_4th_web_readiness.png',
                size: null,
              },
              {
                createdAt: '2021-07-23T05:13:50.810Z',
                id: '4bb2b3b6-e958-4e65-95ec-cc739a314210',
                listing: [],
                name: 'connect_borders.pdf',
                size: null,
              },
              {
                createdAt: '2021-05-23T23:14:25.507Z',
                id: 'c3e83213-8a54-4bb4-8b65-988c9c09dbf5',
                listing: [],
                name: 'concept_clothing.mpe',
                size: null,
              },
            ],
            name: 'incredible_program.pdf',
            size: null,
          },
          {
            createdAt: '2021-05-06T23:36:39.741Z',
            id: '30ec756a-25c5-428a-89a3-ad1e0d8eea19',
            listing: [
              {
                createdAt: '2021-07-16T09:10:07.974Z',
                id: '178f74e4-849e-4275-a4ad-53c776975597',
                listing: [],
                name: 'compressing.mp4v',
                size: null,
              },
            ],
            name: 'bike.mp4',
            size: null,
          },
        ],
        name: 'direct.jpeg',
        size: null,
      },
      {
        createdAt: '2021-12-24T16:39:16.630Z',
        id: 'd105331e-5ac3-4dbf-823a-ff3168ab34a7',
        listing: [
          {
            createdAt: '2021-09-11T08:56:04.615Z',
            id: '0cd197a9-9890-4b31-859a-a375bddf5a56',
            listing: [
              {
                createdAt: '2021-08-20T22:00:17.334Z',
                id: 'a8831abf-a62a-42d2-865d-a9268a8106f0',
                listing: [],
                name: 'unbranded_transmit_electronics.m2v',
                size: null,
              },
              {
                createdAt: '2021-03-25T04:14:58.747Z',
                id: '5c5b777c-78e6-485f-b424-a7d9f5177fc0',
                listing: [],
                name: 'director.gif',
                size: null,
              },
            ],
            name: 'soft_industrial_mexico.mpeg',
            size: null,
          },
          {
            createdAt: '2021-09-26T05:13:16.180Z',
            id: 'd6a49949-772c-41ef-a59d-7ddc87bc78df',
            listing: [
              {
                createdAt: '2022-02-05T04:21:06.083Z',
                id: '5c533bff-cf81-4af2-b2fa-a8322780ac05',
                listing: [],
                name: 'lane.jpeg',
                size: null,
              },
              {
                createdAt: '2022-01-18T05:53:27.910Z',
                id: '6f691311-f8f9-40ad-be0e-0449481328f2',
                listing: [],
                name: 'systems_transmitting_tactics.wav',
                size: null,
              },
              {
                createdAt: '2021-04-12T09:27:02.354Z',
                id: '8c445182-c8aa-46c8-ba88-d7356540bc56',
                listing: [],
                name: 'navigate_albania_lane.mp4',
                size: null,
              },
              {
                createdAt: '2021-04-09T20:15:14.620Z',
                id: '915acd8a-e5b7-41ea-ad3c-de69c7fcf0f8',
                listing: [],
                name: 'sas.gif',
                size: null,
              },
              {
                createdAt: '2021-12-31T03:14:14.663Z',
                id: '6cf06c9e-bb1d-49f7-bfd1-4b4ffa82a5fa',
                listing: [],
                name: 'teal_haptic_delaware.wav',
                size: null,
              },
            ],
            name: 'hub_groupware.png',
            size: null,
          },
          {
            createdAt: '2021-05-10T08:31:07.318Z',
            id: 'b8b8a67a-8e83-4f12-8b3d-8833a84aebe8',
            listing: [
              {
                createdAt: '2022-01-02T21:04:55.128Z',
                id: '05a21370-393b-4870-80bb-916eab52514a',
                listing: [],
                name: 'cambridgeshire_developer_dedicated.png',
                size: null,
              },
              {
                createdAt: '2021-06-06T22:31:29.718Z',
                id: 'd5bf06a3-d5b3-4f30-bb5e-c2b0d182c8f9',
                listing: [],
                name: 'paradigm_ergonomic_handcrafted.m3a',
                size: null,
              },
            ],
            name: 'cambridgeshire.mpg4',
            size: null,
          },
          {
            createdAt: '2021-05-05T09:00:14.984Z',
            id: 'd8aa140e-a9c4-4804-920c-b21d653951f4',
            listing: [
              {
                createdAt: '2021-11-26T04:23:00.954Z',
                id: '9aa5a777-2a18-42a0-b0fc-3f62183b758d',
                listing: [],
                name: 'metrics_dollar.htm',
                size: null,
              },
              {
                createdAt: '2021-05-12T06:05:31.605Z',
                id: '8e2e41ae-6c36-4a1b-9818-2a16e685036e',
                listing: [],
                name: 'deposit_garden_back_end.gif',
                size: null,
              },
              {
                createdAt: '2021-12-20T00:03:36.195Z',
                id: '8506c162-c9c2-4de3-abd6-955a98d75a61',
                listing: [],
                name: 'channels_mouse_indonesia.mp4',
                size: null,
              },
            ],
            name: 'back_end_industrial_integration.mp4',
            size: null,
          },
        ],
        name: 'wooden_deposit_sausages.jpg',
        size: null,
      },
      {
        createdAt: '2021-02-25T11:16:46.888Z',
        id: 'ec329f12-9155-46e4-931a-a3697e2d5793',
        listing: [
          {
            createdAt: '2022-01-21T16:27:35.057Z',
            id: '84d5a17b-d382-467e-911a-09d9166dc0d7',
            listing: [
              {
                createdAt: '2021-04-23T03:36:24.053Z',
                id: 'df42f71e-3a7a-4ab5-9d56-c18803d7c9ca',
                listing: [],
                name: 'divide_salmon.mp2a',
                size: null,
              },
              {
                createdAt: '2021-08-31T09:54:30.230Z',
                id: '26fd3a3e-ba3e-4beb-b6ca-5d717a609415',
                listing: [],
                name: 'designer.mp2',
                size: null,
              },
              {
                createdAt: '2021-11-15T04:04:22.358Z',
                id: 'cb2f34b1-b6c7-4e73-bb71-360777a91f2c',
                listing: [],
                name: 'global_progressive_markets.mpg4',
                size: null,
              },
            ],
            name: 'incredible_track_loan.jpg',
            size: null,
          },
        ],
        name: 'arizona_withdrawal_alarm.jpg',
        size: null,
      },
    ],
    name: 'neural_navigate_health.mpg4',
    size: null,
  },
  {
    createdAt: '2021-06-10T00:23:53.877Z',
    id: 'f64cec38-45f0-45ac-984a-ccbc4f83c0d4',
    listing: [
      {
        createdAt: '2021-04-18T01:03:25.226Z',
        id: '6c4c5f22-1b72-494a-a34b-c893c65af47f',
        listing: [
          {
            createdAt: '2021-12-18T18:53:14.735Z',
            id: '73e6dd40-eb8b-42bf-8565-b78b7e29cf3b',
            listing: [
              {
                createdAt: '2021-03-29T07:54:35.464Z',
                id: '65d48211-2519-401f-bf5e-1b9876a863f4',
                listing: [],
                name: 'korean_eco_centric.mp4v',
                size: null,
              },
              {
                createdAt: '2021-06-17T10:28:36.379Z',
                id: 'bfb491cf-0884-4913-909a-1ee9d590159e',
                listing: [],
                name: 'steel.htm',
                size: null,
              },
              {
                createdAt: '2021-04-03T08:22:36.351Z',
                id: 'a336f165-eaec-41ff-b948-da9cde3b03a8',
                listing: [],
                name: 'concrete.gif',
                size: null,
              },
            ],
            name: 'plum.m1v',
            size: null,
          },
          {
            createdAt: '2021-03-26T03:28:02.132Z',
            id: '32bf5997-da7c-42e3-a7ef-1effc6ae8498',
            listing: [
              {
                createdAt: '2021-10-03T01:41:05.301Z',
                id: '4f55994f-2e3c-44f4-a2bf-1b41a65a6ec1',
                listing: [],
                name: 'morph.pdf',
                size: null,
              },
              {
                createdAt: '2021-11-06T18:04:05.127Z',
                id: 'a1cc2843-1b1f-48b5-8995-c5b861a4dc8c',
                listing: [],
                name: 'virtual_withdrawal.wav',
                size: null,
              },
            ],
            name: 'international_keyboard.png',
            size: null,
          },
          {
            createdAt: '2021-03-10T14:46:42.718Z',
            id: '447cbedf-1281-4816-93be-336e7b63e6f0',
            listing: [
              {
                createdAt: '2021-05-26T07:01:23.279Z',
                id: '09176ad5-dbdb-4607-8f27-fcb36cdbca6b',
                listing: [],
                name: 'granite_cambridgeshire.mpg',
                size: null,
              },
              {
                createdAt: '2022-01-19T18:10:39.484Z',
                id: 'dc6b0be3-ebae-4e33-9f06-b59c803e93e1',
                listing: [],
                name: 'ergonomic_representative_movies.mp4',
                size: null,
              },
              {
                createdAt: '2022-01-07T13:14:54.898Z',
                id: '51cad76c-6308-48dc-9c5f-7dbff234c9c0',
                listing: [],
                name: 'expressway.pdf',
                size: null,
              },
            ],
            name: 'table_music_multi_byte.mpg',
            size: null,
          },
        ],
        name: 'copying.m3a',
        size: null,
      },
      {
        createdAt: '2021-09-08T04:19:18.907Z',
        id: 'a5a67c95-3404-4dd0-88e7-107b97ddcd22',
        listing: [
          {
            createdAt: '2021-06-21T10:16:57.924Z',
            id: '10369092-722d-4fb4-8dc9-3463fdb5894a',
            listing: [
              {
                createdAt: '2021-09-28T21:03:11.339Z',
                id: '10801b6b-38cb-4409-b74a-2630ba7e776f',
                listing: [],
                name: 'savings_kyat_designer.pdf',
                size: null,
              },
            ],
            name: 'gold.jpeg',
            size: null,
          },
          {
            createdAt: '2021-11-18T14:11:51.371Z',
            id: '28ee7066-f1ee-4a74-ac3d-7b0fcf027a30',
            listing: [
              {
                createdAt: '2021-11-17T07:17:14.272Z',
                id: '2e571f26-1360-47d0-bf01-2e500876dc38',
                listing: [],
                name: 'towels_invoice_payment.m1v',
                size: null,
              },
              {
                createdAt: '2021-06-02T00:48:36.961Z',
                id: '6021fb6f-b5b4-4cca-ae74-9b4473b23a25',
                listing: [],
                name: 'music.html',
                size: null,
              },
            ],
            name: 'compressing_gloves_bypass.mpe',
            size: null,
          },
          {
            createdAt: '2021-12-29T20:46:21.992Z',
            id: '0ab67aad-ca70-4e5b-beae-af0ea99ac100',
            listing: [
              {
                createdAt: '2021-12-05T13:57:00.422Z',
                id: '69201bce-8027-431e-aa65-b0bdc8a1fa03',
                listing: [],
                name: 'deposit_electronics_maldives.pdf',
                size: null,
              },
              {
                createdAt: '2021-04-10T01:27:31.583Z',
                id: '7f629a4e-25d8-49ab-a1db-7196dd54de9e',
                listing: [],
                name: 'direct_account.png',
                size: null,
              },
              {
                createdAt: '2021-11-15T00:56:32.033Z',
                id: '03d79e3a-dc54-448e-9e6d-f8d8b4ef9fb9',
                listing: [],
                name: 'compressing.png',
                size: null,
              },
              {
                createdAt: '2021-07-05T08:05:42.743Z',
                id: 'af39bebd-d958-4db8-af19-60cfad623c2d',
                listing: [],
                name: 'capacitor.gif',
                size: null,
              },
              {
                createdAt: '2021-10-24T14:29:10.888Z',
                id: '2b723860-7912-4997-8852-70ad442dc8e7',
                listing: [],
                name: 'towels_for_analyzer.mp4',
                size: null,
              },
            ],
            name: 'global_back_end.shtml',
            size: null,
          },
        ],
        name: 'granite_turkey.pdf',
        size: null,
      },
      {
        createdAt: '2022-01-19T04:35:54.392Z',
        id: 'f05cf1f1-7670-4412-a9e4-46ca311602b0',
        listing: [
          {
            createdAt: '2021-06-17T08:40:56.694Z',
            id: '90cc1249-f299-4b9e-a66a-cadd56dbd8f5',
            listing: [
              {
                createdAt: '2021-04-24T12:26:34.390Z',
                id: 'f3572e26-41a0-4bee-ac45-b49ae9860aa4',
                listing: [],
                name: 'computers.png',
                size: null,
              },
            ],
            name: 'auto_handcrafted_shirt.jpe',
            size: null,
          },
          {
            createdAt: '2021-09-18T04:02:06.715Z',
            id: 'de940f05-611f-4bb1-8a68-a90b03fc5ec9',
            listing: [
              {
                createdAt: '2021-08-17T04:22:23.629Z',
                id: '403b2349-ba49-4173-82ae-fca769ddccc7',
                listing: [],
                name: 'towels.jpg',
                size: null,
              },
              {
                createdAt: '2021-05-17T12:20:37.397Z',
                id: '3ea2e76c-8095-4031-8c20-2a77b4ff3a60',
                listing: [],
                name: 'checking_junction.jpg',
                size: null,
              },
              {
                createdAt: '2022-01-24T13:33:47.666Z',
                id: '464b7473-6ce7-439e-80e0-7cc439664341',
                listing: [],
                name: 'myanmar_embrace_chips.png',
                size: null,
              },
            ],
            name: 'isle.gif',
            size: null,
          },
        ],
        name: 'berkshire.png',
        size: null,
      },
      {
        createdAt: '2021-06-27T12:53:05.477Z',
        id: 'd17e0abb-b619-452f-9052-10beec91c9f8',
        listing: [
          {
            createdAt: '2021-03-18T02:18:34.500Z',
            id: '1e197b81-3a48-4d4a-96ea-fb42b7bd182d',
            listing: [
              {
                createdAt: '2021-03-30T23:41:47.073Z',
                id: '8fa6572a-dc3b-4575-9251-fbb0d2ea9378',
                listing: [],
                name: 'granite.pdf',
                size: null,
              },
            ],
            name: 'loan_brook.htm',
            size: null,
          },
          {
            createdAt: '2021-10-21T13:05:07.436Z',
            id: '238dde5d-bbb8-4836-9bcc-0c153b528557',
            listing: [
              {
                createdAt: '2021-04-29T03:48:10.867Z',
                id: '5e4b0e8a-a8f0-432a-9292-cf5a7fc5cd87',
                listing: [],
                name: 'engineer_forge_coordinator.wav',
                size: null,
              },
              {
                createdAt: '2021-05-10T18:27:38.607Z',
                id: 'df97b09d-e57b-4134-a4fe-47648e71998c',
                listing: [],
                name: 'mobile_cambridgeshire.png',
                size: null,
              },
              {
                createdAt: '2021-10-22T01:53:36.493Z',
                id: 'ec42fc20-628a-48b5-af26-e781b8913bcd',
                listing: [],
                name: 'lodge_paradigm_input.pdf',
                size: null,
              },
            ],
            name: 'ai_services_png.png',
            size: null,
          },
        ],
        name: 'technician_alley.mp4v',
        size: null,
      },
      {
        createdAt: '2021-02-20T18:13:13.547Z',
        id: 'e8300c14-bb51-4cd4-9309-c4ada4ec4eed',
        listing: [
          {
            createdAt: '2021-06-18T22:37:55.530Z',
            id: '1a8ecc33-12e5-4e6d-9886-5178093b5f6e',
            listing: [
              {
                createdAt: '2021-06-27T02:50:14.517Z',
                id: 'b6e28ec0-01e3-475c-a8e8-8371262f4d68',
                listing: [],
                name: 'bricks_and_clicks_ram_paradigms.png',
                size: null,
              },
              {
                createdAt: '2022-01-21T14:58:59.059Z',
                id: '167f7b66-ada1-4d6e-b674-e68f69ee6256',
                listing: [],
                name: 'movies_payment_payment.gif',
                size: null,
              },
              {
                createdAt: '2021-07-24T15:53:53.053Z',
                id: 'bc376978-05f3-4984-aa5b-270e072f1d3f',
                listing: [],
                name: 'standardization.mp3',
                size: null,
              },
            ],
            name: 'convertible_gourde.mp2',
            size: null,
          },
          {
            createdAt: '2021-12-27T19:21:36.100Z',
            id: '2b4afaaf-18ee-446d-a015-5d06d5e256f1',
            listing: [
              {
                createdAt: '2021-12-09T21:47:09.434Z',
                id: '3bf6f6d8-1cd3-4715-a726-b52e9714b2dd',
                listing: [],
                name: 'strategist_solid_withdrawal.mp4',
                size: null,
              },
            ],
            name: 'computer.mp4v',
            size: null,
          },
          {
            createdAt: '2021-09-12T08:30:58.903Z',
            id: 'fd30a521-6bc4-4b41-b631-0c55430ef1ba',
            listing: [
              {
                createdAt: '2021-11-27T11:21:48.242Z',
                id: '4dd5d4d9-9975-43a1-a3fb-f46ae71b2f6e',
                listing: [],
                name: 'payment_clothing_interface.shtml',
                size: null,
              },
              {
                createdAt: '2021-10-10T06:51:06.441Z',
                id: '90c35111-04c4-4bc4-9794-91746b136cdf',
                listing: [],
                name: 'soap_cambridgeshire.jpeg',
                size: null,
              },
              {
                createdAt: '2021-02-25T02:57:18.456Z',
                id: 'd082d803-d299-4dd4-9b27-5c841a81a191',
                listing: [],
                name: 'concrete.m1v',
                size: null,
              },
            ],
            name: 'agent_e_markets_bleeding_edge.htm',
            size: null,
          },
          {
            createdAt: '2021-12-23T20:57:05.117Z',
            id: '32ff102d-4382-4fa3-b91e-7caeb7458363',
            listing: [
              {
                createdAt: '2022-01-30T05:10:10.531Z',
                id: 'b582ed0e-c427-4506-a6e6-15fbb30964fc',
                listing: [],
                name: 'payment.jpg',
                size: null,
              },
              {
                createdAt: '2021-08-27T15:35:14.155Z',
                id: '2a638c74-c598-4408-bad2-d10cc142c1b5',
                listing: [],
                name: 'licensed_rubber.mp4',
                size: null,
              },
            ],
            name: 'mobile.mpg4',
            size: null,
          },
          {
            createdAt: '2021-04-20T09:01:33.905Z',
            id: '49d8a0ea-7fdb-4d2a-9971-52d04060d549',
            listing: [
              {
                createdAt: '2021-11-03T04:24:46.984Z',
                id: 'cd14d65d-5a84-45b7-bc8a-2500bf271d1e',
                listing: [],
                name: 'tasty_synthesizing_national.png',
                size: null,
              },
              {
                createdAt: '2021-11-13T12:51:45.902Z',
                id: '0d0d8d80-2fb3-49e4-996d-c0a37345b417',
                listing: [],
                name: 'towels_invoice_personal.mp4',
                size: null,
              },
              {
                createdAt: '2021-09-05T16:41:38.911Z',
                id: 'cbf80289-cd17-4761-aa24-3ebc2cb13710',
                listing: [],
                name: 'withdrawal_smtp.jpeg',
                size: null,
              },
              {
                createdAt: '2021-07-20T22:14:39.406Z',
                id: 'fdebb675-f51e-42e7-9905-fc84844983da',
                listing: [],
                name: 'deposit_internal_neural.mpg4',
                size: null,
              },
            ],
            name: 'optimize_distributed_shoes.mp4v',
            size: null,
          },
        ],
        name: 'chief_impactful_payment.pdf',
        size: null,
      },
    ],
    name: 'and.jpeg',
    size: null,
  },
];

export const SCHEMA = [
  { dataType: 'STRING', name: 'product_name' },
  { dataType: 'STRING', name: 'sheet_name' },
  { dataType: 'STRING', name: 'grouping_name' },
  { dataType: 'STRING', name: 'grouping_subheader' },
  { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_start' },
  { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_end' },
  { dataType: 'STRING', name: 'description' },
  { dataType: 'STRING', name: 'descriptor_1' },
  { dataType: 'STRING', name: 'descriptor_2' },
  { dataType: 'FLOAT', name: 'value' },
  { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'reported_publish_date' },
  { dataType: 'STRING', name: 'kugou_super_vip_' },
  { dataType: 'STRING', name: 'kugou_vip_' },
  { dataType: 'STRING', name: 'kugou_live' },
  { dataType: 'STRING', name: 'kuwo_luxury_vip_' },
  { dataType: 'STRING', name: 'kuwo_music_pack_' },
  { dataType: 'STRING', name: 'kuwo_live' },
  { dataType: 'STRING', name: 'netease_music_pack_' },
  { dataType: 'STRING', name: 'netease_vinyl_vip_' },
  { dataType: 'STRING', name: 'qq_green_diamond_annual' },
  { dataType: 'STRING', name: 'qq_green_diamond_monthly' },
  { dataType: 'STRING', name: 'qq_paid_music_annual' },
  { dataType: 'STRING', name: 'qq_paid_music_monthly' },
  { dataType: 'STRING', name: 'wesing_ktv' },
  { dataType: 'STRING', name: 'wesing_live' },
];

export const SCHEMA_SAMPLE_DATA = {
  data: [
    {
      description: 'Social Entertainment Revenue (RMB 000s)',
      descriptor_1: 'Coverage Ratio ⁴',
      descriptor_2: 'Coverage Ratio ⁴',
      grouping_name: 'YipitData Metrics',
      grouping_subheader: 'Historical Accuracy',
      kugou_live: null,
      kugou_super_vip_: null,
      kugou_vip_: null,
      kuwo_live: null,
      kuwo_luxury_vip_: null,
      kuwo_music_pack_: null,
      netease_music_pack_: null,
      netease_vinyl_vip_: null,
      period_end: '2018-09-30T00:00:00',
      period_start: '2018-07-01T00:00:00',
      product_name: 'TME_TME',
      qq_green_diamond_annual: null,
      qq_green_diamond_monthly: null,
      qq_paid_music_annual: null,
      qq_paid_music_monthly: null,
      reported_publish_date: '2020-06-18T22:00:27',
      sheet_name: 'Historical Performance',
      value: '0.995',
      wesing_ktv: null,
      wesing_live: null,
    },
    {
      description: 'Social Entertainment Revenue (RMB 000s)',
      descriptor_1: 'Coverage Ratio ⁴',
      descriptor_2: 'Coverage Ratio ⁴',
      grouping_name: 'YipitData Metrics',
      grouping_subheader: 'Historical Accuracy',
      kugou_live: null,
      kugou_super_vip_: null,
      kugou_vip_: null,
      kuwo_live: null,
      kuwo_luxury_vip_: null,
      kuwo_music_pack_: null,
      netease_music_pack_: null,
      netease_vinyl_vip_: null,
      period_end: '2018-12-31T00:00:00',
      period_start: '2018-10-01T00:00:00',
      product_name: 'TME_TME',
      qq_green_diamond_annual: null,
      qq_green_diamond_monthly: null,
      qq_paid_music_annual: null,
      qq_paid_music_monthly: null,
      reported_publish_date: '2020-06-18T22:00:27',
      sheet_name: 'Historical Performance',
      value: '1.005',
      wesing_ktv: null,
      wesing_live: null,
    },
    {
      description: 'Social Entertainment Revenue (RMB 000s)',
      descriptor_1: 'Coverage Ratio ⁴',
      descriptor_2: 'Coverage Ratio ⁴',
      grouping_name: 'YipitData Metrics',
      grouping_subheader: 'Historical Accuracy',
      kugou_live: null,
      kugou_super_vip_: null,
      kugou_vip_: null,
      kuwo_live: null,
      kuwo_luxury_vip_: null,
      kuwo_music_pack_: null,
      netease_music_pack_: null,
      netease_vinyl_vip_: null,
      period_end: '2019-03-31T00:00:00',
      period_start: '2019-01-01T00:00:00',
      product_name: 'TME_TME',
      qq_green_diamond_annual: null,
      qq_green_diamond_monthly: null,
      qq_paid_music_annual: null,
      qq_paid_music_monthly: null,
      reported_publish_date: '2020-06-18T22:00:27',
      sheet_name: 'Historical Performance',
      value: '1.056',
      wesing_ktv: null,
      wesing_live: null,
    },
  ],
  headerSkipRows: [
    {
      description: 'description',
      descriptor_1: 'descriptor_1',
      descriptor_2: 'descriptor_2',
      grouping_name: 'grouping_name',
      grouping_subheader: 'grouping_subheader',
      kugou_live: 'kugou_live',
      kugou_super_vip_: 'kugou_super_vip_',
      kugou_vip_: 'kugou_vip_',
      kuwo_live: 'kuwo_live',
      kuwo_luxury_vip_: 'kuwo_luxury_vip_',
      kuwo_music_pack_: 'kuwo_music_pack_',
      netease_music_pack_: 'netease_music_pack_',
      netease_vinyl_vip_: 'netease_vinyl_vip_',
      period_end: 'period_end',
      period_start: 'period_start',
      product_name: 'product_name',
      qq_green_diamond_annual: 'qq_green_diamond_annual',
      qq_green_diamond_monthly: 'qq_green_diamond_monthly',
      qq_paid_music_annual: 'qq_paid_music_annual',
      qq_paid_music_monthly: 'qq_paid_music_monthly',
      reported_publish_date: 'reported_publish_date',
      sheet_name: 'sheet_name',
      value: 'value',
      wesing_ktv: 'wesing_ktv',
      wesing_live: 'wesing_live',
    },
  ],
  schema: {
    fields: [
      { dataType: 'STRING', name: 'product_name' },
      { dataType: 'STRING', name: 'sheet_name' },
      { dataType: 'STRING', name: 'grouping_name' },
      { dataType: 'STRING', name: 'grouping_subheader' },
      { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_start' },
      { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'period_end' },
      { dataType: 'STRING', name: 'description' },
      { dataType: 'STRING', name: 'descriptor_1' },
      { dataType: 'STRING', name: 'descriptor_2' },
      { dataType: 'FLOAT', name: 'value' },
      { dataType: 'DATETIME', dateFormat: "yyyy-MM-dd'T'HH:mm:ss", name: 'reported_publish_date' },
      { dataType: 'STRING', name: 'kugou_super_vip_' },
      { dataType: 'STRING', name: 'kugou_vip_' },
      { dataType: 'STRING', name: 'kugou_live' },
      { dataType: 'STRING', name: 'kuwo_luxury_vip_' },
      { dataType: 'STRING', name: 'kuwo_music_pack_' },
      { dataType: 'STRING', name: 'kuwo_live' },
      { dataType: 'STRING', name: 'netease_music_pack_' },
      { dataType: 'STRING', name: 'netease_vinyl_vip_' },
      { dataType: 'STRING', name: 'qq_green_diamond_annual' },
      { dataType: 'STRING', name: 'qq_green_diamond_monthly' },
      { dataType: 'STRING', name: 'qq_paid_music_annual' },
      { dataType: 'STRING', name: 'qq_paid_music_monthly' },
      { dataType: 'STRING', name: 'wesing_ktv' },
      { dataType: 'STRING', name: 'wesing_live' },
    ],
    naValues: [''],
  },
};

export const SCHEMA_VALIDATION_RESPONSE = {
  field: [
    {
      key: 'one_or_more_invalid_values',
      values: {
        field: 'field',
        field_type: 'FLOAT',
        values: [
          'AAAU',
          'AADR',
          'AAXJ',
          'ACES',
          'ACIM',
          'ACSG',
          'ACSI',
          'ACT',
          'ACWF',
          'ACWI',
          'ACWV',
          'ACWX',
          'ADRA',
          'ADRD',
          'ADRE',
          'ADRU',
          'AFIF',
          'AFK',
          'AFTY',
          'AGF',
          'AGG',
          'AGGE',
          'AGGP',
          'AGGY',
          'AGND',
          'AGQ',
          'AGT',
          'AGZ',
        ],
      },
    },
  ],
  validationError: true,
};

export const SOURCE_CONNECTION_INPUT = {
  config: {
    address: '123.456.78.9',
    password: 'aabbccdd',
    port: '22',
    username: 'integrate-dev',
  },
  method: 'sftp',
  name: 'Source SFTP 001',
};

export const SOURCE_LIST = [
  {
    name: '',
    path: '/',
    type: 'dir',
    updatedAt: '2022-04-25T14:54:18Z',
  },
  {
    name: 'Archive',
    path: 'Archive',
    type: 'dir',
    updatedAt: '2022-04-25T14:54:18Z',
  },
  {
    name: 'FinancialsPremiumDetailData',
    path: 'Archive/FinancialsPremiumDetailData',
    type: 'dir',
    updatedAt: '2022-04-25T14:54:18Z',
  },
  {
    name: '29794_1774009340_2528010_Transcript_AuditedCopy_20220321065632.pdf',
    path: 'Archive/FinancialsPremiumDetailData/29794_1774009340_2528010_Transcript_AuditedCopy_20220321065632.pdf',
    size: 2340,
    updatedAt: '2022-04-25T14:54:18Z',
  },
  {
    name: '29794_1774009340_2528008_Transcript_SpellcheckedCopy_20220321064649.pdf',
    path: 'Archive/FinancialsPremiumDetailData/29794_1774009340_2528008_Transcript_SpellcheckedCopy_20220321064649.pdf',
    size: 3723,
    updatedAt: '2022-04-25T14:54:18Z',
  },
];

export const SOURCE_TREE = {
  listing: [
    {
      listing: [
        {
          listing: [
            {
              name: '29794_1774009340_2528010_Transcript_AuditedCopy_20220321065632.pdf',
              path: 'Archive/FinancialsPremiumDetailData/29794_1774009340_2528010_Transcript_AuditedCopy_20220321065632.pdf',
              size: 2340,
              type: 'file',
              updatedAt: '2022-04-25T14:54:18Z',
            },
            {
              name: '29794_1774009340_2528008_Transcript_SpellcheckedCopy_20220321064649.zip',
              path: 'Archive/FinancialsPremiumDetailData/29794_1774009340_2528008_Transcript_SpellcheckedCopy_20220321064649.zip',
              size: 3723,
              type: 'archive',
              updatedAt: '2022-04-25T14:54:18Z',
            },
            {
              listing: [
                {
                  name: '29794_1774009340_2528008_Transcript_SpellcheckedCopy_20220321064649.csv',
                  path: 'Archive/FinancialsPremiumDetailData/29794_1774009340_2528008_Transcript_SpellcheckedCopy_20220321064649/29794_1774009340_2528008_Transcript_SpellcheckedCopy_20220321064649.csv',
                  size: 13723,
                  type: 'archive_file',
                  updatedAt: '2022-04-25T14:54:18Z',
                },
              ],
              name: '29794_1774009340_2528008_Transcript_SpellcheckedCopy_20220321064649.zip',
              path: 'Archive/FinancialsPremiumDetailData/29794_1774009340_2528008_Transcript_SpellcheckedCopy_20220321064649.zip',
              type: 'archive_dir',
              updatedAt: '2022-04-25T14:54:18Z',
            },
          ],
          name: 'FinancialsPremiumDetailData',
          path: 'Archive/FinancialsPremiumDetailData',
          type: 'dir',
          updatedAt: '2022-04-25T14:54:18Z',
        },
      ],
      name: 'Archive',
      path: 'Archive',
      type: 'dir',
      updatedAt: '2022-04-25T14:54:18Z',
    },
    {
      name: 'Bucket',
      path: 'Bucket',
      type: 'dir',
      updatedAt: '2022-04-25T14:54:18Z',
    },
  ],
  name: '',
  path: '/',
  type: 'dir',
  updatedAt: '2022-04-25T14:54:18Z',
};

export const DATA_PRODUCT_HISTORY = [
  {
    datasetId: 'Dss7MNO6',
    datasetLegacyId: 'AQJs7MNO6mW-LOjrUpdIU2ruiQ',
    durationScale: 'default',
    executionDate: '2021-09-16T10:53:37.370+0000',
    shardId: 'airflow-5',
    source: 'dynamic',
    status: 'EXPIRED',
    totalDuration: 10343,
    workflowId: 'wolfe_research_quant_csi_history_trial',
  },
  {
    datasetId: 'Dss7MNO6',
    datasetLegacyId: 'AQJs7MNO6mW-LOjrUpdIU2ruiQ',
    durationScale: 'default',
    executionDate: '2021-09-16T11:53:37.370+0000',
    shardId: 'airflow-5',
    source: 'dynamic',
    status: 'FAILURE',
    totalDuration: 10343,
    workflowId: 'wolfe_research_quant_csi_history_trial',
  },
  {
    datasetId: 'Dss7MNO6',
    datasetLegacyId: 'AQJs7MNO6mW-LOjrUpdIU2ruiQ',
    durationScale: 'default',
    executionDate: '2021-09-16T12:53:37.370+0000',
    shardId: 'airflow-5',
    source: 'dynamic',
    status: 'RUNNING',
    totalDuration: 10343,
    workflowId: 'wolfe_research_quant_csi_history_trial',
  },
  {
    datasetId: 'Dss7MNO6',
    datasetLegacyId: 'AQJs7MNO6mW-LOjrUpdIU2ruiQ',
    durationScale: 'default',
    executionDate: '2021-09-16T13:53:37.370+0000',
    shardId: 'airflow-5',
    source: 'dynamic',
    status: 'SCHEDULED',
    totalDuration: 10343,
    workflowId: 'wolfe_research_quant_csi_history_trial',
  },
  {
    datasetId: 'Dss7MNO6',
    datasetLegacyId: 'AQJs7MNO6mW-LOjrUpdIU2ruiQ',
    durationScale: 'default',
    executionDate: '2021-09-16T14:53:37.370+0000',
    shardId: 'airflow-5',
    source: 'dynamic',
    status: 'SKIPPED',
    totalDuration: 10343,
    workflowId: 'wolfe_research_quant_csi_history_trial',
  },
  {
    datasetId: 'Dss7MNO6',
    datasetLegacyId: 'AQJs7MNO6mW-LOjrUpdIU2ruiQ',
    durationScale: 'default',
    executionDate: '2021-09-16T15:53:37.370+0000',
    shardId: 'airflow-5',
    source: 'dynamic',
    status: 'STARTED',
    totalDuration: 10343,
    workflowId: 'wolfe_research_quant_csi_history_trial',
  },
  {
    datasetId: 'Dss7MNO6',
    datasetLegacyId: 'AQJs7MNO6mW-LOjrUpdIU2ruiQ',
    durationScale: 'default',
    executionDate: '2021-09-16T16:53:37.370+0000',
    shardId: 'airflow-5',
    source: 'dynamic',
    status: 'SUCCESS',
    totalDuration: 10343,
    workflowId: 'wolfe_research_quant_csi_history_trial',
  },
];

export const DATA_PRODUCT_OVERRIDE_YAML = `- cron: 0 0 16-23 * * *
  datasetId: Ds7qFx6T
  frames:
    - endDate: 2022-03-01T16:00:00
      id: test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60
      name: test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60
      pattern: aue4/FPD_AUE4L_%Y%m%d.zip/FP_AUE4L_Factor_POR.%Y%m%D
      regex: (?:aue4/FPD_AUE4L_)(%Y)(%m)(%d)(?:.zip/FP_AUE4L_Factor_POR.)(%Y)(%m)(%D)
      schema:
        - dataType: STRING
          name: product_name
        - dataType: STRING
          name: sheet_name
        - dataType: STRING
          name: grouping_name
        - dataType: STRING
          name: grouping_subheader
        - dataType: DATETIME
          dateFormat: yyyy-MM-dd'T'HH:mm:ss
          name: period_start
        - dataType: DATETIME
          dateFormat: yyyy-MM-dd'T'HH:mm:ss
          name: period_end
        - dataType: STRING
          name: description
        - dataType: STRING
          name: descriptor_1
        - dataType: STRING
          name: descriptor_2
        - dataType: FLOAT
          name: value
        - dataType: DATETIME
          dateFormat: yyyy-MM-dd'T'HH:mm:ss
          name: reported_publish_date
        - dataType: STRING
          name: kugou_super_vip_
        - dataType: STRING
          name: kugou_vip_
        - dataType: STRING
          name: kugou_live
        - dataType: STRING
          name: kuwo_luxury_vip_
        - dataType: STRING
          name: kuwo_music_pack_
        - dataType: STRING
          name: kuwo_live
        - dataType: STRING
          name: netease_music_pack_
        - dataType: STRING
          name: netease_vinyl_vip_
        - dataType: STRING
          name: qq_green_diamond_annual
        - dataType: STRING
          name: qq_green_diamond_monthly
        - dataType: STRING
          name: qq_paid_music_annual
        - dataType: STRING
          name: qq_paid_music_monthly
        - dataType: STRING
          name: wesing_ktv
        - dataType: STRING
          name: wesing_live
      startDate: 2022-03-29T16:00:00
      status: success
      totalFiles: 10
      versions:
        - id: test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_latest
          name: Latest schema
          pattern: aue4/FPD_AUE4L_%Y%m%d.zip/FP_AUE4L_Factor_POR.%Y%m%D
          regex: (?:aue4/FPD_AUE4L_)(%Y)(%m)(%d)(?:.zip/FP_AUE4L_Factor_POR.)(%Y)(%m)(%D)
          startDate: 2022-03-29T16:00:00
          status: success
    - endDate: 2022-03-01T16:00:00
      id: test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_2
      name: test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_2
      pattern: aue4/FPD_AUE4L_%Y%m%d.zip/FP_AUE4L_Factor_POR.%Y%m%D
      regex: (?:aue4/FPD_AUE4L_)(%Y)(%m)(%d)(?:.zip/FP_AUE4L_Factor_POR.)(%Y)(%m)(%D)
      schema:
        - dataType: STRING
          name: product_name
        - dataType: STRING
          name: sheet_name
        - dataType: STRING
          name: grouping_name
        - dataType: STRING
          name: grouping_subheader
        - dataType: DATETIME
          dateFormat: yyyy-MM-dd'T'HH:mm:ss
          name: period_start
        - dataType: DATETIME
          dateFormat: yyyy-MM-dd'T'HH:mm:ss
          name: period_end
        - dataType: STRING
          name: description
        - dataType: STRING
          name: descriptor_1
        - dataType: STRING
          name: descriptor_2
        - dataType: FLOAT
          name: value
        - dataType: DATETIME
          dateFormat: yyyy-MM-dd'T'HH:mm:ss
          name: reported_publish_date
        - dataType: STRING
          name: kugou_super_vip_
        - dataType: STRING
          name: kugou_vip_
        - dataType: STRING
          name: kugou_live
        - dataType: STRING
          name: kuwo_luxury_vip_
        - dataType: STRING
          name: kuwo_music_pack_
        - dataType: STRING
          name: kuwo_live
        - dataType: STRING
          name: netease_music_pack_
        - dataType: STRING
          name: netease_vinyl_vip_
        - dataType: STRING
          name: qq_green_diamond_annual
        - dataType: STRING
          name: qq_green_diamond_monthly
        - dataType: STRING
          name: qq_paid_music_annual
        - dataType: STRING
          name: qq_paid_music_monthly
        - dataType: STRING
          name: wesing_ktv
        - dataType: STRING
          name: wesing_live
      startDate: 2022-03-29T16:00:00
      status: success
      totalFiles: 20
      versions:
        - id: test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_latest
          name: Latest schema
          pattern: aue4/FPD_AUE4L_%Y%m%d.zip/FP_AUE4L_Factor_POR.%Y%m%D
          regex: (?:aue4/FPD_AUE4L_)(%Y)(%m)(%d)(?:.zip/FP_AUE4L_Factor_POR.)(%Y)(%m)(%D)
          startDate: 2022-03-29T16:00:00
          status: success
  id: test_supplier_data_epra_nareit_uk_valuation_history_epukv_v8
  name: test_supplier_data_epra_nareit_uk_valuation_history_epukv_v8
  startDate: 2022-03-23T12:00:00
  status: success
- cron: 0 0 16-23 * * *
  datasetId: testId
  frames:
    - endDate: null
      id: test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s59
      name: test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s59
      pattern: epukv(?<YY>d{2})(?<MM>d{2})
      regex: epukv(?<YY>d{2})(?<MM>d{2})
      schema:
        - dataType: STRING
          name: product_name
        - dataType: STRING
          name: sheet_name
        - dataType: STRING
          name: grouping_name
        - dataType: STRING
          name: grouping_subheader
        - dataType: DATETIME
          dateFormat: yyyy-MM-dd'T'HH:mm:ss
          name: period_start
        - dataType: DATETIME
          dateFormat: yyyy-MM-dd'T'HH:mm:ss
          name: period_end
        - dataType: STRING
          name: description
        - dataType: STRING
          name: descriptor_1
        - dataType: STRING
          name: descriptor_2
        - dataType: FLOAT
          name: value
        - dataType: DATETIME
          dateFormat: yyyy-MM-dd'T'HH:mm:ss
          name: reported_publish_date
        - dataType: STRING
          name: kugou_super_vip_
        - dataType: STRING
          name: kugou_vip_
        - dataType: STRING
          name: kugou_live
        - dataType: STRING
          name: kuwo_luxury_vip_
        - dataType: STRING
          name: kuwo_music_pack_
        - dataType: STRING
          name: kuwo_live
        - dataType: STRING
          name: netease_music_pack_
        - dataType: STRING
          name: netease_vinyl_vip_
        - dataType: STRING
          name: qq_green_diamond_annual
        - dataType: STRING
          name: qq_green_diamond_monthly
        - dataType: STRING
          name: qq_paid_music_annual
        - dataType: STRING
          name: qq_paid_music_monthly
        - dataType: STRING
          name: wesing_ktv
        - dataType: STRING
          name: wesing_live
      startDate: 2022-03-29T16:00:00
      status: error
      versions:
        - id: test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_latest
          name: Latest schema
          pattern: epukv(?<YY>d{2})(?<MM>d{2})
          regex: epukv(?<YY>d{2})(?<MM>d{2})
          startDate: 2022-03-29T16:00:00
          status: error
        - id: test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_2022-10-05
          name: 2022-10-05
          pattern: epukv(?<YY>d{2})(?<MM>d{2})
          regex: epukv(?<YY>d{2})(?<MM>d{2})
          startDate: 2022-03-29T16:00:00
          status: success
  id: test_supplier_data_epra_nareit_uk_valuation_history_epukv_v6
  name: test_supplier_data_epra_nareit_uk_valuation_history_epukv_v6
  startDate: 2022-03-23T12:00:00
  status: error
- cron: 0 0 16-23 * * *
  datasetId: DsRicFlair1
  frames:
    - endDate: null
      id: test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s58
      name: test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s58
      pattern: epukv(?<YY>d{2})(?<MM>d{2})
      regex: epukv(?<YY>d{2})(?<MM>d{2})
      schema:
        - dataType: STRING
          name: product_name
        - dataType: STRING
          name: sheet_name
        - dataType: STRING
          name: grouping_name
        - dataType: STRING
          name: grouping_subheader
        - dataType: DATETIME
          dateFormat: yyyy-MM-dd'T'HH:mm:ss
          name: period_start
        - dataType: DATETIME
          dateFormat: yyyy-MM-dd'T'HH:mm:ss
          name: period_end
        - dataType: STRING
          name: description
        - dataType: STRING
          name: descriptor_1
        - dataType: STRING
          name: descriptor_2
        - dataType: FLOAT
          name: value
        - dataType: DATETIME
          dateFormat: yyyy-MM-dd'T'HH:mm:ss
          name: reported_publish_date
        - dataType: STRING
          name: kugou_super_vip_
        - dataType: STRING
          name: kugou_vip_
        - dataType: STRING
          name: kugou_live
        - dataType: STRING
          name: kuwo_luxury_vip_
        - dataType: STRING
          name: kuwo_music_pack_
        - dataType: STRING
          name: kuwo_live
        - dataType: STRING
          name: netease_music_pack_
        - dataType: STRING
          name: netease_vinyl_vip_
        - dataType: STRING
          name: qq_green_diamond_annual
        - dataType: STRING
          name: qq_green_diamond_monthly
        - dataType: STRING
          name: qq_paid_music_annual
        - dataType: STRING
          name: qq_paid_music_monthly
        - dataType: STRING
          name: wesing_ktv
        - dataType: STRING
          name: wesing_live
      startDate: 2022-03-29T16:00:00
      status: pending
      versions:
        - id: test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_latest
          name: Latest schema
          pattern: epukv(?<YY>d{2})(?<MM>d{2})
          regex: epukv(?<YY>d{2})(?<MM>d{2})
          startDate: 2022-03-29T16:00:00
          status: success
        - id: test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_2022-10-01
          name: 2022-10-01
          pattern: epukv(?<YY>d{2})(?<MM>d{2})
          regex: epukv(?<YY>d{2})(?<MM>d{2})
          startDate: 2022-03-29T16:00:00
          status: pending
  id: test_supplier_data_epra_nareit_uk_valuation_history_epukv_v5
  name: test_supplier_data_epra_nareit_uk_valuation_history_epukv_v5
  startDate: 2022-03-23T12:00:00
  status: pending
- cron: "@once"
  datasetId: DsRicFlair2
  frames:
    - endDate: null
      id: test_supplier_data_epra_nareit_uk_valuation_history_epukv_v1_csv_v2
      name: test_supplier_data_epra_nareit_uk_valuation_history_epukv_v1_csv_v2
      pattern: epukv(?<YY>d{2})(?<MM>d{2})_v1
      regex: epukv(?<YY>d{2})(?<MM>d{2})_v1
      schema:
        - dataType: STRING
          name: product_name
        - dataType: STRING
          name: sheet_name
        - dataType: STRING
          name: grouping_name
        - dataType: STRING
          name: grouping_subheader
        - dataType: DATETIME
          dateFormat: yyyy-MM-dd'T'HH:mm:ss
          name: period_start
        - dataType: DATETIME
          dateFormat: yyyy-MM-dd'T'HH:mm:ss
          name: period_end
        - dataType: STRING
          name: description
        - dataType: STRING
          name: descriptor_1
        - dataType: STRING
          name: descriptor_2
        - dataType: FLOAT
          name: value
        - dataType: DATETIME
          dateFormat: yyyy-MM-dd'T'HH:mm:ss
          name: reported_publish_date
        - dataType: STRING
          name: kugou_super_vip_
        - dataType: STRING
          name: kugou_vip_
        - dataType: STRING
          name: kugou_live
        - dataType: STRING
          name: kuwo_luxury_vip_
        - dataType: STRING
          name: kuwo_music_pack_
        - dataType: STRING
          name: kuwo_live
        - dataType: STRING
          name: netease_music_pack_
        - dataType: STRING
          name: netease_vinyl_vip_
        - dataType: STRING
          name: qq_green_diamond_annual
        - dataType: STRING
          name: qq_green_diamond_monthly
        - dataType: STRING
          name: qq_paid_music_annual
        - dataType: STRING
          name: qq_paid_music_monthly
        - dataType: STRING
          name: wesing_ktv
        - dataType: STRING
          name: wesing_live
      startDate: 2022-03-29T16:00:00
      status: queued
      versions:
        - id: test_supplier_data_epra_nareit_uk_valuation_history_epukv_csv_v3_s60_latest
          name: Latest schema
          pattern: epukv(?<YY>d{2})(?<MM>d{2})_v1
          regex: epukv(?<YY>d{2})(?<MM>d{2})_v1
          startDate: 2022-03-29T16:00:00
          status: queued
  id: test_supplier_data_epra_nareit_uk_valuation_history_epukv_v1_v2
  name: test_supplier_data_epra_nareit_uk_valuation_history_epukv_v1_v2
  startDate: 2022-03-23T12:00:00
  status: queued`;

export const WORKFLOW_MODEL = {
  cron: '0 0 10-12 2-23 * *',
  frames: [
    {
      endDate: '2022-03-24T12:00:00',
      groupId: 'etf_global_analytics_us_ytd_v2_csv_v25',
      id: 'etf_global_analytics_us_ytd_v2_csv_v25',
      name: 'etf_global_analytics_us_ytd_v2_csv_v25',
      pattern: '202203(2[0-8]|[0-1]\\d)_analytics_v2.csv',
      regex: '(?<YYYY>\\d{4})(?<MM>\\d{2})(?<DD>\\d{2})_analytics_v2',
      schema: [Array],
      startDate: '2022-03-23T12:00:00',
    },
    {
      endDate: '2022-03-25T12:00:00',
      groupId: 'etf_global_analytics_us_ytd_v2_csv_v25',
      id: 'etf_global_analytics_us_ytd_v2_csv_v25_s2',
      name: 'etf_global_analytics_us_ytd_v2_csv_v25_s2',
      pattern: '202203(2[0-8]|[0-1]\\d)_analytics_v2.csv',
      regex: '(?<YYYY>\\d{4})(?<MM>\\d{2})(?<DD>\\d{2})_analytics_v2',
      schema: [Array],
      startDate: '2022-03-23T12:00:00',
    },
  ],
  id: 'etf_global_analytics_us_ytd_v2_v25',
  name: 'etf_global_analytics_us_ytd_v2_v25',
  startDate: '2022-03-23T12:00:00',
  status: 'precheck_ok',
};

export const MOCK_DATASET_HEALTH_METADATA = {
  dataProduct: { name: 'MSCI - Equity ... Model (EQINTEG)' },
  dataset: { name: 'Barra EQINTEG ID' },
  schema: { name: 'November 12, 2022 00:00 AM' },
};

export const MOCK_DATASET_HEALTH_FRAMES = [
  {
    concurency: 100,
    cron: '30 01 * * *',
    defaultArgs: 'https://www.fda.gov/safety/recalls-market-withdrawals-safety-alerts/...',
    filePath: 'pdk_dags/msci/arch1/arch1_daily...',
    id: '1',
    maximumActiveRuns: '0 / 100',
    name: 'crux_integrate_app_for_dev_testing_fastertest_v8',
    owner: 'CruxInformatics',
    ranAt: '2022-03-29T16:00:00',
    severity: 'error',
    statuses: {
      deliver: 'skipped',
      ingest: 'failure',
      normalize: 'success',
      process: 'failure',
    },
    taskCount: 9,
    taskIds: ['https://www.fda.gov/safety/recalls-market-withdrawals-safety-alerts/...'],
  },
  {
    concurency: 200,
    cron: '31 02 * * *',
    defaultArgs: 'https://www.fda.gov/safety/recalls-market-withdrawals-safety-alerts/...',
    filePath: 'pdk_dags/msci/arch1/arch1_daily...',
    id: '2',
    maximumActiveRuns: '56 / 100',
    name: 'crux_integrate_app_for_banana_testing_blazinghottest_v9',
    owner: 'Bananaland',
    ranAt: '2022-03-29T16:00:00',
    severity: 'warning',
    statuses: {
      deliver: 'skipped',
      ingest: 'skipped',
      normalize: 'skipped',
      process: 'skipped',
    },
    taskCount: 21,
    taskIds: [
      'https://www.fda.gov/safety/recalls-market-withdrawals-safety-alerts/...',
      'https://www.fda.gov/safety/recalls-market-withdrawals-for-bananas/...',
      'https://www.fda.gov/safety/recalls-market-withdrawals-safety-alerts/...',
    ],
  },
];

export const MOCK_FRAME_HEALTH_DATA_RAW = `  [2022-10-10 00:02:54,476] {cli.py:374} INFO - Running on host airflow-worker-6.airflow-worker.airflow-5.svc.cluster.local
  [2022-10-10 00:02:54,519] {models.py:1197} INFO - Dependencies all met for <TaskInstance: fda_recalls_market_withdrawals_and_safety_alerts_ongoing.uber_extract 2022-10-09 00:00:00 [queued]>
  [2022-10-10 00:02:54,530] {models.py:1197} INFO - Dependencies all met for <TaskInstance: fda_recalls_market_withdrawals_and_safety_alerts_ongoing.uber_extract 2022-10-09 00:00:00 [queued]>
  [2022-10-10 00:02:54,530] {models.py:1407} INFO -
  --------------------------------------------------------------------------------
  Starting attempt 1 of 2
  --------------------------------------------------------------------------------

  [2022-10-10 00:02:54,548] {models.py:1428} INFO - Executing <Task(CruxShortCircuitUberOperator): uber_extract> on 2022-10-09 00:00:00
  [2022-10-10 00:02:54,549] {base_task_runner.py:115} INFO - Running: ['bash', '-c', 'airflow run fda_recalls_market_withdrawals_and_safety_alerts_ongoing uber_extract 2022-10-09T00:00:00 --job_id 30721834 --raw -sd DAGS_FOLDER/fda/fda_recalls_market_withdrawals_and_safety_alerts/recalls_market_withdrawals_and_safety_alerts/fda_recalls_market_withdrawals_and_safety_alerts_ongoing_dag.py']
  [2022-10-10 00:02:56,879] {base_task_runner.py:98} INFO - Subtask: [2022-10-10 00:02:56,879] {configuration.py:206} WARNING - section/key [celery/celery_ssl_active] not found in config
  [2022-10-10 00:02:56,880] {base_task_runner.py:98} INFO - Subtask: [2022-10-10 00:02:56,879] {default_celery.py:41} WARNING - Celery Executor will run without SSL
  [2022-10-10 00:02:56,899] {base_task_runner.py:98} INFO - Subtask: [2022-10-10 00:02:56,898] {__init__.py:45} INFO - Using executor fce.FastCeleryExecutor
  [2022-10-10 00:02:56,947] {base_task_runner.py:98} INFO - Subtask: [2022-10-10 00:02:56,946] {models.py:189} INFO - Filling up the DagBag from /var/lib/airflow/gcs-sync/code/pipeline/airflow/sharded_dags/airflow_5/fda/fda_recalls_market_withdrawals_and_safety_alerts/recalls_market_withdrawals_and_safety_alerts/fda_recalls_market_withdrawals_and_safety_alerts_ongoing_dag.py
  [2022-10-10 00:02:57,038] {cli.py:374} INFO - Running on host airflow-worker-6.airflow-worker.airflow-5.svc.cluster.local
  [2022-10-10 00:02:57,038] {base_task_runner.py:98} INFO - Subtask: [2022-10-10 00:02:57,038] {cli.py:374} INFO - Running on host airflow-worker-6.airflow-worker.airflow-5.svc.cluster.local
  [2022-10-10 00:02:57,097] {base_task_runner.py:98} INFO - Subtask: [2022-10-10 00:02:57,097] {crux_python.py:216} INFO - Successfully retrieved latest version_id None from ingestion_id None
  [2022-10-10 00:02:57,097] {base_task_runner.py:98} INFO - Subtask: [2022-10-10 00:02:57,097] {crux_python.py:1186} INFO - Mandatory Cassandra caching is on!
  [2022-10-10 00:02:57,111] {base_task_runner.py:98} INFO - Subtask: [2022-10-10 00:02:57,110] {crux_python.py:230} INFO - Creating path structure if needed /tmp/cruxh9f7irib/fda_recalls_market_withdrawals_and_safety_alerts_ongoing/root/uber_extract/ingestion_id={ingestion_id}/version_id={version_id}/data
  [2022-10-10 00:02:57,139] {base_task_runner.py:98} INFO - Subtask: [2022-10-10 00:02:57,139] {crux_python.py:1237} INFO - File Seek Status enabled: None
  [2022-10-10 00:02:57,139] {base_task_runner.py:98} INFO - Subtask: [2022-10-10 00:02:57,139] {crux_python.py:266} INFO -
  [2022-10-10 00:02:54,519] {models.py:1197} INFO - Dependencies all met for <TaskInstance: fda_recalls_market_withdrawals_and_safety_alerts_ongoing.uber_extract 2022-10-09 00:00:00 [queued]>
  [2022-10-10 00:02:54,530] {models.py:1197} INFO - Dependencies all met for <TaskInstance: fda_recalls_market_withdrawals_and_safety_alerts_ongoing.uber_extract 2022-10-09 00:00:00 [queued]>
  [2022-10-10 00:02:54,530] {models.py:1407} INFO -
  --------------------------------------------------------------------------------
  Starting attempt 2 of 2
  --------------------------------------------------------------------------------

  [2022-10-10 00:02:54,548] {models.py:1428} INFO - Executing <Task(CruxShortCircuitUberOperator): uber_extract> on 2022-10-09 00:00:00
  [2022-10-10 00:02:54,549] {base_task_runner.py:115} INFO - Running: ['bash', '-c', 'airflow run fda_recalls_market_withdrawals_and_safety_alerts_ongoing uber_extract 2022-10-09T00:00:00 --job_id 30721834 --raw -sd DAGS_FOLDER/fda/fda_recalls_market_withdrawals_and_safety_alerts/recalls_market_withdrawals_and_safety_alerts/fda_recalls_market_withdrawals_and_safety_alerts_ongoing_dag.py']
  [2022-10-10 00:02:56,879] {base_task_runner.py:98} INFO - Subtask: [2022-10-10 00:02:56,879] {configuration.py:206} WARNING - section/key [celery/celery_ssl_active] not found in config
  [2022-10-10 00:02:56,880] {base_task_runner.py:98} INFO - Subtask: [2022-10-10 00:02:56,879] {default_celery.py:41} WARNING - Celery Executor will run without SSL
  [2022-10-10 00:02:56,899] {base_task_runner.py:98} INFO - Subtask: [2022-10-10 00:02:56,898] {__init__.py:45} INFO - Using executor fce.FastCeleryExecutor
  [2022-10-10 00:02:56,947] {base_task_runner.py:98} INFO - Subtask: [2022-10-10 00:02:56,946] {models.py:189} INFO - Filling up the DagBag from /var/lib/airflow/gcs-sync/code/pipeline/airflow/sharded_dags/airflow_5/fda/fda_recalls_market_withdrawals_and_safety_alerts/recalls_market_withdrawals_and_safety_alerts/fda_recalls_market_withdrawals_and_safety_alerts_ongoing_dag.py
  [2022-10-10 00:02:57,038] {cli.py:374} INFO - Running on host airflow-worker-6.airflow-worker.airflow-5.svc.cluster.local
  [2022-10-10 00:02:57,038] {base_task_runner.py:98} INFO - Subtask: [2022-10-10 00:02:57,038] {cli.py:374} INFO - Running on host airflow-worker-6.airflow-worker.airflow-5.svc.cluster.local
  [2022-10-10 00:02:57,097] {base_task_runner.py:98} INFO - Subtask: [2022-10-10 00:02:57,097] {crux_python.py:216} INFO - Successfully retrieved latest version_id None from ingestion_id None
  [2022-10-10 00:02:57,097] {base_task_runner.py:98} INFO - Subtask: [2022-10-10 00:02:57,097] {crux_python.py:1186} INFO - Mandatory Cassandra caching is on!
  [2022-10-10 00:02:57,111] {base_task_runner.py:98} INFO - Subtask: [2022-10-10 00:02:57,110] {crux_python.py:230} INFO - Creating path structure if needed /tmp/cruxh9f7irib/fda_recalls_market_withdrawals_and_safety_alerts_ongoing/root/uber_extract/ingestion_id={ingestion_id}/version_id={version_id}/data
  [2022-10-10 00:02:57,139] {base_task_runner.py:98} INFO - Subtask: [2022-10-10 00:02:57,139] {crux_python.py:1237} INFO - File Seek Status enabled: None
  [2022-10-10 00:02:57,139] {base_task_runner.py:98} INFO - Subtask: [2022-10-10 00:02:57,139] {crux_python.py:266} INFO -
`;

export const MOCK_FRAME_HEALTH_DATA = {
  id: '1',
  name: 'crux_integrate_app_for_dev_testing_fastertest_v8',
  ranAt: '2022-03-29T16:00:00',
  rawOutput: MOCK_FRAME_HEALTH_DATA_RAW,
  steps: {
    deliver: {
      changes: [],
      message: 'Step was skipped',
      ranAt: '2022-03-29T16:00:00',
      resolutionStatus: '',
      status: 'skipped',
    },
    ingest: {
      changes: [
        {
          action: 'was removed',
          field: 'date_created',
        },
        {
          action: 'changed from',
          field: 'test_description',
          newValue: 'varchar(90)',
          oldValue: 'varchar(120)',
        },
      ],
      message: 'Failed to process data; Failed to Verify data; Failed to deliver data',
      ranAt: '2022-03-29T16:00:00',
      resolutionStatus: '',
      status: 'failure',
    },
    normalize: {
      changes: [],
      message: '',
      ranAt: '2022-03-29T16:00:00',
      resolutionStatus: '',
      status: 'success',
    },
    process: {
      changes: [
        {
          action: 'was removed',
          field: 'date_created',
        },
        {
          action: 'changed from',
          field: 'test_description',
          newValue: 'varchar(90)',
          oldValue: 'varchar(120)',
        },
      ],
      message: 'Failed to process data; Failed to Verify data; Failed to deliver data',
      ranAt: '2022-03-29T16:00:00',
      resolutionStatus: '',
      status: 'failure',
    },
  },
};

export const DATASETS_V2 = [
  {
    canOverride: true,
    cron: '0 0 14 * * *',
    frames: [
      {
        datastoreId: 3000,
        deliverRaw: true,
        id: '3000',
        name: 'ssy_0217_6_dssnpuc3_products_becrs_trucostiddaily_trucostiddailychange_001',
        pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange%Y%m%d%H%M%S-001.zip',
        regex:
          'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d-001.zip',
        status: 'pending',
        steps: PROFILING_LOADER_STEPS,
        totalFiles: 1,
        totalSizeOfFiles: 1801,
        versions: [
          {
            id: '3000',
            name: 'Version 1',
            pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange%Y%m%d%H%M%S-001.zip',
            regex:
              'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d-001.zip',
          },
        ],
      },
    ],
    id: 'Ds9-X-jcSK4n',
    inOverrideMode: false,
    name: 'ssy_0217_6_dssnpuc3_products_becrs_trucostiddaily-ds1',
  },
  {
    canOverride: true,
    cron: '"0 0 22 * * 7',
    frames: [
      {
        datastoreId: 3001,
        id: '3001',
        name: 'ssy_0217_6_dssnpuc3_products_becrs_trucostiddailyfull',
        pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull%Y%m%d%H%M%S.zip',
        regex: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d.zip',
        status: 'pending',
        steps: PROFILING_LOADER_STEPS,
        totalFiles: 1,
        totalSizeOfFiles: 1801,
        versions: [
          {
            id: '3001',
            name: 'Version 1',
            pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull%Y%m%d%H%M%S.zip',
            regex:
              'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d.zip',
          },
        ],
      },
    ],
    id: 'Ds9-X-jcSK4o',
    inOverrideMode: false,
    name: 'ssy_0217_6_dssnpuc3_products_becrs_trucostiddaily-ds2',
  },
];

export const DATASETS_V2_FRAMES = flatten(map(DATASETS_V2, 'frames'));

export const DATASETS_V2_WITH_PIPELINES = [
  {
    cron: '0 0 14 * * *',
    frames: [
      {
        datastoreId: 3000,
        groupId: 'ssy_0217_6_dssnpuc3_products_becrs_trucostiddaily_trucostiddailychange_001',
        id: 'ssy_0217_6_dssnpuc3_products_becrs_trucostiddaily_trucostiddailychange_001',
        name: 'ssy_0217_6_dssnpuc3_products_becrs_trucostiddaily_trucostiddailychange_001',
        pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange%Y%m%d%H%M%S-001.zip',
        regex:
          'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d-001.zip',
        schema: [],
        status: 'pending',
        steps: PROFILING_LOADER_STEPS,
        totalFiles: 1,
        totalSizeOfFiles: 1801,
        versions: [
          {
            datastoreId: 3000,
            groupId: 'ssy_0217_6_dssnpuc3_products_becrs_trucostiddaily_trucostiddailychange_001',
            id: 'ssy_0217_6_dssnpuc3_products_becrs_trucostiddaily_trucostiddailychange_001_s2',
            name: 'ssy_0217_6_dssnpuc3_products_becrs_trucostiddaily_trucostiddailychange_001',
            pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange%Y%m%d%H%M%S-001.zip',
            regex:
              'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d-001.zip',
            schema: [],
            status: 'pending',
            steps: PROFILING_LOADER_STEPS,
          },
        ],
      },
    ],
    id: 'Ds9-X-jcSK4n',
    name: 'ssy_0217_6_dssnpuc3_products_becrs_trucostiddaily-ds1',
  },
  {
    cron: '"0 0 22 * * 7',
    frames: [
      {
        datastoreId: 3001,
        groupId: 'ssy_0217_6_dssnpuc3_products_becrs_trucostiddailyfull',
        id: 'ssy_0217_6_dssnpuc3_products_becrs_trucostiddailyfull',
        name: 'ssy_0217_6_dssnpuc3_products_becrs_trucostiddailyfull',
        pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull%Y%m%d%H%M%S.zip',
        regex: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d.zip',
        schema: [],
        status: 'pending',
        steps: PROFILING_LOADER_STEPS,
        totalFiles: 1,
        totalSizeOfFiles: 1801,
        versions: [
          {
            datastoreId: 3001,
            groupId: 'ssy_0217_6_dssnpuc3_products_becrs_trucostiddailyfull',
            id: '3001',
            name: 'ssy_0217_6_dssnpuc3_products_becrs_trucostiddailyfull',
            pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull%Y%m%d%H%M%S.zip',
            regex:
              'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d.zip',
            schema: [],
            status: 'pending',
            steps: PROFILING_LOADER_STEPS,
          },
        ],
      },
    ],
    id: 'Ds9-X-jcSK4o',
    name: 'ssy_0217_6_dssnpuc3_products_becrs_trucostiddaily-ds2',
  },
];

export const FLATTENED_FRAMES = [
  {
    datastoreId: 3000,
    deliverRaw: true,
    id: '3000',
    name: 'ssy_0217_6_dssnpuc3_products_becrs_trucostiddaily_trucostiddailychange_001',
    pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange%Y%m%d%H%M%S-001.zip',
    regex: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d-001.zip',
    status: 'pending',
    steps: PROFILING_LOADER_STEPS,
    totalFiles: 1,
    totalSizeOfFiles: 1801,
    versions: [
      {
        id: '3000',
        name: 'Version 1',
        pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange%Y%m%d%H%M%S-001.zip',
        regex:
          'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyChange\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d-001.zip',
      },
    ],
  },
  {
    datastoreId: 3001,
    id: '3001',
    name: 'ssy_0217_6_dssnpuc3_products_becrs_trucostiddailyfull',
    pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull%Y%m%d%H%M%S.zip',
    regex: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d.zip',
    status: 'pending',
    steps: PROFILING_LOADER_STEPS,
    totalFiles: 1,
    totalSizeOfFiles: 1801,
    versions: [
      {
        id: '3001',
        name: 'Version 1',
        pattern: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull%Y%m%d%H%M%S.zip',
        regex: 'Products/BECRS_TrucostIDDaily/BECRS_TrucostIDDailyFull\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d\\d.zip',
      },
    ],
  },
];

export const DATASET_STATUS = {
  test: {
    status: 'pending',
    steps: {
      delivery: {},
      deployment: {},
      pipeline: {},
      precheck: {},
    },
  },
};

export const DATASET_ACTIVATION_PENDING = {
  status: 'pending',
};

export const DATASET_ACTIVATION_FAILED = {
  status: 'failed',
};

export const DATASET_ACTIVATION_RUNNING = {
  status: 'running',
};

export const DATASET_ACTIVATION_DONE = {
  status: 'done',
};

export const DATASET_TEST_FAILED = {
  status: 'failed',
  steps: {
    delivery: {},
    deployment: {
      cruxErrorCode: null,
      endDate: '2023-08-21T09:04:38.897048',
      info: null,
      startDate: '2023-08-21T09:03:34.212475',
      status: 'done',
    },
    pipeline: {
      cruxErrorCode: null,
      endDate: '2023-08-21T09:04:44.415276',
      info: ['UNCLASSIFIED_EXCEPTION'],
      startDate: '2023-08-21T09:04:38.969768',
      status: 'failed',
    },
    precheck: {
      cruxErrorCode: null,
      endDate: '2023-08-21T09:02:32.227169',
      info: null,
      startDate: null,
      status: 'done',
    },
  },
};

export const DATASET_TEST_RUNNING = {
  status: 'running',
  steps: {
    delivery: {},
    deployment: {
      crux_error_code: null,
      end_date: '2023-08-17T17:55:43.697116',
      info: null,
      start_date: '2023-08-17T17:55:20.605294',
      status: 'done',
    },
    pipeline: {
      crux_error_code: null,
      end_date: null,
      info: null,
      start_date: '2023-08-17T17:55:43.752292',
      status: 'running',
    },
    precheck: {
      crux_error_code: null,
      end_date: '2023-08-17T17:54:57.994966',
      info: null,
      start_date: null,
      status: 'done',
    },
  },
};

export const DATASET_TEST_DONE = {
  status: 'done',
  steps: {
    delivery: {
      cruxErrorCode: null,
      endDate: '2023-08-21T14:52:49.733802',
      info: null,
      startDate: '2023-08-21T14:52:41.031310',
      status: 'done',
    },
    deployment: {
      cruxErrorCode: null,
      endDate: '2023-08-21T14:52:12.259710',
      info: null,
      startDate: '2023-08-21T14:52:04.403611',
      status: 'done',
    },
    pipeline: {
      cruxErrorCode: null,
      endDate: '2023-08-21T14:52:38.247782',
      info: null,
      startDate: '2023-08-21T14:52:12.318612',
      status: 'done',
    },
    precheck: {
      cruxErrorCode: null,
      endDate: '2023-08-21T14:45:08.943930',
      info: null,
      startDate: null,
      status: 'done',
    },
  },
};

export const DATA_PRODUCT_GROUPED_ERRORS = {
  delivery: [
    {
      datasetId: 'ric-flair-dataset-3',
      id: 'ric-flair-dataset-3-delivery',
      messages: ['Could not process files'],
      step: 'delivery',
    },
  ],
  precheck: [
    {
      datasetId: 'ric-flair-dataset-1',
      id: 'ric-flair-dataset-1-precheck',
      messages: ['No match found', 'Could not download files'],
      step: 'precheck',
    },
    {
      datasetId: 'ric-flair-dataset-2',
      id: 'ric-flair-dataset-2-precheck',
      messages: ['No match found'],
      step: 'precheck',
    },
  ],
};

export const DESTINATION_META = {
  dataset_test_id_3: {
    AQZXIspSjfjjE9TLoJbWB0dCGg: {
      cduId: 'Cx39184',
      datasetId: 'AQL8a1Ciur5KvFZHBVe2TtuJaw',
      destinationMetadata: {
        datasetName: 'dataframe_cx39184_Fr8a1Ciub48a13',
        fqTableName:
          'dataframe_cx39184_Fr8a1Ciub48a13.CRUX_DATA_EMPORIUM_CMITCHELL_20240110_1_INTEG_4126_FASTTEST_AE2A_CSV_0DC9__AQL8a1Ciur5KvFZHBVe2TtuJaw',
        tableName:
          'CRUX_DATA_EMPORIUM_CMITCHELL_20240110_1_INTEG_4126_FASTTEST_AE2A_CSV_0DC9__AQL8a1Ciur5KvFZHBVe2TtuJaw',
        type: 'BIGQUERY',
      },
      frameId: 'crux_data_emporium_cmitchell_20240110_1_integ_4126_fasttest_ae2a_csv_0dc9',
      message: 'VALIDATED',
      subscriptionName: 'Some destination name',
    },
    AQbYT9qlPRpWn4hmTGBvWnSJaw: {
      cduId: null,
      datasetId: null,
      destinationMetadata: null,
      frameId: null,
      message: 'INVALID_SUBSCRIPTION',
      subscriptionName: 'Another destination name',
    },
    'AQbuqSx-YAiEppN6ZJQqVMoGnA': {
      cduId: 'Cx39184',
      datasetId: 'AQL8a1Ciur5KvFZHBVe2TtuJaw',
      destinationMetadata: {
        datasetName: 'dataframe_cx39184_Fr8a1Ciub48a13',
        fqTableName:
          'dataframe_cx39184_Fr8a1Ciub48a13.CRUX_DATA_EMPORIUM_CMITCHELL_20240110_1_INTEG_4126_FASTTEST_AE2A_CSV_0DC9__AQL8a1Ciur5KvFZHBVe2TtuJaw',
        tableName:
          'CRUX_DATA_EMPORIUM_CMITCHELL_20240110_1_INTEG_4126_FASTTEST_AE2A_CSV_0DC9__AQL8a1Ciur5KvFZHBVe2TtuJaw',
        type: 'BIGQUERY',
      },
      frameId: 'crux_data_emporium_cmitchell_20240110_1_integ_4126_fasttest_ae2a_csv_0dc9',
      message: 'VALIDATED',
      subscriptionName: 'Some other destination name',
    },
  },
};

export const DATASET_FRAMES = [
  {
    averageFileSize: 1500,
    id: 'dataset_id_1',
    name: 'dataset_name_1',
    pattern: 'file_pattern',
    remotePath: 'path/to/something',
    totalFiles: 4,
  },
  {
    averageFileSize: 2000,
    id: 'dataset_test_id_2',
    name: 'different_name',
    pattern: 'file_pattern_2',
    remotePath: 'path/to/something_2',
  },
  {
    id: 'dataset_test_id_3',
    name: 'another_name',
    pattern: 'file_pattern_3',
    remotePath: 'path/to/something_3',
  },
];

export const DATASET_HISTORY = [
  {
    datasetId: 'Ds60gdLM',
    deliveryId: 'b1b566bef3d5c8e26172ab278ac4974b73d118975733608fbb552fefa7ab0bd5.0',
    rowsDelivered: 1830,
    status: 'SUCCESS',
    timestamp: '2023-07-31T16:14:00.000+00:00',
    totalFileSize: 290977,
  },
  {
    datasetId: 'Ds60gdLM',
    deliveryId: 'b1b566bef3d5c8e26172ab278ac4974b73d118975733608fbb552fefa7ab0bd6.0',
    rowsDelivered: 1000,
    status: 'ERROR',
    timestamp: '2023-06-31T16:14:00.000+00:00',
    totalFileSize: 250000,
  },
];

export const BASIC_CRON = '1 2 3 4 5 6';
export const DATASET_DOCUMENTS = [
  {
    active: true,
    id: 'Dc9bc4427f42fa',
    mediaType: 'text/csv',
    modifiedAt: '2023-08-15T10:27:40.781Z',
    name: 'devNote.sv',
    size: 154,
    storageId: 'a9c779b1c59b5874d182ccf4f25afc54',
    tags: [],
    type: 'DEV_NOTES',
  },
  {
    active: true,
    id: 'Dc9bc1234a54vq',
    mediaType: 'text/csv',
    modifiedAt: '2023-08-15T10:27:40.781Z',
    name: 'document.csv',
    size: 50000,
    storageId: 'a9c779b1c59b5874d182ccf4f25afc54',
    tags: [],
  },
  {
    active: true,
    id: 'Dc1ab4321g89as',
    mediaType: 'text/csv',
    modifiedAt: '2023-08-15T10:27:40.781Z',
    name: 'supplierDoc.csv',
    size: 123456,
    storageId: 'a9c779b1c59b5874d182ccf4f25afc54',
    tags: [],
    type: 'SUPPLIER',
  },
];

export const DATASET_SCHEDULES = {
  inferredSchedule: {
    confidence: 90,
    id: 'bananaman',
    inferredSchedule: '0 0 4 ? * MON-SAT',
    scheduleInferredFirst99: '2022-04-21T14:54:18Z',
    scheduleInferredLast99: '2022-06-12T12:32:59Z',
    timezone: 'UTC',
  },
  scheduleConfigured: {
    schedule: '0 0 4 ? * MON-SAT',
    timezone: 'UTC',
  },
  scheduleDeclared: {
    schedule: '0 0 5 ? * MON-FRI',
    timezone: 'UTC',
  },
};

export const DATA_PRODUCT_RAW_CSV_STRING = `name,productFamily,orgCode,ownerIdentityId,sourceType,marketVertical,dataCategory,assetClass,region,mutualizable,display,description,common,sensitive,country,jiraKey,productStatus,containsCusipSedol,discoverListing
fileName,Family,OrzCI7fO,OrgvyxbY,Vendor,FINANCIAL,FUNDS,Funds,Asia,TRUE,TRUE,description,FALSE,FALSE,Australia,DOS-123,Done,FALSE,listing`;

export const DATA_PRODUCT_RAW_CSV = new File([DATA_PRODUCT_RAW_CSV_STRING], 'mock.csv', {
  type: 'text/csv',
});

export const DATA_PRODUCT_PARSED_CSV = [
  {
    assetClass: 'Funds',
    common: false,
    containsCusipSedol: false,
    country: 'Australia',
    dataCategory: 'FUNDS',
    description: 'description',
    discoverListing: 'listing',
    display: true,
    jiraKey: 'DOS-123',
    marketVertical: 'FINANCIAL',
    mutualizable: true,
    name: 'fileName',
    orgCode: 'OrzCI7fO',
    ownerIdentityId: 'OrgvyxbY',
    productFamily: 'Family',
    productStatus: 'Done',
    region: 'Asia',
    sensitive: false,
    sourceType: 'Vendor',
  },
];

export const DATA_PRODUCT_PARSED_CSV_KEYS = [
  'assetClass',
  'common',
  'containsCusipSedol',
  'country',
  'dataCategory',
  'description',
  'discoverListing',
  'display',
  'jiraKey',
  'marketVertical',
  'mutualizable',
  'name',
  'orgCode',
  'ownerIdentityId',
  'productFamily',
  'productStatus',
  'region',
  'sensitive',
  'sourceType',
];
