import { cloneDeep, reject } from 'lodash-es';

import {
  ADD_TOAST_NOTIFICATION,
  CLEAR_NOTIFICATION_CHANNELS,
  REMOVE_TOAST_NOTIFICATION,
  REMOVE_CHIRP_ICON,
  REMOVE_CHIRP_NOTIFICATION,
  SET_CHIRP_ICON,
  SET_CHIRP_NOTIFICATION,
  SET_NOTIFICATION_CHANNELS,
} from './mutation-types';

export default {
  [ADD_TOAST_NOTIFICATION](state, toast) {
    state.toastNotifications = state.toastNotifications.concat([toast]);
  },

  [CLEAR_NOTIFICATION_CHANNELS](state) {
    state.notificationChannels = [];
  },

  [REMOVE_CHIRP_ICON](state) {
    state.chirpIcon = null;
  },

  [REMOVE_CHIRP_NOTIFICATION](state) {
    state.chirpNotification = null;
  },

  [REMOVE_TOAST_NOTIFICATION](state, { id }) {
    state.toastNotifications = reject(state.toastNotifications, { id });
  },

  [SET_CHIRP_ICON](state, icon) {
    state.chirpIcon = icon;
  },

  [SET_CHIRP_NOTIFICATION](state, message) {
    state.chirpNotification = message;
  },

  [SET_NOTIFICATION_CHANNELS](state, notificationChannels) {
    state.notificationChannels = cloneDeep(notificationChannels);
  },
};
