<template>
  <li
    ref="option"
    :key="option.value"
    v-hubble="'wrapper'"
    :data-name="option.label"
    :data-value="option.value"
    class="flex focus:outline-none items-center justify-between p-2.5 select-none text-sm"
    :class="[{ 'bg-blue-50': isHighlighted }, disabledClasses]"
    role="option"
    :aria-selected="ariaSelected"
    :tabindex="-1"
    @click="handleOptionClick"
  >
    <slot>
      <cx-checkbox v-if="hasCheckbox" :model-value="isSelected">
        {{ option.label }}
      </cx-checkbox>
      <span v-else class="block flex-1 font-normal truncate">{{ option.label }}</span>
    </slot>
    <cx-icon v-if="isSelected && !hasCheckbox" name="check-fill" class="flex-none text-blue-600" size="2xl" />
  </li>
</template>

<script>
import { CxIcon, CxCheckbox } from '../../..';

export default {
  name: 'CxSelectOption',

  hubble: 'cx-select-option',

  components: {
    CxCheckbox,
    CxIcon,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    hasCheckbox: {
      type: Boolean,
      default: false,
    },

    highlightedItem: {
      type: Number,
      default: 0,
    },

    isSelected: {
      type: Boolean,
      default: false,
    },

    option: {
      type: Object,
      required: true,
    },

    searchHasFocus: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ariaSelected() {
      if (this.disabled) return false;

      return this.isSelected ? 'true' : 'false';
    },

    disabledClasses() {
      return this.disabled
        ? 'bg-gray-50 cursor-not-allowed text-gray-400'
        : 'cursor-pointer hover:bg-gray-100 text-gray-500';
    },

    isHighlighted() {
      return !this.searchHasFocus && this.option.order === this.highlightedItem;
    },
  },

  watch: {
    isHighlighted(newVal) {
      if (newVal === true) {
        this.$refs.option.focus();
      }
    },
  },

  methods: {
    handleOptionClick() {
      !this.disabled && this.$emit('select', this.option);
    },
  },
};
</script>
