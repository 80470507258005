import { MonitoringService } from '~/support/services';

export default {
  async fetchUsageStats(_, payload) {
    const { body, category } = payload;

    const service = new MonitoringService(useRuntimeConfig().public.monitoringBaseUrl);
    const data = await service.fetchUsageStats(body, category);

    return data;
  },
};
