import ApiBase from './ApiBase';

export default class UsersService extends ApiBase {
  createUser({ email, name, roles }) {
    return this.client.post('/', { email, name, roles });
  }

  deleteUser(userId) {
    return this.client.delete(`/${userId}`);
  }

  fetchApiKey() {
    return this.client.get(`/me/api-key`);
  }

  fetchUsers() {
    return this.client.get('/');
  }

  forgotPassword(email) {
    return this.client.post('/forgot-password', { email });
  }

  regenerateApiKey() {
    return this.client.put(`/me/api-key`);
  }

  registerUser({ password, token }) {
    return this.client.put('/register', { password, verificationToken: token });
  }

  resendInvite(userId) {
    return this.client.patch(`/${userId}/invitation`);
  }

  resetPassword({ password, token }) {
    return this.client.post('/reset-password', { password, recoveryToken: token });
  }

  acceptAgreement({ userId }) {
    return this.client.post('/agreements', { userId });
  }

  updateUser({ userId, payload }) {
    return this.client.put(`/${userId}`, payload);
  }
}
